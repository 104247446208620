import React, { useEffect, useState } from "react";
// library
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// components
import InputFieldOnly from "components/common/input/InputFieldOnly";
// context
import { useEditContext } from "context/EditContext";
// query
import { useRoomDetail, useUpdateRoom } from "query/rooms";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { usePiggeryDetail } from "query/piggery";
import { getRedStar } from "utils/getImportantStar";

type Inputs = {
  name: string;
  area: number | string;
  in_age: number | string;
  in_weight: number | string;
  out_age: number | string;
  out_weight: number | string;
};

type Props = {
  roomId: number;
  selectedPiggery: number;
};

function RoomDetail(props: Props) {
  const { t } = useTranslation();
  const { roomId, selectedPiggery } = props;

  const [overlayPoints, setOverlayPoints] = useState("");
  const editContext = useEditContext();

  const imgWidth = document.getElementById("piggery-image")?.clientWidth;
  const imgHeight = document.getElementById("piggery-image")?.clientHeight;

  const { data: roomData } = useRoomDetail(roomId);
  const { data: piggeryData } = usePiggeryDetail(selectedPiggery);
  const { mutateAsync: updateRoom } = useUpdateRoom();

  const initialState = {
    name: roomData?.name,
    area: roomData?.area === null ? "" : roomData?.area,
    in_age: roomData?.in_age === null ? "" : roomData?.in_age,
    in_weight: roomData?.in_weight === null ? "" : roomData?.in_weight,
    out_age: roomData?.out_age === null ? "" : roomData?.out_age,
    out_weight: roomData?.out_weight === null ? "" : roomData?.out_weight,
  };

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  const currentobj = {
    name: watch("name"),
    area: watch("area"),
    in_age: watch("in_age"),
    in_weight: watch("in_weight"),
    out_age: watch("out_age"),
    out_weight: watch("out_weight"),
  };

  useEffect(() => {
    if (JSON.stringify(initialState) === JSON.stringify(currentobj)) {
      editContext?.setEditState(false);
    } else editContext?.setEditState(true);
  }, [currentobj, initialState]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await updateRoom({
        id: roomId,
        payload: {
          name: data.name,
          area: data.area === "" ? null : Number(data.area),
          in_age: data.in_age === "" ? null : Number(data.in_age),
          in_weight: data.in_weight === "" ? null : Number(data.in_weight),
          out_age: data.out_age === "" ? null : Number(data.out_age),
          out_weight: data.out_weight === "" ? null : Number(data.out_weight),
        },
      });
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error?.response?.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (typeof roomData !== "undefined") {
      reset({
        name: roomData.name,
        area: roomData.area,
        in_age: roomData.in_age ?? "",
        in_weight: roomData.in_weight ?? "",
        out_age: roomData.out_age ?? "",
        out_weight: roomData.out_weight ?? "",
      });
      if (imgHeight && imgWidth && roomData.overlay) {
        setOverlayPoints(roomData.overlay?.map((o: { x: number; y: number }) => `${o.x * imgWidth},${o.y * imgHeight}`).join(" "));
      }
    }
    reset();
  }, [roomData, reset, imgWidth, imgHeight]);

  return typeof roomData !== "undefined" ? (
    <div className="w-full h-fit bg-ef-neutral-white py-40pxr px-50pxr border border-solid border-ef-neutral-150 rounded-[8px]">
      <p className="text-center ef-title-lg text-ef-neutral-900">{`${roomData.name} ${t("common.details")}`}</p>
      <form onSubmit={handleSubmit(onSubmit)} className="outline-none">
        <div className="mt-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
          <div className="flex flex-row items-center">
            <label title={t("common.piggery_name")} className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr break-all">
              {t("common.piggery_name")}
            </label>
            <div className="w-512pxr">
              <InputFieldOnly state={piggeryData?.name} setState={() => {}} disabled />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] mr-12pxr flex flex-row">
              <span title={t("common.room_name")} className="line-clamp-1 break-all">
                {t("common.room_name")}
              </span>
              {getRedStar()}
            </label>
            <div className="w-512pxr relative" onClick={() => clearErrors("name")}>
              {errors.name ? <RequiredAlert /> : <></>}
              <Controller
                name="name"
                control={control}
                rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr">{t("common.area")}</label>
            <div className="flex flex-row items-center">
              <div className="w-284pxr mr-4pxr">
                <Controller
                  name="area"
                  control={control}
                  // regex - 정수+소숫점 셋째자리까지 허용
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.area")} type="number" regex={/^(?:[1-9]\d*|0)?(?:\.\d{1,3})?$/} />}
                />
              </div>
              <span className="ef-body-lg">㎡</span>
            </div>
          </div>
          {/* divider */}
          <div className="w-full h-1pxr bg-ef-neutral-150"></div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr">{t("settings.move_in_age")}</label>
            <div className="flex flex-row items-center">
              <div className="w-170pxr mr-4pxr">
                <Controller
                  name="in_age"
                  control={control}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.day_age")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                />
              </div>
              <span className="ef-body-lg">{t("settings.days")}</span>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr">{t("settings.move_in_weight")}</label>
            <div className="flex flex-row items-center">
              <div className="w-170pxr mr-4pxr">
                <Controller
                  name="in_weight"
                  control={control}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.weight")} type="number" />}
                />
              </div>
              <span className="ef-body-lg">kg</span>
            </div>
          </div>
          {/* divider */}
          <div className="w-full h-1pxr bg-ef-neutral-150"></div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr">{t("settings.move_out_age")}</label>
            <div className="flex flex-row items-center">
              <div className="w-170pxr mr-4pxr">
                <Controller
                  name="out_age"
                  control={control}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.day_age")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                />
              </div>
              <span className="ef-body-lg">{t("settings.days")}</span>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-98pxr min-w-[98px] line-clamp-1 mr-12pxr">{t("settings.move_out_weight")}</label>
            <div className="flex flex-row items-center">
              <div className="w-170pxr mr-4pxr">
                <Controller
                  name="out_weight"
                  control={control}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.weight")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                />
              </div>
              <span className="ef-body-lg">kg</span>
            </div>
          </div>
          {/* divider */}
          <div className="w-full h-1pxr bg-ef-neutral-150"></div>
          <div className="flex flex-row">
            <label title={t("settings.room_location")} className="w-98pxr min-w-[98px] overflow-hidden whitespace-nowrap text-ellipsis mr-12pxr mt-10pxr">
              {t("settings.room_location")}
            </label>
            <div className="w-512pxr p-16pxr rounded-[8px] border border-solid border-ef-neutral-150 flex flex-col">
              <div id="piggery-image" className="w-480pxr h-270pxr relative">
                <img className="w-full h-full rounded-[8px]" src={`${piggeryData?.image_path}`} alt="" />
                <svg className="absolute top-0pxr left-0pxr w-full h-full">
                  <polygon points={overlayPoints} className="fill-ef-state-red-500 stroke-ef-state-red-500 stroke-[4px]" style={{ fillOpacity: 0.5 }} />
                </svg>
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mt-60pxr mb-64pxr mx-auto">
            {t("common.save")}
          </button>
        </div>
      </form>
    </div>
  ) : (
    <></>
  );
}

export default RoomDetail;
