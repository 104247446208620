import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import "./css/table.css";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import jspreadsheet from "jspreadsheet-ce";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingStandard } from "query/farm/type";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  standardList: SettingStandard;
  propsName: string;
};

type SpreadsheetData = (string | number)[][];

// 양식 다운로드 > 미리보기
function PreviewModal(props: Props) {
  const { isToggle, setIsToggle, propsName } = props;
  const { t } = useTranslation();

  // *** table 별 ref와 instance를 각각 관리해주어야 함 *** //

  // 일령별 데이터 sheet Ref
  const spreadsheetRef = useRef<HTMLDivElement | null>(null);
  const [spreadsheetInstance, setSpreadsheetInstance] = useState<any>(null);
  // 등급 기준 설정 sheet Ref
  const weightGradeRef = useRef<HTMLDivElement | null>(null);
  const [weightGradeInstance, setWeightGradeInstance] = useState<any>(null);

  // 일령별 데이터를 담는 arr
  const [data, setData] = useState<SpreadsheetData>([]);
  // 등급 기준 설정 데이터를 담는 arr
  const [weightGradeData, setWeightGradeData] = useState<SpreadsheetData>([]);

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
  };

  // static한 excel파일 가져오는 핸들러
  const loadExcelData = async (filePath: string) => {
    try {
      const response = await fetch(filePath);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }) as SpreadsheetData;

      const weekData = worksheet.slice(1, 33);

      const gradeData = worksheet.slice(36, 40).map((row: any[]) => {
        // 하한값과 상한값에 100을 곱하고 %를 붙여줌
        const lowerBound = typeof row[2] === "number" ? `${(row[2] * 100).toFixed(0)}%` : row[2];
        const upperBound = typeof row[3] === "number" ? `${(row[3] * 100).toFixed(0)}%` : row[3];
        return [row[0], [], lowerBound, upperBound]; // 변환된 값을 반환
      });
      setData(weekData);
      setWeightGradeData(gradeData);
    } catch (error) {
      toast.error(t("settings.load_file_error"));
      console.error("Error loading Excel file:", error);
    }
  };

  // 모달 열렸을 때 excel 파일로부터 데이터를 가져오는 effect
  useEffect(() => {
    const initializeSpreadsheet = () => {
      if (spreadsheetRef.current && data.length > 0) {
        const instance = jspreadsheet(spreadsheetRef.current, {
          data: data,
          minDimensions: [10, 10],
          maxDimensions: [34, 10],
          allowInsertRow: false,
          allowInsertColumn: false,
          readOnly: true,
          mergeCells: {
            A1: [2, 1],
            C1: [2, 1],
            E1: [2, 1],
            G1: [2, 1],
            I1: [2, 1],
          },
        });
        setSpreadsheetInstance(instance);
      }
    };

    const initializeWeightGradeSpreadsheet = () => {
      if (weightGradeRef.current && weightGradeData.length > 0) {
        const weightInstance = jspreadsheet(weightGradeRef.current, {
          data: weightGradeData,
          allowInsertRow: false,
          allowInsertColumn: false,
          mergeCells: {
            A1: [2, 1],
            A2: [2, 1],
            A3: [2, 1],
            A4: [2, 1],
          },
          columns: [
            { type: "text", title: "", width: 63, readOnly: true },
            { type: "text", title: "", width: 63, readOnly: true },
            { type: "text", title: "", width: 80, readOnly: true },
            { type: "text", title: "", width: 80, readOnly: true },
          ],
          readOnly: true,
        });
        setWeightGradeInstance(weightInstance);
      }
    };

    initializeSpreadsheet();
    initializeWeightGradeSpreadsheet();

    return () => {
      spreadsheetInstance?.destroy();
      weightGradeInstance?.destroy();
    };
  }, [data, weightGradeData]);

  // @todo 저성장기/고성장기 일 때의 템플릿이 생기면 대응해야 함
  useEffect(() => {
    loadExcelData("/templates/standard_data.xlsx");
  }, [isToggle]);

  // *** JSpread에서 tailwindCSS로 css 접근이 안 됨
  // 등급 기준 설정 테이블 헤더 css용 이펙트
  useEffect(() => {
    if (weightGradeInstance) {
      setTimeout(() => {
        const table = weightGradeRef?.current?.querySelector("table");
        if (table) {
          const cellA1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(1)");
          const cellA2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(2)");

          const cellB1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(2)");
          const cellB2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(3)");

          const cellC1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(3)");
          const cellC2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(4)");
          const cellC3 = table.querySelector("tbody tr:nth-child(2) td:nth-child(5)");

          if (cellA1) cellA1.classList.add("cell-a1");
          if (cellB1) cellB1.classList.add("cell-b1");
          if (cellC1) cellC1.classList.add("cell-c1");
          if (cellA2) cellA2.classList.add("cell-a2");
          if (cellB2) cellB2.classList.add("cell-b2");
          if (cellC2) cellC2.classList.add("cell-c2");
          if (cellC3) cellC3.classList.add("cell-c3");
        }
      }, 100);
    }
  }, [weightGradeInstance]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr text-ef-neutral-900">
        <div className="w-full relative">
          <p className="ef-title-lg text-center">{t("settings.standard_data_by_age_details")}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 absolute right-0pxr top-0pxr">
            <GeneralClose />
          </button>
        </div>
        <form className="mt-40pxr flex flex-col max-h-[1682px] overflow-y-auto">
          <div className="flex flex-col gap-24pxr ef-label-md">
            <div className="flex flex-row items-center">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("settings.title")}</span>
                {getRedStar()}
              </label>
              <div className="w-514pxr">
                <InputFieldOnly state={propsName} setState={() => {}} disabled />
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row mt-10pxr">
                <span className="line-clamp-1 break-all">{t("settings.standard_data_by_age")}</span>
                {getRedStar()}
              </label>
              <div className="w-574pxr h-[1056px] max-h-[1056px] flex">
                <div id="spreadsheet" ref={spreadsheetRef}></div>
              </div>
            </div>
            <div className="flex flex-row mt-240pxr">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row mt-24pxr">
                <span className="line-clamp-1 break-all">{t("settings.grading_criteria_settings")}</span>
                {getRedStar()}
              </label>
              <div className="w-286pxr mt-24pxr">
                <div ref={weightGradeRef}></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PreviewModal;
