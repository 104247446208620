import React, { useState } from "react";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import { useTranslation } from "react-i18next";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import DeviceDetail from "components/pages/2_manage/device/efcr/DeviceDetail";
import CountCameraDetail from "components/pages/2_manage/device/efcr/CountCameraDetail";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { useCountList } from "query/efcr";
import { format } from "date-fns";
import { CameraForCount } from "query/efcr/type";
import noData from "assets/images/report/no_report_data.png";
import { datePickerLocale } from "utils/useDatepicker";
import { usePiggeries } from "query/piggery";
import { useEfcList } from "query/efc";
import NoDataImg from "assets/images/report/no_report_data.png";

function Efcr() {
  const [deviceStatus, setDeviceStatus] = useState<number[]>([]);
  const [selectedPiggery, setSelectedPiggery] = useState<number[]>([]);
  const [toggledIdx, setToggledIdx] = useState(-1);
  const [countDetailToggle, setCountDetailToggle] = useState(false);
  const [deviceState, setDeviceState] = useState({
    id: 0,
    type: "",
  });
  const [cameraDetailToggle, setCameraDetailToggle] = useState(false);
  const [camState, setCamState] = useState({
    id: 0,
    type: "",
  });
  const { t } = useTranslation();

  const { data: piggeryList } = usePiggeries();
  // efc,efcr을 한가지인것처럼 동시에 표출
  const {
    data: countList,
    isLoading: isCRLoading,
    isFetched: isCRLoaded,
  } = useCountList(
    selectedPiggery,
    deviceStatus.map((o) => o === 1),
  );
  const {
    data: efcList,
    isLoading: isCountLoading,
    isFetched: isCountLoaded,
  } = useEfcList(
    selectedPiggery,
    deviceStatus.map((o) => o === 1),
  );

  const formattedEfcrList = countList
    ? countList.map((o) => {
        return {
          type: "efcr",
          data: o,
        };
      })
    : [];
  const formattedEfcList = efcList
    ? efcList.map((o) => {
        return {
          type: "efc",
          data: o,
        };
      })
    : [];
  const deviceList = [...formattedEfcrList, ...formattedEfcList];

  // 리셋버튼용 함수
  const handleReset = () => {
    setDeviceStatus([]);
    setSelectedPiggery([]);
  };

  // 장치 추가 버튼용 함수
  const handleAdd = () => {
    if (window.confirm(t("manage.add_device_comment"))) {
      window.open("https://intflow.ai/contact/", "_blank");
    } else return;
  };

  // 카운트를 묶고있는 토글에서(엣지박스) 하나라도 connected false가 있으면 false로 보여주기 위한 함수
  const getCountStatus = (cameras: CameraForCount[]) => {
    return cameras.some((o) => o.connected === false);
  };

  return (
    <div className="w-full h-full bg-ef-neutral-50">
      <DeviceDetail isToggle={countDetailToggle} setIsToggle={setCountDetailToggle} device={deviceState} />
      <CountCameraDetail isToggle={cameraDetailToggle} setIsToggle={setCameraDetailToggle} device={camState} />
      <div className="w-[948px] min-w-[948px] mx-auto h-fit flex flex-col pt-60pxr pb-200pxr">
        {/* title */}
        <div className="flex flex-row items-center">
          <span className="ef-headline-lg text-ef-neutral-900 mr-4pxr">EFC {t("manage.list")}</span>
          <span className="ef-headline-lg text-ef-neutral-400 mr-16pxr">{`(${deviceList.length})`}</span>
          <button onClick={handleAdd} className="ef-btn-r8-sm flex flex-row flex-wrap gap-8pxr">
            <Add />
            <span>{t("manage.add_device")}</span>
          </button>
          <span className="ml-auto ef-body-sm text-ef-neutral-500">{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm", { locale: datePickerLocale() })}`}</span>
        </div>
        <div className="mt-40pxr flex flex-row gap-24pxr items-center">
          <div className="flex flex-row gap-8pxr">
            <DropDownCheckBox
              title={t("manage.device_status")}
              state={deviceStatus}
              setState={setDeviceStatus}
              options={[
                { name: t("common.normal"), value: 1 },
                { name: t("common.abnormal"), value: 2 },
              ]}
            />
            <DropDownCheckBox
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { name: o.name, value: o.id };
                    })
                  : []
              }
            />
          </div>
          <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr">
            <Refresh />
            {t("common.reset")}
          </button>
        </div>
        {/* divider */}
        <div className="w-full h-1pxr bg-ef-neutral-200 my-32pxr"></div>
        {/* body */}
        {deviceList.length === 0 && isCRLoaded && isCountLoaded ? (
          <div className="w-full mt-120pxr flex flex-col flex-wrap gap-8pxr">
            <div className="w-310pxr h-220pxr mx-auto">
              <img src={noData} alt="noData" />
            </div>
            <span className="text-ef-neutral-600 mx-auto ef-body-xxl">{t("manage.there_is_no_device_registered")}</span>
          </div>
        ) : (
          <div className="w-full flex flex-col flex-wrap gap-16pxr">
            {deviceList.map((o, idx) => (
              <button
                key={o.data.id}
                onClick={() => {
                  if (toggledIdx === idx) setToggledIdx(-1);
                  else setToggledIdx(idx);
                }}
                className={
                  "w-full flex flex-col flex-wrap gap-8pxr rounded-[8px] bg-ef-neutral-white border border-solid " +
                  (toggledIdx === idx
                    ? "border-ef-primary-500 ef-main-shadow "
                    : "border-ef-neutral-150 hover:ef-main-shadow hover:border-ef-primary-500 active:bg-ef-neutral-50 active:border-ef-neutral-150 active:shadow-none focus:outline-none focus:border-ef-neutral-900")
                }
              >
                <div className="flex flex-row text-left">
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.device_name")}</span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeviceState({
                          id: o.data.id,
                          type: o.type,
                        });
                        setCountDetailToggle(true);
                      }}
                      className="ef-label-lg w-full break-all line-clamp-1 underline underline-offset-4"
                    >
                      {o.data.name}
                    </span>
                  </div>
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.installed_location")}</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1">{o.data.piggery ? o.data.piggery.name : ""}</span>
                  </div>
                  <div className="w-176pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("common.device_connection_status")}</span>
                    <div className="flex flex-row items-center">
                      <div className={"w-6pxr h-6pxr rounded-full mr-4pxr " + (getCountStatus(o.data.cameras) ? "bg-ef-state-red-500" : "bg-ef-state-green-500")}></div>
                      <span className="ef-label-lg w-full break-all line-clamp-1">{getCountStatus(o.data.cameras) ? t("common.abnormal") : t("common.normal")}</span>
                    </div>
                  </div>
                  <div className="w-226pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">{t("manage.serial_number")}</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1 select-text cursor-text">{o.data.serial_number}</span>
                  </div>
                  <div className="w-126pxr flex flex-col flex-wrap gap-4pxr py-26pxr pl-24pxr pr-16pxr">
                    <span className="ef-label-sm text-ef-primary-500 line-clamp-1 w-full break-all">ID</span>
                    <span className="ef-label-lg w-full break-all line-clamp-1 select-text cursor-text">{o.data.id}</span>
                  </div>
                  <div className="my-auto mr-24pxr ml-auto stroke-ef-neutral-700">{toggledIdx === idx ? <ArrowUp /> : <ArrowDown />}</div>
                </div>
                {toggledIdx === idx ? (
                  <div className="px-24pxr pb-24pxr">
                    <table className="ef-table-lg w-full">
                      <thead>
                        <tr>
                          <th className="w-225pxr max-w-[225px]">{t("manage.camera_name")}</th>
                          <th className="w-225pxr max-w-[225px]">{t("manage.detection_location")}</th>
                          <th className="w-225pxr max-w-[225px]">{t("common.device_connection_status")}</th>
                          <th className="w-225pxr max-w-[225px]">ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {o.data.cameras.map((c) => (
                          <tr key={c.id}>
                            <td>
                              <div className="flex flex-row items-center py-8pxr gap-16pxr">
                                <div className="rounded-[4px] w-87pxr h-56pxr relative">
                                  <img
                                    src={c.image_path}
                                    onError={(e) => (e.currentTarget.hidden = true)}
                                    alt="image_path"
                                    className="w-full h-full rounded-[4px] absolute top-0pxr left-0pxr z-[10]"
                                  />
                                  <div className="w-full h-full bg-ef-neutral-white absolute top-0pxr left-0pxr rounded-[4px] border border-solid border-ef-neutral-150 flex items-center justify-center">
                                    <img src={NoDataImg} alt="no-img" className="w-46pxr h-32pxr" />
                                  </div>
                                </div>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCamState({
                                      id: c.id,
                                      type: o.type,
                                    });
                                    setCameraDetailToggle(true);
                                  }}
                                  className="ef-label-sm underline underline-offset-2 w-171pxr text-left max-w-[171px] overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                  {c.name}
                                </span>
                              </div>
                            </td>
                            <td className="w-225pxr max-w-[225px]">{c.room?.name ? c.room?.name : ""}</td>
                            <td>
                              {c.connected === true ? (
                                <div className="flex flex-row items-center justify-center flex-wrap gap-2pxr">
                                  <div className="w-6pxr h-6pxr rounded-full bg-ef-state-green-500"></div>
                                  <span>{t("common.normal")}</span>
                                </div>
                              ) : (
                                <div className="flex flex-row items-center justify-center flex-wrap gap-2pxr">
                                  <div className="w-6pxr h-6pxr rounded-full bg-ef-state-red-500"></div>
                                  <span>{t("common.abnormal")}</span>
                                </div>
                              )}
                            </td>
                            <td className="select-text cursor-text">{c.id}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Efcr;
