import React, { useRef } from "react";
import { useEffect, useState } from "react";
// library
import i18n from "i18n";
// svg
import KorFlag from "assets/flags/ef-asia-kr.svg";
import JpnFlag from "assets/flags/ef-asia-jp.svg";
import UsaFlag from "assets/flags/ef-america-us.svg";
import EspFlag from "assets/flags/ef-europe-es.svg";
import { ReactComponent as KorFlagComp } from "assets/flags/ef-asia-kr.svg";
import { ReactComponent as JpnFlagComp } from "assets/flags/ef-asia-jp.svg";
import { ReactComponent as UsaFlagComp } from "assets/flags/ef-america-us.svg";
import { ReactComponent as EspFlagComp } from "assets/flags/ef-europe-es.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";

type Props = {
  type: "main" | "login";
};

function LanguageSelector(props: Props) {
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const divRef = useRef<HTMLDivElement>(null);
  const { type } = props;

  useEffect(() => {
    i18n.changeLanguage(lang);
    setIsLangOpen(false);
  }, [lang]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setIsLangOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="flex flex-col" ref={divRef}>
      <button
        onClick={() => setIsLangOpen((prev) => !prev)}
        className={
          "group rounded-[8px] border border-solid hover:ef-main-shadow " +
          (isLangOpen
            ? type === "login"
              ? "border-ef-neutral-900"
              : "border-ef-neutral-900 bg-ef-neutral-white text-ef-neutral-900"
            : type === "login"
              ? "border-ef-neutral-900 bg-ef-neutral-50 hover:border-ef-primary-500 active:border-ef-primary-500 text-ef-neutral-900"
              : "bg-ef-neutral-white border-ef-neutral-900 hover:bg-ef-neutral-50 active:border-ef-primary-900 active:bg-ef-neutral-white text-ef-neutral-900 hover:text-ef-neutral-900 active:text-ef-neutral-900")
        }
      >
        <div className="flex flex-row mr-8pxr ml-16pxr py-8pxr items-center justify-center">
          {lang === "ko" ? (
            <>
              <div className="w-24pxr h-24pxr mr-8pxr flex items-center justify-center">
                <object aria-labelledby="korflag" data={KorFlag} className="pointer-events-none" />
              </div>
              <span className="ef-label-lg mr-16pxr">KOR</span>
            </>
          ) : lang === "en" ? (
            <>
              <div className="w-24pxr h-24pxr mr-8pxr flex items-center justify-center">
                <object aria-labelledby="usaflag" data={UsaFlag} className="pointer-events-none" />
              </div>
              <span className="ef-label-lg mr-16pxr">ENG</span>
            </>
          ) : lang === "ja" ? (
            <>
              <div className="w-24pxr h-24pxr mr-8pxr flex items-center justify-center">
                <object aria-labelledby="jpnflag" data={JpnFlag} className="pointer-events-none" />
              </div>
              <span className="ef-label-lg mr-16pxr">JPN</span>
            </>
          ) : (
            //  : lang === "es" ? (
            //   <>
            //     <div className="w-24pxr h-24pxr mr-8pxr flex items-center justify-center">
            //       <object aria-labelledby="espflag" data={EspFlag} className="pointer-events-none" />
            //     </div>
            //     <span className="ef-label-lg mr-16pxr">ESP</span>
            //   </>
            // )
            ""
          )}
          {isLangOpen ? <ArrowUp className="stroke-ef-neutral-900" /> : <ArrowDown className="stroke-ef-neutral-900 " />}
        </div>
      </button>
      {isLangOpen && (
        <div
          className={
            "top-72pxr absolute flex flex-col w-127pxr h-fit text-ef-neutral-900 border border-solid rounded-[8px] z-[1500] py-8pxr ef-main-shadow " +
            (type === "login" ? "bg-ef-neutral-50 border-ef-neutral-150 " : "bg-ef-neutral-white border-none")
          }
        >
          <div
            className={"flex flex-row items-center py-8pxr cursor-pointer " + (type === "login" ? "hover:bg-ef-neutral-150" : "hover:bg-ef-primary-50 hover:text-ef-primary-500")}
            onClick={() => setLang("en")}
          >
            <div className="w-24pxr h-24pxr ml-16pxr flex items-center justify-center">
              <UsaFlagComp />
            </div>
            <span className="ef-label-lg ml-8pxr">ENG</span>
          </div>
          <div
            className={"flex flex-row items-center py-8pxr cursor-pointer " + (type === "login" ? "hover:bg-ef-neutral-150" : "hover:bg-ef-primary-50 hover:text-ef-primary-500")}
            onClick={() => setLang("ko")}
          >
            <div className="w-24pxr h-24pxr ml-16pxr flex items-center justify-center">
              <KorFlagComp />
            </div>
            <span className="ef-label-lg ml-8pxr">KOR</span>
          </div>
          <div
            className={"flex flex-row items-center py-8pxr cursor-pointer " + (type === "login" ? "hover:bg-ef-neutral-150" : "hover:bg-ef-primary-50 hover:text-ef-primary-500")}
            onClick={() => setLang("ja")}
          >
            <div className="w-24pxr h-24pxr ml-16pxr flex items-center justify-center">
              <JpnFlagComp />
            </div>
            <span className="ef-label-lg ml-8pxr">JPN</span>
          </div>
          {/* <div
            className={"flex flex-row items-center py-8pxr cursor-pointer " + (type === "login" ? "hover:bg-ef-neutral-150" : "hover:bg-ef-primary-50 hover:text-ef-primary-500")}
            onClick={() => setLang("es")}
          >
            <div className="w-24pxr h-24pxr ml-16pxr flex items-center justify-center">
              <EspFlagComp />
            </div>
            <span className="ef-label-lg ml-8pxr">ESP</span>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
