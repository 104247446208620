import { useAuthState } from "context/AuthContext";
import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NoticePopup, NoticeSingle, Release } from "./type";

// get notice list
export const useNoticeList = (page: number) => {
  const query = useQuery(["noticeList", page], () => client.get<NoticeSingle[]>("/notices", { params: { page } }).then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};

// get pinned notice list
export const usePinnedNotice = () => {
  const query = useQuery(["pinnedNotice"], () => client.get<NoticeSingle[]>("/notices/pin").then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};

//  공지사항 총 페이지
export const useTotalNoticePage = () => {
  const query = useQuery(["totalNoticePage"], () => client.get<number>("/notice/pages").then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};

// 배포버전 공지 페이지 번호 조회
export const useReleasePageNum = (noticeId: number) => {
  const query = useQuery(["releasePageNum", noticeId], () => client.get<{ page_number: number; notice_id: number }>(`/notice/${noticeId}/page-number`).then((res) => res.data), {
    enabled: noticeId !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 팝업용 공지사항
export const useNoticePopup = () => {
  const query = useQuery(["noticePopup"], () => client.get<NoticePopup>("/notice/popup").then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};

// 푸터용 버전 + 공지사항 id
export const useReleaseVersion = () => {
  const query = useQuery(["releaseVersion"], () => client.get<Release>("/notice/release-version").then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};
