import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import { ReactComponent as Search } from "assets/icons/general/ef-general-search.svg";
import { useTranslation } from "react-i18next";
import { useRoomDropdownList } from "query/rooms";
import { differenceInDays } from "date-fns";
import { RoomDropdown } from "query/rooms/type";
import _ from "lodash";
import { useAuthState } from "context/AuthContext";

type Props = {
  title: string;
  disabled?: boolean;
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  distinct?: boolean | null;
  roomState?: any;
  setRoomState?: React.Dispatch<React.SetStateAction<any>>;
};

function AddGroupDropDown(props: Props) {
  const { title, state, setState, roomState, setRoomState, distinct, disabled = false } = props;
  console.log(state);
  const { current_farm } = useAuthState();
  const openRef = useRef<HTMLDivElement>(null);
  const [openState, setOpenState] = useState(false);
  const [piggeryOption, setPiggeryOption] = useState<Array<RoomDropdown>>([]);
  const [titleState, setTitleState] = useState(title);
  const [searchState, setSearchState] = useState("");
  const categories = [1, 2];
  const { data: roomList } = useRoomDropdownList(roomState?.piggery?.id, categories);
  // const { data: roomList } = useRoomList(undefined, true, true);
  const { t } = useTranslation();

  useEffect(() => {
    if (roomList) {
      setPiggeryOption(roomList);
    }
  }, [roomList]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (openRef.current && !openRef.current.contains(e.target as Node)) {
        setOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openRef]);

  useEffect(() => {
    setSearchState("");
  }, [openState]);

  useEffect(() => {
    if (state?.piggery_id === 0 && state?.room_id === 0) setTitleState(title);
  }, [state]);

  const debouncedHandleChange = _.debounce((value) => {
    setSearchState(value.toLowerCase().trim());
  }, 300);

  useEffect(() => {
    if (distinct === null) {
      setState(state);
    }
  }, [distinct, state]);
  return (
    <div className="realtive ef-body-lg text-ef-neutral-900" ref={openRef}>
      <button
        type="button"
        disabled={disabled}
        onClick={() => setOpenState((prev) => !prev)}
        className={
          "group px-16pxr py-8pxr w-340pxr h-40pxr flex flex-row rounded-[8px] border border-solid " +
          (openState
            ? "bg-ef-primary-50 border-ef-primary-500"
            : "border-ef-neutral-150 hover:border-ef-primary-500 hover:ef-main-shadow active:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:hover:shadow-none disabled:text-ef-neutral-500 bg-ef-neutral-white")
        }
      >
        <span className={"overflow-hidden text-ellipsis whitespace-nowrap mr-8pxr break-all " + (titleState === title ? "text-ef-neutral-300" : "")}>{titleState}</span>
        {openState ? <ArrowUp className="stroke-ef-neutral-700 ml-auto min-w-[24px]" /> : <ArrowDown className="stroke-ef-neutral-700 ml-auto group-disabled:stroke-ef-neutral-500 min-w-[24px]" />}
      </button>
      {openState ? (
        <div className="z-[1500] absolute mt-4pxr w-607pxr h-fit rounded-[8px] bg-ef-neutral-white border border-solid border-ef-neutral-150 p-16pxr ef-main-shadow flex flex-col flex-wrap gap-8pxr">
          <div className="flex flex-row py-8pxr px-16pxr bg-ef-neutral-white border border-solid border-ef-neutral-150 rounded-[8px]">
            <input
              type="text"
              onChange={(e) => debouncedHandleChange(e.target.value)}
              className="grow mr-8pxr ef-body-lg placeholder:text-ef-neutral-300 text-ef-neutral-900 focus:outline-none"
              placeholder={t("manage.select_place_comment")}
            />
            <Search className="stroke-ef-neutral-700" />
          </div>
          <table className="ef-table-sm block w-full">
            <thead>
              <tr>
                <th className="w-93pxr max-w-[93px]">{t("common.piggery_name")}</th>
                <th className="w-147pxr max-w-[147px]">{t("common.room_name")}</th>
                <th className="w-149pxr max-w-[149px]">{t("common.herd_name")}</th>
                <th className="w-93pxr max-w-[93px]">{t("common.day_age")}</th>
                <th className="w-93pxr max-w-[93px]">{t("common.heads")}</th>
              </tr>
            </thead>
            <tbody className="cursor-pointer block max-h-[320px] overflow-y-auto scrollbar-hide rounded-[8px]">
              {(distinct === null
                ? piggeryOption.filter((n) => !n.active_herd)
                : distinct === true
                  ? piggeryOption.filter((p) => p.category.id !== 2)
                  : piggeryOption.filter((p) => p.category.id === 2)
              )
                // .filter((j) => j.category?.id === 1)
                .filter((i) => i?.name.toLowerCase().includes(searchState) || i.name.toLowerCase().includes(searchState))
                .map((o) => (
                  <tr
                    key={o.id}
                    className="hover:!bg-ef-primary-50"
                    onClick={() => {
                      setTitleState(`[${o?.piggery.name}] ${o.name}`);
                      setState(o.id);
                      // setState({ piggery_id: o?.id, room_id: o.id });
                      setRoomState?.(o);
                      setOpenState(false);
                    }}
                  >
                    <td className="w-93pxr max-w-[93px]">{o?.piggery.name}</td>
                    <td className="w-147pxr max-w-[147px]">{o.name}</td>
                    <td className="w-149pxr max-w-[149px]">{o.active_herd?.name}</td>
                    <td className="w-93pxr max-w-[93px]">
                      {o.active_herd?.birth_date && o.active_herd?.birth_date ? `${differenceInDays(new Date(), new Date(o.active_herd?.birth_date))}${t("common.aged")}` : "-"}
                    </td>

                    <td className="w-93pxr max-w-[93px]">{o ? `${o.stock}${t("common.head")}` : "-"}</td>
                  </tr>
                ))}
              {piggeryOption.every((o) => !o?.name.toLowerCase().includes(searchState) && !o.name.toLowerCase().includes(searchState)) && (
                <tr key="no-results">
                  <td className="!h-320pxr w-575pxr">{t("common.no_result_found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddGroupDropDown;
