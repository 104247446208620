import React from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  distinct: string;
  historyInfo: any;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function ExpandImgModal(props: Props) {
  const { isToggle, setIsToggle, historyInfo, distinct } = props;
  const { t } = useTranslation();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
  };

  console.log(historyInfo);

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-fit h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          {/* header */}
          <div className="flex relative w-full">
            <span className="flex mx-auto text-ef-neutral-900 ef-title-lg">
              {historyInfo?.name} {t("common.details")}
            </span>
            <button className="absolute right-0pxr" onClick={() => setIsToggle(false)}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <img src={historyInfo?.history[0]?.image_path} className="w-full h-full mt-24pxr" alt="thumbnail" />
        </div>
      </Modal>
    </>
  );
}

export default ExpandImgModal;
