import ErrorPage from "components/pages/0_common/ErrorPage";
import NavigationBar from "components/pages/0_qr/navigation_bar";
import QrScan from "components/pages/0_qr/qr_scan";
import StockStatus from "components/pages/0_qr/stock_status";
import { useRoomDropdownListQR } from "query/rooms";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';


function QrStock() {
  const { state } = useLocation();
  const piggeryId = state?.piggeryId;
  const [activeTab, setActiveTab] = useState<'scan' | 'status'>('status');
  const { data: roomList } = useRoomDropdownListQR(Number(piggeryId), [1]);

  // 안전영역 높이 계산
  useEffect(() => {
    const updateSafeArea = () => {
      const safeBottom = Math.max(
        Number(getComputedStyle(document.documentElement)
          .getPropertyValue('--sab')
          .slice(0, -2)) || 0,
        Number(getComputedStyle(document.documentElement)
          .getPropertyValue('env(safe-area-inset-bottom)')
          .slice(0, -2)) || 0
      );
      // 실제 viewport 높이 설정
      const vh = window.innerHeight - safeBottom;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // 초기 계산
    updateSafeArea();

    // 방향 전환시 재계산
    window.addEventListener('resize', updateSafeArea);
    window.addEventListener('orientationchange', updateSafeArea);
    return () => {
      window.removeEventListener('resize', updateSafeArea);
      window.removeEventListener('orientationchange', updateSafeArea);
    };
  }, []);
  if (!piggeryId) {
    return <ErrorPage />;
  }
  return (
    <div
      className="w-screen overflow-x-hidden"
      style={{ height: 'var(--vh)' }}
    >
      <div className="w-full min-w-[360px] md:max-w-[360px] h-full mx-auto flex flex-col">
        {activeTab === 'scan' ? (
          <QrScan onClose={() => setActiveTab('status')} />
        ) : (
          <StockStatus />
        )}
        <NavigationBar activeTab={activeTab} onTabChange={setActiveTab} />
      </div>
    </div>
  );
}

export default QrStock;