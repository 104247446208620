import React, { useEffect, useState } from "react";
// library
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// components
import InputFieldOnly from "components/common/input/InputFieldOnly";
// query
import { useTimeZoneList } from "query/common";
// assets
import { ReactComponent as Modify } from "assets/icons/general/ef-general-modify.svg";
import { ReactComponent as Remove } from "assets/icons/general/ef-general-remove.svg";
import NoImage2 from "assets/images/select/Farm Select_02.png";
// context
import { useAuthState } from "context/AuthContext";
import { useEditContext } from "context/EditContext";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { useUpdateFarm, useUpdateFarmPhoto } from "query/farm";
import { getRedStar } from "utils/getImportantStar";

type Inputs = {
  name: string;
  address: string | null;
  address_detail: string | null;
  contact_name: string | null;
  contact_phone: string | null;
  contact_email: string | null;
};

function Farm() {
  const { t } = useTranslation();

  const { current_farm } = useAuthState();
  const editContext = useEditContext();

  // api 제출용 formdata State
  const [imgState, setImgState] = useState<FormData | null>(null);
  // 이미지 변화시 노출용 string state
  const [thumbnailState, setThumbnailState] = useState<string | null>(null);

  const initialState = {
    name: current_farm.name,
    address: current_farm.address,
    address_detail: current_farm.address_detail,
    contact_name: current_farm.contact_name,
    contact_phone: current_farm.contact_phone,
    contact_email: current_farm.contact_email,
  };

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({ defaultValues: initialState });

  const contact_phone = watch("contact_phone");
  const { mutateAsync: updateFarm } = useUpdateFarm();
  const { mutateAsync: updateFarmPhoto } = useUpdateFarmPhoto();

  // 폼 제출시 data put
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formData = {
      name: data.name,
      contact_name: data.contact_name && data.contact_name.trim() === "" ? null : data.contact_name,
      contact_phone: data.contact_phone === "" ? null : data.contact_phone,
      contact_email: data.contact_email === "" ? null : data.contact_email,
    };
    // 이미지가 변경되었으면 별도로 api 한번 더 전송
    try {
      if (imgState) await updateFarmPhoto(imgState);
      await updateFarm(formData);
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  // 이미지 업로드용 함수
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files ? e.target.files[0] : null;
    const formData = new FormData();
    if (uploadedFile) {
      formData.append("farm_image_file", uploadedFile);
      setImgState(formData);
      setThumbnailState(URL.createObjectURL(uploadedFile));
    } else {
      formData.append("farm_image_file", "");
      setImgState(formData);
      setThumbnailState(null);
    }
  };

  // 리셋용
  useEffect(() => {
    const { name, address, contact_name, contact_phone, contact_email, address_detail } = current_farm;
    reset({
      name,
      address,
      address_detail,
      contact_name,
      contact_phone,
      contact_email,
    });
    if (current_farm.image_path) {
      setThumbnailState(current_farm.image_path);
    } else setThumbnailState(null);
  }, [current_farm, reset]);

  // 내용 수정시 edit context에 반영
  useEffect(() => {
    if (current_farm) {
      if (isDirty) {
        editContext?.setEditState(true);
      } else {
        if (current_farm.image_path === null) {
          if (current_farm.image_path !== thumbnailState) {
            editContext?.setEditState(true);
          }
        } else if (!current_farm.image_path) {
          if (`${process.env.REACT_APP_BASE_URL}/${current_farm.image_path}` !== thumbnailState) {
            editContext?.setEditState(true);
          }
        } else editContext?.setEditState(false);
      }
    }
  }, [isDirty, thumbnailState, editContext, current_farm]);

  // 전화번호에 슬래시 자동입력
  useEffect(() => {
    if (contact_phone) {
      const numericPhone = contact_phone.replace(/\D/g, "");

      if (numericPhone.length === 10) {
        setValue("contact_phone", numericPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      } else if (numericPhone.length === 11) {
        setValue("contact_phone", numericPhone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      }
    }
  }, [contact_phone]);

  return (
    <div className="bg-ef-neutral-50 w-full h-full">
      <div className="w-[948px] mx-auto pt-60pxr pb-200pxr">
        <p className="ef-headline-lg text-ef-neutral-900 mb-40pxr">{t("gnb.basic_information_settings")}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col w-948pxr flex-wrap gap-24pxr ef-body-xl text-ef-neutral-900">
            {/* <div className="flex flex-row">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all mt-8pxr">{t("settings.image")}</label>
              <div className="w-fit flex flex-col flex-wrap gap-8pxr">
                <div className={"w-246pxr h-246pxr rounded-[8px] border border-solid overflow-hidden bg-ef-neutral-white " + (thumbnailState ? "border-transparent" : "border-ef-neutral-150")}>
                  <img src={thumbnailState ? thumbnailState : ""} alt="farm-img" className="w-full h-full object-cover" onError={(e) => (e.currentTarget.src = NoImage2)} />
                </div>
                {thumbnailState ? (
                  <div className="flex flex-row flex-wrap gap-6pxr">
                    <label htmlFor="upload_img" className="cursor-pointer">
                      <div className="ef-btn-r8-sm flex flex-row h-40pxr min-w-[119px]">
                        <Modify className="mr-8pxr" />
                        <span className="line-clamp-1 mx-auto">{t("common.edit_btn")}</span>
                      </div>
                    </label>
                    <input id="upload_img" type="file" accept="image/*" className="hidden" onChange={(e) => handleFileUpload(e)} />
                    <div
                      className="ef-btn-r8-sm flex flex-row h-40pxr cursor-pointer min-w-[119px]"
                      onClick={() => {
                        const formData = new FormData();
                        formData.append("farm_image_file", "");
                        setImgState(formData);
                        setThumbnailState(null);
                      }}
                    >
                      <Remove className="mr-8pxr" />
                      <span className="line-clamp-1">{t("common.delete_btn")}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <label htmlFor="upload_img" className="cursor-pointer">
                      <div className="ef-btn-r8-sm flex flex-row h-40pxr justify-center">
                        <Modify className="mr-8pxr" />
                        <span>{t("settings.add_photo")}</span>
                      </div>
                    </label>
                    <input id="upload_img" type="file" accept="image/*" className="hidden" onChange={(e) => handleFileUpload(e)} />
                  </>
                )}
              </div>
            </div> */}
            <div className="flex flex-row ef-body-xl text-ef-neutral-900 items-center">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all">
                {t("settings.farm_name")}
                {getRedStar()}
              </label>
              <div className="w-821pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            {/* <div className="flex flex-row items-center">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all">{t("settings.register_number")}</label>
              <div className="w-821pxr">
                <Controller name="livestock_industry_id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />} />
              </div>
            </div> */}
            <div className="flex flex-row">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all mt-8pxr">{t("settings.farm_address")}</label>
              <div className="w-821pxr flex flex-col flex-wrap gap-8pxr">
                <Controller name="address" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
                <Controller name="address_detail" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            {/* <div className="flex flex-row items-center">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all">{t("settings.local_time")}</label>
              <div className="w-821pxr">
                <DropDownSmall
                  state={0}
                  setState={() => {}}
                  options={
                    timezoneList
                      ? timezoneList.map((o) => {
                          return { value: o.id, name: o.name };
                        })
                      : []
                  }
                />
              </div>
            </div> */}
            <div className="flex flex-row items-center">
              <label className="w-115pxr mr-12pxr line-clamp-1 break-all">{t("settings.contact_person")}</label>
              <div className="w-821pxr">
                <Controller name="contact_name" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("settings.contact_phone")} className="w-115pxr mr-12pxr line-clamp-1 break-all">
                {t("settings.contact_phone")}
              </label>
              <div className="w-821pxr">
                <Controller name="contact_phone" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("settings.contact_email")} className="w-115pxr mr-12pxr line-clamp-1 break-all">
                {t("settings.contact_email")}
              </label>
              <div className="w-821pxr">
                <Controller name="contact_email" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} type="email" />} />
              </div>
            </div>
            <button className="ef-btn-modal mx-auto" type="submit">
              {t("common.save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Farm;
