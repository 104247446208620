import axios from "axios";
import qs from "qs";

const URL = process.env.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL: URL,
});

client.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

client.defaults.headers.common["Authorization"] = `bearer ${localStorage.getItem("Authorization")}`;
/*
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
client.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);

/*
  2. 응답 인터셉터
  2개의 콜백 함수를 받습니다.
*/
client.interceptors.response.use(
  function (response) {
    /*
      http status가 200인 경우
      응답 성공 직전 호출됩니다. 
      .then() 으로 이어집니다.
  */
    return response;
  },

  function (error) {
    /*
      http status가 200이 아닌 경우
      응답 에러 직전 호출됩니다.
      .catch() 으로 이어집니다.    
  */
    // console.log("axios error boundary");
    return Promise.reject(error);
  },
);

export default client;
