import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { getRedStar } from "utils/getImportantStar";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { Controller, useForm } from "react-hook-form";
import { useEditReportCalibrate } from "query/movement";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  currentStock: number;
  movementId: number;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Inputs = {
  stock: number;
};

function StockCorrectionModal(props: Props) {
  const { isToggle, setIsToggle, currentStock, movementId } = props;
  const [stockState, setStockState] = useState(0);

  const { t } = useTranslation();

  const { mutateAsync: editStock } = useEditReportCalibrate();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    if (movementId !== 0) {
      try {
        await editStock({
          id: movementId,
          payload: {
            calibrated_stock: data.stock,
          },
        });
        toast.success(t("common.save_completed"));
        onClose("closeButtonClick");
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setValue("stock", currentStock);
  }, [isToggle]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex  justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-580pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        <div className="w-full relative flex flex-col items-center">
          <p className="ef-title-lg text-center">{t("manage.moved_heads_correction")}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 right-0pxr top-0pxr absolute">
            <GeneralClose />
          </button>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div className="mt-60pxr flex flex-row items-center justify-center mx-auto gap-58pxr">
              <span className="ef-label-md">
                {t("common.heads")}
                {getRedStar()}
              </span>
              <div className="flex flex-row gap-4pxr items-center">
                <div onClick={() => clearErrors("stock")} className="w-98pxr relative">
                  {errors.stock && <RequiredAlert />}
                  <Controller
                    name="stock"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} textAlign="right" type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                  />
                </div>
                <span className="ef-label-md">{t("common.head")}</span>
              </div>
            </div>
            <button type="submit" className="ef-btn-modal mt-60pxr">
              {t("common.save")}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default StockCorrectionModal;
