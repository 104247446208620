import React, { useState } from "react";
import { useMonitoringList } from "query/monitoring";
import { useTranslation } from "react-i18next";
import MainStream from "components/pages/6_monitoring/modal/MainStream";

type Props = {
  selectedRoom: number;
};

function MonitoringStock(props: Props) {
  const { selectedRoom } = props;
  const { data: monitoringList } = useMonitoringList();
  const { t } = useTranslation();
  
  // MainStream 모달 상태 관리
  const [isStreamModalOpen, setIsStreamModalOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState<{
    streamUrl: string;
    roomName: string;
    piggeryName: string;
    cameraName: string;
  } | null>(null);

  // 선택된 방의 카메라 목록 필터링
  const selectedRoomCameras = monitoringList
    ?.find(room => room.id === selectedRoom)
    ?.efg_cameras.map(camera => ({
      ...camera,
      roomName: monitoringList.find(room => room.id === selectedRoom)?.name || "",
      piggeryName: monitoringList.find(room => room.id === selectedRoom)?.piggery?.name || ""
    })) || [];

  return (
    <div className="w-full h-full overflow-y-auto py-40pxr px-69pxr">
      <MainStream 
        isToggle={isStreamModalOpen}
        setIsToggle={setIsStreamModalOpen}
        streamUrl={selectedCamera?.streamUrl || ""}
        roomName={selectedCamera?.roomName || ""}
        piggeryName={selectedCamera?.piggeryName || ""}
        cameraName={selectedCamera?.cameraName || ""}
        hidePiggeryName={true}
      />
      <div className="grid grid-cols-3 gap-16pxr">
        {selectedRoomCameras.length > 0 ? (
          selectedRoomCameras.map((camera) => (
            <div 
              key={camera.id} 
              className="w-full h-full flex flex-col w-294pxr h-235pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white"
              onClick={() => {
                if (camera.connected) {
                  setSelectedCamera({
                    streamUrl: camera.stream_url,
                    roomName: camera.roomName,
                    piggeryName: camera.piggeryName,
                    cameraName: camera.name
                  });
                  setIsStreamModalOpen(true);
                }
              }}
              style={{ cursor: camera.connected ? 'pointer' : 'default' }}
            >
              <div className="h-179pxr">
                {camera.connected ? (
                  <iframe
                    src={camera.stream_url + "sub"}
                    className="w-full h-full object-fill pointer-events-none rounded-t-[8px]"
                    allowFullScreen
                    allow="camera; microphone; autoplay"
                    title={camera.name}
                  />
                ) : (
                  <div className="w-full h-full flex flex-col items-center justify-center bg-ef-neutral-50 rounded-t-[8px]">
                    <img 
                      src={require("assets/images/report/no_report_data.png")} 
                      className="w-140pxr h-99pxr" 
                      alt="no-data" 
                    />
                    <span className="mt-8pxr ef-body-lg text-ef-neutral-700">
                      {t("common.no_data")}
                    </span>
                  </div>
                )}
              </div>
              <div className="h-56pxr py-16pxr pl-24pxr ef-body-xl">
                {camera.name}
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-3 flex flex-col items-center justify-center pt-120pxr">
            <img 
              src={require("assets/images/report/no_report_data.png")} 
              className="w-310pxr h-220pxr" 
              alt="no-data" 
            />
            <span className="mt-8pxr ef-body-lg text-ef-neutral-700">
              {t("common.no_info")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MonitoringStock;
