// heatindex 관련 색깔 함수

export const getHeatIndexColorName = (categoryId: number | undefined | null) => {
  switch (categoryId) {
    case 1:
      return "ef-state-blue-500";
    case 2:
      return "ef-state-cyan-500";
    case 3:
      return "ef-state-green-500";
    case 4:
      return "ef-state-orange-500";
    case 5:
      return "ef-state-red-500";
    default:
      return "";
  }
};

export const getHeatIndexColorCode = (categoryId: number | undefined | null) => {
  switch (categoryId) {
    case 1:
      return "#1C7ED6";
    case 2:
      return "#1098AD";
    case 3:
      return "#37B24D";
    case 4:
      return "#F76707";
    case 5:
      return "#F03E3E";
    default:
      return "";
  }
};
