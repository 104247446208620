import client from "query/client";
import { useAuthState } from "context/AuthContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMemberInfo } from "query/member";
import { FarmUpdatePayload, StockDashBoard, PiggeryStructure, PiggerySummary, SettingStandard, StandardPayload, StandardType, ProductivityMetrics } from "./type";

// 농장 get(돈사까지)
export const usePiggerySummary = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["piggerySummary"], () => client.get<PiggerySummary>("/farms").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 농장 get(돈방까지)
export const usePiggeryRoomList = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["piggeryRoomList"], () => client.get<PiggeryStructure>("/farms/structure").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 농장 정보 수정 put
export const useUpdateFarm = () => {
  const { mutateAsync: init } = useMemberInfo();
  const mutation = useMutation("updateFarm", (payload: FarmUpdatePayload) => client.put("/farms", payload).then((res) => res.data), {
    onSuccess: async () => {
      await init();
    },
  });
  return mutation;
};

// 농장현황용 data get
export const useFarmDashboard = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["StockDashBoard"], () => client.get<StockDashBoard>("/farms/dashboard").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 농장 이미지 post
export const useUpdateFarmPhoto = () => {
  const { mutateAsync: init } = useMemberInfo();
  const mutation = useMutation("updateFarmPhoto", (payload: FormData) => client.post("/farms/image", payload, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => res.data), {
    onSuccess: async () => {
      await init();
    },
  });
  return mutation;
};

//기준데이터 설정 관련 ====================================================================================

// get standardList
export const useStandardList = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["standardList"], () => client.get<SettingStandard>("/farms/standard").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get standard detail
export const useStandardDetail = (standard_id: number) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["standardDetail", standard_id], () => client.get<StandardType>(`/farms/standard/${standard_id}`).then((res) => res.data), {
    enabled: current_farm.id !== 0 && standard_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// post standardList
export const useAddStandardList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addStandard", (payload: StandardPayload) => client.post("/farms/standard", payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["standardList"]);
    },
  });
  return mutation;
};

// copy standardList
export const useCopyStandardList = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("copyStandardList", (standard_id: number) => client.post(`/farms/standard/${standard_id}`).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["standardList"]);
    },
  });
  return mutation;
};

// delete standard
export const useDeleteStandard = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("deleteStandard", (standard_id: number) => client.delete(`/farms/standard/${standard_id}`).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["standardList"]);
    },
  });
  return mutation;
};

// put flag
export const useSettingFlag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("settingStandardFlag", (standard_id: number) => client.put(`/farms/standard/${standard_id}/flag`).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["standardList"]);
    },
  });
  return mutation;
};

// edit standard
export const useEditStandard = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editStandard", (obj: { standard_id: number; payload: StandardPayload }) => client.put(`/farms/standard/${obj.standard_id}`, obj.payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["standardList"]);
    },
  });
  return mutation;
};

// get gnb 생산성지표
export const useFarmProductivity = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["farmProductivity"], () => client.get<ProductivityMetrics>("/farms/productivity-metrics").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};
