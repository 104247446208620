import React, { createContext, useContext, useEffect, useState } from "react";

type EditContext = {
  editState: boolean;
  setEditState: React.Dispatch<React.SetStateAction<boolean>>;
};

const editContext = createContext<EditContext | null>(null);

export const EditProvider = ({ children }: { children: React.ReactNode }) => {
  const [editState, setEditState] = useState(false);

  return <editContext.Provider value={{ editState, setEditState }}>{children}</editContext.Provider>;
};

export const useEditContext = () => {
  return useContext(editContext);
};
