import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useGrowCamDetail } from "query/efg";
import NoImg from "assets/images/report/no_report_data.png";
import { useReportCamView } from "query/report";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  camId: number;
  token: string;
};

function CamDetailModal(props: Props) {
  const { isToggle, setIsToggle, camId, token } = props;

  const { data: camDetail } = useReportCamView(token ?? "", camId);

  const { t } = useTranslation();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center"
    >
      <div className="w-full max-w-[360px] h-full overflow-y-auto mx-auto bg-ef-neutral-white flex flex-col outline-none">
        <div className="sticky top-0pxr z-[5] bg-ef-neutral-white p-16pxr flex flex-row ef-title-md text-ef-neutral-900 border-b border-solid border-ef-neutral-150">
          <span className="break-all line-clamp-1">{camDetail ? `${camDetail.room.name} ${camDetail.name}` : `${t("manage.camera")} ${t("common.details")}`}</span>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 ml-auto">
            <GeneralClose />
          </button>
        </div>
        <div className="grid grid-cols-1 gap-16pxr pt-24pxr px-20pxr pb-180pxr">
          {camDetail ? (
            camDetail.time_images.map((o) => (
              <div key={o.created_at} className="w-full h-fit">
                <div className="w-full aspect-video rounded-t-[8px] relative">
                  <div className="w-full h-full bg-ef-neutral-50 absolute top-0pxr rounded-t-[8px] flex flex-col items-center justify-center">
                    <img src={NoImg} className="w-140pxr" alt="no-data" />
                    <span className="mt-10pxr ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
                  </div>
                  <img src={o.image_path} className="w-full rounded-t-[8px] relative top-0pxr" onError={(e) => (e.currentTarget.hidden = true)} alt={o.created_at} />
                </div>
                <div className="w-full border-b border-x border-solid border-ef-neutral-150 p-16pxr flex flex-row items-center rounded-b-[8px]">
                  <span className="ef-body-xl">{t("common.at_time", { time: format(new Date(o.created_at), "HH") })}</span>
                  <span className="ml-auto text-ef-neutral-700 ef-body-lg">{format(new Date(o.created_at), "HH:mm:ss")}</span>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CamDetailModal;
