import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import { usePiggeries } from "query/piggery";
import OperationDatePicker from "components/pages/3_report/operation/OperationDatePicker";
import DailyReport from "components/pages/3_report/operation/daily/DailyReport";
import { subDays } from "date-fns";

function OperationReport() {
  const { t } = useTranslation();

  // @TODO 월간, 연간 등 추가 예정
  const typeOptions = [{ value: 1, name: t("report.daily") }];

  // 필터용 state
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedType, setSelectedType] = useState(1);
  const [selectedDate, setSelectedDate] = useState(subDays(new Date(), 1));

  const { data: piggeryList } = usePiggeries();

  // reset btn용
  const handleReset = () => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
    setSelectedType(1);
    setSelectedDate(subDays(new Date(), 1));
  };

  // 첫번째 돈사 선택
  useEffect(() => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
  }, [piggeryList]);

  return (
    <div className="w-full h-full bg-ef-neutral-50 flex flex-col">
      {/* title and filter */}
      <div className="w-full flex flex-row items-center px-40pxr py-60pxr bg-ef-neutral-white border-b border-solid border-ef-neutral-200">
        <span className="ef-headline-lg">{t("gnb.operation_report")}</span>
        <div className="ml-auto flex flex-row items-center">
          <div className="flex flex-row gap-8pxr">
            <DropDownFilter
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
            <DropDownFilter title={t("report.period")} state={selectedType} setState={setSelectedType} options={typeOptions} />
            <OperationDatePicker date={selectedDate} setDate={setSelectedDate} selectedType={selectedType} maxDate={subDays(new Date(), 1)} />
          </div>
          <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center ml-24pxr">
            <Refresh />
            {t("common.reset")}
          </button>
        </div>
      </div>
      <main className="w-full grow">{selectedType === 1 ? <DailyReport selectedPiggery={selectedPiggery} selectedDate={selectedDate} /> : <></>}</main>
    </div>
  );
}

export default OperationReport;
