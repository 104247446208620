// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#record-table {
  border-collapse: separate !important;
  border-spacing: 0px;
  box-shadow: 0 0 0 1px #878787 !important;
}

/* 방 구분선 */
#record-table th[colspan="6"],
#record-table td[colspan="6"] {
  border-right: 1px solid #878787 !important;
}

/* thead 두 번째 행의 3의 배수 위치마다 테두리 */
#record-table thead tr:last-child th:nth-child(3n) {
  border-right: 1px solid #878787;
}

#record-table .border-divide {
  border-right: 1px solid #878787;
}
`, "",{"version":3,"sources":["webpack://./src/pages/2_manage/stock/record/recordTable.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA,UAAU;AACV;;EAEE,0CAA0C;AAC5C;;AAEA,iCAAiC;AACjC;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["#record-table {\n  border-collapse: separate !important;\n  border-spacing: 0px;\n  box-shadow: 0 0 0 1px #878787 !important;\n}\n\n/* 방 구분선 */\n#record-table th[colspan=\"6\"],\n#record-table td[colspan=\"6\"] {\n  border-right: 1px solid #878787 !important;\n}\n\n/* thead 두 번째 행의 3의 배수 위치마다 테두리 */\n#record-table thead tr:last-child th:nth-child(3n) {\n  border-right: 1px solid #878787;\n}\n\n#record-table .border-divide {\n  border-right: 1px solid #878787;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
