import { useRef, useCallback, useEffect, useState } from 'react';
import { ReactComponent as CameraRotate } from "assets/icons/general/ef-general-cameraRotate.svg";
import { ReactComponent as Close } from "assets/icons/general/ef-general-close.svg";

import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface QrScanProps {
  onClose: () => void;
}

function QrScan({ onClose }: QrScanProps) {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [facingMode, setFacingMode] = useState<"environment" | "user">("environment");
  const navigate = useNavigate();

  const toggleCamera = () => {
    setFacingMode(prev => prev === "environment" ? "user" : "environment");
  };

  const scanQRCode = useCallback(() => {
    const video = webcamRef.current?.video;
    if (!video || !isVideoReady || video.videoWidth === 0) return;

    // 캔버스 생성
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // 이미지 데이터 가져오기
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code) {
      console.log('QR Code detected:', code.data);
      // QR 코드가 URL인 경우 이동
      if (code.data.startsWith('http')) {
        window.location.href = code.data;
      }
    }
  }, [isVideoReady]);

  useEffect(() => {
    if (isVideoReady) {
      const interval = setInterval(scanQRCode, 250);
      return () => clearInterval(interval);
    }
  }, [scanQRCode, isVideoReady]);

  return (
    <div className="flex-1 flex flex-col min-h-0 relative">
      <Webcam
        ref={webcamRef}
        className="w-full h-full object-cover"
        videoConstraints={{
          facingMode: facingMode,
          width: 1920,
          height: 1080,
        }}
        onUserMedia={() => setIsVideoReady(true)}
      />
      <div className="absolute inset-0 w-full h-full">
        <div className="flex flex-col items-center h-full">
          <div className="flex flex-row w-full h-56pxr px-20pxr border-b border-ef-neutral-white border-opacity-40 items-center justify-between bg-ef-primary-900 bg-opacity-50">
            <button onClick={toggleCamera}>
              <CameraRotate />
            </button>
            <span className="ef-title-md text-ef-neutral-white">{t('qr.room_qr_scan')}</span>
            <button onClick={onClose}>
              <Close className='stroke-ef-neutral-white' />
            </button>
          </div>
          <div className="w-full text-center pt-118pxr pb-40pxr ef-label-lg text-ef-neutral-white bg-ef-primary-900 bg-opacity-50">
            {t('qr.scan_qr_comment')}
          </div>
          <div className="w-full flex flex-row justify-center">
            <div className="flex-1 bg-ef-primary-900 bg-opacity-50" />
            <div className="w-[180px] h-[180px] relative">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" width="184" height="184" viewBox="0 0 184 184" fill="none">
                  <path d="M22 2H2V22" stroke="white" stroke-width="3" />
                  <path d="M162 182L182 182L182 162" stroke="white" stroke-width="3" />
                  <path d="M182 22L182 2L162 2" stroke="white" stroke-width="3" />
                  <path d="M2 162L2 182L22 182" stroke="white" stroke-width="3" />
                </svg>
              </div>
            </div>
            <div className="flex-1 bg-ef-primary-900 bg-opacity-50" />
          </div>
          <div className="w-full flex-1 bg-ef-primary-900 opacity-50" />
        </div>
      </div>
    </div>
  );
}

export default QrScan;