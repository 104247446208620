import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useGrowCamDetail } from "query/efg";
import NoImg from "assets/images/report/no_report_data.png";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  camId: number;
  selectedDate: Date;
};

function CameraDetailViewModal(props: Props) {
  const { isToggle, setIsToggle, camId, selectedDate } = props;
  const { t } = useTranslation();
  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
    }
  };

  const { data: camData, isLoading } = useGrowCamDetail(camId, format(selectedDate, "yyyy-MM-dd"));
  const imgArr = camData ? camData.time_images : [];

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr pb-120pxr overflow-y-auto"
    >
      <div className="w-994pxr h-[920px] max-h-[920px] overflow-y-auto bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{camData ? `${camData.room.name} - ${camData.name}` : ""}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        {isLoading ? (
          <div className="flex flex-col w-full h-full items-center justify-center">
            <img src={NoImg} alt="no-data-img" className="w-310pxr" />
            <span className="ef-body-lg text-ef-neutral-600">{t("common.data_loading")}</span>
          </div>
        ) : (
          <main className="grid grid-cols-3 gap-16pxr mt-40pxr">
            {imgArr.map((o, idx) => (
              <div key={idx} className="w-full h-fit">
                <div className="aspect-video w-full relative">
                  <div className="bg-ef-neutral-50 rounded-t-[8px] absolute top-0pxr left-0pxr w-full h-full flex flex-col items-center justify-center">
                    <img className="w-140pxr h-99pxr" src={NoImg} alt="error-img" />
                    <span className="ef-body-sm text-ef-neutral-700 text-center">{t("common.image_path_not_found")}</span>
                  </div>
                  <img className="rounded-t-[8px] w-full absolute top-0pxr left-0pxr" src={o.image_path} alt="dd" onError={(e) => (e.currentTarget.hidden = true)} />
                </div>
                <div className="py-16pxr px-24pxr flex flex-row items-center border-x border-b border-solid border-ef-neutral-150 rounded-b-[8px]">
                  <span className="ef-body-xl">{t("common.at_time", { time: format(new Date(o.created_at), "HH") })}</span>
                  <span className="ef-body-lg text-ef-neutral-700 ml-auto">{format(new Date(o.created_at), "HH:mm:ss")}</span>
                </div>
              </div>
            ))}
          </main>
        )}
      </div>
    </Modal>
  );
}

export default CameraDetailViewModal;
