import { LoginPayload, LoginResponse } from "./type";
import { useMutation } from "react-query";
import { useAuthDispatch } from "context/AuthContext";
import client from "query/client";

// post /login 로그인
export const useLogin = () => {
  const loginAPI = (payload: LoginPayload) =>
    client
      .post("/login", payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.data);

  const authDispatch = useAuthDispatch();

  const mutation = useMutation((payload: LoginPayload) => loginAPI(payload), {
    onSuccess(data: LoginResponse) {
      /**
       * 1. 토큰 local저장
       * 2. context에 토큰저장
       * 3. axios header 토큰저장
       */
      const { access_token } = data;
      // # 1
      localStorage.setItem("Authorization", access_token);
      // # 2
      authDispatch({
        type: "SET_TOKEN",
        payload: {
          access_token: access_token,
        },
      });
      // # 3
      client.defaults.headers.common["Authorization"] = `bearer ${access_token}`;
    },
  });
  return mutation;
};
