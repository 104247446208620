import React, { useEffect } from "react";
import { useState } from "react";
// icons
import { ReactComponent as GeneralUser } from "assets/icons/general/ef-general-user.svg";
import { ReactComponent as GeneralPassword } from "assets/icons/general/ef-general-password.svg";
import { ReactComponent as GeneralCloseCircle } from "assets/icons/general/ef-general-closeCircle.svg";
import { ReactComponent as GeneralEye } from "assets/icons/general/ef-general-eye.svg";
import { ReactComponent as GeneralEyeClosed } from "assets/icons/general/ef-general-eyeClosed.svg";
import { ReactComponent as Logo } from "assets/logo/ef-logo.svg";
import { ReactComponent as Graphic } from "assets/graphic/ef-graphic-symbol-color.svg";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";

// components
import LanguageSelector from "components/common/lang/LanguageSelector";
// library
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogin } from "query/authorize";
import { useAuthDispatch } from "context/AuthContext";
import { useMemberInfo } from "query/member";
import client from "query/client";

type Data = {
  id: string;
  password: string;
};

function Login() {
  const { t } = useTranslation();
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [isUnAccess, setIsUnAccess] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);

  const navigate = useNavigate();

  const { isLoading, isSuccess, mutateAsync: signin } = useLogin();
  const { mutateAsync: init } = useMemberInfo();
  const authDispatch = useAuthDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Data>();

  const id = watch("id");
  const pw = watch("password");

  const onSubmit = async (data: Data) => {
    const { id, password } = data;
    const trimedId = id.trim();

    try {
      await signin({ username: trimedId, password });
      const memberData = await init();

      // 계정에 농장이 1개뿐이라면 바로 메인으로
      if (memberData.farms.length === 1) {
        const selectedFarm = memberData.farms[0];
        authDispatch({
          type: "SET_FARM",
          payload: selectedFarm,
        });

        localStorage.setItem("farm-id", selectedFarm.id.toString());
        client.defaults.headers.common["farm-id"] = `${selectedFarm.id}`;
        navigate(`/`);
        // 농장이 계정에 여러개라면 농장선택 페이지로
      } else {
        navigate(`/select`);
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) setIsAuthError(true);
      else if (error?.response?.status === 500 || error?.code === "ERR_NETWORK") setIsUnAccess(true);
    }
  };

  // input에서 캡스락 감지하기
  const detectCapsLock = (e: any) => {
    if (e.getModifierState("CapsLock")) setIsCapsLock(true);
    else setIsCapsLock(false);
  };

  return (
    <div className="w-full h-screen bg-ef-neutral-50 min-w-[1440px] overflow-x-auto">
      <div className="flex h-full w-full">
        <div className="w-[1440px] h-full mx-auto flex flex-col">
          {/* header */}
          <div className="w-full h-80pxr flex">
            <div className="my-20pxr flex ml-auto">
              <LanguageSelector type="login" />
            </div>
          </div>
          {/* body */}
          <div className="mx-auto flex flex-col items-center">
            <div className="w-243pxr h-189pxr">
              <Logo />
            </div>
            <p className="mt-8pxr ef-body-xxl text-ef-neutral-700">{t("signin.signin_comment")}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-456pxr mt-40pxr flex flex-col h-fit">
                <div className="group inline-flex w-full h-64pxr bg-ef-neutral-white items-center rounded-[8px] border border-solid border-ef-neutral-150 focus-within:border-ef-primary-500">
                  <div className="w-24pxr h-24pxr ml-24pxr flex items-center justify-center">
                    <GeneralUser className="stroke-ef-neutral-300 group-focus-within:stroke-ef-neutral-700" />
                  </div>
                  <div className="h-32pxr border-l border-solid border-ef-neutral-200 ml-16pxr"></div>
                  <div className="flex flex-col ml-16pxr">
                    <div className="h-16pxr w-250pxr ef-label-sm text-ef-primary-500">{t("signin.id")}</div>
                    <input
                      {...register("id", { required: true })}
                      onKeyUp={detectCapsLock}
                      type="text"
                      className="h-24pxr w-250pxr ef-body-lg text-ef-neutral-900 placeholder:text-ef-neutral-400 placeholder:font-pre focus:outline-none caret-ef-primary-900"
                      placeholder={t("signin.enter_id")}
                    />
                  </div>
                  {id === "" || id === undefined ? (
                    <></>
                  ) : (
                    <div onClick={() => setValue("id", "")} className="w-24pxr h-24pxr ml-auto mr-24pxr flex items-center justify-center">
                      <GeneralCloseCircle className="stroke-ef-neutral-700 cursor-pointer" />
                    </div>
                  )}
                </div>
                <div className="group inline-flex w-full h-64pxr bg-ef-neutral-white mt-8pxr items-center rounded-[8px] border border-solid border-ef-neutral-150 focus-within:border-ef-primary-500">
                  <GeneralPassword className="stroke-ef-neutral-300 ml-24pxr group-focus-within:stroke-ef-neutral-700" />
                  <div className="h-32pxr border-l border-solid border-ef-neutral-200 ml-16pxr"></div>
                  <div className="flex flex-col ml-16pxr">
                    <div className="h-16pxr w-250pxr ef-label-sm text-ef-primary-500">{t("signin.password")}</div>
                    <input
                      {...register("password", { required: true })}
                      onKeyUp={detectCapsLock}
                      type={isPwVisible ? "text" : "password"}
                      className="h-24pxr w-250pxr ef-body-lg placeholder:text-ef-neutral-400 placeholder:font-pre focus:outline-none caret-ef-primary-900"
                      placeholder={t("signin.enter_pw")}
                    />
                  </div>
                  <div className="mr-24pxr ml-auto flex flex-row">
                    {pw === "" || pw === undefined ? (
                      <></>
                    ) : (
                      <div onClick={() => setValue("password", "")} className="w-24pxr h-24pxr ml-auto mr-24pxr flex items-center justify-center">
                        <GeneralCloseCircle className="cursor-pointer stroke-ef-neutral-700" />
                      </div>
                    )}

                    {pw === "" || pw === undefined ? (
                      <></>
                    ) : (
                      <div className="w-24pxr h-24pxr flex items-center justify-center">
                        {isPwVisible ? (
                          <GeneralEye onClick={() => setIsPwVisible(false)} className="cursor-pointer stroke-ef-neutral-700" />
                        ) : (
                          <GeneralEyeClosed onClick={() => setIsPwVisible(true)} className="cursor-pointer fill-ef-neutral-700" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-32pxr flex flex-col">
                  <div className="w-206pxr h-32pxr mb-8pxr items-end flex">
                    <p className="text-ef-state-red-500 ef-body-xs break-words">
                      {errors.id
                        ? `${t("signin.enter_id")}`
                        : errors.password
                          ? `${t("signin.enter_pw")}`
                          : isAuthError
                            ? `${t("signin.incorrect_id_or_pw")}`
                            : isCapsLock
                              ? `${t("signin.capslock_on")}`
                              : ""}
                    </p>
                  </div>
                  <button
                    type="submit"
                    className="w-full h-64pxr text-center rounded-[8px] text-ef-neutral-white ef-label-lg bg-ef-primary-500 hover:bg-ef-primary-900 active:bg-ef-primary-500 focus:outline-ef-primary-900 focus:outline-1 disabled:bg-ef-neutral-500"
                  >
                    {t("signin.login")}
                  </button>
                </div>
              </div>
            </form>
            {isUnAccess ? (
              <div className="flex flex-col absolute w-400pxr h-240pxr">
                <div className="relative flex flex-col h-full w-full bg-ef-neutral-white">
                  <div
                    style={{
                      background: "linear-gradient(279deg, #033687 0%, #83B3FF 100%)",
                    }}
                    className="p-4pxr flex items-center"
                  ></div>
                  <div className="absolute flex w-full justify-end">
                    <Graphic />
                  </div>
                  <div className="relative flex flex-col w-full h-full">
                    <div className="flex flex-row justify-between p-24pxr">
                      <span className="flex ef-title-lg">{t("signin.notice")}</span>
                    </div>
                    <div className="w-full h-full p-24pxr flex flex-col">
                      <span className="ef-body-sm">{t("signin.service_checking_comment")}</span>
                    </div>
                    <div className="flex w-full">
                      <button onClick={() => setIsUnAccess(false)} className="flex w-full p-20pxr bg-ef-primary-900 ef-label-md text-ef-neutral-white justify-center">
                        {t("signin.close")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-24pxr flex flex-row relative">
              <span className="text-ef-neutral-900 ef-body-lg">{t("signin.click_contact_register")}</span>
              <span onClick={() => window.open("https://intflow.ai/contact/", "_blank")} className="ml-8pxr ef-label-lg text-ef-primary-500 cursor-pointer">
                {t("signin.contact")}
              </span>
            </div>
          </div>
          <div className="flex grow justify-center items-end">
            <p className="text-center mb-60pxr ef-body-xs text-ef-neutral-400">© 2024. intflow Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
