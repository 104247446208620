import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as Pig } from "assets/icons/general/ef-general-pig.svg";
import { ReactComponent as Movement } from "assets/icons/gnb/ef-gnb-movement.svg";
import { ReactComponent as Death } from "assets/icons/report/ef-report-death2.svg";
import { ReactComponent as Arrival } from "assets/icons/report/ef-report-arrival.svg";
import { ReactComponent as Out } from "assets/icons/report/ef-report-shipment.svg";
import { ReactComponent as ErrorDisabled } from "assets/icons/general/ef-general-roundErrorDisable.svg";
import { ReactComponent as ErrorEnabled } from "assets/icons/general/ef-general-roundErrorEnable.svg";
import { ReactComponent as CloseCircle } from "assets/icons/general/ef-general-closeCircle.svg";
import { useNavigate, useSearchParams } from 'react-router-dom';
import StockInput from "components/pages/0_qr/stock_input";
import ErrorPage from "components/pages/0_common/ErrorPage";
import { useRoomDropdownListQR } from "query/rooms";
import { useTranslation } from "react-i18next";
import { useAddHerdHistoryQR, useAddHerdQR, useDeactivateHerdQR } from "query/herd";
import { format, subDays } from "date-fns";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import DropDownSmall from "components/pages/0_qr/qrDropDownSmall";
import GroupSingleDatePicker from "components/pages/0_qr/qrDatePicker";
import DatePicker from "react-datepicker";
import { usePiggeriesQR } from "query/piggery";

type Inputs = {
  category_id: number | null;
  arrival_piggery: number | null;
  arrival_room: number | null;
  departure_piggery: number | null;
  departure_room: number | null;
  created_at: Date | null;
};

const initialValue = {
  category_id: null,
  arrival_piggery: -1,
  arrival_room: null,
  departure_piggery: -1,
  departure_room: null,
  created_at: new Date(),
};

function QrStockRecord() {
  const { t, i18n } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [count, setCount] = useState<number | ''>('');
  const ghostRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [spanWidth, setSpanWidth] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState(new Date());

  const { mutateAsync: addHerdHistory } = useAddHerdHistoryQR();
  const { mutateAsync: addHerd } = useAddHerdQR();
  const { mutateAsync: deactivateHerdQR } = useDeactivateHerdQR();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({ defaultValues: initialValue });

  const piggeryId = Number(searchParams.get('piggery_id'));
  const roomId = Number(searchParams.get('room_id'));

  const { data: roomList } = useRoomDropdownListQR(Number(piggeryId), [1]);
  const { data: piggeryList } = usePiggeriesQR(Number(piggeryId));
  const currentRoom = roomList?.find(room => room.id === Number(roomId));
  const arrival_piggery = watch("arrival_piggery");
  const departure_piggery = watch("departure_piggery");
  const { data: ArrivalRoomList } = useRoomDropdownListQR(arrival_piggery);
  const { data: DepartureRoomList } = useRoomDropdownListQR(departure_piggery);

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };
  
  // 초기 렌더링 시 무조건 20px 설정
  useLayoutEffect(() => {
    setSpanWidth(20);
  }, []);

  // count가 변경될 때만 실제 너비 계산
  useLayoutEffect(() => {
    if (ghostRef.current) {
      const width = ghostRef.current.offsetWidth;
      setSpanWidth(Math.max(20, width));
    }
  }, [count, selectedIndex]);

  // 안전영역 높이 계산
  useEffect(() => {
    const updateSafeArea = () => {
      const safeBottom = Math.max(
        Number(getComputedStyle(document.documentElement)
          .getPropertyValue('--sab')
          .slice(0, -2)) || 0,
        Number(getComputedStyle(document.documentElement)
          .getPropertyValue('env(safe-area-inset-bottom)')
          .slice(0, -2)) || 0
      );
      // 실제 viewport 높이 설정
      const vh = window.innerHeight - safeBottom;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateSafeArea();
    window.addEventListener('resize', updateSafeArea);
    window.addEventListener('orientationchange', updateSafeArea);

    return () => {
      window.removeEventListener('resize', updateSafeArea);
      window.removeEventListener('orientationchange', updateSafeArea);
    };
  }, []);

  // 브라우저 언어 감지 및 적용 및 값 초기화
  useEffect(() => {
    reset(initialValue);
    const browserLang = navigator.language.split('-')[0]; // 'ko-KR' -> 'ko'
    const supportedLangs = ['ko', 'en', 'ja'];

    // 지원하는 언어인 경우에만 변경
    if (supportedLangs.includes(browserLang)) {
      i18n.changeLanguage(browserLang);
    } else {
      i18n.changeLanguage('en'); // 지원하지 않는 언어는 영어로
    }

    // 컴포넌트가 언마운트될 때 원래 설정으로 복원
    const originalLang = localStorage.getItem('i18nextLng');
    return () => {
      if (originalLang) {
        i18n.changeLanguage(originalLang);
      }
    };
  }, []);

  useEffect(() => {
    setCount('');
    if (selectedIndex === 0 || selectedIndex === 2) {
      setValue("departure_piggery", -1);
      setValue("departure_room", null);
      setValue("arrival_piggery", Number(piggeryId));
      setValue("arrival_room", Number(roomId));
    } else if (selectedIndex === 1 || selectedIndex === 3) {
      setValue("arrival_piggery", -1);
      setValue("arrival_room", null);
      setValue("departure_piggery", Number(piggeryId));
      setValue("departure_room", Number(roomId));
    } else {
      setValue("arrival_piggery", null);
      setValue("arrival_room", null);
      setValue("departure_piggery", null);
      setValue("departure_room", null);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (watch("arrival_piggery") === -1) {
      setValue("arrival_room", null);
    }
    if (watch("departure_piggery") === -1) {
      setValue("departure_room", null);
    }
  }, [arrival_piggery, departure_piggery]);

  if (!piggeryId || !roomId) {
    return <ErrorPage />;
  }
  const categoryMap = {
    "0": 2,
    "1": 3,
    "2": 1,
    "3": 4,
    "4": 5,
    "5": 6,
    "6": 7,
  }
  const getPlannedMoveColor = (days: number | undefined | null) => {
    if (!days) return '';
    if (days <= 3) return 'text-ef-state-red-500';
    if (days <= 7) return 'text-ef-state-yellow-500';
    return '';
  };
  const onSubmit = async (data: Inputs) => {
    const response_state = [];
    // 필수 항목 검증
    if (typeof count !== 'number' || count == 0) {
      toast.error(t('common.required_comment'));
      return;
    }

    // 날짜 및 시간 포맷팅
    const formattedDateTime = format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss");
    
    // API 요청을 위한 payload 생성
    const payload = {
      category_id: categoryMap[selectedIndex.toString() as keyof typeof categoryMap],
      // 전출, 출하, 폐사, 도태일 경우 "-" 붙여줌
      change: selectedIndex === 1 || selectedIndex === 3 || selectedIndex === 4 || selectedIndex === 5 
        ? -Number(count) 
        : selectedIndex === 6 // 오류(재고 보정)일 경우
          ? Number(count) 
          : Number(count),
      stock: currentRoom?.stock,
      created_at: formattedDateTime,
      memo: "",
      arrival_room_id: data.arrival_room,
      departure_room_id: data.departure_room,
    };

    // 1. 전입 또는 입식인 경우
    if (selectedIndex === 0 || selectedIndex === 2) {
      if(departure_piggery !== -1 && watch("departure_room") === null) {
        toast.error(t("common.required_comment"));
        return;
      }
      payload.arrival_room_id = Number(roomId);
      const departureRoom = DepartureRoomList?.find(room => room.id === watch("departure_room"));

      if(departureRoom && departureRoom.stock - Number(payload.change) < 0) {
        toast.error(t("status.stocks_for_move_in_cannot_comment"));
        return;
      }

      // 출발 돈방이 0마리가 되는 경우 (돈군 종료)
      if (departureRoom && departureRoom.stock - Number(payload.change) === 0) {
        payload.departure_room_id = null;
        const deactiveHerdPayload = {
          category_id: payload.category_id === 1 ? 4 : 3,
          created_at: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
          change: -Number(payload.change),
        };
        try {
          const response = await deactivateHerdQR({ payload: deactiveHerdPayload, id: departureRoom.active_herd?.id ?? 0 });
          response_state.push(response);
        } catch (error: any) {
          console.log(error);
        }
      }
      if(currentRoom?.stock === 0) {
        const birthDate = departureRoom ? departureRoom.active_herd?.birth_date!
                         : currentRoom.in_age ? subDays(new Date(data.created_at!), currentRoom.in_age) : data.created_at!;
        const createdHerdpayload = {
          nickname: null,
          birth_date: format(new Date(birthDate), "yyyy-MM-dd"),
          arrival_date: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
          room_id: currentRoom?.id,
          memo: null,
          history: {
            category_id: payload.category_id,
            change: Number(count),
          },
          departure_room_id: payload.departure_room_id,
        };
        try {
          const response = await addHerd(createdHerdpayload);
          response_state.push(response);
          toast.success(t("common.save_completed"));
          setCount('');
          setSelectedIndex(0);
          navigate('/qr/stock/status', {
            state: {
              piggeryId: Number(piggeryId),
              roomName: currentRoom?.name,
              categoryId: payload.category_id,
              change: Number(count),
              cancelData: response_state,
              createdAt: new Date(),
            }
          });
          return;
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            toast.error(t("manage.add_one_herd_same_comment"));
          } else {
            toast.error(t("common.an_error_occurred"));
          }
          return;
        }
      }
    } 
    // 2. 전출 또는 출하인 경우
    else if (selectedIndex === 1 || selectedIndex === 3) {
      if (arrival_piggery !== -1 && watch("arrival_room") === null) {
        toast.error(t("common.required_comment"));
        return;
      }
      if (currentRoom?.stock && currentRoom?.stock - Number(count) < 0) {
        toast.error(t("status.stocks_for_move_error_cannot_comment"));
        return;
      }
      payload.departure_room_id = Number(roomId);
      const arrivalRoom = ArrivalRoomList?.find(room => room.id === watch("arrival_room"));
      // 현재 돈방이 0마리가 되는 경우 (돈군 종료 여부 확인)
      if (currentRoom?.stock === Number(count)) {
        if (window.confirm(t("status.confirm_deactivate_herd"))) {
          // 사용자가 확인한 경우
        } else {
          // 사용자가 취소한 경우
          return;
        }
      }
      // 도착 돈방에 돈군이 없는 경우 (돈군 생성)
      if (arrivalRoom && arrivalRoom.active_herd === null) {
        payload.arrival_room_id = null;
        const createdHerdpayload = {
          nickname: null,
          birth_date: format(new Date(currentRoom?.active_herd?.birth_date!), "yyyy-MM-dd"),
          arrival_date: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
          room_id: arrivalRoom.id,
          memo: null,
          history: {
            category_id: payload.category_id === 4 ? 1 : 2,
            change: Number(count),
          },
        };
        try {
          const response = await addHerd(createdHerdpayload);
          response_state.push(response);
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            toast.error(t("manage.add_one_herd_same_comment"));
          } else {
            toast.error(t("common.an_error_occurred"));
          }
          return;
        }
      }
    }
    // 3. 폐사, 도태 또는 오류인 경우
    else {
      if (selectedIndex !== 6 && Math.abs(Number(count)) > Number(currentRoom?.stock)) {
        toast.error(t("status.stocks_for_move_error_cannot_comment"));
        return;
      }
      payload.departure_room_id = Number(roomId);
      // 오류(재고 보정)의 경우, 음수 값 검증
      if (selectedIndex === 6 && Number(count) < 0 && Math.abs(Number(count)) > Number(currentRoom?.stock)) {
        toast.error(t("status.stocks_for_move_error_cannot_comment"));
        return;
      }
      // 현재 돈방이 0마리가 되는 경우 (돈군 종료 여부 확인)
      if (selectedIndex !== 6 && currentRoom?.stock === Number(count)) {
        if (window.confirm(t("status.confirm_deactivate_herd"))) {
          // 사용자가 확인한 경우
        } else {
          // 사용자가 취소한 경우
          return;
        }
      }
    }
    try {
      const response = await addHerdHistory({ payload: payload, id: currentRoom?.active_herd?.id || 0 });
      response_state.push(response);
      toast.success(t("common.save_completed"));
      setCount('');
      setSelectedIndex(0);
      navigate('/qr/stock/status', {
        state: {
          piggeryId: Number(piggeryId),
          roomName: currentRoom?.name,
          categoryId: payload.category_id,
          change: payload.change,
          cancelData: response_state,
          createdAt: new Date(),
        }
      });
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        toast.error(t("status.cannot_add_history_same_herd_time_comment"));
      } else {
        toast.error(t("common.an_error_occurred"));
      }
    }
  }

  return (
    <div
      className="w-screen overflow-x-hidden bg-ef-neutral-white [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
      style={{ height: 'var(--vh)' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full min-w-[360px] md:max-w-[360px] h-full mx-auto">
        <div className="w-full h-128pxr flex flex-col px-16pxr" style={{
          background: 'var(--test03, linear-gradient(279deg, #033687 0%, #1149A3 100%))',
          boxShadow: '0px 4px 8px 0px rgba(14, 44, 74, 0.12)'
        }}>
          <div className="min-h-[56px] flex flex-row items-center">
            <button
              type="button"
              className="w-32pxr h-32pxr p-4pxr"
              onClick={() => navigate('/qr/stock/status', {
                state: {
                  piggeryId: Number(piggeryId)
                }
              })}>
              <ArrowLeft className="stroke-white fill-none" />
            </button>
            <span className="ef-label-xl text-ef-neutral-white ml-4pxr">
              [{currentRoom?.piggery.name}] {currentRoom?.name}
            </span>
            <Pig className="stroke-white fill-white ml-auto" />
            <span className="ef-title-md text-ef-neutral-white ml-4pxr">
              {currentRoom?.stock}
            </span>
          </div>
          <div className="w-full h-full grid grid-cols-3 px-2pxr pb-8pxr gap-4pxr items-center justify-items-center">
            <div className="flex flex-col items-center">
              <span className="ef-body-xs text-ef-neutral-white">{t('common.day_age')}</span>
              <span className="mt-4pxr ef-title-lg text-ef-neutral-white">{currentRoom?.active_herd ? `${currentRoom?.active_herd.age}${t('common.day_age')}` : '-'}</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="ef-body-xs text-ef-neutral-white">{t('common.move_in')}</span>
              <span className="mt-4pxr ef-title-lg text-ef-neutral-white">{currentRoom?.active_herd ? `+${currentRoom?.active_herd.after_move_in}${t('settings.days')}` : '-'}</span>
            </div><div className="flex flex-col items-center">
              <span className="ef-body-xs text-ef-neutral-white">{t('qr.planned_move')}</span>
              <span className={`mt-4pxr ef-title-lg text-ef-neutral-white ${getPlannedMoveColor(currentRoom?.active_herd?.plan_move_out)}`}>
                {currentRoom?.active_herd?.plan_move_out 
                  ? currentRoom?.active_herd?.plan_move_out > 0 
                    ? `D-${currentRoom?.active_herd?.plan_move_out}` 
                    : t('qr.date_passed')
                  : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-full flex flex-col gap-8pxr">
          <span className="mt-24pxr ml-16pxr ef-body-xl text-ef-neutral-900">{t('common.work_type')}</span>
          <div className="flex flex-row gap-8pxr px-16pxr">
            <button
              key={0}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 0 ? 'bg-ef-primary-50 text-ef-primary-500 border border-ef-primary-500 stroke-ef-primary-500': ''}`}
              onClick={() => setSelectedIndex(0)}
              >
              <Movement className={`fill-none ${selectedIndex === 0 ? 'stroke-ef-primary-500' : 'stroke-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 0 ? 'text-ef-primary-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_2')}</span>
            </button>
            <button
              key={1}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 1 ? 'bg-ef-primary-50 text-ef-primary-500 border border-ef-primary-500 stroke-ef-primary-500' : ''}`}
              onClick={() => setSelectedIndex(1)}
              >
              <Movement className={`fill-none ${selectedIndex === 1 ? 'stroke-ef-primary-500' : 'stroke-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 1 ? 'text-ef-primary-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_3')}</span>
            </button>
          </div>
          {selectedIndex === 0 ? (
            <div className="w-full h-136pxr flex flex-col bg-ef-neutral-50 border-b border-ef-neutral-150 py-16pxr px-16pxr gap-8pxr">
              <div className="flex flex-row items-center justify-start gap-8pxr">
                <div className="w-148pxr h-48pxr">
                  <Controller
                    name="departure_piggery"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={"돈사 선택"}
                        options={[
                          { value: -1, name: t("common.external") },
                          ...(piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                        ]}
                      />
                    )}
                  />
                </div>
                <div className="w-148pxr h-48pxr">
                  <Controller
                    name="departure_room"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={"돈방 선택"}
                        options={DepartureRoomList ? DepartureRoomList.filter(o => o.id !== roomId && o.stock > 0).map((o) => ({ value: o.id, name: o.name })) : []}
                        disabled={watch("departure_piggery") === -1}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-8pxr">
                <span className="ef-body-lg">→</span>
                <div className="ml-8pxr w-160pxr flex flex-col">
                  <DropDownSmall
                    state={piggeryId}
                    setState={() => { }}
                    placeholder={t("common.select_piggery")}
                    options={piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                    disabled={true}
                  />
                </div>
                <div className="w-160pxr ml-8pxr flex flex-col">
                  <DropDownSmall
                    state={roomId}
                    setState={() => { }}
                    placeholder={t("common.select_room")}
                    options={roomList ? roomList.map((o) => ({ value: o.id, name: o.name })) : []}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          ) : selectedIndex === 1 ? (
              <div className="w-full h-136pxr flex flex-col bg-ef-neutral-50 border-b border-ef-neutral-150 py-16pxr px-16pxr gap-8pxr">
                <div className="flex flex-row items-center justify-start gap-8pxr">
                  <div className="w-160pxr flex flex-col">
                    <DropDownSmall
                      state={piggeryId}
                      setState={() => { }}
                      placeholder={t("common.select_piggery")}
                      options={piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <DropDownSmall
                      state={roomId}
                      setState={() => { }}
                      placeholder={t("common.select_room")}
                      options={DepartureRoomList ? DepartureRoomList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start gap-8pxr">
                  <span className="ef-body-lg">→</span>
                  <div className="w-148pxr h-48pxr">
                    <Controller
                      name="arrival_piggery"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={"돈사 선택"}
                          options={[
                            { value: -1, name: t("common.external") },
                            ...(piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                          ]}
                        />
                      )}
                    />
                  </div>
                  <div className="w-148pxr h-48pxr">
                    <Controller
                      name="arrival_room"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={"돈방 선택"}
                          options={ArrivalRoomList ? ArrivalRoomList.filter(o => o.id !== roomId).map((o) => ({ value: o.id, name: o.name })) : []}
                          disabled={watch("arrival_piggery") === -1}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
          ) : (
            <></>
          )}
          <div className="flex flex-row gap-8pxr px-16pxr">
            <button
              key={2}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 2 ? 'bg-ef-primary-50 text-ef-primary-500 border border-ef-primary-500 stroke-ef-primary-500' : ''}`}
              onClick={() => setSelectedIndex(2)}
            >
              <Arrival className={`fill-none ${selectedIndex === 2 ? 'stroke-ef-primary-500' : 'stroke-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 2 ? 'text-ef-primary-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_1')}</span>
            </button>
            <button
              key={3}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 3 ? 'bg-ef-primary-50 text-ef-primary-500 border border-ef-primary-500 stroke-ef-primary-500' : ''}`}
              onClick={() => setSelectedIndex(3)}
            >
              <Out className={`fill-none ${selectedIndex === 3 ? 'stroke-ef-primary-500' : 'stroke-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 3 ? 'text-ef-primary-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_4')}</span>
            </button>
          </div>
          {selectedIndex === 2 ? (
            <div className="w-full h-136pxr flex flex-col bg-ef-neutral-50 border-b border-ef-neutral-150 py-16pxr px-16pxr gap-8pxr">
              <div className="flex flex-row items-center justify-start gap-8pxr">
                <div className="w-148pxr h-48pxr">
                  <Controller
                    name="departure_piggery"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t("common.select_piggery")}
                        options={[
                          { value: -1, name: t("common.external") },
                          ...(piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                        ]}
                      />
                    )}
                  />
                </div>
                <div className="w-148pxr h-48pxr">
                  <Controller
                    name="departure_room"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t("common.select_room")}
                        options={DepartureRoomList ? DepartureRoomList.filter(o => o.id !== roomId).map((o) => ({ value: o.id, name: o.name })) : []}
                        disabled={watch("departure_piggery") === -1}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-8pxr">
                <span className="ef-body-lg">→</span>
                <div className="ml-8pxr w-160pxr flex flex-col">
                  <DropDownSmall
                    state={piggeryId}
                    setState={() => { }}
                    placeholder={t("common.select_piggery")}
                    options={piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                    disabled={true}
                  />
                </div>
                <div className="w-160pxr ml-8pxr flex flex-col">
                  <DropDownSmall
                    state={roomId}
                    setState={() => { }}
                    placeholder={t("common.select_room")}
                    options={roomList ? roomList.map((o) => ({ value: o.id, name: o.name })) : []}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          ) : selectedIndex === 3 ? (
              <div className="w-full h-136pxr flex flex-col bg-ef-neutral-50 border-b border-ef-neutral-150 py-16pxr px-16pxr gap-8pxr">
                <div className="flex flex-row items-center justify-start gap-8pxr">
                  <div className="w-160pxr flex flex-col">
                    <DropDownSmall
                      state={piggeryId}
                      setState={() => { }}
                      placeholder={t("common.select_piggery")}
                      options={piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <DropDownSmall
                      state={roomId}
                      setState={() => { }}
                      placeholder={t("common.select_room")}
                      options={DepartureRoomList ? DepartureRoomList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start gap-8pxr">
                  <span className="ef-body-lg">→</span>
                  <div className="w-148pxr h-48pxr">
                    <Controller
                      name="arrival_piggery"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.select_piggery")}
                          options={[
                            { value: -1, name: t("common.external") },
                            ...(piggeryList ? piggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                          ]}
                        />
                      )}
                    />
                  </div>
                  <div className="w-148pxr h-48pxr">
                    <Controller
                      name="arrival_room"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.select_room")}
                          options={ArrivalRoomList ? ArrivalRoomList.filter(o => o.id !== roomId).map((o) => ({ value: o.id, name: o.name })) : []}
                          disabled={watch("arrival_piggery") === -1}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
          ) : (
            <></>
          )}
          <div className="flex flex-row gap-8pxr px-16pxr">
            <button
              key={4}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 4 ? 'bg-ef-state-red-50 text-ef-state-red-500 border border-ef-state-red-500 stroke-ef-state-red-500' : ''}`}
              onClick={() => setSelectedIndex(4)}
            >
              <Death className={`${selectedIndex === 4 ? 'stroke-ef-state-red-500 fill-ef-state-red-500' : 'stroke-ef-neutral-300 fill-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 4 ? 'text-ef-state-red-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_5')}</span>
            </button>
            <button
              key={5}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 5 ? 'bg-ef-state-red-50 text-ef-state-red-500 border border-ef-state-red-500 stroke-ef-state-red-500' : ''}`}
              onClick={() => setSelectedIndex(5)}
            >
              <Death className={`${selectedIndex === 5 ? 'stroke-ef-state-red-500 fill-ef-state-red-500' : 'stroke-ef-neutral-300 fill-ef-neutral-300'}`} />
              <span className={`ef-body-lg ${selectedIndex === 5 ? 'text-ef-state-red-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_6')}</span>
            </button>
            <button
              key={6}
              type="button"
              className={`w-full h-48pxr py-12pxr pl-8pxr flex flex-row items-center justify-start gap-4pxr rounded-[8px] border border-ef-neutral-150 bg-ef-neutral-white ${selectedIndex === 6 ? 'bg-ef-primary-50 text-ef-primary-500 border border-ef-primary-500 stroke-ef-primary-500' : ''}`}
              onClick={() => setSelectedIndex(6)}
            >
            {selectedIndex === 6 ? <ErrorEnabled /> : <ErrorDisabled />}
              <span className={`ef-body-lg ${selectedIndex === 6 ? 'text-ef-primary-500' : 'text-ef-neutral-300'}`}>{t('common.herd_history_7')}</span>
            </button>
          </div>
          {/* 두수 입력 */}
          <div className="flex flex-col mt-24pxr px-16pxr">
            <div className="flex flex-row items-center justify-start">
              <span className="ef-body-xl text-ef-neutral-900">{t('common.heads')} </span>
              <span className="ml-4pxr ef-body-lg text-ef-neutral-500">({t('qr.current_stock')}: {currentRoom?.stock}{t('common.head')})</span>
            </div>
            {selectedIndex !== 6 ? (
              <>
                <div className="w-full min-h-[48px] mt-8pxr flex flex-row items-center px-16pxr py-6pxr relative border border-ef-neutral-150 rounded-[8px]">
                  <div
                    className="absolute left-0 w-[70%] h-full cursor-text"
                    onClick={() => inputRef.current?.focus()}
                  />
                  {count && selectedIndex !== null && (
                    <span className={`ef-headline-md ${selectedIndex === 0 || selectedIndex === 2
                      ? 'text-ef-primary-500'
                      : 'text-ef-secondary-500'
                      }`}>
                      {selectedIndex === 0 || selectedIndex === 2 ? '+' : '-'}
                    </span>
                  )}
                  <input
                    ref={inputRef}
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={count}
                    placeholder="0"
                    maxLength={10}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      if (value.length <= 10) {
                        setCount(value ? Number(value) : '');
                      }
                    }}
                    className={`ef-headline-md bg-transparent outline-none placeholder:text-ef-neutral-500 placeholder:text-center ${count
                      ? selectedIndex === null
                        ? 'text-ef-primary-500'
                        : selectedIndex === 0 || selectedIndex === 2
                          ? 'text-ef-primary-500'
                          : 'text-ef-secondary-500'
                      : 'text-ef-neutral-500'
                      }`}
                    style={{
                      width: spanWidth ? `${Math.max(20, spanWidth)}px` : '20px',
                    }}
                  />
                  <div className="ml-4pxr ef-body-lg text-ef-neutral-900 self-end">
                    {t('common.head')}
                  </div>
                  <div className="flex items-center h-full absolute right-[16px]">
                    {count !== '' && (
                      <button
                        type="button"
                        onClick={() => setCount('')}
                        className="cursor-pointer stroke-ef-neutral-700"
                      >
                        <CloseCircle />
                      </button>
                    )}
                  </div>
                  <span
                    ref={ghostRef}
                    aria-hidden="true"
                    className="ef-headline-md invisible absolute"
                    style={{ whiteSpace: 'pre' }}
                  >
                    {count || '0'}
                  </span>
                </div>
                <div className="w-full flex flex-row gap-8pxr mt-16pxr">
                  <button
                    type="button"
                    className="w-70pxr h-40pxr rounded-[8px] bg-ef-neutral-50 ef-body-sm text-ef-neutral-900 border border-ef-neutral-150"
                    onClick={() => {
                      const newCount = (typeof count === 'number' ? count : 0) + 1;
                      setCount(newCount);
                    }}
                  >
                    1
                  </button>
                  <button
                    type="button"
                    className="w-70pxr h-40pxr rounded-[8px] bg-ef-neutral-50 ef-body-sm text-ef-neutral-900 border border-ef-neutral-150"
                    onClick={() => {
                      const newCount = (typeof count === 'number' ? count : 0) + 10;
                      setCount(newCount);
                    }}
                  >
                    10
                  </button>
                  <button
                    type="button"
                    className="w-70pxr h-40pxr rounded-[8px] bg-ef-neutral-50 ef-body-sm text-ef-neutral-900 border border-ef-neutral-150"
                    onClick={() => {
                      const newCount = (typeof count === 'number' ? count : 0) + 100;
                      setCount(newCount);
                    }}
                  >
                    100
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center gap-8pxr">
                  <button 
                    type="button"
                    className="w-70pxr h-48pxr rounded-[8px] bg-ef-state-red-50 ef-body-xxl text-ef-state-red-500 border border-ef-state-red-500"
                    onClick={() => setCount(prev => (typeof prev === 'number' ? prev - 1 : -1))}
                  >
                    -1
                  </button>
                  <input 
                    className="w-172pxr h-48pxr rounded-[8px] ef-headline-md text-ef-neutral-900 border border-ef-neutral-150"
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={count}
                    placeholder="0"
                    style={{ textAlign: 'center' }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setCount(value ? Number(value) : '');
                    }}
                  ></input>
                  <button 
                    type="button"
                    className="w-70pxr h-48pxr rounded-[8px] bg-ef-primary-50 ef-body-xxl text-ef-primary-500 border border-ef-primary-500"
                    onClick={() => setCount(prev => (typeof prev === 'number' ? prev + 1 : 1))}
                  >
                    +1
                  </button>
                </div>
              </>
            )}
            <span className="mt-32pxr ef-body-xl text-ef-neutral-900">{t('common.work_datetime')}</span>
            <div className="w-full h-full flex flex-row mt-8pxr">
              <div className="w-160pxr h-48pxr" onClick={() => clearErrors("created_at")}>
                <Controller
                  name="created_at"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <GroupSingleDatePicker date={field.value} setDate={field.onChange} maxDate={new Date()}/>}
                />
              </div>
              <div
                className={
                  "ml-8pxr group cursor-pointer rounded-[8px] border border-solid border-ef-neutral-150 w-120pxr h-48pxr py-12pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500 text-ef-neutral-900 bg-ef-neutral-white"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <DatePicker
                  selected={selectedTime}
                  onChange={handleTimePickerChange}
                  showTimeSelect
                  autoComplete="on"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelectOnly
                  placeholderText={t("common.time")}
                  timeCaption={t("common.time")}
                  timeIntervals={1}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 229) e.preventDefault();
                    e.preventDefault();
                  }}
                  customInput={
                    <button type="button" onClick={(e) => e.stopPropagation()} className="grow flex flex-row items-center">
                      <span className="grow w-63pxr text-left ef-body-lg">{format(selectedTime ? selectedTime : new Date(), "HH:mm")}</span>
                      <div className="w-24pxr h-24pxr">
                        <ArrowDown className="stroke-ef-neutral-900 " />
                      </div>
                    </button>
                  }
                />
              </div>
            </div>
            <div className="w-full h-96pxr mt-40pxr border-t border-ef-neutral-150">
              <button
                type="submit"
                className="w-full h-56pxr mt-16pxr mb-24pxr rounded-[8px] bg-ef-primary-500 cursor-pointer"
              >
                <span className="ef-label-lg text-ef-neutral-white">
                  {t('qr.apply')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default QrStockRecord;
