import React from "react";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  piggeryName: string;
};
function BackHomeBtn(props: Props) {
  const { piggeryName } = props;

  const navigate = useNavigate();

  return (
    <div className="flex flex-row gap-4pxr items-center">
      <button onClick={() => navigate("/")} type="button" className="stroke-ef-neutral-900 h-24pxr w-24pxr">
        <ArrowLeft />
      </button>
      <span className="text-ef-neutral-900 ef-title-lg">{piggeryName}</span>
    </div>
  );
}

export default BackHomeBtn;
