import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { useCountCamDetail, useEditCountCam } from "query/efcr";
import { toast } from "react-toastify";
import { format, isBefore, subHours } from "date-fns";
import { useEditEfcCam, useEfcCamDetail } from "query/efc";
import NoDataImg from "assets/images/report/no_report_data.png";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  device: {
    id: number;
    type: string;
  };
};

type Inputs = {
  name: string;
  location: string;
  network_status: string;
  id: number;
};

function CountCameraDetail(props: Props) {
  const { isToggle, setIsToggle, device } = props;
  const { t } = useTranslation();

  const { data: camDetail } = useCountCamDetail(device.type === "efcr" ? device.id : 0);
  const { data: efcCamDetail } = useEfcCamDetail(device.type === "efc" ? device.id : 0);

  const { mutateAsync: editCam } = useEditCountCam();
  const { mutateAsync: editEfcCam } = useEditEfcCam();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    try {
      if (device.type === "efc") {
        await editEfcCam({ payload: { name: data.name }, id: device.id });
      } else {
        await editCam({ payload: { name: data.name }, id: device.id });
      }
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (device.type === "efc") {
      if (efcCamDetail) {
        reset({
          name: efcCamDetail.name,
          location: efcCamDetail.room.name ? efcCamDetail.room.name : "",
          network_status: efcCamDetail.connected === true ? t("common.normal") : t("common.abnormal"),
          id: efcCamDetail.id,
        });
      }
    } else {
      if (camDetail) {
        reset({
          name: camDetail.name,
          location: camDetail.room.name ? camDetail.room.name : "",
          network_status: camDetail.connected === true ? t("common.normal") : t("common.abnormal"),
          id: camDetail.id,
        });
      }
    }
  }, [camDetail, efcCamDetail]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("manage.camera")} ${t("common.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="my-60pxr flex flex-col ef-label-md text-ef-neutral-900 flex-wrap gap-24pxr">
            <div className="flex flex-row">
              <label className="w-107pxr min-w-[107px] mr-12pxr">
                <span className="flex line-clamp-1 break-all">{t("manage.camera_image")}</span>
              </label>
              <div className="flex flex-col gap-8pxr">
                <div className="w-344pxr aspect-video relative">
                  <img
                    src={device.type === "efc" ? efcCamDetail?.image_path : camDetail?.image_path}
                    onError={(e) => (e.currentTarget.hidden = true)}
                    alt="image_path"
                    className="rounded-[8px] w-348pxr absolute top-0pxr left-0pxr z-[10]"
                  />
                  <div className="w-full h-full flex-col gap-8pxr rounded-[8px] border border-solid border-ef-neutral-150 flex items-center justify-center absolute top-0pxr left-0pxr">
                    <img src={NoDataImg} alt="no-img" className="w-184pxr h-128pxr" />
                    <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
                  </div>
                </div>
                <span className="ef-body-sm text-ef-neutral-500">{`${t("manage.loaded")} : ${format(new Date(), "yyyy-MM-dd HH:mm")}`}</span>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("manage.detection_location")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("manage.detection_location")}
              </label>
              <div className="w-340pxr">
                <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("common.device_connection_status")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("common.device_connection_status")}
              </label>
              <div className="w-340pxr">
                <Controller name="network_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">ID</label>
              <div className="w-340pxr">
                <Controller name="id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default CountCameraDetail;
