import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMovementDetail } from "query/movement";
// import HandWritingReport from "./report/handWritingReport";
// import noEnteredAgeDetailReport from "./report/noEnteredAgeDetailReport";
// import NoWeightListReport from "./report/noWeightListReport";
// import NoBirthNoWeightListReport from "./report/noBirthnoWeightListReport";
import NewDetailReport from "./report/detailReport";

function WindowDetailReport() {
  const { id, edit } = useParams();
  const [isWindow, setIsWindow] = useState(true);
  const reportId = typeof id === "undefined" ? 0 : Number(id);
  const { data: detailData } = useMovementDetail(reportId);

  return (
    <div className="w-full items-center justify-center">
      <div className="w-[1178px] px-40pxr h-full">
        {/* {detailData && detailData.auto === false ? (
          <HandWritingReport reportData={detailData} isWindow={isWindow} />
        ) : detailData && !detailData?.herd?.birth_date ? (
          <noEnteredAgeDetailReport reportData={detailData} isWindow={isWindow} />
        ) : detailData && (detailData?.unit_details.length === 0 || !detailData?.unit_details.length) ? (
          <NoWeightListReport reportData={detailData} isWindow={isWindow} />
        ) : detailData && (detailData?.unit_details.length === 0 || !detailData?.unit_details.length) && !detailData?.herd?.birth_date ? (
          <NoBirthNoWeightListReport reportData={detailData} isWindow={isWindow} />
        ) : detailData ? (
          <NewDetailReport reportData={detailData} isWindow={isWindow} />
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
}

export default WindowDetailReport;
