// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.innerText {
  animation: move 7s infinite;
  animation-delay: 1.5s;
  top: 0;
  left: 0;
  position: relative;
}

@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -28px;
  }
  40% {
    top: -56px;
  }
  60% {
    top: -84px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/gnb/css/ProductivityPopover.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,MAAM;EACN,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".innerText {\n  animation: move 7s infinite;\n  animation-delay: 1.5s;\n  top: 0;\n  left: 0;\n  position: relative;\n}\n\n@keyframes move {\n  0% {\n    top: 0px;\n  }\n  20% {\n    top: -28px;\n  }\n  40% {\n    top: -56px;\n  }\n  60% {\n    top: -84px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
