import React from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PiggerySingleDetail } from "query/piggery/type";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { useUpdatePiggerySetting } from "query/piggery";
import { toast } from "react-toastify";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  piggeryInfo: PiggerySingleDetail;
};

type Inputs = {
  in_age: number | string;
  in_weight: number | string;
  out_age: number | string;
  out_weight: number | string;
};

function PiggeryMoveInfoModal(props: Props) {
  const { isToggle, setIsToggle, piggeryInfo } = props;
  const { t } = useTranslation();

  const { mutateAsync: updateAllRooms } = useUpdatePiggerySetting();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    try {
      await updateAllRooms({
        piggeryId: piggeryInfo.id,
        object: {
          in_age: data.in_age === "" ? null : Number(data.in_age),
          in_weight: data.in_weight === "" ? null : Number(data.in_weight),
          out_age: data.out_age === "" ? null : Number(data.out_age),
          out_weight: data.out_weight === "" ? null : Number(data.out_weight),
        },
      });
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-650pxr h-fit overflow-y-auto bg-ef-neutral-white outline-none flex flex-col p-40pxr text-ef-neutral-900">
        <div className="w-full relative">
          <p className="ef-title-lg text-center">{`${piggeryInfo.floor}${t("settings.floor_number")} ${piggeryInfo.name} ${t("settings.enter_move_in_out_information_btn")}`}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 absolute right-0pxr top-0pxr">
            <GeneralClose />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="flex flex-col gap-24pxr mt-60pxr">
            <div className="flex flex-col gap-16pxr">
              <div className="flex flex-row gap-12pxr items-center ef-label-md">
                <label title={t("settings.move_in_age")} className="min-w-[98px] w-98pxr whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("settings.move_in_age")}
                </label>
                <div className="flex flex-row gap-4pxr items-center">
                  <div className="w-170pxr">
                    <Controller
                      name="in_age"
                      control={control}
                      render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.day_age")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                    />
                  </div>
                  <span className="ef-body-lg">{t("settings.days")}</span>
                </div>
              </div>
              <div className="flex flex-row gap-12pxr items-center ef-label-md">
                <label title={t("settings.move_in_weight")} className="min-w-[98px] w-98pxr whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("settings.move_in_weight")}
                </label>
                <div className="flex flex-row gap-4pxr items-center">
                  <div className="w-170pxr">
                    <Controller
                      name="in_weight"
                      control={control}
                      render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.weight")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                    />
                  </div>
                  <span className="ef-body-lg">kg</span>
                </div>
              </div>
            </div>
            {/* divider */}
            <div className="w-full h-1pxr bg-ef-neutral-150"></div>
            <div className="flex flex-col gap-16pxr">
              <div className="flex flex-row gap-12pxr items-center ef-label-md">
                <label title={t("settings.move_out_age")} className="min-w-[98px] w-98pxr whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("settings.move_out_age")}
                </label>
                <div className="flex flex-row gap-4pxr items-center">
                  <div className="w-170pxr">
                    <Controller
                      name="out_age"
                      control={control}
                      render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.day_age")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                    />
                  </div>
                  <span className="ef-body-lg">{t("settings.days")}</span>
                </div>
              </div>
              <div className="flex flex-row gap-12pxr items-center ef-label-md">
                <label title={t("settings.move_out_weight")} className="min-w-[98px] w-98pxr whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("settings.move_out_weight")}
                </label>
                <div className="flex flex-row gap-4pxr items-center">
                  <div className="w-170pxr">
                    <Controller
                      name="out_weight"
                      control={control}
                      render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.weight")} type="number" regex={/^(?:[1-9]\d*|0)?$/} />}
                    />
                  </div>
                  <span className="ef-body-lg">kg</span>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mt-60pxr mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}
export default PiggeryMoveInfoModal;
