import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { format } from "date-fns";
import { options } from "@fullcalendar/core/preact";
import { datePickerLocale } from "utils/useDatepicker";
import { useAddFeedHistory, useEditFeedHistory } from "query/feeder";
import { useForm, Controller } from "react-hook-form";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { toast } from "react-toastify";
import { getRedStar } from "utils/getImportantStar";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  data: {
    feederName: string;
    historyId: number | null;
    amount: number | null;
    selectedDate: Date;
    feederId: number;
  };
};

type Inputs = {
  amount: number;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function FeedLogDetailModal(props: Props) {
  const { isToggle, setIsToggle, data } = props;

  const { t } = useTranslation();

  const { mutateAsync: addData } = useAddFeedHistory();
  const { mutateAsync: editData } = useEditFeedHistory();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  useEffect(() => {
    setValue("amount", data.amount !== null ? data.amount : 0);
  }, [data.historyId, data.amount, isToggle]);

  const onSubmit = async (formData: Inputs) => {
    try {
      // 히스토리 아이디가 있다면 수정(put)
      if (typeof data.historyId === "number") {
        await editData({ payload: { amount: formData.amount }, historyId: data.historyId });
        onClose("closeButtonClick");
        toast.success(t("common.save_completed"));
        // 히스토리 아이디 없으면 post
      } else {
        const payload = {
          feeder_id: data.feederId,
          amount: formData.amount,
          created_at: format(data.selectedDate, "yyyy-MM-dd'T'HH:mm:00"),
        };
        await addData(payload);
        onClose("closeButtonClick");
        toast.success(t("common.save_completed"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="outline-none bg-ef-neutral-white w-654pxr h-fit p-40pxr text-ef-neutral-900">
        <header className="w-full h-fit relative">
          <p className="text-center ef-title-lg">{t("manage.feeding_details")}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="absolute top-0pxr right-0pxr stroke-ef-neutral-900">
            <Close />
          </button>
        </header>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-60pxr flex flex-col gap-60pxr ef-label-md">
          <div className="flex flex-col gap-24pxr">
            <div className="flex flex-row gap-8pxr items-center">
              <span className="w-110pxr max-w-[110px] whitespace-nowrap overflow-hidden text-ellipsis">{t("common.feeder")}</span>
              <div className="w-340pxr">
                <InputFieldOnly state={data.feederName} setState={() => {}} disabled />
              </div>
            </div>
            <div className="flex flex-row gap-8pxr items-center">
              <span className="w-110pxr flex flex-row">
                <span title={t("manage.feeding_datetime")} className="max-w-[102px] whitespace-nowrap overflow-hidden text-ellipsis">
                  {t("manage.feeding_datetime")}
                </span>
                {getRedStar()}
              </span>
              <div className="flex flex-row gap-8pxr">
                <div className="w-180pxr">
                  <InputFieldOnly state={format(data.selectedDate, "yyyy-MM-dd(EEEE)", { locale: datePickerLocale() })} setState={() => {}} disabled />
                </div>
                <div className="w-144pxr">
                  <InputFieldOnly state={format(data.selectedDate, "HH:mm")} setState={() => {}} disabled />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-8pxr items-center">
              <span className="w-110pxr flex flex-row">
                <span className="max-w-[102px] whitespace-nowrap overflow-hidden text-ellipsis">{t("common.feeding_amount")}</span>
                {getRedStar()}
              </span>
              <div className="flex flex-row gap-4pxr items-center">
                <div className="w-120pxr relative" onClick={() => clearErrors("amount")}>
                  {errors.amount && <RequiredAlert />}
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ validate: (v) => v.toString() !== "" && v >= 0 }}
                    render={({ field }) => <InputFieldOnly type="number" textAlign="right" state={field.value} setState={field.onChange} regex={/^(?:[1-9]\d*|0)?$/} />}
                  />
                </div>
                <span>kg</span>
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal !w-494pxr mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default FeedLogDetailModal;
