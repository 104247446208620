import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
// library
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios, { AxiosError } from "axios";
// router
import { router } from "routes";
// library css
import "react-toastify/dist/ReactToastify.css";
import "overlayscrollbars/overlayscrollbars.css";
// core css
import "styles/index.css";
import "styles/editor.css";
// context
import { AuthProvider } from "context/AuthContext";
// i18n
import "./i18n";
import useLogout from "hooks/useLogout";
import { EditProvider } from "context/EditContext";
import { ReportProvider } from "context/reportContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Wrapper = () => {
  const logout = useLogout();
  const token = localStorage.getItem("Authorization");

  const [queryClient] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error: unknown | AxiosError) => {
          if (axios.isAxiosError(error)) {
            if (token !== null && error?.response?.status === 401) {
              logout();
            }
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error: unknown | AxiosError) => {
          if (axios.isAxiosError(error)) {
            if (token !== null && error?.response?.status === 401) {
              logout();
            }
          }
        },
      }),
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: 2,
          staleTime: 0,
          onError(err) {
            // @TODO 다국어
            // toast("에러");
            console.log(err);
          },
        },
        mutations: {
          onError(error, variables, context) {
            console.log(error);
            // toast("에러");
          },
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="top-center" hideProgressBar autoClose={500} />
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

root.render(
  <AuthProvider>
    <EditProvider>
      <ReportProvider>
        <Wrapper />
      </ReportProvider>
    </EditProvider>
  </AuthProvider>,
);

reportWebVitals();
