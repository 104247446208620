import React, { ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./dailyReport.css";
import { useOperationReport, usePiggeryMonitoring } from "query/piggery";
import { format, setHours } from "date-fns";
import { useTranslation } from "react-i18next";
import ComponentSpinner from "components/common/spinner/spinner";
import { useNavigate } from "react-router-dom";
import { datePickerLocale } from "utils/useDatepicker";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import _ from "lodash";
import NoImg from "assets/images/report/no_report_data.png";
import CameraDetailViewModal from "./CameraDetailViewModal";
import PiggeryViewModal from "./PiggeryViewModal";

type Props = {
  selectedPiggery: number;
  selectedDate: Date;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: 8,
    padding: "12px 16px",
    fontFamily: "Pretendard",
  },
}));

const tooltipDivider = <div className="h-12pxr w-1pxr bg-ef-neutral-200"></div>;

function DailyReport(props: Props) {
  const { selectedPiggery, selectedDate } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(true); // true = 상세 <-> false = 요약

  const [scrollState, setScrollState] = useState({
    position: 0,
    clientWidth: 0,
    scrollWidth: 0,
  });

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { data: reportData, isLoading: isTableLoading, isError: isTableError } = useOperationReport(selectedPiggery, format(selectedDate, "yyyy-MM-dd"));
  const herdArr = reportData ? reportData.herds : [];

  const { data: imageData, isLoading: isImgLoading } = usePiggeryMonitoring(selectedPiggery, { base_datetime: format(setHours(selectedDate, 12), "yyyy-MM-dd'T'HH:00:00") });
  const imgArr = imageData ? imageData.efg_camera : [];

  // 카메라 상세보기 모달
  const [selectedCamId, setSelectedCamId] = useState(0);
  const [toggleCamDetail, setToggleCamDetail] = useState(false);

  // 이미지 전체보기 모달
  const [toggleViewAll, setToggleViewAll] = useState(false);

  const handleScrollBtn = (type: "next" | "prev") => {
    if (!scrollRef.current) return;
    if (type === "prev") {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - 170,
        behavior: "smooth",
      });
    } else {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + 170,
        behavior: "smooth",
      });
    }
  };

  // 가로 스크롤 핸들링(디바운스 해서 버튼, shadow 노출)
  useEffect(() => {
    const handleScroll = _.debounce(() => {
      if (scrollRef.current) {
        setScrollState({
          position: scrollRef.current.scrollLeft,
          clientWidth: scrollRef.current.clientWidth,
          scrollWidth: scrollRef.current.scrollWidth,
        });
      }
    }, 100);

    const current = scrollRef.current;

    current?.addEventListener("scroll", handleScroll);

    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 데이터 변경시 scroll 초기화
  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        setScrollState({
          position: scrollRef.current.scrollLeft,
          clientWidth: scrollRef.current.clientWidth,
          scrollWidth: scrollRef.current.scrollWidth,
        });
      }
    }, 500);
  }, [reportData]);

  // 툴팁과 함께 td 만드는 함수
  const getTdWithTooltip = (index: number, data: number | string, tooltipContent: ReactElement | string, css?: string) => {
    return (
      <HtmlTooltip
        disableInteractive
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
        title={tooltipContent}
        key={index}
      >
        <td className={`max-w-[170px] overflow-hidden text-ellipsis whitespace-nowrap ${css}`}>{data}</td>
      </HtmlTooltip>
    );
  };

  return (
    <div className="w-full h-full flex flex-row relative">
      {isTableLoading && <ComponentSpinner />}
      <CameraDetailViewModal isToggle={toggleCamDetail} setIsToggle={setToggleCamDetail} camId={selectedCamId} selectedDate={selectedDate} />
      <PiggeryViewModal isToggle={toggleViewAll} setIsToggle={setToggleViewAll} selectedDate={selectedDate} selectedPiggery={selectedPiggery} />
      <div className="border-r border-solid border-ef-neutral-200 w-476pxr min-w-[476px] bg-ef-neutral-white p-40pxr flex flex-col gap-40pxr">
        {/* 이미지 스와이퍼 */}
        <div className="flex flex-col gap-24pxr">
          <div className="flex flex-row items-center">
            <span className="ef-headline-md whitespace-nowrap overflow-hidden text-ellipsis">{t("report.time_room_image", { time: 12 })}</span>
            <button
              onClick={() => setToggleViewAll(true)}
              type="button"
              className="ml-auto items-center flex flex-row gap-4pxr ef-label-xl text-ef-neutral-700 stroke-ef-neutral-700 whitespace-nowrap"
            >
              <span>{t("report.see_all")}</span>
              <ArrowRight />
            </button>
          </div>
          <div className="w-full relative border border-ef-neutral-200 rounded-lg">
            <button type="button" className="arrow-left rounded-full p-8pxr bg-ef-neutral-900 bg-opacity-70 absolute top-91pxr left-8pxr z-[10] cursor-pointer">
              <ArrowLeft className="stroke-ef-neutral-white" />
            </button>
            <button type="button" className="arrow-right rounded-full p-8pxr bg-ef-neutral-900 bg-opacity-70 absolute top-91pxr right-8pxr z-[10] cursor-pointer">
              <ArrowRight className="stroke-ef-neutral-white" />
            </button>
            <Swiper
              navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
            >
              {imgArr.length > 0 ? (
                imgArr.map((d, idx) => (
                  <SwiperSlide key={idx}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCamId(d.id);
                        setToggleCamDetail(true);
                      }}
                      className="w-full flex flex-col cursor-pointer"
                    >
                      <div className="rounded-t-[8px] aspect-video relative">
                        <div className="w-full h-full rounded-t-[8px] bg-ef-neutral-50 flex flex-col absolute top-0pxr left-0pxr items-center justify-center">
                          <img src={NoImg} className="w-140pxr h-99pxr mx-auto" alt="error-img" />
                          {d.history.length === 0 ? (
                            <span className="ef-body-sm text-ef-neutral-700 text-center">{t("common.no_data")}</span>
                          ) : (
                            <span className="ef-body-sm text-ef-neutral-700 text-center">{t("common.image_path_not_found")}</span>
                          )}
                        </div>
                        <img
                          className="absolute top-0pxr left-0pxr rounded-t-[8px]"
                          src={d.history.length === 0 ? "" : d.history[0].image_path}
                          alt={`${d.name}-${d.room.name}`}
                          onError={(e) => (e.currentTarget.hidden = true)}
                        />
                      </div>
                      <div className="p-16pxr flex flex-row gap-8pxr items-center rounded-b-[8px]">
                        <span className="ef-label-xl">{d.room.name}</span>
                        <span className="ef-body-xxl">{d.name}</span>
                        <span className="ef-body-lg ml-auto">
                          <span className="ef-body-xl text-ef-primary-500">{idx + 1}</span>
                          <span className="text-ef-neutral-700">/{imgArr.length}</span>
                        </span>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : isImgLoading ? (
                <div className="w-full h-290pxr"></div>
              ) : (
                <SwiperSlide>
                  <div className="w-full h-290pxr flex flex-col items-center justify-center">
                    <img src={NoImg} alt="no-img" className="w-243pxr" />
                    <p className="pt-18pxr ef-body-xxl text-ef-neutral-600 text-center">{t("common.no_info")}</p>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
        {/* 재고 변화 */}
        <div className="flex flex-col gap-24pxr">
          <div className="flex flex-row items-center">
            <span className="ef-headline-md whitespace-nowrap overflow-hidden text-ellipsis">{t("report.inventory_change")}</span>
            <button
              onClick={() => navigate("/manage/stock/record")}
              type="button"
              className="ml-auto items-center flex flex-row gap-4pxr ef-label-xl text-ef-neutral-700 stroke-ef-neutral-700 whitespace-nowrap"
            >
              <span>{t("report.view_details")}</span>
              <ArrowRight />
            </button>
          </div>
          <table className="!w-full ef-table-toggle">
            <tbody className="[&>*]:text-left">
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100 w-167pxr max-w-[167px] rounded-tl-[8px]">{t("report.total_heads_yesterday")}</td>
                <td>{`${reportData?.stock.yesterday ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("report.arrival_heads")}</td>
                <td>{`${reportData?.stock.arrival ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("report.shipment_heads")}</td>
                <td>{`${reportData?.stock.shipment ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("report.move_in_heads")}</td>
                <td>{`${reportData?.stock.in ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("report.move_out_heads")}</td>
                <td>{`${reportData?.stock.out ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("report.death_heads")}</td>
                <td>{`${reportData?.stock.death ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.culling_heads")}</td>
                <td>{`${reportData?.stock.culling ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.error_heads")}</td>
                <td>{`${reportData?.stock.error ?? 0}${t("common.head")}`}</td>
              </tr>
              <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
                <td className="bg-ef-neutral-500 text-ef-neutral-white">{t("report.total_heads_raised")}</td>
                <td className="ef-body-md">{`${reportData?.stock.total ?? 0}${t("common.head")}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 실내대기 */}
        <div className="flex flex-col gap-24pxr">
          <div className="flex flex-row items-center">
            <span className="ef-headline-md whitespace-nowrap overflow-hidden text-ellipsis">{t("report.indoor_atmosphere")}</span>
            <button
              onClick={() => navigate("/manage/environment/sensorLog", { state: { date: selectedDate } })}
              type="button"
              className="ml-auto items-center flex flex-row gap-4pxr ef-label-xl text-ef-neutral-700 stroke-ef-neutral-700 whitespace-nowrap"
            >
              <span>{t("report.view_details")}</span>
              <ArrowRight />
            </button>
          </div>
          <div className="w-full flex flex-col">
            <table className="w-full ef-table-toggle">
              <thead>
                <tr className="bg-ef-neutral-200 [&>*]:!py-10pxr">
                  <th colSpan={2} className="w-124pxr max-w-[124px]">
                    {t("report.item")}
                  </th>
                  <th className="w-136pxr max-w-[136px]">{format(selectedDate, "MMM do", { locale: datePickerLocale() })}</th>
                  <th className="w-136pxr max-w-[136px]">{t("report.date_last_year", { date: format(selectedDate, "MMM do", { locale: datePickerLocale() }) })}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.temperature")} rowSpan={3} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.temperature")}
                  </td>
                  <td title={t("common.average")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.average")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.temperature.avg !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.temperature.avg !== null
                          ? `${reportData.sensor.this_year.temperature.avg.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.temperature.avg !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.temperature.avg !== null
                          ? `${reportData.sensor.last_year.temperature.avg.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.highest")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.highest")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.temperature.upper !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.temperature.upper !== null
                          ? `${reportData.sensor.this_year.temperature.upper.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.temperature.upper !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.temperature.upper !== null
                          ? `${reportData.sensor.last_year.temperature.upper.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.lowest")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.lowest")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.temperature.lower !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.temperature.lower !== null
                          ? `${reportData.sensor.this_year.temperature.lower.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.temperature.lower !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.temperature.lower !== null
                          ? `${reportData.sensor.last_year.temperature.lower.toFixed(2)}℃`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.humidity")} rowSpan={3} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.humidity")}
                  </td>
                  <td title={t("common.average")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.average")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.humidity.avg !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.humidity.avg !== null
                          ? `${reportData.sensor.this_year.humidity.avg.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.humidity.avg !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.humidity.avg !== null
                          ? `${reportData.sensor.last_year.humidity.avg.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.highest")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.highest")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.humidity.upper !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.humidity.upper !== null
                          ? `${reportData.sensor.this_year.humidity.upper.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.humidity.upper !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.humidity.upper !== null
                          ? `${reportData.sensor.last_year.humidity.upper.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td title={t("common.lowest")} className="bg-ef-neutral-100 max-w-[62px]">
                    {t("common.lowest")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.humidity.lower !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.humidity.lower !== null
                          ? `${reportData.sensor.this_year.humidity.lower.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.humidity.lower !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.humidity.lower !== null
                          ? `${reportData.sensor.last_year.humidity.lower.toFixed(2)}%`
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
                <tr className="[&>*]:!py-10pxr">
                  <td colSpan={2} className="bg-ef-neutral-100">
                    {t("common.heat_index")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.this_year.has_sensor && reportData.sensor.this_year.heat_index.index !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.this_year.has_sensor
                        ? reportData.sensor.this_year.heat_index.index !== null
                          ? Math.floor(reportData.sensor.this_year.heat_index.index)
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                  <td
                    style={{
                      color: reportData && reportData.sensor.last_year.has_sensor && reportData.sensor.last_year.heat_index.index !== null ? "" : "#878787",
                    }}
                  >
                    {reportData
                      ? reportData.sensor.last_year.has_sensor
                        ? reportData.sensor.last_year.heat_index.index !== null
                          ? Math.floor(reportData.sensor.last_year.heat_index.index)
                          : t("common.no_info")
                        : t("common.no_device")
                      : t("common.no_info")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* main table ======================================================================== */}
      <div className="w-full h-full pb-200pxr p-40pxr">
        <div className="flex flex-row items-center">
          <span className="ef-headline-md">{`${t("report.growth_records_by_herd")} ${herdArr.length > 0 ? `[${herdArr[0].info.piggery.name}]` : ""}`}</span>
          <div className="flex flex-row gap-8pxr ef-label-md ml-auto">
            <button
              onClick={() => setSortState(true)}
              type="button"
              className={
                "rounded-[4px] py-4pxr min-w-[80px] px-16pxr w-fit border border-solid " +
                (sortState
                  ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
                  : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
              }
            >
              {t("report.detailed_mode")}
            </button>
            <button
              onClick={() => setSortState(false)}
              type="button"
              className={
                "rounded-[4px] py-4pxr min-w-[80px] px-16pxr w-fit border border-solid " +
                (!sortState
                  ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
                  : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
              }
            >
              {t("report.summary_mode")}
            </button>
          </div>
        </div>
        {/* 메인 테이블 */}
        <div className="w-full h-fit relative mt-24pxr">
          {scrollState.position > 0 && (
            <>
              <div className="w-160pxr h-[calc(100%-8px)] absolute z-[10] left-232pxr bg-[linear-gradient(270deg,_#F8F8F800_0%,_#f8f8f8_100%)]"></div>
              <button
                onClick={() => handleScrollBtn("prev")}
                type="button"
                className="w-40pxr h-40pxr bg-ef-neutral-900 bg-opacity-50 stroke-ef-neutral-white absolute top-100pxr left-252pxr rounded-full flex items-center justify-center z-[10]"
              >
                <ArrowLeft />
              </button>
            </>
          )}
          {scrollState.position < scrollState.scrollWidth - scrollState.clientWidth - 1 && (
            <>
              <div className="w-160pxr h-[calc(100%-8px)] absolute z-[10] right-0pxr bg-[linear-gradient(270deg,_#F8F8F8_0%,_#f8f8f800_100%)]"></div>
              <button
                onClick={() => handleScrollBtn("next")}
                type="button"
                className="w-40pxr h-40pxr bg-ef-neutral-900 bg-opacity-50 stroke-ef-neutral-white absolute top-100pxr right-60pxr rounded-full flex items-center justify-center z-[10]"
              >
                <ArrowRight />
              </button>
            </>
          )}
          <div ref={scrollRef} className="w-full max-w-[1360px] overflow-x-auto relative">
            {isTableLoading ? (
              <></>
            ) : isTableError || herdArr.length === 0 ? (
              <div className="w-full flex flex-col pt-120pxr justify-center items-center">
                <img src={NoImg} alt="no-img" className="w-243pxr" />
                <p className="pt-18pxr ef-body-xxl text-ef-neutral-600 text-center">{t("common.no_info")}</p>
              </div>
            ) : (
              <table id="data-table" className="ef-table-toggle !w-fit">
                <thead>
                  <tr>
                    <th colSpan={2}>{t("report.item")}</th>
                    {herdArr.map((d, idx) => (
                      <th key={idx} className="w-170pxr min-w-[170px] max-w-[170px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {d.info.herd_nickname !== null ? d.info.herd_nickname : d.info.herd_name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* 기본정보 */}
                  <tr>
                    <td title={t("report.basic_information")} rowSpan={sortState ? 4 : 3} className="sticky left-0pxr bg-ef-neutral-100 td-header">
                      {t("report.basic_information")
                        .split("\n")
                        .map((line: any, index: number) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </td>
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.breeding_status")}</td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(idx, d.info.herd_active_status ? t("common.breeding") : t("common.closed"), "", d.info.herd_active_status ? "text-ef-primary-500" : "text-ef-neutral-500"),
                    )}
                  </tr>
                  <tr>
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.location")}</td>
                    {herdArr.map((d, idx) => getTdWithTooltip(idx, `[${d.info.piggery.name}] ${d.info.room.name}`, ""))}
                  </tr>
                  <tr>
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100 ">{t("common.birth_date_aged_day")}</td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        d.info.birth_date ? `${d.info.birth_date}(${d.info.age}${t("common.aged")})` : "-",
                        d.info.birth_date ? "" : <span className="ef-body-sm">{t("report.birth_date_not_registered")}</span>,
                        `${d.info.birth_date ? "" : "hover:text-ef-neutral-white hover:bg-ef-primary-500"}`,
                      ),
                    )}
                  </tr>
                  {sortState && (
                    <tr>
                      <td title={`${t("common.move_in_date")}(${t("report.move_in_period")})`} className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{`${t(
                        "common.move_in_date",
                      )}(${t("report.move_in_period")})`}</td>
                      {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.info.arrival_date}(${t("manage.day", { day: d.info.arrival_age })})`, "", ""))}
                    </tr>
                  )}
                  {/* 재고 */}
                  <tr>
                    <td title={t("report.inventory")} rowSpan={sortState ? 9 : 2} className="sticky left-0pxr bg-ef-neutral-100 td-header">
                      {t("report.inventory")}
                    </td>
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("report.stock_yesterday")}</td>
                    {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.yesterday}${t("common.head")}`, "", "text-left"))}
                  </tr>
                  {sortState && (
                    <>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.arrival")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.arrival}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.shipment")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.shipment}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.move_in")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.in}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.move_out")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.out}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.death")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.death}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.culling")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.culling}${t("common.head")}`, "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.error")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.error}${t("common.head")}`, "", "text-left"))}
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("report.total_stock")}</td>
                    {herdArr.map((d, idx) => getTdWithTooltip(idx, `${d.stock.total}${t("common.head")}${!sortState ? `(±${(d.stock.yesterday - d.stock.total) * 0})` : ""}`, "", "text-left"))}
                  </tr>
                  {/* 성장 */}
                  <tr>
                    <td rowSpan={sortState ? 8 : 2} className="sticky left-0pxr bg-ef-neutral-100 td-header">
                      {t("report.growth")}
                    </td>
                    <td title={`${t("common.average_weight")}(${t("common.weight_gain")})`} className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{`${t(
                      "common.average_weight",
                    )}(${t("common.weight_gain")})`}</td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        `${
                          d.growth.avg_weight
                            ? `${d.growth.avg_weight.toFixed(2)}kg${d.growth.weight_gain ? `(${d.growth.weight_gain >= 0 ? "+" : ""}${d.growth.weight_gain.toFixed(2)}kg)` : ""}`
                            : t("common.no_info")
                        }`,
                        "",
                        "text-left",
                      ),
                    )}
                  </tr>
                  <tr>
                    <td title={t("report.total_feeding_amount")} className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">
                      {t("report.total_feeding_amount")}
                    </td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        d.growth.feeder.total_mount ? `${Math.floor(d.growth.feeder.total_mount)}kg` : t("common.no_info"),
                        d.growth.feeder.total_mount ? (
                          <div className="flex flex-col gap-8pxr ef-body-sm">
                            <div className="flex flex-row gap-8pxr">
                              <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                              {tooltipDivider}
                              <span>{d.growth.feeder.dawn ? `${Math.floor(d.growth.feeder.dawn)}kg` : t("common.no_info")}</span>
                            </div>
                            <div className="flex flex-row gap-8pxr">
                              <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                              {tooltipDivider}
                              <span>{d.growth.feeder.date_time ? `${Math.floor(d.growth.feeder.date_time)}kg` : t("common.no_info")}</span>
                            </div>
                            <div className="flex flex-row gap-8pxr">
                              <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                              {tooltipDivider}
                              <span>{d.growth.feeder.night ? `${Math.floor(d.growth.feeder.night)}kg` : t("common.no_info")}</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        ),
                        `text-left ${d.growth.feeder.total_mount ? "hover:text-ef-neutral-white hover:bg-ef-primary-500" : ""}`,
                      ),
                    )}
                  </tr>
                  {sortState && (
                    <>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.feeding_time")}</td>
                        {herdArr.map((d, idx) =>
                          getTdWithTooltip(
                            idx,
                            d.growth.feeding_time.avg !== null ? `${d.growth.feeding_time.avg.toFixed(2)}${t("common.minutes")}` : t("common.no_info"),
                            d.growth.feeding_time.avg !== null ? (
                              <div className="flex flex-col gap-8pxr ef-body-sm">
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.feeding_time.dawn !== null ? `${d.growth.feeding_time.dawn.toFixed(2)}${t("common.minutes")}` : t("common.no_info")}</span>
                                </div>
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.feeding_time.date_time !== null ? `${d.growth.feeding_time.date_time.toFixed(2)}${t("common.minutes")}` : t("common.no_info")}</span>
                                </div>
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.feeding_time.night !== null ? `${d.growth.feeding_time.night.toFixed(2)}${t("common.minutes")}` : t("common.no_info")}</span>
                                </div>
                              </div>
                            ) : (
                              ""
                            ),
                            `text-left ${d.growth.feeding_time.avg ? "hover:text-ef-neutral-white hover:bg-ef-primary-500" : ""}`,
                          ),
                        )}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.feeder_approach_count")}</td>
                        {herdArr.map((d, idx) =>
                          getTdWithTooltip(
                            idx,
                            d.growth.feeder_access_count === null ? t("common.no_info") : `${d.growth.feeder_access_count.toLocaleString()}${t("common.count_time")}`,
                            "",
                            "text-left",
                          ),
                        )}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.vitality")}</td>
                        {herdArr.map((d, idx) =>
                          getTdWithTooltip(
                            idx,
                            d.growth.activity.avg ? `${d.growth.activity.avg.toFixed(1)}m` : t("common.no_info"),
                            d.growth.activity.avg ? (
                              <div className="flex flex-col gap-8pxr ef-body-sm">
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.activity.dawn ? `${d.growth.activity.dawn.toFixed(1)}m` : t("common.no_info")}</span>
                                </div>
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.activity.date_time ? `${d.growth.activity.date_time.toFixed(1)}m` : t("common.no_info")}</span>
                                </div>
                                <div className="flex flex-row gap-8pxr">
                                  <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                  {tooltipDivider}
                                  <span>{d.growth.activity.night ? `${d.growth.activity.night.toFixed(1)}m` : t("common.no_info")}</span>
                                </div>
                              </div>
                            ) : (
                              ""
                            ),
                            `text-left ${d.growth.activity.avg ? "hover:text-ef-neutral-white hover:bg-ef-primary-500" : ""}`,
                          ),
                        )}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.disease_signs")}</td>
                        {/* @J-TODO 질병징후 */}
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, "-", "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("report.cumulative_fcr")}</td>
                        {/* @J-TODO 사료요구율 */}
                        {/* {herdArr.map((d, idx) => getTdWithTooltip(idx, d.growth.feed_require !== null ? Math.floor(d.growth.feed_require) : t("common.no_info"), "", "text-left"))} */}
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, "-", "", "text-left"))}
                      </tr>
                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.growing_rate")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, d.growth.growing_rate !== null ? `${Math.floor(d.growth.growing_rate)}%` : t("common.no_info"), "", "text-left"))}
                      </tr>
                    </>
                  )}
                  {/* 규격 외 */}
                  {sortState && (
                    <>
                      <tr>
                        <td title={t("common.out_of_standard")} rowSpan={4} className="sticky left-0pxr bg-ef-neutral-100 td-header">
                          {t("common.out_of_standard")}
                        </td>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.underweight")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, d.weight_etc.underweight !== null ? `${Math.floor(d.weight_etc.underweight)}${t("common.head")}` : "-", "", "text-left"))}
                      </tr>

                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.overweight")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, d.weight_etc.overweight !== null ? `${Math.floor(d.weight_etc.overweight)}${t("common.head")}` : "-", "", "text-left"))}
                      </tr>

                      <tr>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.suspected_death")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, "-", "", "text-left"))}
                      </tr>
                      <tr>
                        <td title={t("common.move_recommendation")} className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">
                          {t("common.move_recommendation")}
                        </td>
                        {herdArr.map((d, idx) =>
                          getTdWithTooltip(idx, d.weight_etc.shipment_recommendation !== null ? `${Math.floor(d.weight_etc.shipment_recommendation)}${t("common.head")}` : "-", "", "text-left"),
                        )}
                      </tr>
                    </>
                  )}
                  {/* 환경 */}
                  <tr>
                    <td title={t("report.environment")} rowSpan={sortState ? 3 : 2} className="sticky left-0pxr bg-ef-neutral-100 td-header">
                      {t("report.environment")}
                    </td>
                    <td
                      title={t("report.average_temperature_highest_lowest")}
                      dangerouslySetInnerHTML={{ __html: t("report.average_temperature_highest_lowest").replaceAll("\n", "<br/>") }}
                      className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100 !py-0pxr"
                    ></td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        d.sensor.temperature.avg !== null
                          ? `${d.sensor.temperature.avg.toFixed(2)}℃\r\n${
                              d.sensor.temperature.upper !== null && d.sensor.temperature.lower !== null ? `(${d.sensor.temperature.upper.toFixed(2)}℃/${d.sensor.temperature.lower.toFixed(2)}℃)` : ""
                            }`
                          : t("common.no_info"),
                        "",
                        "text-left !whitespace-pre-line !py-0pxr",
                      ),
                    )}
                  </tr>
                  <tr>
                    <td
                      dangerouslySetInnerHTML={{ __html: t("report.average_humidity_highest_lowest").replaceAll("\n", "<br/>") }}
                      className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100 !py-0pxr"
                    ></td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        d.sensor.humidity.avg !== null
                          ? `${d.sensor.humidity.avg.toFixed(2)}%\r\n${
                              d.sensor.humidity.upper !== null && d.sensor.humidity.lower !== null ? `(${d.sensor.humidity.upper.toFixed(2)}%/${d.sensor.humidity.lower.toFixed(2)}%)` : ""
                            }`
                          : t("common.no_info"),
                        "",
                        "text-left !whitespace-pre-line !py-0pxr",
                      ),
                    )}
                  </tr>
                  {sortState && (
                    <tr>
                      <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("common.heat_index")}</td>
                      {herdArr.map((d, idx) =>
                        getTdWithTooltip(
                          idx,
                          d.sensor.heat_index !== null && d.sensor.heat_index.index !== null
                            ? `${t(`common.heat_${d.sensor.heat_index.category}`)}(${Math.floor(d.sensor.heat_index.index)})`
                            : t("common.no_info"),
                          "",
                          "text-left",
                        ),
                      )}
                    </tr>
                  )}
                  {/* 기타 */}
                  {sortState && (
                    <>
                      <tr>
                        <td rowSpan={2} className="sticky left-0pxr bg-ef-neutral-100 td-header !rounded-bl-[8px]" style={{ boxShadow: "-5px 5px 0 #f8f8f8" }}>
                          {t("report.etc")}
                        </td>
                        <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100">{t("report.health_event")}</td>
                        {herdArr.map((d, idx) => getTdWithTooltip(idx, t("common.preparing_now"), "", "text-left"))}
                      </tr>
                    </>
                  )}
                  <tr>
                    {!sortState && <td className="sticky left-0pxr bg-ef-neutral-100 td-header !rounded-bl-[8px]" style={{ boxShadow: "-5px 5px 0 #f8f8f8" }}>{t("report.etc")}</td>}
                    <td className="sticky left-80pxr max-w-[152px] min-w-[152px] text-left bg-ef-neutral-100 !rounded-none">{t("common.breeding_density")}</td>
                    {herdArr.map((d, idx) =>
                      getTdWithTooltip(
                        idx,
                        d.etc.breeding_density ? `${Math.floor(d.etc.breeding_density)}%` : t("common.no_info"),
                        d.etc.breeding_density ? "" : <span className="ef-body-sm">{t("report.area_not_registered")}</span>,
                        `text-left ${
                          d.etc.breeding_density ? (d.etc.breeding_density > 100 ? "bg-ef-state-red-50 text-ef-state-red-500" : "") : "hover:bg-ef-primary-500 hover:text-ef-neutral-white"
                        }`,
                      ),
                    )}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyReport;
