import React, { memo, useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Clock } from "assets/icons/general/ef-general-clock.svg";
import { ReactComponent as Pig } from "assets/icons/general/ef-general-pig.svg";
import { ReactComponent as Weigher } from "assets/icons/general/ef-general-weigher.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEditStockHistoryDetail, useStockHistoryDetail } from "query/herd";
import { format } from "date-fns";
import { datePickerLocale } from "utils/useDatepicker";
import { toast } from "react-toastify";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  historyId: number;
};

function RecordHistoryModal(props: Props) {
  const { isToggle, setIsToggle, historyId } = props;
  const { t } = useTranslation();

  const { data: historyDetail } = useStockHistoryDetail(historyId);
  const { mutateAsync: editHistoryDetail } = useEditStockHistoryDetail();

  const [memoState, setMemoState] = useState("");

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setMemoState("");
      setIsToggle(false);
    }
  };

  useEffect(() => {
    if (historyDetail) {
      setMemoState(historyDetail.memo !== null ? historyDetail.memo : "");
    }
  }, [historyDetail, isToggle]);

  const handleSubmit = async () => {
    try {
      await editHistoryDetail({
        history_id: historyId,
        payload: {
          memo: memoState,
        },
      });
      toast.success(t("common.save_completed"));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr pb-120pxr overflow-y-auto"
    >
      <div className="w-734pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("gnb.stock_history")} ${t("common.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        {/* summary */}
        <div className="mt-60pxr flex flex-row gap-16pxr mx-auto h-120pxr">
          <div className="p-16pxr flex flex-row gap-8pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-50">
            <div className="mt-auto ef-body-xl">
              <p className="ef-label-sm text-ef-primary-500">{t("common.work_datetime")}</p>
              <p>{historyDetail ? format(new Date(historyDetail.created_at), "yyyy-MM-dd(EEE)", { locale: datePickerLocale() }) : "-"}</p>
              <p>{historyDetail ? format(new Date(historyDetail.created_at), "HH:mm") : "--:--"}</p>
            </div>
            <div className="rounded-full w-40pxr h-40pxr min-w-[40px] bg-ef-neutral-300 stroke-ef-neutral-white flex items-center justify-center">
              <Clock />
            </div>
          </div>
          <div className="p-16pxr flex flex-row gap-8pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-50">
            <div className="mt-auto ef-body-xl">
              <p className="ef-label-sm text-ef-primary-500">{t("common.moved_stock")}</p>
              <p>{`${historyDetail ? historyDetail.moved_stock : "-"}${t("common.head")}`}</p>
            </div>
            <div className="rounded-full w-40pxr h-40pxr min-w-[40px] bg-ef-neutral-300 stroke-ef-neutral-white flex items-center justify-center">
              <Pig />
            </div>
          </div>
          <div className="p-16pxr flex flex-row gap-8pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-50">
            <div className="mt-auto ef-body-xl">
              <p className="ef-label-sm text-ef-primary-500">{t("common.number_of_stocks")}</p>
              <p>{`${historyDetail ? historyDetail.stock : "-"}${t("common.head")}`}</p>
            </div>
            <div className="rounded-full w-40pxr h-40pxr min-w-[40px] bg-ef-neutral-300 stroke-ef-neutral-white flex items-center justify-center">
              <Pig />
            </div>
          </div>
        </div>
        {/* herd info */}
        <div className="mt-40pxr w-full flex flex-col gap-24pxr">
          <p className="ef-title-md">{t("manage.movement_information")}</p>
          <table className="w-full border-collapse border border-solid border-ef-neutral-200">
            <thead>
              <tr className="bg-ef-neutral-50 [&>*]:text-left [&>*]:py-14pxr [&>*]:px-12pxr [&>*]:ef-title-sm [&>*]:border [&>*]:border-solid [&>*]:border-ef-neutral-200">
                <th className="w-180pxr max-w-[180px]">{t("common.herd_name")}</th>
                <th className="w-100pxr max-w-[100px]">{t("common.work_type")}</th>
                <th className="w-144pxr max-w-[144px] overflow-hidden text-ellipsis whitespace-nowrap">{t("common.birth_date_aged_day")}</th>
                <th className="w-230pxr max-w-[230px]">{t("common.location")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="[&>*]:py-16pxr [&>*]:px-12pxr [&>*]:border [&>*]:border-solid [&>*]:border-ef-neutral-200 [&>*]:ef-body-lg [&>*]:overflow-hidden [&>*]:text-ellipsis [&>*]:whitespace-nowrap">
                <td title={historyDetail ? (historyDetail.herd.nickname !== null ? historyDetail.herd.nickname : historyDetail.herd.name) : ""} className="w-180pxr max-w-[180px]">
                  {historyDetail ? (historyDetail.herd.nickname !== null ? historyDetail.herd.nickname : historyDetail.herd.name) : "-"}
                </td>
                <td className="w-100pxr max-w-[100px]">{historyDetail ? historyDetail.category.name : "-"}</td>
                <td className="w-144pxr max-w-[144px]">
                  {historyDetail ? historyDetail.herd.birth_date : "-"}
                  <br />
                  {`(${historyDetail ? historyDetail.herd.age : "-"}${t("common.aged")})`}
                </td>
                <td className="w-230pxr max-w-[230px]">{historyDetail ? `[${historyDetail.herd.room.piggery.name}] ${historyDetail.herd.room.name}` : "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 메모 */}
        <div className="mt-24pxr rounded-[8px] border border-solid border-ef-neutral-150 p-24pxr flex flex-col gap-16pxr">
          <div className="flex flex-row items-center">
            <span className="ef-title-md">{t("common.memo")}</span>
            <button disabled={memoState === historyDetail?.memo} onClick={handleSubmit} type="button" className="ml-auto ef-title-md text-ef-primary-500 disabled:text-ef-neutral-500">
              {t("common.save_btn")}
            </button>
          </div>
          <textarea value={memoState} onChange={(e) => setMemoState(e.target.value)} className="ef-textarea h-280pxr" placeholder={t("common.enter_memo")}></textarea>
        </div>
      </div>
    </Modal>
  );
}

export default RecordHistoryModal;
