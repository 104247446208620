import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { DailyReport } from "query/report/type";
import React, { useEffect, useState } from "react";
import NoImg from "assets/images/report/no_report_data.png";
import { useTranslation } from "react-i18next";

type Props = {
  data: DailyReport | undefined;
};

function DetailDailyReport(props: Props) {
  const { data } = props;

  const { t } = useTranslation();

  const [selectedHerd, setSelectedHerd] = useState(0);
  const detailData = data?.herds.find((o) => o.info.herd_id === selectedHerd);

  useEffect(() => {
    if (typeof data !== "undefined") {
      setSelectedHerd(data.herds[0].info.herd_id);
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-16pxr">
      <div className="flex flex-row items-center">
        <span className="ef-label-lg">{t("report.select_herd")}</span>
        {typeof data !== "undefined" && (
          <div className="ml-auto w-190pxr">
            <DropDownSmall
              state={selectedHerd}
              setState={setSelectedHerd}
              options={
                data
                  ? data.herds.map((o) => {
                      return {
                        value: o.info.herd_id,
                        name: o.info.herd_name,
                      };
                    })
                  : []
              }
            />
          </div>
        )}
      </div>
      {typeof detailData !== "undefined" ? (
        <table className="ef-table-toggle">
          <thead className="!bg-ef-primary-50">
            <tr className="[&>*]:!py-12pxr">
              <th className="w-150pxr max-w-[150px]">{t("report.item")}</th>
              <th className="w-170pxr max-w-[170px]">{detailData.info.herd_nickname !== null ? detailData.info.herd_nickname : detailData.info.herd_name}</th>
            </tr>
          </thead>
          <tbody className="[&_tr>td:first-child]:bg-ef-neutral-100 [&_tr>td]:text-left">
            <tr className="[&>*]:!py-12pxr">
              <td>{t("common.breeding_status")}</td>
              <td
                style={{
                  color: detailData.info.herd_active_status ? "#033687" : "#878787",
                }}
                className="!text-center"
              >
                {detailData.info.herd_active_status ? t("common.breeding") : t("common.closed")}
              </td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("common.location")}</td>
              <td className="!text-center">{`[${detailData.info.piggery.name}] ${detailData.info.room.name}`}</td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("common.birth_date_aged_day")}</td>
              <td className="!text-center">{`${detailData.info.birth_date}(${detailData.info.age}${t("common.aged")})`}</td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("report.stock_yesterday")}</td>
              <td>{`${detailData.stock.yesterday}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("report.total_stock")}</td>
              <td>{`${detailData.stock.total}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("report.total_feeding_amount")}</td>
              <td>{detailData.growth.feeder.total_mount !== null ? `${detailData.growth.feeder.total_mount.toFixed(2)}kg` : t("common.no_info")}</td>
            </tr>
            <tr>
              <td className="!py-0pxr">
                {t("report.average_temperature_highest_lowest")
                  .split("\n")
                  .map((line: any, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </td>
              <td className="!py-0pxr">
                {detailData.sensor.temperature.avg !== null ? `${detailData.sensor.temperature.avg.toFixed(2)}℃` : t("common.no_info")}
                <br />
                {detailData.sensor.temperature.upper !== null && detailData.sensor.temperature.lower !== null
                  ? `(${detailData.sensor.temperature.lower.toFixed(2)}℃/${detailData.sensor.temperature.upper.toFixed(2)}℃)`
                  : ""}
              </td>
            </tr>
            <tr>
              <td className="!py-0pxr">
                {t("report.average_humidity_highest_lowest")
                  .split("\n")
                  .map((line: any, index: number) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </td>
              <td className="!py-0pxr">
                {detailData.sensor.humidity.avg !== null ? `${detailData.sensor.humidity.avg.toFixed(2)}%` : t("common.no_info")}
                <br />
                {detailData.sensor.humidity.upper !== null && detailData.sensor.humidity.lower !== null
                  ? `(${detailData.sensor.humidity.lower.toFixed(2)}%/${detailData.sensor.humidity.upper.toFixed(2)}%)`
                  : ""}
              </td>
            </tr>
            <tr className="[&>*]:!py-12pxr">
              <td>{t("common.breeding_density")}</td>
              <td>{detailData.etc.breeding_density !== null ? `${Math.floor(detailData.etc.breeding_density)}%` : t("common.no_info")}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col pt-40pxr items-center">
          <img src={NoImg} alt="no-img" className="w-243pxr h-172pxr" />
          <p className="pt-18pxr ef-body-lg text-ef-neutral-600 text-center">{t("common.no_info")}</p>
        </div>
      )}
    </div>
  );
}

export default DetailDailyReport;
