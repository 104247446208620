import React from "react";
import { ReactComponent as Danger } from "assets/icons/icon16_general/ef-16general-danger.svg";
import { useTranslation } from "react-i18next";

function RequiredAlert() {
  const { t } = useTranslation();
  return (
    <div className="absolute top-[-18px] z-[999] left-80pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
      <Danger className="fill-ef-secondary-500" />
      <span>{t("common.required_comment")}</span>
    </div>
  );
}

export default RequiredAlert;
