import React, { useState } from "react";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { format } from "date-fns";
import NoImg from "assets/images/report/no_report_data.png";
import { usePiggeries } from "query/piggery";
import { useFeederCompanyList, useFeederList } from "query/feeder";
import FeederDetail from "components/pages/2_manage/device/feeder/FeederDetail";
import AddFeederModal from "components/pages/2_manage/device/feeder/AddFeederModal";

function Feeder() {
  const { t } = useTranslation();
  const [detailToggle, setDetailToggle] = useState(false);
  const [feederId, setFeederId] = useState(0);
  // 장치 추가 토글
  const [toggleAdd, setToggleAdd] = useState(false);

  // 필터용 state 순서대로 장치상태-돈사-급이기회사
  const [deviceStatus, setDeviceStatus] = useState<(boolean | string)[]>([]);
  const [selectedPiggery, setSelectedPiggery] = useState<number[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number[]>([]);

  // 필터 list 로드용 query
  const { data: piggeryList } = usePiggeries();
  const { data: companyList } = useFeederCompanyList();

  // feeder list query
  const { data: feederList } = useFeederList(deviceStatus, selectedPiggery, selectedCompany);

  // function for reset btn
  const handleReset = () => {
    setDeviceStatus([]);
    setSelectedPiggery([]);
    setSelectedCompany([]);
  };

  return (
    <div className="w-full h-full bg-ef-neutral-50">
      <FeederDetail isToggle={detailToggle} setIsToggle={setDetailToggle} deviceId={feederId} />
      <AddFeederModal isToggle={toggleAdd} setIsToggle={setToggleAdd} />
      <div className="w-[948px] min-w-[948px] mx-auto h-fit pt-60pxr pb-200pxr">
        <header className="flex flex-row gap-16pxr items-center">
          <div className="flex flex-row gap-4pxr items-center ef-headline-lg">
            <span className="text-ef-neutral-900">{`${t("gnb.feeder")} ${t("manage.list")}`}</span>
            <span className="text-ef-neutral-400">{`(${feederList ? feederList.length : 0})`}</span>
          </div>
          <button onClick={() => setToggleAdd(true)} type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr">
            <Add />
            {t("manage.add_device")}
          </button>
          <span className="ml-auto text-ef-neutral-500 ef-body-sm">{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</span>
        </header>
        {/* filter starts here */}
        <section className="flex flex-row gap-24pxr mt-40pxr">
          <div className="flex flex-row gap-8pxr">
            <DropDownCheckBox
              title={t("manage.device_status")}
              state={deviceStatus}
              setState={setDeviceStatus}
              options={[
                { value: true, name: t("common.normal") },
                { value: false, name: t("common.abnormal") },
                { value: "unlinked", name: t("common.not_supported") },
              ]}
            />
            <DropDownCheckBox
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
            <DropDownCheckBox
              title={t("common.feeder_company")}
              state={selectedCompany}
              setState={setSelectedCompany}
              options={
                companyList
                  ? companyList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
          </div>
          <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center">
            <Refresh />
            {t("common.reset")}
          </button>
        </section>
        {/* divider */}
        <div className="w-full h-1pxr bg-ef-neutral-200 my-32pxr"></div>
        {feederList && (
          <>
            {feederList.length === 0 ? (
              <div className="mt-80pxr flex flex-col gap-8pxr w-fit mx-auto">
                <img src={NoImg} alt="no-device" />
                <p className="ef-body-xxl text-ef-neutral-600 mx-auto">{t("manage.there_is_no_device_registered")}</p>
              </div>
            ) : (
              <div className="flex flex-col gap-16pxr">
                {feederList.map((o) => (
                  <div
                    key={o.id}
                    onClick={() => {
                      setFeederId(o.id);
                      setDetailToggle(true);
                    }}
                    className="w-full border border-solid border-ef-neutral-150 bg-ef-neutral-white rounded-[8px] ef-body-xl text-ef-neutral-900 flex flex-row cursor-pointer"
                  >
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("manage.device_name")}</p>
                      <p>{o.name}</p>
                    </div>
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("common.feeder_company")}</p>
                      <p>{o.company.name}</p>
                    </div>
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("manage.installed_location")}</p>
                      <p>{`[${o.room.piggery.name}] ${o.room.name}`}</p>
                    </div>
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("common.device_connection_status")}</p>
                      <div className="flex flex-row items-center gap-4pxr">
                        <div
                          style={{
                            backgroundColor: o.connected === null ? "#878787" : o.connected ? "#37B24D" : "#F03E3E",
                          }}
                          className="rounded-full w-6pxr h-6pxr min-w-[6px]"
                        ></div>
                        <span
                          style={{
                            color: o.connected === null ? "#878787" : "",
                          }}
                        >
                          {o.connected === null ? t("common.not_supported") : o.connected ? t("common.normal") : t("common.abnormal")}
                        </span>
                      </div>
                    </div>
                    <div className="w-126pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">ID</p>
                      <p>{o.id}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Feeder;
