import { Tooltip } from "@mui/material";
import React from "react";
import { ReactComponent as GeneralBellDefault } from "assets/icons/general/ef-general-bellDefault.svg";
import { useTranslation } from "react-i18next";

function AlarmComponent() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col relative">
      {/* <button
                  onClick={() => setToggleAlarm((prev) => !prev)}
                  className={
                    "cursor-pointer w-40pxr h-40pxr flex items-center justify-center my-auto rounded-[8px] border border-solid border-ef-neutral-white mr-16pxr " +
                    (toggleAlarm
                      ? "bg-ef-neutral-white stroke-ef-neutral-900 text-ef-neutral-900"
                      : "stroke-ef-neutral-white active:stroke-ef-neutral-white hover:stroke-ef-neutral-900 text-ef-neutral-white hover:text-ef-neutral-900 active:text-ef-neutral-white hover:bg-ef-neutral-50 active:bg-transparent active:border-ef-primary-900 border-ef-neutral-white")
                  }
                >
                  {groupedData.filter((p: AlarmResultType) => p.checked === 0).length > 0 ? <GeneralBellOn className="" /> : <GeneralBellDefault className="" />}
                </button>
                {toggleAlarm ? <AlarmToggleTab setToggleAlarm={setToggleAlarm} /> : ""} */}
      <Tooltip title={t("common.prepare_comment")}>
        <button
          //   onClick={() => setToggleAlarm((prev) => !prev)}
          //   onMouseEnter={() => setShowSubGNB(false)}
          className={
            "stroke-ef-neutral-300 border-ef-neutral-300 cursor-not-allowed p-8pxr my-20pxr h-full flex items-center justify-center rounded-[8px] border border-solid "
            // "cursor-pointer p-8pxr my-20pxr h-full flex items-center justify-center rounded-[8px] border border-solid border-ef-neutral-900  mr-16pxr " +
            // (toggleAlarm
            //   ? "stroke-ef-neutral-900 bg-ef-neutral-white text-ef-neutral-900"
            //   : "stroke-ef-neutral-900 active:stroke-ef-neutral-900 hover:stroke-ef-neutral-900 text-ef-neutral-white hover:text-ef-neutral-900 active:text-ef-neutral-white hover:bg-ef-neutral-50 active:bg-transparent active:border-ef-primary-900 border-ef-neutral-900")
          }
        >
          <GeneralBellDefault className="" />
        </button>
      </Tooltip>
    </div>
  );
}

export default AlarmComponent;
