import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "assets/icons/report/ef-report-calendar.svg";
import "styles/datepicker.css";
import { isAfter, isBefore } from "date-fns";
import { datePickerLocale } from "utils/useDatepicker";

type Props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

function GroupRangeDatePicker(props: Props) {
  const { startDate, setStartDate, endDate, setEndDate } = props;
  const startRef = useRef<HTMLDivElement | null>(null);
  const endRef = useRef<HTMLDivElement | null>(null);
  const [startState, setStartState] = useState(false);
  const [endState, setEndState] = useState(false);

  const handleStartDateChange = (date: Date) => {
    if (isAfter(date, endDate!)) setEndDate(date);
    setStartDate(date);
    setStartState(false);
  };

  const handleEndDateChange = (date: Date) => {
    if (isBefore(date, startDate!)) setStartDate(date);
    setEndDate(date);
    setEndState(false);
  };

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (startRef.current && !startRef.current.contains(e.target as Node)) {
        setStartState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startRef]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (endRef.current && !endRef.current.contains(e.target as Node)) {
        setEndState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endRef]);

  return (
    <div
      className={
        "group h-56pxr py-16pxr flex flex-row rounded-[8px] border border-solid " +
        (startState || endState
          ? "border-ef-primary-500 bg-ef-primary-50 hover:shadow-none"
          : "bg-ef-neutral-white border-ef-neutral-150 hover:border-ef-primary-500 hover:ef-main-shadow active:shadow-none focus-within:border-ef-primary-500 focus:outline-none focus:border-ef-primary-500 disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:hover:shadow-none")
      }
    >
      <div className="flex mx-auto">
        <div className="w-200pxr mr-4pxr flex flex-row items-center text-ef-neutral-900 group-disabled:text-ef-neutral-300">
          <div className="grow overflow-hidden">
            <div className="w-full" ref={startRef}>
              <DatePicker
                open={startState}
                onInputClick={() => setStartState((prev) => !prev)}
                locale={datePickerLocale()}
                maxDate={new Date()}
                selected={startDate}
                onChange={handleStartDateChange}
                className="outline-none cursor-pointer caret-transparent"
                dateFormat={"yyyy-MM-dd"}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 229) e.preventDefault();
                  e.preventDefault();
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <span className="ef-title-md">~</span>
          <div className="grow overflow-hidden">
            <div className="w-full" ref={endRef}>
              <DatePicker
                open={endState}
                onInputClick={() => setEndState((prev) => !prev)}
                locale={datePickerLocale()}
                maxDate={new Date()}
                selected={endDate}
                onChange={handleEndDateChange}
                className="outline-none cursor-pointer caret-transparent"
                dateFormat={"yyyy-MM-dd"}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 229) e.preventDefault();
                  e.preventDefault();
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </div>
        <Calendar className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-300" />
      </div>
    </div>
  );
}

export default GroupRangeDatePicker;
