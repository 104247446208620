import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";

type Props = {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  options: Option[] | undefined;
  placeholder?: string;
  disabled?: boolean;
  roomState?: any;
  setRoomState?: React.Dispatch<React.SetStateAction<any>>;
};

type Option = {
  value: any;
  name: string;
};

function DropDownSmall(props: Props) {
  const { state, setState, options, placeholder = "", disabled = false, roomState, setRoomState } = props;
  const [toggleState, setToggleState] = useState(false);
  const openRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (openRef.current && !openRef.current.contains(e.target as Node)) {
        setToggleState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openRef]);

  return (
    <div className="relative ef-body-lg" ref={openRef}>
      <button
        disabled={disabled}
        type="button"
        onClick={() => setToggleState((prev) => !prev)}
        className={
          "w-full h-40pxr flex flex-row items-center px-16pxr py-8pxr rounded-[8px] border border-solid focus:outline-none focus:border-ef-primary-500 " +
          (toggleState
            ? "stroke-ef-primary-500 border-ef-primary-500 bg-ef-primary-50 text-ef-primary-500"
            : "bg-ef-neutral-white border-ef-neutral-150 items-center stroke-ef-neutral-700 hover:border-ef-primary-500 active:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-500 disabled:stroke-ef-neutral-500 disabled:border-ef-neutral-150")
        }
      >
        <span className={"overflow-hidden whitespace-nowrap text-ellipsis break-all " + (state === null ? "text-ef-neutral-300" : "")}>
          {state === null ? placeholder : options?.find((o) => o.value === state)?.name}
        </span>
        {toggleState ? <ArrowUp className="ml-auto min-w-[24px]" /> : <ArrowDown className="ml-auto min-w-[24px]" />}
      </button>
      {toggleState && (
        <div className="z-[1500] h-fit max-h-[416px] overflow-y-auto py-8pxr rounded-[8px] absolute mt-4pxr bg-white w-full border border-solid border-ef-neutral-150 ef-main-shadow cursor-pointer">
          {options &&
            options.map((o) => (
              <button
                type="button"
                onClick={() => {
                  setState(o.value);
                  setToggleState(false);
                  setRoomState?.(o);
                }}
                key={o.value}
                className="w-full h-40pxr py-8pxr px-16pxr hover:bg-ef-primary-50"
              >
                <div className="w-full h-full text-left">
                  <span className="line-clamp-1 break-all">{o.name}</span>
                </div>
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

export default DropDownSmall;
