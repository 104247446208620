import React, { useState } from "react";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import AddGroupDropDown from "components/pages/2_manage/stock/group/AddGroupDropDown";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { toast } from "react-toastify";
import { useAddMovementReport, useDeathCauseList } from "query/movement";
import { useReportContext } from "context/reportContext";
import { useTranslation } from "react-i18next";
import RequiredAlert from "components/common/alert/RequiredAlert";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { MovementPayload } from "query/movement/type";
import { getRedStar } from "utils/getImportantStar";

interface Props {
  workDate: Date | null;
  workStartTime: Date;
  workEndTime: Date;
  workType: string | number | null;
  isToggle: boolean;
  setIsToggle: (isToggle: boolean) => void;
}

const initialReportValue = {
  stock: 0,
  avg_weight: 0,
  // body_rate: data?.body_rate,
  memo: "",
  started_at: "",
  ended_at: "",
  category_id: 4,
  departure_id: 0,
  arrival_id: 0,
  death_cause_id: 1,
};

// worktype 폐사
function DeathComponent(props: Props) {
  const { t } = useTranslation();
  const { workDate, workStartTime, workEndTime, setIsToggle } = props;
  const { isReportCreated, setReportCreated, setWorkDate } = useReportContext();
  const { mutateAsync: death } = useAddMovementReport();
  const [groupData, setGroupData] = useState(initialReportValue);
  const { data: deathCauseList } = useDeathCauseList();
  const combinedDateStartTime = workDate && new Date(workDate.getFullYear(), workDate.getMonth(), workDate.getDate(), workStartTime.getHours(), workStartTime.getMinutes(), workStartTime.getSeconds());
  const combinedDateEndTime = workDate && new Date(workDate.getFullYear(), workDate.getMonth(), workDate.getDate(), workEndTime.getHours(), workEndTime.getMinutes(), workEndTime.getSeconds());

  // const initialReportValue = {
  //   category_id: 4,
  //   started_at: workStartTime,
  //   ended_at: workEndTime,
  //   stock: 0,
  //   departure: { piggery_id: 0, room_id: 0 },
  //   memo: "",
  // };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    clearErrors,
    watch,
    register,
  } = useForm<MovementPayload>();

  const departure = watch("departure_id");
  const stock = watch("stock");

  const isFormComplete = stock && departure;

  const onSubmit = async (data: MovementPayload) => {
    const reportData = {
      stock: data?.stock,
      avg_weight: data?.avg_weight,
      // body_rate: data?.body_rate,
      memo: data?.memo,
      started_at: combinedDateStartTime,
      ended_at: combinedDateEndTime,
      category_id: 4,
      departure_id: data?.departure_id,
      arrival_id: data?.arrival_id,
      death_cause_id: data?.death_cause_id,
    };

    try {
      if (isFormComplete) {
        // const confirmShipment =
        //   reportData.stock === groupData?.stock
        //     ? window.confirm(`${groupData?.name}의 모든 두수가 입력되었습니다.${groupData?.name}을 전체 출하처리하고 ${groupData?.name}의 운영을 종료하시겠습니까?`)
        //     : true;

        // if (confirmShipment) {
        await death(reportData);
        setIsToggle(false);
        toast.success("보고서 작성이 완료되었습니다");
        reset();
        setReportCreated(true);
        setWorkDate(workDate);
        setTimeout(() => {
          setReportCreated(false);
        }, 3000);
      } else {
        setReportCreated(false);
        alert(t("common.required_comment"));
      }
      // } else {
      // }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(errors);

  console.log(errors);
  return (
    <div>
      {/* 돈방선택*, 폐사사유*, 두수* */}
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
          <div className="flex flex-row items-center">
            <label className="w-77pxr ef-label-md min-w-[77px] line-clamp-1 mr-12pxr">
              출발장소
              {getRedStar()}
            </label>

            <div className="w-340pxr relative" onClick={() => clearErrors("departure_id")}>
              {errors.departure_id ? <RequiredAlert /> : <></>}
              <Controller
                name="departure_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <AddGroupDropDown title={t("report.departure_location")} distinct={true} state={field.value} setState={field.onChange} roomState={groupData} setRoomState={setGroupData} />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-89pxr ef-label-md line-clamp-1">
              {t("report.arrival_location")} <span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="w-168pxr">
              <Controller
                name="arrival_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <AddGroupDropDown title={t("report.arrival_location")} distinct={true} state={field.value} setState={field.onChange} roomState={groupData} setRoomState={setGroupData} />
                )}
              />
            </div>
          </div>

          <div className="flex flex-row items-center mb-8pxr">
            <label className="w-89pxr ef-label-md line-clamp-1">
              폐사사유<span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="w-340pxr flex flex-col">
              <Controller
                name="death_cause_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DropDownSmall
                    state={field.value}
                    setState={field.onChange}
                    options={
                      deathCauseList
                        ? deathCauseList.map((o: { id: number; name: string }) => {
                            return { id: o.id, name: o.name };
                          })
                        : []
                    }
                  />
                )}
              />
              <div className="w-340pxr h-130pxr mt-8pxr">
                <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder="폐사 사유를 입력해주세요."></textarea>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <label className="w-89pxr ef-label-md">
              {t("common.heads")}
              <span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="w-146pxr relative" onClick={() => clearErrors("stock")}>
              {errors.stock ? <RequiredAlert /> : <></>}
              <Controller
                name="stock"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputFieldOnly
                    state={field.value}
                    setState={(value) => {
                      field.onChange(value);
                    }}
                    type="number"
                    placeholder={t("common.heads")}
                    regex={/^(?:[1-9]\d*|0)?$/}
                  />
                )}
              />
            </div>
            <span className="ml-4pxr">{t("common.head")}</span>
          </div>

          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">{t("common.average_weight")}</label>
            <div className="flex flex-row flex-wrap gap-8pxr">
              <div className="flex flex-row items-center justify-center">
                <div className="w-146pxr">
                  <Controller
                    name="avg_weight"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly
                        state={field.value}
                        setState={(value) => {
                          // if (heads ===0) setValue("total_weight", Math.floor(Number(value)).toString());
                          // else if (heads !==0) setValue("total_weight", Math.floor(Number(value) * Number(heads)).toString());
                          field.onChange(value);
                        }}
                        type="number"
                        placeholder={t("common.average_weight")}
                        regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                      />
                    )}
                  />
                </div>
                <span className="ml-4pxr">kg</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">{t("report.average_body_length")}</label>
            <div className="flex flex-row flex-wrap gap-8pxr">
              <div className="flex flex-row items-center justify-center">
                <div className="w-146pxr">
                  <Controller
                    name="body_rate"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly
                        state={field.value}
                        setState={(value) => {
                          // if (heads ===0) setValue("total_weight", Math.floor(Number(value)).toString());
                          // else if (heads !==0) setValue("total_weight", Math.floor(Number(value) * Number(heads)).toString());
                          field.onChange(value);
                        }}
                        type="number"
                        placeholder={t("report.avg_wrate")}
                        regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <button className={`w-494pxr ef-btn-modal 
        ${isFormComplete ? "" : "bg-ef-disabled"}`}
         onClick={handleSubmit(onSubmit)} 
         disabled={!isFormComplete} type="submit">
          저장
        </button> */}
          {/* <button className={`w-494pxr ef-btn-modal`} type="submit">
              {t("common.save")}
            </button> */}

          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">
              <span className="line-clamp-1 break-all">{t("manage.memo")}</span>
            </label>
            <div className="w-514pxr h-130pxr">
              <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("report.write_memo")}></textarea>
            </div>
          </div>

          <div className="flex justify-center mt-36pxr">
            <button className={`w-494pxr ef-btn-modal`} type="submit">
              {t("common.save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DeathComponent;
