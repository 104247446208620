import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import { useTranslation } from "react-i18next";

type Props = {
  title: any;
  state: any[];
  setState: React.Dispatch<React.SetStateAction<any[]>>;
  options: Option[] | [];
  disabled?: boolean;
};

type Option = {
  value: string | number | boolean | null;
  name: any;
};

function DropDownRoom(props: Props) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const { title, options, state, setState, disabled = false } = props;
  const [toggleSelect, setToggleSelect] = useState(false);
  const { t } = useTranslation();

  // 외부클릭 반응
  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setToggleSelect(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="relative" ref={divRef}>
      <button
        disabled={disabled}
        onClick={() => setToggleSelect((prev) => !prev)}
        className={
          "group w-197pxr h-56pxr border items-center border-solid rounded-[8px] flex flex-row py-8pxr pr-4pxr pl-16pxr text-left " +
          (toggleSelect
            ? "border-ef-primary-500 bg-ef-primary-50"
            : "bg-ef-neutral-white border-ef-neutral-150 hover:border-ef-primary-500 hover:ef-main-shadow active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:hover:shadow-none")
        }
      >
        <div className="grow overflow-hidden flex flex-col">
          <div className="grow ef-body-xl text-ef-neutral-900 group-disabled:text-ef-neutral-500 line-clamp-1">
            {state.length === 0 || state.length === options.length ? t("common.all") : state.map((i) => options.find((j) => j.value === i)?.name).join(", ")}
          </div>
        </div>
        <div className="w-24pxr h-24pxr mx-8pxr">
          {toggleSelect ? <ArrowUp className="stroke-ef-neutral-900" /> : <ArrowDown className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-500" />}
        </div>
      </button>
      {toggleSelect && (
        <div className="z-[1500] w-197pxr py-8pxr h-fit max-h-[452px] overflow-y-auto rounded-[8px] ef-body-xl bg-ef-neutral-white ef-main-shadow absolute mt-4pxr cursor-pointer  border border-solid border-ef-neutral-150">
          {options.length > 0 &&
            options.map((o, i) => (
              <button
                type="button"
                key={i}
                className="flex flex-row w-full p-16pxr hover:bg-ef-primary-50"
                onClick={() => {
                  if (state.includes(o.value)) {
                    setState(state.filter((item) => item !== o.value));
                  } else {
                    setState((prev) => [...prev, o.value]);
                  }
                }}
              >
                <input
                  type="checkbox"
                  id={o?.value?.toString()}
                  checked={state.includes(o.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setState(state.filter((item: any) => item !== o.value));
                    } else {
                      setState((prev: any) => [...prev, o.value]);
                    }
                  }}
                />
                <label className="!pl-31pxr w-full line-clamp-1 break-all text-left" htmlFor={o?.value?.toString()}>
                  {o.name}
                </label>
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

export default DropDownRoom;
