import { ReactComponent as StarOn } from "assets/graphic/ef-graphic-star-big-on.svg";
import { ReactComponent as StarOff } from "assets/graphic/ef-graphic-star-big-off.svg";

export const RatingDisplay = (rating: number, size?: number) => {
  // 별 아이콘을 담을 배열 생성
  const stars = [];

  // 별 아이콘을 rating 만큼 채워주기
  for (let i = 0; i < rating; i++) {
    stars.push(<StarOn className={size ? `w-${size}pxr h-${size}pxr` : ""} key={i} />);
  }

  // 남은 별 아이콘을 빈 별 아이콘으로 채워주기
  for (let i = rating; i < 5; i++) {
    stars.push(<StarOff className={size ? `w-${size}pxr h-${size}pxr` : ""} key={i} />);
  }

  return (
    <div>
      {/* 별 아이콘 출력 */}
      <span className={"flex flex-row"}>{stars}</span>
    </div>
  );
};
