import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthState } from "context/AuthContext";
import {
  PiggeriesList,
  StockDashBoard,
  PiggerySingleDetail,
  PiggeryStructure,
  EnvDashBoard,
  SpecDashBoard,
  PiggeryMonitoring,
  PiggeryGrowthRecord,
  DeviceDashBoard,
  OperationReport,
  PiggeryStockHistory,
  PiggerySettingUpdatePayload,
} from "./type";

// 농장내 돈사 전체 정보 get
export const usePiggeries = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["piggeries"], () => client.get<PiggeriesList[]>("/piggeries").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// QR 용 농장내 돈사 전체 정보 get
export const usePiggeriesQR = (piggery_id?: number | number[] | null) => {
  const query = useQuery(["piggeriesQR", piggery_id], () => client.get<PiggeriesList[]>("/qr/piggeries", { params: { piggery_id: piggery_id !== null ? piggery_id : undefined } }).then((res) => res.data), {
    onSuccess(data) {},
  });
  return query;
};

// 농장내 돈사 전체 구조 get
export const usePiggeryStructure = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["piggeryStructure"], () => client.get<PiggeryStructure[]>("/piggeries/structure").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈사 현황(재고)
export const useStockDashboard = (piggery_id: number | null) => {
  const { current_farm } = useAuthState();
  const query = useQuery(
    ["StockDashboard", piggery_id],
    () =>
      client
        .get<StockDashBoard>(`/piggeries/dashboard/${piggery_id}/stock`, {
          // params: {
          //   piggery_id: piggery_id !== null ? piggery_id : undefined,
          // },
        })
        .then((res) => res.data),
    {
      enabled: piggery_id !== 0 && current_farm.id !== 0,
      onSuccess(data) {},
    },
  );
  return query;
};

// 돈사 현황(환경)
export const useEnvDashboard = (piggery_id: number | null) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["EnvDashboard", piggery_id], () => client.get<EnvDashBoard>(`/piggeries/dashboard/${piggery_id}/env`).then((res) => res.data), {
    enabled: piggery_id !== 0 && current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈사 현황(사양)
export const useSpecDashboard = (piggery_id: number | null) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["SpecDashboard", piggery_id], () => client.get<SpecDashBoard>(`/piggeries/dashboard/${piggery_id}/spec`, {}).then((res) => res.data), {
    enabled: piggery_id !== 0 && current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈사 현황(장치)
export const useDeviceDashboard = (piggery_id: number | null) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["DeviceDashboard", piggery_id], () => client.get<DeviceDashBoard>(`/piggeries/dashboard/${piggery_id}/device`, {}).then((res) => res.data), {
    enabled: piggery_id !== 0 && current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈사 모니터링
export const usePiggeryMonitoring = (piggery_id: number, obj: { rooms?: number[]; base_datetime?: string }) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["PiggeryMonitoring", piggery_id, obj], () => client.get<PiggeryMonitoring>(`/piggeries/monitoring/${piggery_id}`, { params: obj }).then((res) => res.data), {
    enabled: current_farm.id !== 0 && piggery_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get 돈사 detail(single)
export const usePiggeryDetail = (id: number | number[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["piggeryDetail", id], () => client.get<PiggerySingleDetail>(`/piggeries/${id}`).then((res) => res.data), {
    enabled: id !== 0 && current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// put(edit) 돈사
export const useUpdatePiggery = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("updatePiggery", (payload: { id: number; name: string }) => client.put(`/piggeries/${payload.id}`, { name: payload.name }).then((res) => res.data), {
    onSuccess: async () => {
      queryClient.invalidateQueries(["piggeryDetail"]);
      queryClient.invalidateQueries(["piggeryRoomList"]);
    },
  });
  return mutation;
};

// put(edit) 돈사 전입전출 설정
export const useUpdatePiggerySetting = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    "updatePiggerySetting",
    (payload: { piggeryId: number; object: PiggerySettingUpdatePayload }) => client.put(`/piggeries/${payload.piggeryId}/transfer`, payload.object).then((res) => res.data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["RoomDetail"]);
      },
    },
  );
  return mutation;
};

// 성장기록 용=====================================================================

// get 돈사별 성장기록
export const useGrowthRecord = (piggery_id: number, rooms?: number[], start_date?: string, end_date?: string) => {
  const { current_farm } = useAuthState();
  const query = useQuery(
    ["growthRecord", piggery_id, rooms, start_date, end_date],
    () => client.get<PiggeryGrowthRecord[]>(`/piggeries/growth/${piggery_id}`, { params: { rooms, start_date, end_date } }).then((res) => res.data),
    {
      enabled: current_farm.id !== 0 && piggery_id !== 0,
      onSuccess(data) {},
    },
  );
  return query;
};

// 운영보고서 용==========================================================================

// get 운영보고서 기록
export const useOperationReport = (piggery_id: number, base_date?: string) => {
  const { current_farm } = useAuthState();
  const query = useQuery(
    ["operationReport", piggery_id, base_date],
    () => client.get<OperationReport>(`/piggeries/operation-reports/${piggery_id}`, { params: { base_date } }).then((res) => res.data),
    {
      enabled: current_farm.id !== 0 && piggery_id !== 0,
      onSuccess(data) {},
    },
  );
  return query;
};

// 재고기록 용 =============================================================================
export const useStockHistory = (piggery_id: number, rooms?: number[], target_month?: string) => {
  const { current_farm } = useAuthState();
  const query = useQuery(
    ["stockHistory", piggery_id, rooms, target_month],
    () => client.get<PiggeryStockHistory>(`/piggeries/${piggery_id}/stock/history`, { params: { rooms, target_month } }).then((res) => res.data),
    {
      enabled: current_farm.id !== 0 && piggery_id !== 0,
      onSuccess(data) {},
    },
  );
  return query;
};

// 자동 돈군 존재하는지(성장기록용)
export const useIsAutoHerdExists = (piggery_id: number) => {
  const query = useQuery(["isAutoHerdExists", piggery_id], () => client.get<boolean>("/piggeries/autoherds/exists", { params: { piggery_id } }).then((res) => res.data), {
    enabled: piggery_id !== 0,
    onSuccess(data) {},
  });
  return query;
};
