import axios from "axios";
import { useMutation, useQuery } from "react-query";
import client from "query/client";

const weatherAPI = (payload: { lat: number; log: number }) =>
  axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${payload.lat}&lon=${payload.log}&appid=94170917912c1330b1de843d13d85ecd&units=metric`).then((res) => res.data);

export const useWeather = (payload: { lat: number; log: number }) => {
  const query = useQuery("weather", () => weatherAPI(payload), {
    staleTime: 30 * 60 * 1000,
    enabled: payload.lat !== 0 && payload.log !== 0 ? true : false,
    onSuccess(data) {},
    onError(err) {},
  });

  return query;
};
