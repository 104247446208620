import React, { useState } from "react";
import { Modal, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { StockDashBoard } from "query/piggery/type";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import StockDetailHistory from "./StockDetailHistory";
import MonitoringStock from "./MonitoringStock";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F5FAFF",
    color: "#033687",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #033687",
    borderRadius: 8,
    padding: "8px 16px",
    fontFamily: "Pretendard",
  },
}));

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  roomId: number;
  piggeryId: number;
  roomData: StockDashBoard | undefined;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function RoomDetailModal(props: Props) {
  const { roomData, isToggle, setIsToggle, roomId, piggeryId } = props;

  const [menuState, setMenuState] = useState(true); //true 재고상세, false 모니터링

  const selectedRoom = roomData?.rooms?.find((v) => v.id === roomId);

  const { t } = useTranslation();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setMenuState(true);
      setIsToggle(false);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr pb-120pxr overflow-y-auto"
    >
      <div className="w-[1052px] h-980pxr bg-ef-neutral-white outline-none flex flex-col pt-40pxr relative text-ef-neutral-900">
        <button onClick={() => onClose("closeButtonClick")} type="button" className="absolute top-40pxr right-40pxr">
          <GeneralClose className="stroke-ef-neutral-900" />
        </button>
        <p className="text-center ef-title-lg">{`${selectedRoom ? selectedRoom.name : ""} ${t("common.details")}`}</p>
        <div className="flex flex-row ef-label-lg mx-auto mt-40pxr relative">
          <button
            type="button"
            onClick={() => setMenuState(true)}
            style={{
              color: menuState ? "#033687" : "#878787",
            }}
            className="w-360pxr h-60pxr flex items-center justify-center"
          >
            {t("status.stock_details")}
          </button>
          <button
            type="button"
            onClick={() => setMenuState(false)}
            style={{
              color: !menuState ? "#033687" : "#878787",
            }}
            className="w-360pxr h-60pxr flex items-center justify-center disabled:cursor-not-allowed"
          >
            {`${t("status.streaming")}`}
          </button>
          <div
            style={{
              transform: menuState ? "translateX(0)" : "translateX(100%)",
            }}
            className="w-360pxr absolute h-3pxr bg-ef-primary-500 bottom-0pxr transition-all ease-in-out duration-300"
          ></div>
        </div>
        <div className="w-full h-1pxr bg-ef-neutral-200"></div>
        {selectedRoom && <div className="grow w-full overflow-hidden">{menuState ? <StockDetailHistory selectedRoom={selectedRoom} piggeryId={piggeryId} setToggleModal={setIsToggle} /> : <MonitoringStock selectedRoom={selectedRoom.id}/>}</div>}
      </div>
    </Modal>
  );
}

export default RoomDetailModal;
