import React, { useState } from "react";
import { Modal, Tooltip } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Clock } from "assets/icons/general/ef-general-clock.svg";
import { ReactComponent as Temperature } from "assets/icons/general/ef-general-temperature.svg";
import { ReactComponent as Humidity } from "assets/icons/gnb/ef-gnb-rain.svg";
import { ReactComponent as Flame } from "assets/icons/general/ef-general-flame.svg";
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { ReactComponent as WeightSquare } from "assets/icons/legend/ef-legend-weight.svg";

import { Trans, useTranslation } from "react-i18next";
import { useRoomEnvDetail } from "query/rooms";
import { format } from "date-fns";
import AddGroupModal from "components/pages/2_manage/stock/group/AddGroupModal";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  roomId: number;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function IndoorAtmosphere(props: Props) {
  const { isToggle, setIsToggle, roomId } = props;
  const { t } = useTranslation();
  const { data } = useRoomEnvDetail(roomId);
  const [hoveredTemperautreIndex, setHoveredTemperautreIndex] = useState<number | null>(null);
  const [hoveredHumidityIndex, setHoveredHumidityIndex] = useState<number | null>(null);
  const [hoveredHeatIndex, setHoveredHeatIndex] = useState<number | null>(null);
  const [addHerdModal, setAddHerdModal] = useState(false);
  const currentTime = format(new Date(), "HH:mm:ss");

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
  };

  const sortedTimes: string[] = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, "0"));
  const formattedData = sortedTimes.map((time) => {
    const dateTime = format(new Date(), "yyyy-MM-dd") + ` ${time}:00:00`;
    const timeData = data?.[dateTime] || { temperature: null, humidity: null, heat_index: null };
    return timeData;
  });

  return (
    <>
      <AddGroupModal roomId={roomId} envData={data?.room} distinct="envModal" isToggle={addHerdModal} setIsToggle={setAddHerdModal} />
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center py-120pxr overflow-y-auto"
      >
        <div className="w-[1341px] h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full pb-40pxr">
            <span className="flex mx-auto text-ef-neutral-900 ef-title-lg">
              {data?.room.name} {t("status.indoor_atmosphere")} {t("common.details")}
            </span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <div className="flex flex-row ef-body-lg mb-16pxr">
            {/* @todo 지금은 active_herd인데 active_herd.day_age 들어오면 예외처리 수정 */}

            {!data?.room?.active_herd || Object.keys(data.room.active_herd).length === 0 ? (
              <></>
            ) : (
              <div>
                <span className="text-ef-neutral-500">
                  <Trans
                    values={{
                      week: data?.room?.active_herd?.week,
                      days: `${data?.room?.active_herd?.start_day_age} ~ ${data?.room?.active_herd?.end_day_age}`,
                    }}
                    components={{ 1: <span className="text-ef-primary-500" /> }}
                    i18nKey="status.proper_indoor_air_quality_comment"
                  />
                </span>
              </div>
            )}
          </div>
          {!data?.room?.active_herd || Object.keys(data.room.active_herd).length === 0 ? (
            ""
          ) : (
            <div className="flex justify-between mb-24pxr ">
              <div className="w-fit items-center flex flex-row gap-16pxr border border-solid rounded-[8px] border-ef-neutral-150 bg-ef-neutral-50 px-16pxr py-8pxr">
                <span className="flex flex-row gap-8pxr">
                  <span className="ef-body-sm text-ef-neutral-700">{t("common.temperature")}</span>
                  <span className="ef-body-md text-ef-neutral-900">
                    {data?.room?.active_herd?.standard_data?.temperature?.lower}~{data?.room.active_herd?.standard_data?.temperature?.upper}℃
                  </span>
                </span>
                <div className="h-8pxr w-1pxr bg-ef-neutral-200 mx-8pxr"></div>
                <span className="flex flex-row gap-8pxr">
                  <span className="ef-body-sm text-ef-neutral-700">{t("common.humidity")}</span>
                  <span className="ef-body-md text-ef-neutral-900">
                    {data?.room.active_herd?.standard_data?.humidity?.lower}~{data?.room.active_herd?.standard_data?.humidity?.upper}%
                  </span>
                </span>
                <div className="h-8pxr w-1pxr bg-ef-neutral-200 mx-8pxr"></div>
                <span className="flex flex-row gap-8pxr">
                  <span className="ef-body-sm text-ef-neutral-700">{t("common.heat_index")}</span>
                  <span className="ef-body-md text-ef-neutral-900">
                    {data?.room.active_herd?.standard_data?.heat_index?.lower}~{data?.room.active_herd?.standard_data?.heat_index?.upper}
                  </span>
                </span>
              </div>
              <div className="flex flex-row gap-8pxr items-center justify-center">
                <div className="px-16pxr py-8pxr gap-4pxr flex flex-row items-center border border-solid border-ef-neutral-150 bg-ef-neutral-white rounded-[8px] ef-body-md">
                  <WeightSquare className="stroke-ef-state-blue-500" /> {t("status.below_appropriate_level")}
                </div>
                <div className="px-16pxr py-8pxr gap-4pxr flex flex-row  items-center border border-solid border-ef-neutral-150 bg-ef-neutral-white rounded-[8px] ef-body-md">
                  <WeightSquare className="stroke-ef-state-red-500" /> {t("status.above_appropriate_level")}
                </div>
              </div>
            </div>
          )}
          <table className="">
            <thead className="flex text-ef-neutral-900 bg-ef-neutral-50">
              <tr className="flex">
                <th className="flex  justify-start items-center ef-body-sm border border-solid border-r-transparent border-b-transparent px-8pxr py-11pxr w-145pxr min-w-[145px]">
                  <Clock className="stroke-ef-neutral-900 mr-4pxr" />
                  {t("common.time")}
                </th>
                {sortedTimes.map((time: string, index: number) => (
                  <td key={index} className="w-44pxr min-w-[44px] flex items-center justify-center ef-body-sm border border-solid border-r-transparent  border-b-transparent px-12pxr py-11pxr ">
                    {time}
                  </td>
                ))}
                <th className="flex items-center justify-center ef-body-sm border border-solid border-r-transparent  border-b-transparent px-12pxr py-11pxr  w-60pxr min-w-[60pxr] bg-ef-neutral-700 text-ef-neutral-white">
                  {t("common.average")}
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col">
              <tr className="flex">
                <th className="w-145pxr min-w-[145px] flex justify-start items-center ef-body-sm border border-solid border-r-transparent border-b-transparent px-8pxr py-11pxr">
                  <Temperature className="stroke-ef-neutral-900 mr-4pxr" />
                  {t("common.temperature")}(℃)
                </th>
                {formattedData.map((time, index) => (
                  <td
                    key={`temperature-${index}`}
                    className={
                      "w-44pxr min-w-[44px] items-center flex justify-center ef-body-sm border border-solid border-r-transparent py-11pxr border-b-transparent " +
                      (time.temperature && Number(time.temperature) < Number(data?.room?.active_herd?.standard_data?.temperature?.lower)
                        ? "bg-ef-state-blue-50 text-ef-state-blue-500" // min보다 작을 때
                        : time.temperature && Number(time.temperature) > Number(data?.room?.active_herd?.standard_data?.temperature?.upper)
                          ? "bg-ef-state-red-50 text-ef-state-red-500" // max보다 클 때
                          : "bg-ef-neutral-white text-ef-neutral-900") + // 그 사이에 있을 때
                      (!time.temperature ? " hover:bg-ef-primary-500 hover:text-ef-neutral-white" : "")
                    }
                    onMouseEnter={() => setHoveredTemperautreIndex(index)}
                    onMouseLeave={() => setHoveredTemperautreIndex(null)}
                  >
                    {hoveredTemperautreIndex === index && !time.temperature && (
                      <div
                        key={index}
                        className="absolute z-[999] top-340pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center stroke-ef-neutral-700 text-ef-neutral-700 py-11pxr px-16pxr border border-solid border-ef-neutral-700 ef-sub-shadow ef-label-md"
                      >
                        {/* <Info className="min-w-[16px] mr-4pxr" /> */}
                        <span className="whitespace-nowrap">{sortedTimes[index] < currentTime ? t("common.no_info") : t("common.no_info")}</span>
                      </div>
                    )}
                    {time.temperature ? time.temperature?.toFixed(2) : "-"}
                  </td>
                ))}
                <td className="flex justify-center ef-body-sm border border-solid border-r-transparent border-b-transparent py-11pxr w-60pxr min-w-[60pxr] bg-ef-neutral-700 text-ef-neutral-white">
                  {data && data.avg?.temperature ? (data.avg?.temperature).toFixed(2) : "-"}
                </td>
              </tr>
              <tr className="flex">
                <th className="w-145pxr min-w-[145px] flex justify-start items-center ef-body-sm border border-solid border-r-transparent border-b-transparent px-8pxr py-11pxr">
                  {" "}
                  <Humidity className="stroke-ef-neutral-900 mr-4pxr" />
                  {t("common.humidity")}(%)
                </th>
                {formattedData.map((time, index) => (
                  <td
                    key={`humidity-${index}`}
                    className={
                      "w-44pxr min-w-[44px] items-center flex justify-center ef-body-sm border border-solid border-r-transparent border-b-transparent py-11pxr " +
                      (time.humidity && Number(time.humidity) < Number(data?.room?.active_herd?.standard_data?.humidity?.lower)
                        ? "bg-ef-state-blue-50 text-ef-state-blue-500" // min보다 작을 때
                        : time.humidity && Number(time.humidity) > Number(data?.room?.active_herd?.standard_data?.humidity?.upper)
                          ? "bg-ef-state-red-50 text-ef-state-red-500" // max보다 클 때
                          : "bg-ef-neutral-white text-ef-neutral-900") + // 그 사이에 있을 때
                      (!time.humidity ? " hover:bg-ef-primary-500 hover:text-ef-neutral-white" : "")
                    }
                    onMouseEnter={() => setHoveredHumidityIndex(index)}
                    onMouseLeave={() => setHoveredHumidityIndex(null)}
                  >
                    {hoveredHumidityIndex === index && !time.humidity && (
                      <div className="absolute z-[999] top-388pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center stroke-ef-neutral-700 text-ef-neutral-700 py-11pxr px-16pxr border border-solid border-ef-neutral-700 ef-sub-shadow ef-label-md">
                        {/* <Info className="min-w-[16px] mr-4pxr" /> */}
                        <span className="whitespace-nowrap">{sortedTimes[index] < currentTime ? t("common.no_info") : t("common.no_info")}</span>
                      </div>
                    )}
                    {time.humidity ? time.humidity?.toFixed(2) : "-"}
                  </td>
                ))}
                <td className="flex justify-center ef-body-sm border border-solid border-r-transparent py-11pxr w-60pxr min-w-[60pxr] bg-ef-neutral-700 text-ef-neutral-white">
                  {data && data.avg?.humidity ? (data.avg?.humidity).toFixed(2) : "-"}
                </td>
              </tr>
              <tr className="flex">
                <th className="w-145pxr min-w-[145px] flex justify-start items-center ef-body-sm border border-solid border-r-transparent px-8pxr py-11pxr">
                  {" "}
                  <Flame className="stroke-ef-neutral-900 mr-4pxr" />
                  {t("common.heat_index")}
                </th>
                {formattedData.map((time, index) => (
                  <td
                    key={`heatIndex-${index}`}
                    className={
                      "w-44pxr min-w-[44px] items-center flex justify-center ef-body-sm border border-solid border-r-transparent py-11pxr " +
                      (time.heat_index && Number(time.heat_index) < Number(data?.room?.active_herd?.standard_data?.heat_index?.lower)
                        ? "bg-ef-state-blue-50 text-ef-state-blue-500" // min보다 작을 때
                        : time.heat_index && Number(time.heat_index) > Number(data?.room?.active_herd?.standard_data?.heat_index?.upper)
                          ? "bg-ef-state-red-50 text-ef-state-red-500" // max보다 클 때
                          : "bg-ef-neutral-white text-ef-neutral-900") + // 그 사이에 있을 때
                      (!time.heat_index ? " hover:bg-ef-primary-500 hover:text-ef-neutral-white" : "")
                    }
                    onMouseEnter={() => setHoveredHeatIndex(index)}
                    onMouseLeave={() => setHoveredHeatIndex(null)}
                  >
                    {hoveredHeatIndex === index && !time.heat_index && (
                      <div className="absolute z-[999] top-436pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center stroke-ef-neutral-700 text-ef-neutral-700 py-11pxr px-16pxr border border-solid border-ef-neutral-700 ef-sub-shadow ef-label-md">
                        {/* <Info className="min-w-[16px] mr-4pxr" /> */}
                        <span className="whitespace-nowrap">{sortedTimes[index] < currentTime ? t("common.no_info") : t("common.no_info")}</span>
                      </div>
                    )}
                    {time.heat_index ? Math.round(time.heat_index) : "-"}
                  </td>
                ))}
                <td className="flex justify-center ef-body-sm border border-solid border-r-transparent py-11pxr w-60pxr min-w-[60pxr] bg-ef-neutral-700 text-ef-neutral-white">
                  {data && data.avg?.heat_index ? (data.avg?.heat_index).toFixed(2) : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
}
export default IndoorAtmosphere;
