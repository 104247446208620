import DropDownFilter from "components/common/dropdown/DropDownFilter";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { ReactComponent as AltArrowUp } from "assets/icons/general/ef-general-altArrowUp.svg";
import { ReactComponent as AltArrowDown } from "assets/icons/general/ef-general-altArrowDown.svg";
import { ReactComponent as Forward } from "assets/icons/general/ef-general-forward.svg";
import { addDays, differenceInDays, format, isSameDay, subDays } from "date-fns";
import { usePiggeries } from "query/piggery";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import { useRoomDropdownList } from "query/rooms";
import { useSensorHistory } from "query/sensor";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import LogRangePicker from "components/pages/2_manage/environment/sensorLog/LogRangePicker";
import ComponentSpinner from "components/common/spinner/spinner";
import { RangeData, SensorHistoryData } from "query/sensor/type";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { ReactComponent as Download } from "assets/icons/report/ef-report-download.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";

type ToggleState = {
  rooms: number[]; // 열려 있는 방들의 roomId를 저장
  dates: {
    [roomId: number]: string[]; // 각 roomId에 대해 열려 있는 날짜들의 배열
  };
};

// data 상태에 따라 다른 css를 load하는 함수
const getTextColor = (data: number | null, range: RangeData | null, section: "temp" | "humid", needRangeCheck: boolean) => {
  if (range !== null && data !== null && needRangeCheck) {
    if (section === "temp" && _.inRange(data, range.lower_temperature, range.upper_temperature)) return "text-ef-neutral-900 hover:bg-ef-primary-500";
    else if (section === "humid" && _.inRange(data, range.lower_humidity, range.upper_humidity)) return "text-ef-neutral-900 hover:bg-ef-primary-500";
    else return "text-ef-state-red-500 bg-ef-state-red-50 hover:text-ef-neutral-white hover:bg-ef-state-red-500";
  } else return "text-ef-neutral-900 hover:bg-ef-primary-500";
};

// 열지수용 css 함수
const getHeatIndexCss = (categoryId: number | undefined | null) => {
  switch (categoryId) {
    case 1:
      return "text-ef-state-blue-500 hover:bg-ef-state-blue-500";
    case 2:
      return "text-ef-state-cyan-500 hover:bg-ef-state-cyan-500";
    case 3:
      return "text-ef-state-green-500 hover:bg-ef-state-green-500";
    case 4:
      return "text-ef-state-orange-500 hover:bg-ef-state-orange-500";
    case 5:
      return "text-ef-state-red-500 hover:bg-ef-state-red-500";
    default:
      return "";
  }
};

// 호버용 툴팁 기본 css
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: 8,
    padding: "0px",
    fontFamily: "Pretendard",
  },
}));

function ReportEnvironment() {
  const { t } = useTranslation();

  const { state } = useLocation();
  const date = state ? state.date : new Date();

  // state for filters
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(date);
  const [endDate, setEndDate] = useState<Date | null>(date);

  const [toggleState, setToggleState] = useState<ToggleState>({
    rooms: [],
    dates: {},
  });

  // 엑셀 다운로드용 ref
  const tableRef = useRef<HTMLTableElement | null>(null);

  const { data: piggeryList } = usePiggeries();
  const { data: roomList } = useRoomDropdownList(selectedPiggery, [1]);
  const { data: sensorData, isLoading: isDataLoading } = useSensorHistory({
    piggery_id: selectedPiggery,
    rooms: selectedRooms,
    start_date: startDate ? format(startDate, "yyyy-MM-dd") : "",
    end_date: endDate ? format(endDate, "yyyy-MM-dd") : "",
  });

  // mount시 0번째 돈사 선택
  useEffect(() => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
  }, [piggeryList]);

  // 돈사 바꿀때마다 돈방 reset
  useEffect(() => {
    setSelectedRooms([]);
  }, [selectedPiggery]);

  // 시작일자 변경시 6일 이상 차이나면 종료일을 선택한 시작일자의 6일뒤로
  useEffect(() => {
    if (startDate && endDate) {
      if (differenceInDays(endDate, startDate) > 6) {
        alert(t("common.max_view_date_comment", { day: 7 }));
        setEndDate(addDays(startDate, 6));
      }
    }
  }, [startDate]);

  // 종료일자 변경시 시작일자보다 6일뒤면 시작일자를 선택한 종료일자의 6일전으로
  useEffect(() => {
    if (startDate && endDate) {
      if (differenceInDays(endDate, startDate) > 6) {
        alert(t("common.max_view_date_comment", { day: 7 }));
        setStartDate(subDays(endDate, 6));
      }
    }
  }, [endDate]);

  // function for reset btn
  const handleReset = () => {
    setSelectedPiggery(piggeryList ? piggeryList[0].id : 0);
    setSelectedRooms([]);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  // 셀 한칸을 툴팁+테이블 한칸세트로 로드하는 함수
  // 초기 제작 후 예외처리가 계속 추가로 생겨서 지저분합니다..
  const getSuffix = (
    isDeviceExist: boolean,
    data: number | null,
    section: "temp" | "humid" | "heat",
    date: string,
    wholeData: SensorHistoryData,
    isSummary: boolean,
    needRangeCheck: boolean,
    heatCategory?: number,
  ) => {
    let tooltipComment: any = "";
    if (data === null) {
      if (data !== null) tooltipComment = "";
      else {
        if (!isDeviceExist) tooltipComment = "";
        else {
          tooltipComment = <div className="ef-body-sm py-12pxr px-16pxr">{t("common.no_info")}</div>;
        }
      }
    } else {
      if (!isSummary) tooltipComment = "";
      else {
        const getProperRange = () => {
          if (wholeData.range !== null) {
            const range = wholeData.range;
            switch (section) {
              case "temp":
                return `${range.lower_temperature}℃ ~ ${range.upper_temperature}℃`;
              case "humid":
                return `${range.lower_humidity}% ~ ${range.upper_humidity}%`;
              case "heat":
                return `${range.lower_heat_index} ~ ${range.upper_heat_index}`;
            }
          } else return t("common.no_info");
        };
        tooltipComment = (
          <div className="flex w-174pxr max-w-[174px] p-16pxr flex-col gap-8pxr ef-body-md text-ef-neutral-900">
            <div className="flex flex-col gap-2pxr">
              <p className="text-ef-primary-500 ef-label-sm">{t("common.herd")}</p>
              <p
                style={{
                  color: wholeData.herd ? "" : "#878787",
                }}
              >
                {wholeData.herd ? (wholeData.herd.nickname !== null ? wholeData.herd.nickname : wholeData.herd.name) : t("manage.herd_not_created")}
              </p>
            </div>
            <div className="flex flex-col gap-2pxr">
              <p className="text-ef-primary-500 ef-label-sm">{t("common.heads")}</p>
              <div className="flex flex-col">
                <p
                  style={{
                    color: wholeData.herd ? "" : "#878787",
                  }}
                >
                  {wholeData.herd ? `${wholeData.herd.stock}${t("common.head")}` : t("manage.stock_not_registered")}
                </p>
                {/* @TODO api에 재고 업데이트 일자 추가되면 작업 */}
                {/* <p className="ef-body-xxs text-ef-neutral-500">{`재고 업데이트 : 2024-01-01`}</p> */}
              </div>
            </div>
            <div className="flex flex-col gap-2pxr">
              <p className="text-ef-primary-500 ef-label-sm">{t("common.birth_date_aged_day")}</p>
              <p
                style={{
                  color: wholeData.herd?.birth_date ? "" : "#878787",
                }}
              >
                {wholeData.herd?.birth_date ? `${wholeData.herd?.birth_date}(${wholeData.herd?.age}${t("common.aged")})` : t("manage.birth_date_not_registered")}
              </p>
            </div>
            <div className="flex flex-col gap-2pxr">
              <p className="text-ef-primary-500 ef-label-sm">
                {section === "temp" ? t("common.proper_temperature") : section === "humid" ? t("common.proper_humidity") : section === "heat" ? t("common.proper_heat_index") : ""}
              </p>
              <p
                style={{
                  color: wholeData.range ? "" : "#878787",
                }}
              >
                {getProperRange()}
              </p>
            </div>
          </div>
        );
      }
    }

    let tdData: string = "";
    if (!isDeviceExist) tdData = t("common.no_device");
    else if (data === null) tdData = "-";
    else {
      switch (section) {
        case "temp":
          tdData = `${data}℃`;
          break;
        case "humid":
          tdData = `${data}%`;
          break;
        case "heat":
          tdData = `${data} (${t(`common.heat_${heatCategory}`)})`;
          break;
      }
    }

    return (
      <HtmlTooltip
        disableInteractive
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
        title={tooltipComment}
      >
        <td
          className={
            "hover:text-ef-neutral-white " +
            (isSummary && section !== "heat"
              ? getTextColor(data, wholeData.range, section, needRangeCheck)
              : !isSummary && section !== "heat"
                ? "text-ef-neutral-900 hover:bg-ef-primary-500"
                : section === "heat" && data !== null
                  ? getHeatIndexCss(heatCategory)
                  : "text-ef-neutral-900 hover:bg-ef-primary-500")
          }
        >
          {tdData}
        </td>
      </HtmlTooltip>
    );
  };

  // 방을 토글할때 함수
  const handleToggleRoom = (roomId: number) => {
    setToggleState((prevState) => {
      const isRoomOpen = prevState.rooms.includes(roomId);

      return {
        ...prevState,
        rooms: isRoomOpen
          ? prevState.rooms.filter((id) => id !== roomId) // 열려 있으면 닫기
          : [...prevState.rooms, roomId], // 닫혀 있으면 열기
      };
    });
  };

  // 날짜를 토글할때 함수
  const handleToggleDate = (roomId: number, date: string) => {
    setToggleState((prevState) => {
      const currentDates = prevState.dates[roomId] || [];
      const isDateOpen = currentDates.includes(date);

      return {
        ...prevState,
        dates: {
          ...prevState.dates,
          [roomId]: isDateOpen
            ? currentDates.filter((d) => d !== date) // 열려 있으면 닫기
            : [...currentDates, date], // 닫혀 있으면 열기
        },
      };
    });
  };

  // 데이터 변경시에 토글 state 초기값으로 변경
  useEffect(() => {
    if (sensorData) {
      if (sensorData[0].period.length === 1) {
        setToggleState({
          rooms: [],
          dates: {},
        });
      } else {
        setToggleState({
          rooms: sensorData.map((o) => o.id),
          dates: {},
        });
      }
    }
  }, [sensorData]);

  return (
    <div className="w-full h-full bg-ef-neutral-50 text-ef-neutral-900">
      <div className="w-[1440px] min-w-[1440px] mx-auto pt-60pxr h-fit flex flex-col">
        <header className="flex flex-row items-center">
          <span className="ef-headline-lg whitespace-nowrap">{t("gnb.environmental_records")}</span>
          <DownloadTableExcel
            filename={`${startDate !== null ? format(startDate, "yyyy-MM-dd") : ""} ~ ${endDate !== null ? format(endDate, "yyyy-MM-dd") : ""} ${t("gnb.environmental_records")}`}
            sheet=""
            currentTableRef={tableRef.current}
          >
            <button type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr ml-16pxr">
              <Download />
              {t("manage.download_excel")}
            </button>
          </DownloadTableExcel>
          <div className="flex flex-row ml-auto gap-8pxr items-center">
            <DropDownFilter
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
            <DropDownCheckBox
              title={t("common.room")}
              state={selectedRooms}
              setState={setSelectedRooms}
              options={
                roomList
                  ? roomList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
            <LogRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} maxDate={new Date()} />
            <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center ml-16pxr">
              <Refresh />
              {t("common.reset")}
            </button>
          </div>
        </header>
        <div className="w-full h-fit relative pb-200pxr mt-60pxr ">
          {isDataLoading && <ComponentSpinner />}
          <div className="mb-24pxr w-full h-1pxr bg-ef-neutral-200"></div>
          <div className="flex flex-col gap-24pxr">
            <span className="ml-auto ef-body-lg text-ef-neutral-700">{`${t("common.view_date")}(${format(new Date(), "yyyy-MM-dd HH:mm")})`}</span>
            <table ref={tableRef} className="ef-table-toggle !w-full">
              <thead>
                <tr>
                  <th rowSpan={2} className="w-160pxr min-w-[160px]"></th>
                  <th colSpan={9}>{t("common.temperature")}</th>
                  <th colSpan={4}>{t("common.humidity")}</th>
                  <th rowSpan={2}>{t("common.heat_index")}</th>
                </tr>
                <tr className="[&>th]:w-86pxr [&>th]:min-w-[86px] [&>th]:max-w-[86px]">
                  <th title={t("manage.daily_average")}>{t("manage.daily_average")}</th>
                  <th>{t("common.at_time", { time: "07" })}</th>
                  <th>{t("common.at_time", { time: "12" })}</th>
                  <th>{t("common.at_time", { time: "14" })}</th>
                  <th>{t("common.at_time", { time: "17" })}</th>
                  <th>{t("common.at_time", { time: "22" })}</th>
                  <th title={t("common.lowest_temperature")}>{t("common.lowest_temperature")}</th>
                  <th title={t("common.highest_temperature")}>{t("common.highest_temperature")}</th>
                  <th title={t("manage.deviation")}>{t("manage.deviation")}</th>
                  <th title={t("manage.daily_average")}>{t("manage.daily_average")}</th>
                  <th title={t("common.lowest_humidity")}>{t("common.lowest_humidity")}</th>
                  <th title={t("common.highest_humidity")}>{t("common.highest_humidity")}</th>
                  <th title={t("manage.deviation")}>{t("manage.deviation")}</th>
                </tr>
              </thead>
              <tbody>
                {sensorData?.map((d) => (
                  <React.Fragment key={d.id}>
                    <tr key={d.id} className={" " + (d.period[0].sensors.length === 0 ? "[&>*]:text-ef-neutral-300" : "")}>
                      {/* 방 이름 */}
                      <td
                        colSpan={d.period.length > 1 ? 15 : 1}
                        style={{
                          backgroundColor: d.period.length > 1 ? "#F5FAFF" : "#FFFFFF",
                          cursor: "pointer",
                          padding: "12px 8px",
                        }}
                        className={"!text-ef-neutral-900 " + (d.period.length > 1 ? "ef-body-md" : "ef-body-sm")}
                        onClick={() => {
                          if (d.period[0].sensors.length === 0) return;
                          else handleToggleRoom(d.id);
                        }}
                      >
                        <div className="flex flex-row w-full items-center max-w-[144px]">
                          <span className="max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap">{d.name}</span>
                          {d.period[0].sensors.length !== 0 && (
                            <div className="ml-auto">{toggleState.rooms.includes(d.id) ? <AltArrowUp className="fill-ef-neutral-700" /> : <AltArrowDown className="fill-ef-neutral-700" />}</div>
                          )}
                        </div>
                      </td>
                      {d.period.length === 1 && (
                        <>
                          {/* 온도 */}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.avg, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.seven, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.twelve, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.fourteen, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.seventeen, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.twentytwo, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.min, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.max, "temp", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].temperature.diff, "temp", d.period[0].date, d.period[0], true, false)}
                          {/* 습도 */}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].humidity.avg, "humid", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].humidity.min, "humid", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].humidity.max, "humid", d.period[0].date, d.period[0], true, true)}
                          {getSuffix(d.period[0].sensors.length !== 0, d.period[0].humidity.diff, "humid", d.period[0].date, d.period[0], true, false)}
                          {/* 열지수 */}
                          {getSuffix(
                            d.period[0].sensors.length !== 0,
                            d.period[0].heat_index ? Math.floor(d.period[0].heat_index.index) : null,
                            "heat",
                            d.period[0].date,
                            d.period[0],
                            true,
                            false,
                            d.period[0].heat_index?.category,
                          )}
                        </>
                      )}
                    </tr>
                    {toggleState.rooms.includes(d.id) &&
                      d.period.map((o) => (
                        <React.Fragment key={o.date}>
                          {d.period.length !== 1 && (
                            <tr key={o.date} className={" " + (o.sensors.length === 0 ? "[&>*]:text-ef-neutral-300" : "")}>
                              {/* 날짜 */}
                              <td onClick={() => handleToggleDate(d.id, o.date)} className="cursor-pointer !py-12pxr !text-ef-neutral-900">
                                <div className="flex flex-row w-full items-center gap-4pxr">
                                  <span className="max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap">{o.date}</span>
                                  {o.sensors.length !== 0 && (
                                    <div className="ml-auto">
                                      {toggleState.dates[d.id]?.includes(o.date) ? <AltArrowUp className="fill-ef-neutral-700" /> : <AltArrowDown className="fill-ef-neutral-700" />}
                                    </div>
                                  )}
                                </div>
                              </td>
                              {/* 온도 */}
                              {getSuffix(o.sensors.length !== 0, o.temperature.avg, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.seven, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.twelve, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.fourteen, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.seventeen, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.twentytwo, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.min, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.max, "temp", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.temperature.diff, "temp", o.date, o, true, false)}
                              {/* 습도 */}
                              {getSuffix(o.sensors.length !== 0, o.humidity.avg, "humid", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.humidity.min, "humid", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.humidity.max, "humid", o.date, o, true, true)}
                              {getSuffix(o.sensors.length !== 0, o.humidity.diff, "humid", o.date, o, true, false)}
                              {/* 열지수 */}
                              {getSuffix(o.sensors.length !== 0, o.heat_index ? Math.floor(o.heat_index.index) : null, "heat", o.date, o, true, false, o.heat_index?.category)}
                            </tr>
                          )}
                          {d.period.length === 1 || toggleState.dates[d.id]?.includes(o.date) ? (
                            o.sensors.map((s) => (
                              <tr key={s.id} className="bg-ef-neutral-50">
                                {/* 센서 이름 */}
                                <td title={s.name} className="!py-12pxr">
                                  <div className="flex flex-row w-full items-center gap-4pxr">
                                    <Forward className="stroke-ef-neutral-900" />
                                    <span className="max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap">{s.name}</span>
                                  </div>
                                </td>
                                {/* 온도 */}
                                {getSuffix(true, s.temperature.avg, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.seven, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.twelve, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.fourteen, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.seventeen, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.twentytwo, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.min, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.max, "temp", o.date, o, false, false)}
                                {getSuffix(true, s.temperature.diff, "temp", o.date, o, false, false)}
                                {/* 습도 */}
                                {getSuffix(true, s.humidity.avg, "humid", o.date, o, false, false)}
                                {getSuffix(true, s.humidity.min, "humid", o.date, o, false, false)}
                                {getSuffix(true, s.humidity.max, "humid", o.date, o, false, false)}
                                {getSuffix(true, s.humidity.diff, "humid", o.date, o, false, false)}
                                {/* 열지수 */}
                                {getSuffix(true, s.heat_index ? Math.floor(s.heat_index.index) : null, "heat", o.date, o, false, false, o.heat_index?.category)}
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportEnvironment;
