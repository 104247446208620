// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 모든 datepicker에 통용되는 css */

.react-datepicker-wrapper input {
  /* ef title md */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 150%;
  letter-spacing: -0.025rem;
  width: 100%;
  background-color: transparent;
  /* ef neutral 900 */
  color: #303030;
  text-align: center !important;
}
.react-datepicker-wrapper input:disabled {
  /* neutral-300 */
  color: #878787;
}

.react-datepicker-wrapper input::selection {
  background-color: transparent;
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

#singleDate {
  font-weight: 400 !important;
  line-height: 150%;
  letter-spacing: -0.025rem;
  text-align: left !important;
}

.react-datepicker-popper {
  z-index: 1300 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/datepicker.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,2BAA2B;EAC3B,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX,6BAA6B;EAC7B,mBAAmB;EACnB,cAAc;EACd,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B;AACA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["/* 모든 datepicker에 통용되는 css */\n\n.react-datepicker-wrapper input {\n  /* ef title md */\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 600 !important;\n  line-height: 150%;\n  letter-spacing: -0.025rem;\n  width: 100%;\n  background-color: transparent;\n  /* ef neutral 900 */\n  color: #303030;\n  text-align: center !important;\n}\n.react-datepicker-wrapper input:disabled {\n  /* neutral-300 */\n  color: #878787;\n}\n\n.react-datepicker-wrapper input::selection {\n  background-color: transparent;\n}\n.react-datepicker__input-container {\n  width: inherit;\n}\n\n.react-datepicker-wrapper {\n  width: 100%;\n}\n\n#singleDate {\n  font-weight: 400 !important;\n  line-height: 150%;\n  letter-spacing: -0.025rem;\n  text-align: left !important;\n}\n\n.react-datepicker-popper {\n  z-index: 1300 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
