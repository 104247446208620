import React, { useState } from "react";
import { ReactComponent as GeneralLogout } from "assets/icons/general/ef-general-logout.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as List } from "assets/icons/general/ef-general-list2.svg";
import { useRoomHistorys } from "query/rooms";
import { useDeleteStockHistory, useHerdHistoryCategory } from "query/herd";
import { RoomForStockDashBoard } from "query/piggery/type";
import NoDataImg from "assets/images/report/no_report_data.png";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import AddGroupModal from "components/pages/2_manage/stock/group/AddGroupModal";
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import BtnSort from "components/common/button/BtnSort";
import { ReactComponent as Modify } from "assets/icons/general/ef-general-modify.svg";
import { ReactComponent as Remove } from "assets/icons/general/ef-general-remove.svg";
import { format } from "date-fns";
import { ReactComponent as Death } from "assets/icons/report/ef-report-death.svg";
import { ReactComponent as SymbolSmall } from "assets/graphic/ef-graphic-symbol-small.svg";
import { ReactComponent as Arrival } from "assets/icons/report/ef-report-arrival.svg";
import { ReactComponent as Shipment } from "assets/icons/report/ef-report-shipment.svg";
import { ReactComponent as Movement } from "assets/icons/gnb/ef-gnb-movement.svg";
import DeactivateHerdLayer from "components/pages/2_manage/stock/group/DeactivateHerdLayer";
import AddStockHistory from "./addStockHistory";
import { toast } from "react-toastify";
import EditStockHistory from "./editStockHistory";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F5FAFF",
    color: "#033687",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #033687",
    borderRadius: 8,
    padding: "8px 16px",
    fontFamily: "Pretendard",
  },
}));

const getColor = (type: number) => {
  switch (type) {
    case 1:
      return "#1C7ED6"; //state blue
    case 2:
      return "#37B24D"; //state green
    case 3:
      return "#1098AD"; //state cyan
    case 4:
      return "#F76707"; //state orange
    case 5:
      return "#F03E3E"; //state red
    default:
      return "#878787";
  }
};

const getIcon = (type: number) => {
  switch (type) {
    case 1:
      return <Arrival />; // 입식
    case 2:
      return <Movement />; // 전입
    case 3:
      return <Movement />; // 전출
    case 4:
      return <Shipment />; // 출하
    case 5:
      return <Death className="fill-ef-neutral-white" />; // 폐사
    default:
      return <SymbolSmall className="fill-ef-neutral-white" />;
  }
};

type Props = {
  selectedRoom: RoomForStockDashBoard;
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function StockDetailHistory(props: Props) {
  const { selectedRoom, setToggleModal } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  // 돈군 추가 모달 toggle state
  const [addHerdModal, setAddHerdModal] = useState(false);
  // 돈방 재고 히스토리 추가 모달 toggle state
  const [addHerdHistory, setAddHerdHistory] = useState(false);
  // 돈방 재고 히스토리 수정 모달 toggle state
  const [editHerdHistory, setEditHerdHistory] = useState(false);
  const [selectedHistoryId, setSelectedHistoryId] = useState(0);
  // 돈방 히스토리 카테고리 선택
  const [movement, setMovement] = useState(0);
  // 최신순 오래된순
  const [sortState, setSortState] = useState(true); // true=최신순, false=오래된순
  // 돈군종료 모달 toggleState
  const [quitHerdModal, setQuitHerdModal] = useState(false);

  const { data: movementList } = useHerdHistoryCategory();
  const { data: stockHistoryData } = useRoomHistorys(selectedRoom.id);
  const { mutateAsync: deleteStockHistory } = useDeleteStockHistory();

  const onDelete = async (id: number, isLastIdx: boolean) => {
    if (window.confirm(isLastIdx ? t("status.delete_initial_history_herd_alert_comment") : t("status.delete_history_alert"))) {
      try {
        await deleteStockHistory(id);
        toast.success(t("common.deletion_completed"));
      } catch (error) {}
    } else return;
  };

  const filteredData = {
    ...stockHistoryData,
    active_herd: stockHistoryData?.active_herd
      ? {
          ...stockHistoryData.active_herd,
          historys: stockHistoryData?.active_herd?.historys?.filter((d) => movement === 0 || d.category.id === movement) ?? [], // historys 필터링, 만약 null이면 빈 배열로 처리
        }
      : null, // active_herd가 null이면 그대로 null
  };

  const getFilteredHistory = () => {
    if (!filteredData?.active_herd?.historys) return [];
    return sortState ? filteredData.active_herd.historys.slice() : filteredData.active_herd.historys.slice().reverse();
  };

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto p-40pxr">
      {stockHistoryData?.active_herd !== null && (
        <AddStockHistory
          herdStock={stockHistoryData?.active_herd?.summary?.stock}
          isToggle={addHerdHistory}
          setIsToggle={setAddHerdHistory}
          herdId={stockHistoryData?.active_herd.id}
          herdData={stockHistoryData?.active_herd}
        />
      )}
      {stockHistoryData && <EditStockHistory historyData={stockHistoryData} isToggle={editHerdHistory} setIsToggle={setEditHerdHistory} historyId={selectedHistoryId} />}
      <AddGroupModal distinct="historyModal" roomId={selectedRoom.id} data={selectedRoom} isToggle={addHerdModal} setIsToggle={setAddHerdModal} />
      <div className="flex flex-row">
        <button
          type="button"
          onClick={(e) => {
            if (selectedRoom.summary !== null || typeof selectedRoom.summary !== "undefined") {
              setQuitHerdModal(true);
            } else {
              alert(t("status.there_is_no_herd_to_quit"));
            }
          }}
          disabled={selectedRoom.summary === null || typeof selectedRoom.summary === "undefined"}
          className="ef-btn-regular relative"
        >
          <GeneralLogout />
          {t("common.quit_herd")}
          {quitHerdModal && (
            <DeactivateHerdLayer
              setModalToggle={setToggleModal}
              historyData={stockHistoryData}
              herdId={stockHistoryData?.active_herd ? stockHistoryData.active_herd.id : 0}
              isOpen={quitHerdModal}
              setIsOpen={setQuitHerdModal}
            />
          )}
        </button>

        <button
          type="button"
          className="ef-btn-regular ml-auto"
          onClick={() => {
            navigate("/manage/stock/record", {
              state: {
                roomId: selectedRoom.id,
              },
            });
          }}
        >
          <List /> {t("status.monthly_inventory_records")}
        </button>
      </div>

      {selectedRoom.vacant_period ? (
        <div className="flex items-center justify-center flex-col h-full">
          <img src={NoDataImg} alt="no-data" className="w-310pxr" />
          <span className="ef-body-lg text-ef-neutral-700 mt-8pxr">{t("status.there_is_no_herd_added")}</span>
          <button onClick={() => setAddHerdModal(true)} type="button" className="mt-24pxr ef-btn-regular">
            <Add /> {t("common.add_herd")}
          </button>
        </div>
      ) : (
        <>
          <div className="p-24pxr rounded-[8px] bg-ef-neutral-50 mt-24pxr flex flex-col gap-16pxr">
            <div className="grid grid-cols-4 gap-8pxr">
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.herd_name")}</p>
                <p className="ef-body-xl line-clamp-1 break-all text-ef-primary-500 underline">
                  {stockHistoryData?.active_herd !== null
                    ? stockHistoryData?.active_herd.nickname !== null
                      ? stockHistoryData?.active_herd.nickname
                      : stockHistoryData?.active_herd?.name
                    : t("common.no_info")}
                </p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.birth_date_aged_day")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">
                  {stockHistoryData?.active_herd !== null ? `${stockHistoryData?.active_herd.birth_date}(${stockHistoryData?.active_herd.age}${t("common.aged")})` : t("common.no_info")}
                </p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.arrival_date")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{selectedRoom.summary ? format(new Date(selectedRoom.summary.arrival_date), "yyyy-MM-dd") : t("common.no_info")}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.average_weight")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{selectedRoom.avg_weight ? `${selectedRoom.avg_weight.toFixed(2)}kg` : t("common.no_info")}</p>
              </div>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-200"></div>
            <div className="grid grid-cols-7 gap-8pxr">
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.arrival")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.arrival : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.shipment")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.shipment : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.move_in")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.movein : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.move_out")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.moveout : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.death")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.death : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.unknown")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.none : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
              <div className="flex flex-col gap-4pxr">
                <p className="ef-body-lg text-ef-neutral-700 line-clamp-1 break-all">{t("common.number_of_stocks")}</p>
                <p className="ef-body-xl line-clamp-1 break-all">{`${stockHistoryData && stockHistoryData.active_herd !== null ? stockHistoryData.active_herd.summary.stock : "-"}${t(
                  "common.head",
                )}`}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-16pxr mt-40pxr">
            <div className="flex flex-row items-center">
              <div className="flex flex-row gap-24pxr items-center">
                <span className="flex flex-row gap-4pxr items-center ef-title-md stroke-ef-neutral-700">
                  {t("status.room_history")}
                  <HtmlTooltip placement="top" title={<span className="ef-label-md">{t("status.display_history_from_room_comment")}</span>}>
                    <Info className="w-24pxr h-24pxr" />
                  </HtmlTooltip>
                </span>
                <div className="w-180pxr">
                  <DropDownSmall
                    state={movement}
                    setState={setMovement}
                    options={movementList ? [{ value: 0, name: t("common.all") }, ...movementList.map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) }))] : []}
                  />
                </div>
              </div>
              <BtnSort sortState={sortState} setSortState={setSortState} />
            </div>
            {/* history mapping */}
            <div className="rounded-[8px] border border-solid border-ef-neutral-150 p-24pxr flex flex-col gap-24pxr">
              <button
                onClick={() => setAddHerdHistory(true)}
                type="button"
                className="w-full h-62pxr border border-dashed border-ef-neutral-500 stroke-ef-neutral-900 ef-label-lg px-16pxr py-8pxr flex flex-row gap-8pxr items-center justify-center rounded-[8px] hover:bg-ef-primary-50 hover:ef-main-shadow active:shadow-none focus:ef-main-shadow focus:border-solid focus:outline-none"
              >
                <Add />
                {t("status.add_room_inventory_history")}
              </button>
              <div className="flex flex-col gap-4pxr">
                {getFilteredHistory().length === 0 ? (
                  <div className="w-full py-40pxr flex flex-col gap-4pxr items-center justify-center">
                    <img src={NoDataImg} alt="no-data" className="w-340pxr" />
                    <span className="text-ef-neutral-700 ef-label-lg">
                      {t("status.no_history_worktype_comment", { type: movement === 0 ? t("manage.total") : t(`common.herd_history_${movement}`) })}
                    </span>
                  </div>
                ) : (
                  getFilteredHistory().map((o, idx) => (
                    <div key={o.id} className="flex flex-row gap-8pxr">
                      <div className="flex flex-col items-center gap-4pxr">
                        <div
                          style={{
                            backgroundColor: getColor(o.category.id),
                          }}
                          className="rounded-full w-40pxr h-40pxr min-w-[40px] min-h-[40px] flex items-center justify-center stroke-ef-neutral-white"
                        >
                          {getIcon(o.category.id)}
                        </div>
                        {idx + 1 < getFilteredHistory().length && <div className="w-0pxr h-38pxr border-l border-dashed border-ef-neutral-400"></div>}
                      </div>
                      <div className="flex flex-row items-center w-full rounded-[8px] pl-16pxr py-12pxr pr-40pxr h-fit border border-solid border-ef-neutral-150">
                        <div className="flex flex-row items-center gap-32pxr">
                          <div className="flex flex-col gap-2pxr w-92pxr">
                            <p className="ef-label-sm text-ef-primary-500 line-clamp-1 break-all">{t("common.work_type")}</p>
                            <p className="ef-body-sm line-clamp-1 break-al">{t(`common.herd_history_${o.category.id}`)}</p>
                          </div>
                          <div className="flex flex-col gap-2pxr w-72pxr">
                            <p className="ef-label-sm text-ef-primary-500 line-clamp-1 break-all">{t("common.moved_stock")}</p>
                            <p className="ef-body-sm line-clamp-1 break-al">{`${o.change}${t("common.head")}`}</p>
                          </div>
                          <div className="flex flex-col gap-2pxr w-72pxr">
                            <p title={t("common.number_of_stocks")} className="ef-label-sm text-ef-primary-500 line-clamp-1 break-all">
                              {t("common.number_of_stocks")}
                            </p>
                            <p className="ef-body-sm line-clamp-1 break-al">{`${o.stock}${t("common.head")}`}</p>
                          </div>
                          <div className="flex flex-col gap-2pxr w-115pxr">
                            <p className="ef-label-sm text-ef-primary-500 line-clamp-1 break-all">{t("common.work_datetime")}</p>
                            <p className="ef-body-sm line-clamp-1 break-al">{o.created_at ? format(new Date(o.created_at), "yyyy-MM-dd HH:mm") : t("common.no_info")}</p>
                          </div>
                          <div className="flex flex-col gap-2pxr w-115pxr">
                            <p className="ef-label-sm text-ef-primary-500 line-clamp-1 break-all">{t("common.memo")}</p>
                            <p className="ef-body-sm line-clamp-1 break-al">{o.memo === "" || o.memo === null ? "-" : o.memo}</p>
                          </div>
                        </div>
                        <div className="flex flex-row ml-auto items-center gap-24pxr">
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedHistoryId(o.id);
                              setEditHerdHistory(true);
                            }}
                            className="flex flex-row ef-label-lg text-ef-neutral-700"
                          >
                            <Modify className="stroke-ef-neutral-700" />
                            {t("common.edit")}
                          </button>
                          <button
                            type="button"
                            onClick={() => onDelete(o.id, stockHistoryData?.active_herd.historys.findIndex((v) => v.id === o.id) === stockHistoryData?.active_herd.historys.length! - 1)}
                            className="flex flex-row ef-label-lg text-ef-neutral-700"
                          >
                            <Remove className="stroke-ef-neutral-700" />
                            {t("common.delete")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default StockDetailHistory;
