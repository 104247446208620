import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { useTranslation } from "react-i18next";
import { MovementDetail } from "query/movement/type";
import { differenceInCalendarDays, differenceInDays, format, subDays } from "date-fns";
import { toast } from "react-toastify";
import { useEditMovement } from "query/movement";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { getRedStar } from "utils/getImportantStar";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<any>>;
  defaultData: MovementDetail;
  targetDate: Date | null;
  age?: number | null;
}

type Inputs = {
  age: number | string;
};

function EnterDayAgeModal(props: Props) {
  const { isToggle, setIsToggle, defaultData, targetDate, age } = props;
  const { t } = useTranslation();
  const { mutateAsync: editReport } = useEditMovement();
  // const [dayAge, setDayAge] = useState<Date | null | string>(defaultData.herd?.age ? defaultData.herd?.age : new Date());
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  const watchedAge = watch("age");

  // const onSubmit: SubmitHandler<{ age: number }> = async () => {
  //   const formattedData = {
  //     id: defaultData?.id,
  //     age: targetDate && birth_date && differenceInDays(targetDate, birth_date),
  //   };

  //   if (formattedData) {
  //     try {
  //       // await editMemo(formattedData);
  //       toast.success(t("common.save_completed"),  );
  //       // setIsEditing(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const onSubmit = async (data: Inputs) => {
    const payload = {
      id: defaultData.id,
      age: data.age,
    };
    try {
      await editReport({ id: defaultData.id, payload: { age: Number(payload.age) } });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
    } catch (error) {}
  };

  console.log(watchedAge);

  useEffect(() => {
    if (age !== null && typeof age !== "undefined") {
      setValue("age", age);
    }
  }, [age]);

  // useEffect(() => {
  //   clearErrors();
  // }, [isToggle]);

  return (
    <>
      <div className="relative">
        <div className="absolute w-380pxr z-10 mt-4pxr ml-[-54px] bg-ef-neutral-white outline-none flex flex-col p-24pxr  border border-solid border-ef-neutral-150 rounded-md">
          <div className="flex relative w-full items-center">
            <span className="flex mx-auto ef-neutral-800 ef-title-md">{t("manage.enter_birth_date")}</span>
            <button className="absolute p-8pxr z-[10] bg-ef-neutral-100 rounded-full bottom-[-7px] right-0pxr" onClick={() => setIsToggle(false)}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>

          <form className="flex flex-col mt-40pxr" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row">
              <label className="w-89pxr ef-label-md text-ef-neutral-900 mt-10pxr">
                {t("common.day_age")} {getRedStar()}
              </label>
              <div className="flex flex-col gap-8pxr">
                <div className="flex flex-row items-center gap-4pxr">
                  {targetDate && (
                    <div className="w-162pxr relative">
                      {errors.age && <RequiredAlert />}
                      <Controller
                        name="age"
                        control={control}
                        rules={{ validate: (value) => typeof value !== "undefined" && value !== "" }}
                        render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.day_age")} type="number" regex={/^(?:[1-9]\d{0,2}|0)?$/} />}
                      />
                    </div>
                  )}
                  <span className="flex ef-body-sm whitespace-nowrap">{t("common.aged")}</span>
                </div>
                <span className="ef-body-sm h-20pxr text-ef-secondary-500">
                  {watchedAge !== "" && typeof watchedAge !== "undefined" ? `${t("common.birth_date")} : ${format(subDays(new Date(defaultData.ended_at), Number(watchedAge)), "yyyy-MM-dd")}` : ""}
                </span>
              </div>
            </div>
            <div className="flex justify-center mb-16pxr mt-32pxr">
              <button type="submit" className="w-300pxr ef-btn-modal">
                {t("common.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EnterDayAgeModal;
