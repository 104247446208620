import { FarmType, MemberType } from "query/member/type";
import React, { useEffect, useReducer } from "react";

type AuthAction =
  | {
      type: "SET_TOKEN";
      payload: {
        access_token: string;
      };
    }
  | {
      type: "SET_INIT";
      payload: MemberType;
    }
  | {
      type: "SET_FARM";
      payload: FarmType;
    }
  | {
      type: "CLEAR";
    };

type AuthState = {
  user: MemberType;
  access_token: string;
  current_farm: FarmType;
};

const initialState: AuthState = {
  user: {
    username: "",
    name: "",
    phone: "",
    email: "",
    id: 0,
    active_status: false,
    farms: [],
    alarm_status: false,
  },
  access_token: "",
  current_farm: {
    name: "",
    code: "",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    coordinate: "",
    address: "",
    address_detail: "",
    id: 0,
    image_path: "",
    active_status: false,
  },
};

const AuthStateContext = React.createContext<AuthState | null>(null);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthAction> | null>(null);

function AuthReducer(state: AuthState, action: AuthAction) {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, access_token: action.payload.access_token };
    case "SET_INIT":
      return { ...state, user: action.payload };
    case "SET_FARM":
      return { ...state, current_farm: action.payload };
    case "CLEAR":
      return { ...initialState };
    default:
      throw new Error("Unhandled action");
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function useAuthState() {
  const state = React.useContext(AuthStateContext);
  if (state === null) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return state;
}

export function useAuthDispatch() {
  const dispatch = React.useContext(AuthDispatchContext);
  if (dispatch === null) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return dispatch;
}
