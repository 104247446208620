import Pagination from "components/common/pagination/Pagination";
import NoticeCard from "components/pages/0_notice/NoticeCard";
import { useNoticeList, usePinnedNotice, useTotalNoticePage } from "query/notice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const PAGELIMIT = 20;

function Notice() {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.state !== null ? location.state.pageNum : 1);
  // 고정-일반과 공지사항 아이디를 동시에 state로 관리
  const [selectedNotice, setSelectedNotice] = useState({
    id: location.state !== null ? location.state.noticeId : 0,
    type: "normal",
  });

  const { data: noticeList } = useNoticeList(currentPage);
  const { data: pinnedNotice } = usePinnedNotice();
  const { data: totalPage } = useTotalNoticePage();

  // footer 자세히 보기로 state가 입력되었을때 감지하고 selectedNotice 변경
  useEffect(() => {
    if (location.state !== null) {
      setCurrentPage(location.state.pageNum);
      setSelectedNotice({ id: location.state.noticeId, type: "normal" });
    }
  }, [location.key]);

  // 게시글 클릭할때 상단으로 조정(외부에서 들어올때도)
  useEffect(() => {
    const section = document.getElementById(`${selectedNotice.type}-${selectedNotice.id}`);
    if (section) {
      // 요소가 렌더링된 후에 스크롤
      setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, [selectedNotice.id, selectedNotice.type, location.key]);

  return (
    <div className="w-full h-full bg-ef-neutral-white">
      <div className="w-[948px] min-w-[948px] mx-auto h-fit flex flex-col pt-60pxr pb-200pxr text-ef-neutral-900">
        <p className="text-center ef-headline-lg">{t("notice.notice")}</p>
        <div className="w-full border-t-2 border-ef-neutral-900 border-solid flex flex-col mt-40pxr">
          {pinnedNotice &&
            pinnedNotice.map((o) => (
              <div key={o.id} id={`pin-${o.id}`}>
                <NoticeCard setSelectedNotice={setSelectedNotice} selectedNotice={selectedNotice} type="pin" data={o} />
              </div>
            ))}
          {noticeList &&
            noticeList.map((o, i) => (
              <div key={o.id} id={`normal-${o.id}`}>
                <NoticeCard setSelectedNotice={setSelectedNotice} selectedNotice={selectedNotice} type="normal" data={o} index={i} totalLength={noticeList.length} />
              </div>
            ))}
        </div>
        <div className="mx-auto mt-60pxr">
          {typeof totalPage !== "undefined" && <Pagination dataLength={totalPage} pageLimit={PAGELIMIT} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
        </div>
      </div>
    </div>
  );
}

export default Notice;
