import { Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ReportDownload } from "assets/icons/report/ef-report-download.svg";
import { Controller, useForm, Control } from "react-hook-form";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useTranslation } from "react-i18next";
import BtnSort from "components/common/button/BtnSort";
import { MovementDetail } from "query/movement/type";
// import { useDeathCategories } from "query/movement";
import { utils, WorkSheet, writeFile } from "xlsx";
import { useAuthState } from "context/AuthContext";
import { format } from "date-fns";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<any>>;
  detailReportData: MovementDetail | undefined;
}

function WeightListModal(props: Props) {
  const { current_farm } = useAuthState();
  const { isToggle, setIsToggle, detailReportData } = props;
  const [sortState, setSortState] = useState(true); // true 체중순 false 이동시간순
  const { t } = useTranslation();
  // const { data: deathCauseList } = useDeathCategories();

  const exportToExcel = (jsonData: any, fileName: string) => {
    // 새 workbook 생성
    const workbook = utils.book_new();

    // weight_list_json_confirm과 confirm_summary를 같은 시트에서 보여주기
    const weightConfirmData = jsonData.weight_list_json_confirm;
    const confirmSummaryData = jsonData.confirm_summary;

    // confirm_summary 데이터를 배열로 변환 (첫 번째 행에만 값을 넣기 위해)
    const confirmSummaryRow = [
      confirmSummaryData.avg_weight,
      confirmSummaryData.stdev,
      confirmSummaryData.cnt,
      confirmSummaryData.confirm_cnt,
      confirmSummaryData.out_weight,
      confirmSummaryData.set_updown,
    ];

    // weight_list_json_confirm 데이터를 적절하게 가공
    const combinedConfirmData = weightConfirmData.map((row: any, index: number) => ({
      no: row.no,
      track_id: row.track_id,
      weight: row.weight === 0 ? "-" : row.weight,
      w_rate: row.w_rate,
      outlier: row.outlier === 0 ? "FALSE" : row.outlier === 1 ? "TRUE" : "-",
      direction: row.direction,
      "": "",
      avg_weight: index === 0 ? confirmSummaryRow[0] : "", // 첫 번째 행에만 avg_weight
      stdev: index === 0 ? confirmSummaryRow[1] : "", // 첫 번째 행에만 stdev
      cnt: index === 0 ? confirmSummaryRow[2] : "", // 첫 번째 행에만 cnt
      confirm_cnt: index === 0 ? confirmSummaryRow[3] : "", // 첫 번째 행에만 confirm_cnt
      out_weight: index === 0 ? confirmSummaryRow[4] : "", // 첫 번째 행에만 out_weight
      set_updown: index === 0 ? confirmSummaryRow[5] : "", // 첫 번째 행에만 set_updown
    }));

    // 결합된 데이터를 시트로 변환
    const trackRawSheet = utils.json_to_sheet(combinedConfirmData);
    utils.book_append_sheet(workbook, trackRawSheet, "track_count_list");

    // weight_list_json과 raw_summary를 같은 시트에서 보여주기
    const weightListData = jsonData.weight_list_json;
    const rawSummaryData = jsonData.raw_summary;

    // raw_summary 데이터를 배열로 변환 (첫 번째 행에만 값을 넣기 위해)
    const summaryRow = [rawSummaryData.avg_weight, rawSummaryData.stdev, rawSummaryData.cnt];

    // weight_list_json 데이터를 적절하게 가공
    const combinedData = weightListData.map((row: any, index: number) => ({
      no: row.no,
      track_id: row.track_id,
      weight: row.weight === 0 ? "-" : row.weight,
      w_rate: row.w_rate,
      outlier: row.outlier === 0 ? "FALSE" : row.outlier === 1 ? "TRUE" : "-",
      direction: row.direction,
      "": "",
      avg_weight: index === 0 ? summaryRow[0] : "", // 첫 번째 행에만 avg_weight
      stdev: index === 0 ? summaryRow[1] : "", // 첫 번째 행에만 stdev
      cnt: index === 0 ? summaryRow[2] : "", // 첫 번째 행에만 cnt
    }));

    // 결합된 데이터를 시트로 변환
    const trackCountSheet = utils.json_to_sheet(combinedData);
    utils.book_append_sheet(workbook, trackCountSheet, "track_raw_list");

    // Excel 파일 생성
    writeFile(workbook, `${fileName}.xlsx`);
  };

  const downloadExcel = () => {
    // detailReportData가 undefined일 경우 빈 배열을 기본값으로 설정
    const worksheetData =
      detailReportData?.unit_details.map((d, index) => ({
        "No.": index + 1,
        "Track No.": d.track_id,
        체중: `${d.weight.toFixed(2)} kg`,
      })) || []; // 빈 배열로 기본값 설정

    // 엑셀 워크시트 생성
    const worksheet = utils.json_to_sheet(worksheetData);

    // 워크북 생성
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Report");

    // 엑셀 파일 다운로드
    writeFile(workbook, `weightList.xlsx`);
  };

  const mappingData = () => {
    if (typeof detailReportData === "undefined") return [];
    else {
      if (detailReportData.excel_data === null) {
        return (
          detailReportData.unit_details
            // @TODO 이동시간 순일때 sorting 변경
            .sort((a, b) => (sortState ? b.weight - a.weight : b.weight - a.weight))
            .map((o, idx) => {
              return {
                weight: o.weight,
                track_id: o.track_id,
                no: idx + 1,
                outlier: 0,
              };
            })
        );
      } else {
        return detailReportData.excel_data.weight_list_json_confirm
          .sort((a, b) => (sortState ? b.weight - a.weight : b.weight - a.weight))
          .map((o) => {
            return {
              weight: o.weight,
              track_id: o.track_id,
              no: o.no,
              outlier: o.outlier,
            };
          });
      }
    }
  };

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={() => setIsToggle(false)}
        className="flex justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-574pxr h-684pxr bg-ef-neutral-white outline-none flex flex-col p-24pxr">
          <div className="flex relative w-full items-center mb-24pxr">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("manage.weight_list")}</span>
            <button className="absolute p-8pxr bottom-[-7px] right-0pxr" onClick={() => setIsToggle(false)}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <span className="ef-body-lg text-ef-neutral-700 text-center mb-40pxr">{t("manage.edit_report_differ_alert")}</span>
          <div className="flex justify-between items-center mb-24pxr">
            <button
              className="ef-btn-r8-sm flex gap-8pxr"
              onClick={() => {
                if (detailReportData?.excel_data) {
                  const workDate = format(new Date(detailReportData.ended_at), 'yyyy-MM-dd');
                  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const downloadTime = format(new Date(), 'HH:mm:ss');
                  
                  exportToExcel(
                    detailReportData.excel_data, 
                    `Weight_data_${current_farm.name}_${workDate}_${userTimeZone}_${downloadTime}`
                  );
                } else {
                  downloadExcel();
                }
              }}
            >
              <ReportDownload className="stroke-ef-neutral-900" />
              {t("manage.download_excel")}
            </button>
            <div className="flex flex-row ml-auto flex-wrap gap-4pxr ef-label-md">
              <button
                onClick={() => setSortState(true)}
                type="button"
                className={
                  "rounded-[4px] py-4pxr w-80pxr h-28pxr border border-solid break-all line-clamp-1 " +
                  (sortState
                    ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
                    : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
                }
              >
                {t("manage.weight_based")}
              </button>
              <Tooltip title={t("common.prepare_comment")}>
                <button
                  disabled
                  onClick={() => setSortState(false)}
                  type="button"
                  className={
                    "rounded-[4px] py-4pxr w-80pxr h-28pxr border border-solid " +
                    (!sortState
                      ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
                      : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
                  }
                >
                  {t("manage.time_based")}
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="flex overflow-y-auto p-2pxr">
            <table className="ef-table-toggle !w-full">
              <thead>
                <tr>
                  <th className="text-start ef-body-sm w-82pxr min-w-[82px] ">No.</th>
                  <th className="text-start ef-body-sm w-82pxr min-w-[82px] ">Track No.</th>
                  {/* <th className="text-start ef-body-sm w-120pxr min-w-[120px]">이동순서</th>
                <th className="text-start ef-body-sm w-160pxr min-w-[160px]">이동시간</th> */}
                  <th className="text-start ef-body-sm w-132pxr min-w-[132px]">{t("common.weight")}</th>
                </tr>
              </thead>
              <tbody>
                {mappingData().map((d, index) => (
                  <React.Fragment>
                    <tr key={index} className={" " + (!d.weight ? "[&>*]:text-ef-neutral-300" : "")}>
                      <td>
                        <div className="flex flex-row w-full items-center max-w-[82]">
                          <span className="max-w-[82px] overflow-hidden text-ellipsis whitespace-nowrap ef-body-sm">{d.no}</span>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-row w-full items-center max-w-[82]">
                          <span className="max-w-[82px] overflow-hidden text-ellipsis whitespace-nowrap ef-body-sm">{d.track_id}</span>
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-row w-full items-center max-w-[132]">
                          <span className="max-w-[132px] overflow-hidden text-ellipsis whitespace-nowrap ef-body-sm">
                            {d.outlier === 1 || d.weight === 0 ? "-" : `${d.weight.toFixed(2)}kg`}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WeightListModal;
