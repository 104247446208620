import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as Calendar } from "assets/icons/report/ef-report-calendar.svg";
import { useTranslation } from "react-i18next";
import { PiggeryForFarmSummary } from "query/farm/type";
import DatePicker from "react-datepicker";
import { format, isBefore, isEqual, subHours } from "date-fns";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import { usePiggeries, usePiggeryMonitoring } from "query/piggery";
import { useRoomDropdownList } from "query/rooms";
import DropDownRoom from "../dropdown/DropdownRoom";
import noData from "assets/images/report/no_report_data.png";
import ExpandImgModal from "./expandImgModal";
import { RoomMonitoring } from "query/piggery/type";
import { datePickerLocale } from "utils/useDatepicker";
import DropDownFilter from "components/common/dropdown/DropDownFilter";

type Props = {
  piggeryId: number;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
};
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function PiggeryMonitoringModal(props: Props) {
  const { t } = useTranslation();
  const { isToggle, setIsToggle, piggeryId } = props;
  const [selectedTime, setSelectedTime] = useState<Date>(new Date(new Date().setHours(new Date().getHours() - 1)));
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [hoveredNetwork, setHoveredNetwork] = useState<number | null>(null);
  const [hoveredPollution, setHoveredPollution] = useState<number | null>(null);
  const [expandImg, setExpandImg] = useState(false);
  const [historyInfo, setHistoryInfo] = useState<RoomMonitoring>();
  const [isError, setIsError] = useState(false);
  const { data: roomList } = useRoomDropdownList(piggeryId, [1]);
  const { data: monitoringData, isLoading } = usePiggeryMonitoring(piggeryId, {
    rooms: selectedRooms,
    base_datetime: String(format(new Date(selectedDate), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:00:ss")),
  });

  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();

  const timeOptions = Array.from({ length: format(selectedDate, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") ? (currentMinute < 10 ? currentHour + 1 : currentHour) : 24 }, (_, value) => {
    const name = `${value.toString().padStart(2, "0")}:00 ~ ${value.toString().padStart(2, "0")}:59`;
    return { value, name };
  });

  // const timeOptions = data?.time_images
  // .map((image) => {
  //   const hour = new Date(image.created_at).getHours();
  //   const name = `${hour.toString().padStart(2, "0")}:00 ~ ${hour.toString().padStart(2, "0")}:59`;
  //   return { value: hour, name };
  // })
  // .sort((a, b) => a.value - b.value);

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
    setSelectedDate(new Date());
    setSelectedTime(new Date(new Date().setHours(new Date().getHours() - 1)));
    setSelectedRooms([]);
  };

  const handleDatePickerChange = (date: Date) => {
    if (date) {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setSelectedDate(firstDayOfMonth);
    }
  };

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  useEffect(() => {
    if (format(selectedDate, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
      setSelectedTime(new Date(new Date().setHours(new Date().getHours() - 1)));
    } else {
      setSelectedTime(new Date(new Date().setHours(0, 0, 0, 0)));
    }
  }, [selectedDate]);

  return (
    <>
      <ExpandImgModal distinct="piggery" isToggle={expandImg} setIsToggle={setExpandImg} historyInfo={historyInfo} />

      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center py-80pxr"
      >
        <div className="h-852pxr w-994pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          {/* header */}
          <div className="flex relative w-full">
            <span className="flex mx-auto text-ef-neutral-900 ef-title-lg">
              {monitoringData?.piggery.name} {t("status.monitoring")}
            </span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          {/* 필터 */}
          <div className="flex flex-col flex-wrap ef-label-md text-ef-neutral-900 mt-60pxr">
            <div className="flex flex-row gap-24pxr items-center justify-center mb-24pxr">
              <div className="flex flex-row gap-16pxr items-center">
                <span className="ef-label-lg text-ef-neutral-900">{t("common.date")}</span>
                <span className="flex items-center justify-center bg-ef-neutral-white w-250pxr h-56pxr text-center border border-solid rounded-md ef-title-md">
                  <div className="z-10">
                    <DatePicker
                      maxDate={new Date()}
                      selected={selectedDate}
                      locale={datePickerLocale()}
                      onChange={handleDatePickerChange}
                      customInput={
                        <button className="flex items-center justify-center">
                          <span className="mr-8pxr">{format(selectedDate ? selectedDate : new Date(), "yyyy-MM-dd")}</span>
                          <Calendar className="stroke-ef-neutral-900" />
                        </button>
                      }
                    />
                  </div>
                </span>
              </div>
              <div className="flex flex-row gap-16pxr items-center">
                <span className="ef-label-lg text-ef-neutral-900">{t("common.room")}</span>
                <span className="flex items-center justify-center bg-ef-neutral-white h-56pxr text-center rounded-md ef-title-md">
                  <div className="z-10 w-full ">
                    <DropDownRoom
                      title={t("common.room")}
                      state={selectedRooms}
                      setState={setSelectedRooms}
                      options={
                        roomList
                          ? roomList.map((o) => {
                              return { value: o.id, name: o.name };
                            })
                          : []
                      }
                    />
                  </div>
                </span>
              </div>
              <div className="flex flex-row gap-16pxr items-center">
                <span className="ef-label-lg text-ef-neutral-900">{t("common.time")}</span>
                <div className="flex items-center justify-center h-56pxr">
                  <DropDownFilter
                    title={""}
                    state={selectedTime ? selectedTime.getHours() : null} // state는 시간을 전달
                    setState={(value) => {
                      try {
                        const newSelectedTime = new Date(selectedTime || new Date());
                        newSelectedTime.setHours(value); // 선택한 시간을 설정
                        setSelectedTime(newSelectedTime);
                      } catch (error) {
                        console.error("시간 설정 중 오류가 발생했습니다: ", error);
                      }
                    }}
                    options={timeOptions}
                  />
                </div>
                {/* <div className="flex items-center bg-ef-neutral-white w-197pxr h-56pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-md ef-title-md">
                 
                  <div className="z-10 w-full flex justify-between">
                    <DatePicker
                      selected={selectedTime}
                      onChange={handleTimePickerChange}
                      showTimeSelect
                      autoComplete="on"
                      showTimeSelectOnly
                      locale={datePickerLocale()}
                      minTime={format(selectedDate, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date(0)} // 자정부터
                      maxTime={format(selectedDate, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") ? new Date(new Date().setHours(new Date().getHours() - 1)) : new Date(23, 59)}
                      timeIntervals={60}
                      timeCaption="시간선택"
                      dateFormat="hh"
                      customInput={
                        <button className="grow w-full flex flex-row justify-between">
                          <span className="grow text-left">{format(selectedTime ? selectedTime : new Date(), "HH:00 ~ HH:59")}</span>
                          <div className="w-24pxr h-24pxr mx-8pxr">
                            <ArrowDown className="stroke-ef-neutral-900 " />
                          </div>
                        </button>
                      }
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* 필터 끝 */}
          <main className="overflow-x-hidden overflow-y-auto pr-8pxr">
            {monitoringData && monitoringData?.efg_camera.length > 0 ? (
              <div className="grid grid-cols-3 gap-16pxr">
                {monitoringData?.efg_camera?.map((v: RoomMonitoring, index) => (
                  <div key={`piggery-${index}`} className="relative rounded-[8px] border border-solid border-ef-neutral-150 w-294pxr">
                    {v.history[0]?.image_path ? (
                      <img
                        // onError={() => setIsError(true)}
                        onClick={() => {
                          setExpandImg(true);
                          setHistoryInfo(v);
                        }}
                        src={isError ? noData : v.history[0]?.image_path}
                        alt="thumbnail"
                        className="cursor-pointer h-180pxr w-full bg-ef-neutral-150 rounded-t-lg"
                      />
                    ) : !v.history ? (
                      <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                        <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                        <span className="ef-body-sm text-ef-neutral-700">{t("common.no_data")}</span>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center h-180pxr w-full bg-ef-neutral-50 rounded-t-lg py-20pxr">
                        <img src={noData} alt="no_data" className="w-140pxr h-100pxr" />
                        <span className="ef-body-sm text-ef-neutral-700">{t("common.image_path_not_found")}</span>
                      </div>
                    )}
                    <div className="h-full rounded-b-lg flex flex-col gap-8pxr px-24pxr py-16pxr ">
                      <div className="flex flex-row items-center justify-between ">
                        <div className="flex flex-col center gap-4pxr">
                          <span className="ef-body-sm text-ef-neutral-900">{v.room.name}</span>
                          {/* <span className="w-1pxr h-10pxr bg-ef-neutral-200"></span> */}
                          <span className="ef-body-xl w-198pxr min-w-[198px] whitespace-nowrap text-ef-neutral-900 text-ellipsis overflow-hidden">{v.name}</span>
                        </div>
                        <div className="h-full flex flex-row gap-16pxr place-items-start">
                          <span className="flex items-center">
                            {v?.last_access_at ? (
                              isBefore(new Date(v?.last_access_at), subHours(new Date(), 1)) ? (
                                <>
                                  {hoveredNetwork === index ? (
                                    <div
                                      key={`abnormal-network-${index}`}
                                      className="absolute z-[999] mb-60pxr -ml-72pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center text-ef-neutral-900 py-11pxr px-16pxr border border-solid border-ef-neutral-200 ef-sub-shadow ef-label-md"
                                    >
                                      <span className="whitespace-nowrap flex flex-row items-center gap-8pxr ef-body-md">
                                        {t("common.network_connection_status")} <span className="h-12pxr w-1pxr bg-ef-neutral-200"></span>
                                        <span className="text-ef-state-red-500">{t("common.abnormal")}</span>
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className="relative"
                                    onMouseEnter={() => setHoveredNetwork(index)}
                                    onMouseLeave={() => setHoveredNetwork(null)}
                                    style={{ width: "12px", height: "12px", backgroundColor: "#F03E3E", borderRadius: "50%" }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  {hoveredNetwork === index ? (
                                    <div
                                      key={`normal-network-${index}`}
                                      className="absolute z-[999] mb-60pxr -ml-72pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center text-ef-neutral-900 py-11pxr px-16pxr border border-solid border-ef-neutral-200 ef-sub-shadow ef-label-md"
                                    >
                                      <span className="whitespace-nowrap flex flex-row items-center gap-8pxr ef-body-md">
                                        {t("common.network_connection_status")} <span className="h-12pxr w-1pxr bg-ef-neutral-200"></span>
                                        <span className="text-ef-state-green-500">{t("common.normal")}</span>
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    onMouseEnter={() => setHoveredNetwork(index)}
                                    onMouseLeave={() => setHoveredNetwork(null)}
                                    style={{ width: "12px", height: "12px", backgroundColor: "#37B24D", borderRadius: "50%" }}
                                  ></div>
                                </>
                              )
                            ) : (
                              <>
                                {hoveredNetwork === index ? (
                                  <div
                                    key={index}
                                    className="absolute z-[999] mb-60pxr -ml-72pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center text-ef-neutral-900 py-11pxr px-16pxr border border-solid border-ef-neutral-200 ef-sub-shadow ef-label-md"
                                  >
                                    <span className="whitespace-nowrap flex flex-row items-center gap-8pxr ef-body-md">
                                      {t("common.network_connection_status")} <span className="h-12pxr w-1pxr bg-ef-neutral-200"></span>
                                      <span className="text-ef-state-red-500">{t("common.abnormal")}</span>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="relative"
                                  onMouseEnter={() => setHoveredNetwork(index)}
                                  onMouseLeave={() => setHoveredNetwork(null)}
                                  style={{ width: "12px", height: "12px", backgroundColor: "#F03E3E", borderRadius: "50%" }}
                                ></div>
                              </>
                            )}
                          </span>
                          <span className="flex items-center ">
                            {v?.pollution === true ? (
                              <>
                                {hoveredPollution === index ? (
                                  <div
                                    key={index}
                                    className="absolute z-[999] mb-60pxr -ml-72pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center text-ef-neutral-900 py-11pxr px-16pxr border border-solid border-ef-neutral-200 ef-sub-shadow ef-label-md"
                                  >
                                    <span className="whitespace-nowrap flex flex-row items-center gap-8pxr ef-body-md">
                                      {t("common.camera_contamination_status")}
                                      <span className="h-12pxr w-1pxr bg-ef-neutral-200"></span>
                                      <span className="text-ef-state-red-500">{t("common.abnormal")}</span>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="relative"
                                  onMouseEnter={() => setHoveredPollution(index)}
                                  onMouseLeave={() => setHoveredPollution(null)}
                                  style={{ width: "12px", height: "12px", backgroundColor: "#F03E3E", borderRadius: "50%" }}
                                ></div>
                              </>
                            ) : (
                              <>
                                {hoveredPollution === index ? (
                                  <div
                                    key={index}
                                    className="absolute z-[999] mb-60pxr -ml-72pxr rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center text-ef-neutral-900 py-11pxr px-16pxr border border-solid border-ef-neutral-200 ef-sub-shadow ef-label-md"
                                  >
                                    <span className="whitespace-nowrap flex flex-row items-center gap-8pxr ef-body-md">
                                      {t("common.camera_contamination_status")} <span className="h-12pxr w-1pxr bg-ef-neutral-200"></span>
                                      <span className="text-ef-state-green-500">{t("common.normal")}</span>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="relative"
                                  onMouseEnter={() => setHoveredPollution(index)}
                                  onMouseLeave={() => setHoveredPollution(null)}
                                  style={{ width: "12px", height: "12px", backgroundColor: "#37B24D", borderRadius: "50%" }}
                                ></div>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : isLoading ? (
              <div className="flex flex-col w-full items-center my-80pxr">
                <img src={noData} alt="noData" className="flex w-310pxr h-220pxr " />
                <span className="text-center ef-body-lg text-ef-neutral-600">{t("common.data_loading")}</span>
              </div>
            ) : (
              <div className="flex flex-col w-full items-center my-80pxr">
                <img src={noData} alt="noData" className="flex w-310pxr h-220pxr " />
                <span className="text-center ef-body-lg text-ef-neutral-600">{t("status.no_grow_comment")}</span>
                <div className="flex flex-row mt-24pxr">
                  <button
                    className="ef-btn-r8-sm !ef-label-md flex flex-row gap-8pxr"
                    onClick={() => {
                      setIsToggle(false);
                      // setAddHerdModal(true);
                      window.open("https://intflow.ai/contact/", "_blank");
                    }}
                  >
                    {t("status.contact")}
                  </button>
                </div>
              </div>
            )}
          </main>
        </div>
      </Modal>
    </>
  );
}

export default PiggeryMonitoringModal;
