import React, { useEffect, useState } from "react";
import { ReactComponent as Arrival } from "assets/icons/report/ef-report-arrival.svg";
import { ReactComponent as Shipment } from "assets/icons/report/ef-report-shipment.svg";
import { ReactComponent as Death } from "assets/icons/report/ef-report-death.svg";
import { ReactComponent as Movement } from "assets/icons/gnb/ef-gnb-movement.svg";
import { ReactComponent as List } from "assets/icons/general/ef-general-list2.svg";
import { ReactComponent as SymbolSmall } from "assets/graphic/ef-graphic-symbol-small.svg";
import { ReactComponent as QuestionSquare } from "assets/icons/general/ef-general-question-quare.svg";
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { ReactComponent as Stock } from "assets/icons/general/ef-general-stock.svg";
import { ReactComponent as Remove } from "assets/icons/general/ef-general-remove.svg";

import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import DefaultTooltip from "components/common/alert/DefaultTooltip";
import { HerdDetail, RoomForHerdDetail, RoomHistory } from "query/herd/type";
import { RoomHistoryType } from "query/rooms/type";
import { useDeleteStockHistory, useEditStockHistoryDetail } from "query/herd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import MemoModal from "./layer/memoLayer";
import { ReactComponent as DoubleCheck } from "assets/icons/general/ef-general-doubleCheck.svg";

type Props = {
  category: number;
  roomData: any;
  historyInfo: any;
  index: number;
  dataLength: number;
  isEditable: boolean;
  isActivate: boolean;
  isBasicCard?: boolean;
};

function HistoryCard(props: Props) {
  const { roomData, category, historyInfo, index, dataLength, isEditable = false, isActivate, isBasicCard = false } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredHerd, setHoveredHerd] = useState(0);
  const [isMemo, setIsMemo] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: deleteStockHistory } = useDeleteStockHistory();
  const { mutateAsync: editHerdHistory } = useEditStockHistoryDetail();
  const [memoState, setMemoState] = useState(historyInfo?.memo ? String(historyInfo?.memo) : "");

  const getColor = (type: number) => {
    switch (type) {
      case 1:
        return "#1C7ED6"; //state blue
      case 2:
        return "#1098AD"; //state green
      case 3:
        return "#1098AD"; //state cyan
      case 4:
        return "#37B24D"; //state orange
      case 5:
        return "#F03E3E"; //state red
      case 6:
        return "#F03E3E"; //state red 
      default:
        return "#878787";
    }
  };

  const getIcon = (type: number) => {
    switch (type) {
      case 1:
        return <Arrival />; // 입식
      case 2:
        return <Movement />; // 전입
      case 3:
        return <Movement />; // 전출
      case 4:
        return <Shipment />; // 출하
      case 5:
        return <Death className="stroke-none" />; // 폐사
      case 6:
        return <Death className="stroke-none" />; // 도태 
      case 7:
        return <DoubleCheck />; // 오류
      default:
        return <SymbolSmall className="stroke-none fill-ef-neutral-white" />;
    }
  };

  const getTypeTitle = (type: number) => {
    switch (type) {
      case 1:
        return t("common.group_creation");
      case 2:
        return t("common.stock_transfer");
      case 3:
        return t("common.location_change");
      case 4:
        return t("common.merge_group");
      case 5:
        return t("common.split_group");
      case 6:
        return t("common.simple_measurement");
      case 7:
        return t("common.death");
      case 8:
        return t("common.group_termination");
      default:
        return "";
    }
  };

  const getHeadSign = (type: number) => {
    switch (type) {
      case 1:
        return "+";
      case 2:
        return "+";
      case 3:
      case 4:
        return Number(historyInfo.change_stock) < 0 ? "" : "";
      case 5:
        return Number(historyInfo.change_stock) < 0 ? "" : "";
      case 6:
        return Number(historyInfo.change_stock) < 0 ? "" : "";
      case 7:
        return Number(historyInfo.change) < 0 ? "" : "+";
      default:
        return "";
    }
  };

  const getStartLocation = (type: number) => {
    return historyInfo.movement?.departure ? `[${roomData?.piggery?.name}] ${historyInfo.movement.departure?.name}` : t("common.no_info");
  };

  const getEndLocation = (type: number) => {
    return historyInfo.movement?.arrival ? ` [${roomData?.piggery.name}] ${historyInfo.movement?.arrival?.name}` : ` ${t("common.no_info")}`;
  };

  const onDelete = async (id: number) => {
    if (window.confirm(t("manage.delete_history_alert"))) {
      try {
        await deleteStockHistory(id);
        toast.success(t("common.deletion_completed"));
        // setIsToggle(false);
      } catch (error) {}
    } else return;
  };

  const onEdit = async () => {
    try {
      await editHerdHistory({
        history_id: Number(historyInfo?.id),
        payload: {
          memo: memoState,
        },
      });
      toast.success(t("common.save_completed"));
      setIsMemo(false);
      setMemoState("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (historyInfo) {
      setMemoState(historyInfo.memo !== null ? historyInfo.memo : "");
    }
  }, [historyInfo, isMemo, roomData]);

  return (
    <div className="w-full h-fit flex flex-row flex-wrap gap-8pxr relative">
      <div className="flex flex-col flex-wrap gap-4pxr items-center w-40pxr h-full">
        <div className="w-40pxr h-40pxr flex items-center justify-center rounded-full stroke-ef-neutral-white" style={{ backgroundColor: getColor(category) }}>
          {getIcon(category)}
        </div>
        {index !== dataLength - 1 ? (
          <div className="grow border-l border-ef-neutral-400 border-dashed"></div>
        ) : (
          <></>
        )}
      </div>
      <div className="grow pb-18pxr">
        <div className="w-full h-full ef-main-shadow rounded-[4px] bg-ef-neutral-white flex flex-col">
          {/* header */}
          <div className="h-40pxr w-full flex flex-row rounded-t-[4px] py-8pxr px-16pxr items-center" style={{ backgroundColor: getColor(category) }}>
            <div className="w-full flex flex-row items-center justify-between ef-label-md text-ef-neutral-white">
              <span className="line-clamp-1 break-all max-w-[280px]">
                {t(`common.herd_history_${historyInfo?.category?.id}`)}
                {/* {historyInfo.related_group ? `(${historyInfo.related_group.nickname ? historyInfo.related_group.nickname : historyInfo.related_group.name})` : ""} */}
              </span>

              {/* {historyInfo.related_group ? (
                <button
                  type="button"
                  onClick={() => {
                    if (historyInfo.related_group) window.open(`/groupDetail/${historyInfo.related_group?.id}/${isEditable ? 1 : 0}`, "_blank", "width=1080, height=800");
                    else return;
                  }}
                  className="stroke-ef-neutral-white ml-4pxr"
                >
                  <Link />
                </button>
              ) : (
                <></>
              )} */}
              <p className="flex ef-body-sm max-w-[131px] line-clamp-1 text-ef-neutral-white ">{format(new Date(historyInfo.created_at), "yyyy-MM-dd HH:mm")}</p>
            </div>
            {/* 수동 무게 측정은 보고서 제공안함 */}
            {/* {category === 6 && historyInfo.movement === null ? (
              <></>
            ) : (
              <div onClick={() => window.open(`/reportDetail/${historyInfo.movement?.id}`, "_blank", "width=1175, height=1040")} className="ml-auto cursor-pointer">
                <List className="ml-4pxr stroke-ef-neutral-white" />
              </div>
            )} */}
          </div>
          <div className="w-full grow rounded-b-[4px] py-12pxr flex flex-col flex-wrap gap-12pxr">
            <div className="flex px-16pxr flex-row text-ef-neutral-900 relative">
              <div className="w-full flex flex-col flex-wrap gap-2pxr">
                <span className="ef-label-sm text-ef-primary-500 line-clamp-1">{t("common.location")}</span>
                <div className="flex flex-row overflow-hidden whitespace-nowrap text-ellipsis max-w-[338px]">
                  {/* <span className="ef-body-sm">{getStartLocation(category)}&nbsp;</span>
                <span className="ef-body-md">&rarr;{getEndLocation(category)}</span> */}
                  <span className="ef-body-md">{roomData?.name}</span>
                </div>
              </div>
              {isBasicCard ? (
                <></>
              ) : (
                <div className="w-full flex flex-col flex-wrap gap-2pxr mr-24pxr">
                  <p className="max-w-[131px] w-full line-clamp-1 ef-label-sm text-ef-primary-500">{t("common.heads")}</p>
                <p className="ef-body-sm w-131pxr max-w-[131px] line-clamp-1">
                  {getHeadSign(category)}
                  {historyInfo.change}
                  {t("common.head")}
                  {`(${t("manage.remain")} ${historyInfo.stock}${t("common.head")})`}
                  </p>
                </div>
              )}
            </div>
            {isBasicCard ? (
              <></>
            ) : (
              <>
                <div className="w-full border-t border-solid border-ef-neutral-150"></div>
                <div className="flex flex-row px-16pxr text-ef-neutral-900 relative">
                  <div className="w-full flex justify-between">
                    <div className="w-full flex flex-row items-center flex-wrap gap-12pxr relative">
                      <button
                        onClick={() => {
                          navigate("/manage/stock/record", {
                            state: {
                              roomId: roomData?.id,
                              piggeryId: roomData?.piggery?.id,
                            },
                          });
                        }}
                        className="flex flex-row gap-4pxr items-center ef-label-md"
                      >
                        <Stock className="stroke-ef-neutral-900" />
                        {t("gnb.stock_history")}
                      </button>
                      <div className="h-10pxr w-1pxr bg-ef-neutral-200"></div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsMemo(true);
                        }}
                        className="flex relative flex-row gap-4pxr items-center ef-label-md"
                      >
                        <List className="stroke-ef-neutral-900" />
                        {t("common.memo")}
                        {historyInfo.memo ? <div className="absolute left-54pxr top-2pxr h-6pxr w-6pxr rounded-full bg-ef-state-red-500"></div> : <></>}
                      </button>
                      {isMemo ? <MemoModal memoState={memoState} setMemoState={setMemoState} setIsMemo={setIsMemo} onEdit={onEdit} /> : <></>}
                    </div>
                    <button onClick={() => onDelete(historyInfo?.id)} className="flex flex-row gap-4pxr items-center ef-label-md whitespace-nowrap">
                      <Remove className="stroke-ef-neutral-900" /> {t("common.delete")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryCard;
