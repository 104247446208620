import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Stock } from "assets/icons/general/ef-general-stock.svg";
import { ReactComponent as GraphUp } from "assets/icons/general/ef-general-graphUp.svg";
import { ReactComponent as Fan } from "assets/icons/general/ef-general-fan.svg";
import { ReactComponent as Device } from "assets/icons/general/ef-general-device.svg";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  piggeryId: number;
};

function NavFilters(props: Props) {
  const { piggeryId } = props;
  const location = useLocation();
  const currentId = location.pathname.split("/")[2];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const filterOptions = [
    { name: t("status.stock"), icon: <Stock />, link: `/status/stock/${piggeryId}`, id: "stock" },
    { name: t("status.environment"), icon: <Fan />, link: `/status/env/${piggeryId}`, id: "env" },
    { name: t("status.device"), icon: <Device />, link: `/status/device/${piggeryId}`, id: "device" },
  ];
  return (
    <div className="flex flex-row gap-16pxr">
      {filterOptions.map((o) => (
        <button
          key={o.id}
          onClick={() => navigate(o.link)}
          type="button"
          className={"ef-btn-map-light " + (currentId === o.id ? "!text-ef-neutral-white !bg-ef-primary-500 !stroke-ef-neutral-white !border-ef-primary-500" : "")}
        >
          {o.icon}
          {o.name}
        </button>
      ))}
    </div>
  );
}

export default NavFilters;
