import React, { useState } from "react";
import { useMonitoringList } from "query/monitoring";
import { useTranslation } from "react-i18next";
import MainStream from "components/pages/6_monitoring/modal/MainStream";
import { MonitoringList } from "query/monitoring/type";
import StreamOverlay from "components/pages/6_monitoring/StreamOverlay";

type Props = {
  selectedRoom: number;
};

function MonitoringStock(props: Props) {
  const { selectedRoom } = props;
  const { data: monitoringList } = useMonitoringList();
  const { t } = useTranslation();
  
  const [isStreamModalOpen, setIsStreamModalOpen] = useState(false);
  const [isOverlayOn, setIsOverlayOn] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState<{
    streamUrl: string;
    roomName: string;
    piggeryName: string;
    cameraName: string;
    camera: MonitoringList['cameras'][0];
    roomData: MonitoringList;
    category: number;
  } | null>(null);

  // 선택된 방의 데이터 찾기
  const selectedRoomData = monitoringList?.find(room => room.id === selectedRoom);
  console.log(selectedRoomData);
  // 선택된 방의 카메라 목록 필터링
  const selectedRoomCameras = selectedRoomData?.cameras.map(camera => ({
    ...camera,
    roomName: selectedRoomData.name || "",
    piggeryName: selectedRoomData.piggery?.name || "",
    category: selectedRoomData.category || 0
  })) || [];

  return (
    <div className="w-full h-full overflow-y-auto pb-40pxr px-69pxr">
      <MainStream 
        isToggle={isStreamModalOpen}
        setIsToggle={setIsStreamModalOpen}
        streamUrl={selectedCamera?.streamUrl || ""}
        roomName={selectedCamera?.roomName || ""}
        piggeryName={selectedCamera?.piggeryName || ""}
        cameraName={selectedCamera?.cameraName || ""}
        hidePiggeryName={true}
        roomData={selectedCamera?.roomData!}
        camera={selectedCamera?.camera!}
        category={selectedCamera?.category || 0}
      />
      {selectedRoomCameras.length > 0 && (
        <div className="flex flex-row items-center pt-32pxr pb-24pxr">
          <div className="ef-label-lg ef-neutral-900">
            {t('common.overlay')}
          </div>
          <div className="pl-16pxr">
            <button
              type="button"
              onClick={() => setIsOverlayOn(prev => !prev)}
              className="w-120pxr h-40pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white p-4pxr flex flex-row text-ef-neutral-200 relative ef-label-sm"
            >
              <div
                className={
                  "w-55pxr h-30pxr rounded-[6px] absolute transition-transform duration-300 " + 
                  (isOverlayOn ? "bg-ef-primary-500 translate-x-full" : "bg-ef-neutral-400 translate-x-0")
                }
              ></div>
              <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (!isOverlayOn && "text-ef-neutral-white")}>OFF</div>
              <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (isOverlayOn && "text-ef-neutral-white")}>ON</div>
            </button>
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-16pxr">
        {selectedRoomCameras.length > 0 ? (
          selectedRoomCameras.map((camera) => (
            <div key={camera.id} className="w-full h-full flex flex-col w-294pxr h-235pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white">
              <div className="h-179pxr">
                {camera.connected ? (
                  <div 
                    className="w-full h-full cursor-pointer relative"
                    onClick={() => {
                      if (camera.connected) {
                        setSelectedCamera({
                          streamUrl: camera.stream_url,
                          roomName: selectedRoomData!.name || "",
                          piggeryName: selectedRoomData!.piggery?.name || "",
                          cameraName: camera.name,
                          camera: camera,
                          roomData: selectedRoomData!,
                          category: selectedRoomData!.category.id
                        });
                        setIsStreamModalOpen(true);
                      }
                    }}
                  >
                    <div className="relative w-full h-full">
                      <iframe
                        src={camera.stream_url}
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-[8px] pointer-events-none"
                        allowFullScreen
                        allow="camera; microphone; autoplay"
                        title={`camera-${camera.id}`}
                      />
                      {selectedRoomData && selectedRoomData.category.id === 1 && (
                        <div className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-t-[8px]">
                          <StreamOverlay 
                            monitoringData={selectedRoomData}
                            camera={camera}
                            isOverlayOn={isOverlayOn}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-full flex flex-col items-center justify-center bg-ef-neutral-50 rounded-t-[8px]">
                    <img 
                      src={require("assets/images/report/no_report_data.png")} 
                      className="w-140pxr h-99pxr" 
                      alt="no-data" 
                    />
                    <span className="mt-8pxr ef-body-lg text-ef-neutral-700">
                      {t("common.no_data")}
                    </span>
                  </div>
                )}
              </div>
              <div className="h-56pxr py-16pxr pl-24pxr ef-body-xl">
                {camera.name}
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-3 flex flex-col items-center justify-center pt-120pxr">
            <img 
              src={require("assets/images/report/no_report_data.png")} 
              className="w-310pxr h-220pxr" 
              alt="no-data" 
            />
            <span className="mt-8pxr ef-body-lg text-ef-neutral-700">
              {t("common.no_info")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MonitoringStock;
