import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Danger } from "assets/icons/icon16_general/ef-16general-danger.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { Controller, useForm } from "react-hook-form";
import { HerdHistoryPayload } from "query/herd/type";
import { format } from "date-fns";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import DatePicker from "react-datepicker";
import { useAddHerd, useAddHerdHistory, useDeactivateHerd, useHerdHistoryCategory } from "query/herd";
import GroupSingleDatePicker from "components/pages/2_manage/stock/group/GroupSingleDatePicker";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { getRedStar } from "utils/getImportantStar";
import { usePiggeries } from "query/piggery";
import { useRoomDropdownList } from "query/rooms";
import ErrorInputField from "components/common/input/ErrorInputField";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  herdData: any;
  herdId: number | undefined;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  herdStock?: number;
  roomId: number;
  piggeryId: number;
};

type Inputs = {
  category_id: number | null;
  arrival_piggery: number | null;
  arrival_room: number | null;
  departure_piggery: number | null;
  departure_room: number | null;
  change?: number;
  stock?: number;
  created_at: Date;
  memo: string;
};

const initialValue = {
  category_id: null,
  arrival_piggery: null,
  arrival_room: null,   
  departure_piggery: null,
  departure_room: null,
  change: 0,
  stock: 0,
  created_at: new Date(),
  memo: "",
};

function AddStockHistory(props: Props) {
  const { isToggle, setIsToggle, herdId, herdData, herdStock, roomId, piggeryId } = props;
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState(new Date());

  const { mutateAsync: addHerdHistory } = useAddHerdHistory();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({ defaultValues: initialValue });

  const { data: categoryList } = useHerdHistoryCategory();
  const { data: PiggeryList } = usePiggeries();
  const arrival_piggery = watch("arrival_piggery");
  const departure_piggery = watch("departure_piggery");
  const { data: ArrivalRoomList } = useRoomDropdownList(arrival_piggery);
  const { data: DepartureRoomList } = useRoomDropdownList(departure_piggery);
  const { mutateAsync: deactivateHerd } = useDeactivateHerd();
  const { mutateAsync: addHerd } = useAddHerd();

  const category_id = watch("category_id");
  const change = watch("change");

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      if (isDirty) {
        if (window.confirm(t("common.alert_not_saved"))) {
          setIsToggle(false);
          reset(initialValue);
        } else return;
      } else {
        setIsToggle(false);
        reset(initialValue);
      }
    }
  };

  const onSubmit = async (data: HerdHistoryPayload) => {
    const payload = {
      category_id: data.category_id,
      // 전출, 출하, 폐사일 경우 "-" 붙여줘야 함
      change: data.category_id === 3 || data.category_id === 4 || data.category_id === 5 || data.category_id === 6 ? -Number(data.change) : data.change,
      stock: data.stock,
      created_at: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
      memo: data.memo,
      arrival_room_id: data.arrival_room,
      departure_room_id: data.departure_room,
    };
    if (data.category_id === 1 || data.category_id === 2){
      payload.arrival_room_id = roomId;
      const departureRoom = DepartureRoomList?.find(room => room.id === watch("departure_room"));
      // 출발 돈방이 0마리가 되는 경우 (돈군 종료)
      if (departureRoom && departureRoom.stock - Number(payload.change) === 0) {
        payload.departure_room_id = null;
        const deactiveHerdPayload = {
          category_id: data.category_id === 1 ? 4 : 3,
          created_at: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
          change: -Number(data.change),
        };
        try {
          await deactivateHerd({ payload: deactiveHerdPayload, id: departureRoom.active_herd?.id ?? 0 });
        } catch (error: any) {
          console.log(error);
        }
      }
    } else if (data.category_id === 3 || data.category_id === 4) {
      payload.departure_room_id = roomId;
      const arrivalRoom = ArrivalRoomList?.find(room => room.id === watch("arrival_room"));
      
      if (herdStock && herdStock - Number(data.change) === 0) {
        if (window.confirm(t("status.confirm_deactivate_herd"))) {
        } else {
          return;
        }
      }
      // 도착 돈방에 돈군이 없는 경우 (돈군 생성)
      if (arrivalRoom && arrivalRoom.active_herd === null) {
        payload.arrival_room_id = null;
        const createdHerdpayload = {
          nickname: null,
          birth_date: format(new Date(herdData.birth_date!), "yyyy-MM-dd"),
          arrival_date: format(new Date(data.created_at!), "yyyy-MM-dd") + "T" + format(selectedTime, "HH:mm:ss"),
          room_id: arrivalRoom.id,
          memo: data.memo ?? null,
          history: {
            category_id: data.category_id === 4 ? 1 : 2,
            change: data.change ?? 0,
          },
        };
        try {
          await addHerd(createdHerdpayload);
        } catch (error: any) {
          if (error.response && error.response.status === 409) {
            toast.error(t("manage.add_one_herd_same_comment"));
          } else {
            toast.error(t("common.an_error_occurred"));
          }
          return;
        }
      }
    } else {
      if (herdStock && herdStock - Math.abs(Number(data.change)) === 0) {
        if (window.confirm(t("status.confirm_deactivate_herd"))) {
        } else {
          return;
        }
      }
      payload.departure_room_id = roomId;
    }

    try {
      await addHerdHistory({ payload: payload, id: herdId! });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
      reset(initialValue);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        toast.error(t("status.cannot_add_history_same_herd_time_comment"));
      } else {
        toast.error(t("common.an_error_occurred"));
      }
    }
  };

  useEffect(() => {
    if(category_id === 1 || category_id === 2) {
      setValue("departure_piggery", -1);
      setValue("departure_room", null);
      setValue("arrival_piggery", piggeryId);
      setValue("arrival_room", roomId);
    }else if (category_id === 3 || category_id === 4) {
      setValue("arrival_piggery", -1);
      setValue("arrival_room", null);
      setValue("departure_piggery", piggeryId);
      setValue("departure_room", roomId);
    }else{
      setValue("arrival_piggery", null);
      setValue("arrival_room", null);
      setValue("departure_piggery", null);
      setValue("departure_room", null);
    }
  }, [category_id]);

  useEffect(() => {
    setSelectedTime(new Date());
  }, [isToggle]);

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center items-center overflow-y-auto"
      >
        <div className="w-[980px] bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full mb-24pxr">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("status.add_herd_inventory_history")}</span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <span className="ef-body-lg text-ef-neutral-700 w-full flex justify-center">{t("status.enter_history_occurred_room_comment")}</span>

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div className="my-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_type")}</span>
                  {getRedStar()}
                </label>
                <div className="w-full relative">
                  {errors.category_id ? <RequiredAlert /> : <></>}
                  <div className="w-340pxr flex flex-col">
                    <Controller
                      name="category_id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.work_type")}
                          options={categoryList ? categoryList.map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) })) : []}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {(category_id === 1 || category_id === 2) && (
                <div className="flex flex-row items-center relative">
                  <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                    <span className="line-clamp-1 break-all">
                      {t('common.movement_route')}
                      {getRedStar()}
                    </span>
                  </label>
                  {(errors.departure_piggery || (errors.departure_room && watch("departure_piggery") !== -1)) ? <RequiredAlert /> : <></>}
                  <div className="w-160pxr flex flex-col">
                    <Controller
                      name="departure_piggery"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={(value) => {
                            field.onChange(value);
                            setValue("departure_room", null);
                          }}
                          placeholder={t("common.select_piggery")}
                          options={[
                            { value: -1, name: t("common.external") },
                            ...(PiggeryList ? PiggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                          ]}
                        />
                      )}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <Controller
                      name="departure_room"
                      control={control}
                      rules={{ 
                        required: false,
                        validate: {
                          checkDepartureRoom: () => {
                            const departure_piggery = watch("departure_piggery");
                            return departure_piggery === -1 || !!watch("departure_room");
                          }
                        }
                      }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.select_room")}
                          options={DepartureRoomList ? DepartureRoomList.filter(o => o.id !== roomId && o.category.id === 1).map((o) => ({ value: o.id, name: o.name })) : []}
                          disabled={!departure_piggery || departure_piggery === -1}
                        />
                      )}
                    />
                  </div>
                  <span className="ml-8pxr ef-body-lg text-ef-neutral-900">→</span>
                  <div className="ml-8pxr w-160pxr flex flex-col">
                    <DropDownSmall
                      state={piggeryId}
                      setState={() => {console.log("123")}}
                      placeholder={t("common.select_piggery")}
                      options={PiggeryList ? PiggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <DropDownSmall
                      state={roomId}
                      setState={() => {}}
                      placeholder={t("common.select_room")}
                      options={ArrivalRoomList ? ArrivalRoomList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {(category_id === 3 || category_id === 4) && (
                <div className="flex flex-row items-center relative">
                  <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                    <span className="line-clamp-1 break-all">
                      {t('common.movement_route')}
                      {getRedStar()}
                    </span>
                  </label>
                  {(errors.arrival_piggery || (errors.arrival_room && watch("arrival_piggery") !== -1)) ? 
                  <div className="absolute top-[-50%] z-[999] left-500pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
                    <Danger className="fill-ef-secondary-500" />
                    <span>{t("common.required_comment")}</span>
                  </div> : <></>}
                  <div className="w-160pxr flex flex-col">
                    <DropDownSmall
                      state={piggeryId}
                      setState={() => { }}
                      placeholder={t("common.select_piggery")}
                      options={PiggeryList ? PiggeryList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <DropDownSmall
                      state={roomId}
                      setState={() => { }}
                      placeholder={t("common.select_room")}
                      options={DepartureRoomList ? DepartureRoomList.map((o) => ({ value: o.id, name: o.name })) : []}
                      disabled={true}
                    />
                  </div>
                  <span className="ml-8pxr ef-body-lg text-ef-neutral-900">→</span>
                  <div className="ml-8pxr w-160pxr flex flex-col">
                    <Controller
                      name="arrival_piggery"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={(value) => {
                            field.onChange(value);
                            setValue("arrival_room", null);
                          }}
                          placeholder={t("common.select_piggery")}
                          options={[
                            { value: -1, name: t("common.external") },
                            ...(PiggeryList ? PiggeryList.map((o) => ({ value: o.id, name: o.name })) : [])
                          ]}
                        />
                      )}
                    />
                  </div>
                  <div className="w-160pxr ml-8pxr flex flex-col">
                    <Controller
                      name="arrival_room"
                      control={control}
                      rules={{
                        required: false,
                        validate: {
                          checkDepartureRoom: () => {
                            const arrival_piggery = watch("arrival_piggery");
                            return arrival_piggery === -1 || !!watch("arrival_room");
                          }
                        }
                      }}
                      render={({ field }) => (
                        <DropDownSmall
                          state={field.value}
                          setState={field.onChange}
                          placeholder={t("common.select_room")}
                          options={ArrivalRoomList ? ArrivalRoomList.filter(o => o.id !== roomId && o.category.id === 1).map((o) => ({ value: o.id, name: o.name })) : []}
                          disabled={!arrival_piggery || arrival_piggery === -1}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">
                    {t("common.heads")}
                    {getRedStar()}
                  </span>
                </label>
                <div className="flex flex-row items-center relative w-full">
                  {errors.change && (!change || Number(change) === 0) ? <RequiredAlert /> : <></>}
                  {errors.change && errors.change?.message === 'outStockLimit' ? (
                    <div className="absolute top-[-50%] z-[999] left-80pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
                      <Danger className="fill-ef-secondary-500" />
                      <span>{t("status.stocks_for_move_cannot_comment")}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {errors.change && errors.change?.message === 'inStockLimit' ? (
                    <div className="absolute top-[-50%] z-[999] left-80pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
                      <Danger className="fill-ef-secondary-500" />
                      <span>{t("status.stocks_for_move_in_cannot_comment")}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {errors.change && errors.change?.message === 'errorStockLimit' ? (
                    <div className="absolute top-[-50%] z-[999] left-80pxr rounded-[8px] h-36pxr bg-[#FFF8F8] flex flex-row gap-4pxr items-center stroke-ef-secondary-500 text-ef-secondary-500 py-8pxr px-16pxr border border-solid border-ef-secondary-500 ef-sub-shadow ef-label-md whitespace-nowrap">
                      <Danger className="fill-ef-secondary-500" />
                      <span>{t("status.stocks_for_move_error_cannot_comment")}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-row items-center" onClick={() => clearErrors("change")}>
                    <div className="w-300pxr flex flex-col gap-8pxr">
                      {category_id === 7 ? (
                        <Controller
                          name="change"
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) => {
                              if (Number(value) === 0) {
                                return false;
                              }
                              if (Number(value) < 0 && Math.abs(Number(value)) > Number(herdStock)) {
                                console.log("errorStockLimit", Math.abs(Number(value)), Number(herdStock));
                                return 'errorStockLimit';
                              }
                              return true;
                            },
                          }}
                          render={({ field }) => (
                            <div className="flex flex-row items-center gap-8pxr">
                              <button 
                                type="button"
                                className="w-60pxr h-40pxr bg-ef-state-red-50 rounded-[8px] border border-solid border-ef-state-red-500 items-center justify-center"
                                onClick={() => {
                                  const currentValue = Number(field.value) || 0;
                                  field.onChange(currentValue - 1);
                                }}
                              >
                                <span className="ef-body-lg text-ef-state-red-500">-1</span>
                              </button>
                              <div className="w-180pxr">
                                <ErrorInputField
                                  state={field.value || ''}
                                  displayValue={field.value ? `${field.value} (${Number(herdStock) + (Number(field.value) || 0)}${t("common.head")})` : ''}
                                  setState={(value) => {
                                    field.onChange(value);
                                  }}
                                  type="text"
                                  placeholder={category_id === 7 ? `0 (${herdStock}${t("common.head")})` : t("common.heads")}
                                  textAlign="right"
                                />
                              </div>
                              <button 
                                type="button"
                                className="w-60pxr h-40pxr bg-ef-state-blue-50 rounded-[8px] border border-solid border-ef-state-blue-500 items-center justify-center"
                                onClick={() => {
                                  const currentValue = Number(field.value) || 0;
                                  field.onChange(currentValue + 1);
                                }}
                              >
                                <span className="ef-body-lg text-ef-state-blue-500">+1</span>
                              </button>
                            </div>
                          )}
                        />
                      ) : (
                        <div className="flex flex-row items-center gap-4pxr">
                          <div className="w-120pxr">
                            <Controller
                              name="change"
                              control={control}
                              rules={{
                                required: true,
                                validate: (value) => {
                                  if (Number(value) === 0) {
                                    return false;
                                  }
                                  if (category_id === 3 || category_id === 4 || category_id === 5 || category_id === 6 || category_id === 7) {
                                    if (Math.abs(Number(value)) > Number(herdStock)) {
                                      return 'outStockLimit';
                                    }
                                  }
                                  if ((category_id === 1 || category_id === 2) && departure_piggery !== -1) {
                                    const departureRoom = DepartureRoomList?.find(room => room.id === watch("departure_room"));
                                    if (departureRoom && Number(value) > departureRoom.stock) {
                                      return 'inStockLimit';
                                    }
                                  }
                                  return true;
                                },
                              }}
                              render={({ field }) => (
                                <InputFieldOnly
                                  state={field.value}
                                  setState={(value) => {
                                    field.onChange(value);
                                  }}
                                  type="number"
                                  placeholder={category_id === 7 ? `0 (${herdStock}${t("common.head")})` : t("common.heads")}
                                  regex={/^(?:[1-9]\d*|0)?$/}
                                  textAlign="right"
                                />
                              )}
                            />
                          </div>
                          <span className="ml-4pxr">{t("common.head")}</span>
                        </div>
                      )}
                      {category_id === null ? (
                        ""
                      ) : category_id === 7 ? (
                        <span className="ef-body-xs text-ef-secondary-500">{t("status.stock_correction_comment")}</span>
                      ) : (
                        <span className="ef-body-xs text-ef-secondary-500">※ {t("status.enter_stocks_moved_comment")}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_datetime")}</span>
                  {getRedStar()}
                </label>
                <div className="w-full flex flex-row relative">
                  {errors.created_at ? <RequiredAlert /> : <></>}
                  <div className="w-248pxr" onClick={() => clearErrors("created_at")}>
                    <Controller
                      name="created_at"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <GroupSingleDatePicker minDate={new Date(herdData.arrival_date)} maxDate={new Date()} date={field.value} setDate={field.onChange} />}
                    />
                  </div>
                  <div
                    className={
                      "ml-8pxr group cursor-pointer rounded-[8px] border border-solid border-ef-neutral-150 w-144pxr h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500 text-ef-neutral-900 bg-ef-neutral-white"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <DatePicker
                      selected={selectedTime}
                      onChange={handleTimePickerChange}
                      showTimeSelect
                      autoComplete="on"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelectOnly
                      placeholderText={t("common.time")}
                      timeCaption={t("common.time")}
                      timeIntervals={1}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === 229) e.preventDefault();
                        e.preventDefault();
                      }}
                      customInput={
                        <button onClick={(e) => e.stopPropagation()} className="grow flex flex-row items-center">
                          <span className="grow w-88pxr text-left ef-body-lg">{format(selectedTime ? selectedTime : new Date(), "HH:mm")}</span>
                          <div className="w-24pxr h-24pxr mx-8pxr">
                            <ArrowDown className="stroke-ef-neutral-900 " />
                          </div>
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row mt-11pxr">
                  <span className="line-clamp-1 break-all">{t("common.memo")}</span>
                </label>
                <div className="w-514pxr h-130pxr" onClick={() => clearErrors("memo")}>
                  <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")}></textarea>
                </div>
              </div>
            </div>
            <button type="submit" className="ef-btn-modal mx-auto">
              {t("common.save_btn")}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddStockHistory;
