import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "assets/icons/general/ef-general-calendar.svg";
import "styles/datepicker.css";
import { datePickerLocale } from "utils/useDatepicker";
import { useTranslation } from "react-i18next";

type Props = {
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
};

function GroupSingleDatePicker(props: Props) {
  const { t } = useTranslation();
  const { disabled = false, date, setDate, dateFormat = "yyyy-MM-dd", placeholder, maxDate, minDate } = props;
  const dateRef = useRef<HTMLDivElement>(null);
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (dateRef.current && !dateRef.current.contains(e.target as Node)) {
        setOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRef]);

  return (
    <div
      ref={dateRef}
      className={
        "group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500 " +
        (disabled ? "bg-ef-neutral-50 text-ef-neutral-500" : "text-ef-neutral-900 bg-ef-neutral-white cursor-pointer")
      }
    >
      <DatePicker
        id="singleDate"
        open={openState}
        disabled={disabled}
        autoComplete="off"
        onInputClick={() => setOpenState((prev) => !prev)}
        locale={datePickerLocale()}
        minDate={minDate}
        maxDate={maxDate}
        selected={date}
        onChange={(date) => {
          setDate(date);
          setOpenState(false);
        }}
        className="outline-none caret-transparent"
        dateFormat={dateFormat}
        onKeyDown={(e: any) => {
          if (e.keyCode === 229) e.preventDefault();
          e.preventDefault();
        }}
        placeholderText={placeholder}
      />
      {disabled ? <></> : <Calendar onClick={() => setOpenState((prev) => !prev)} className="stroke-ef-neutral-700 fill-ef-neutral-700" />}
    </div>
  );
}

export default GroupSingleDatePicker;
