import { Modal } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Controller, useForm } from "react-hook-form";
import AddGroupDropDown from "components/pages/2_manage/stock/group/AddGroupDropDown";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useReportError } from "query/report";
import { toast } from "react-toastify";
import { ReportCategory } from "query/report/type";
import { useDeleteMovement, useMovementCategories } from "query/movement";
import { getRedStar } from "utils/getImportantStar";
import RequiredAlert from "components/common/alert/RequiredAlert";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<any>>;
  movementId: number | undefined;
  setDetailReportToggle: React.Dispatch<React.SetStateAction<any>>;
}

type Inputs = {
  isChecked: boolean;
  reason: string;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

function ReportErrorModal(props: Props) {
  const { isToggle, setIsToggle, movementId, setDetailReportToggle } = props;
  const { t } = useTranslation();
  const [isCheck, setisCheck] = useState<boolean>(false);
  const [memoState, setMemoState] = useState("");

  const { mutateAsync: reportError } = useReportError();
  const { mutateAsync: deleteReport } = useDeleteMovement();

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      reason: "",
      isChecked: false,
    },
  });

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await reportError({ id: Number(movementId), complaint: data.reason });
      if (data.isChecked) {
        await deleteReport(Number(movementId));
      }
      toast.success(t("common.save_completed"));
      onClose("closeButtonClick");
    } catch (error) {
      console.log(error);
    }
  };

  // const onSubmit = async (data: Inputs) => {
  //   const formattedData = {
  //     memo: String(data.memo),
  //     movement_id: Number(movementId),
  //     report_category_id: Number(data.report_category_id),
  //   };

  //   try {
  //     await postReport({ payload: formattedData });
  //     toast.success(t("report.report_completed"));
  //     setIsToggle(false);
  //     setDetailReportToggle(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex  justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-900pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        <div className="flex relative w-full items-center">
          <span className="flex mx-auto text-ef-neutral-900 ef-title-lg">{t("manage.report_an_error")}</span>
          <button className="absolute p-8pxr right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex mt-60pxr">
            <label className="w-88pxr ef-label-md flex flex-row mt-10pxr">
              <span title={t("manage.reason_for_reporting")} className="w-66pxr max-w-[66px] break-all whitespace-nowrap overflow-hidden text-ellipsis">
                {t("manage.reason_for_reporting")}
              </span>
              {getRedStar()}
            </label>
            <div className="w-514pxr relative">
              {errors.reason && <RequiredAlert />}
              <Controller
                name="reason"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <textarea value={field.value} onChange={field.onChange} className="ef-textarea w-514pxr h-130pxr" placeholder={t("manage.reason_for_reporting")}></textarea>}
              />
            </div>
          </div>
          {/* =================== */}
          <div className="mt-60pxr h-24pxr flex mx-auto">
            <Controller name="isChecked" control={control} render={({ field }) => <input type="checkbox" id="delete" checked={field.value} onChange={field.onChange} />} />
            <label htmlFor="delete" className="!pl-28pxr ef-label-md text-ef-neutral-700 h-full flex items-center">
              {t("manage.delete_report_after_reporting")}
            </label>
          </div>
          {/* ===================== */}
          <div className="flex flex-row gap-16pxr mx-auto mt-18pxr">
            <button className="!w-239pxr ef-btn-modal-delete" onClick={() => setIsToggle(false)} type="button">
              {t("common.cancel")}
            </button>
            <button className="!w-239pxr ef-btn-modal" type="submit">
              {t("manage.report")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ReportErrorModal;
