import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataRecord, RoomDetail, RoomDropdown, RoomHistoryType, RoomMonitoring, UpdateRoomPayload, RoomDropdownQR } from "./type";
import { useAuthState } from "context/AuthContext";

// 돈사 내 구역 전체 정보 get
export const useRoomDropdownList = (piggery_id?: number | number[] | null, categories?: number[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(
    ["RoomDropdown", piggery_id, categories],
    () =>
      client
        .get<RoomDropdown[]>("/rooms", {
          params: {
            categories: categories !== null ? categories : undefined,
            piggery_id: piggery_id !== null ? piggery_id : undefined,
          },
        })
        .then((res) => res.data),
    {
      enabled: piggery_id !== 0 && current_farm.id !== 0,
      onSuccess(data) {},
    },
  );
  return query;
};

// QR 전용 돈사 내 구역 전체 정보 get
export const useRoomDropdownListQR = (piggery_id?: number | number[] | null, categories?: number[]) => {
  const query = useQuery(
    ["RoomDropdownQR", piggery_id, categories],
    () =>
      client
        .get<RoomDropdownQR[]>("/qr/rooms", {
          params: {
            categories: categories !== null ? categories : undefined,
            piggery_id: piggery_id !== null ? piggery_id : undefined,
          },
        })
        .then((res) => res.data),
    {
      onSuccess(data) { },
    },
  );
  return query;
};

// get 돈방 재고 히스토리
export const useRoomHistorys = (room_id: number) => {
  const query = useQuery(["RoomHistory", room_id], () => client.get<RoomHistoryType>(`/rooms/${room_id}/historys`).then((res) => res.data), {
    enabled: room_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get 돈방 detail(single)
export const useRoomDetail = (id: number) => {
  const query = useQuery(["RoomDetail", id], () => client.get<RoomDetail>(`/rooms/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

export const useUpdateRoom = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editRoom", (object: { payload: UpdateRoomPayload; id: number }) => client.put(`/rooms/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["RoomDetail"]);
      queryClient.invalidateQueries(["piggeryRoomList"]);
    },
  });
  return mutation;
};

export const useRoomEnvDetail = (id: number) => {
  const query = useQuery(["RoomEnvDetail", id], () => client.get<DataRecord>(`/rooms/${id}/env`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈방 모니터링
export const useRoomMonitoring = (room_id: number, obj: { base_datetime: string }) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["RoomMonitoring", room_id, obj], () => client.get<RoomMonitoring[]>(`/rooms/monitoring/${room_id}`, { params: obj }).then((res) => res.data), {
    enabled: room_id !== 0,
    onSuccess(data) {},
  });
  return query;
};
