import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { useCountDetail, useEditCount } from "query/efcr";
import { toast } from "react-toastify";
import { isBefore, subHours } from "date-fns";
import { useEditEfc, useEfcDetail } from "query/efc";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  device: {
    id: number;
    type: string;
  };
};

type Inputs = {
  name: string;
  location: string;
  network_status: string;
  serialize_num: string;
  id: number;
};

function DeviceDetail(props: Props) {
  const { isToggle, setIsToggle, device } = props;
  const { t } = useTranslation();

  const { data: countDetail } = useCountDetail(device.type === "efcr" ? device.id : 0, true);
  const { data: efcDetail } = useEfcDetail(device.type === "efc" ? device.id : 0);

  const { mutateAsync: editCount } = useEditCount();
  const { mutateAsync: editEfc } = useEditEfc();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };

  const onSubmit = async (data: Inputs) => {
    try {
      if (device.type === "efc") {
        await editEfc({ payload: { name: data.name }, id: device.id });
      } else {
        await editCount({ payload: { name: data.name }, id: device.id });
      }
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (device.type === "efc") {
      if (efcDetail) {
        reset({
          name: efcDetail.name,
          location: efcDetail.piggery.name ? efcDetail.piggery?.name : "",
          network_status: efcDetail.connected === false ? t("common.abnormal") : t("common.normal"),
          serialize_num: efcDetail.serial_number,
          id: efcDetail.id,
        });
      }
    } else {
      if (countDetail) {
        reset({
          name: countDetail.name,
          location: countDetail.piggery.name ? countDetail.piggery?.name : "",
          network_status: countDetail.connected === false ? t("common.abnormal") : t("common.normal"),
          serialize_num: countDetail.serial_number,
          id: countDetail.id,
        });
      }
    }
  }, [countDetail, efcDetail]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">
            {device.type === "efcr" ? "EFCR" : "EFC"} {t("common.details")}
          </span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="my-60pxr flex flex-col ef-label-md text-ef-neutral-900 flex-wrap gap-24pxr">
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.installed_location")}</label>
              <div className="w-340pxr">
                <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("common.device_connection_status")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("common.device_connection_status")}
              </label>
              <div className="w-340pxr">
                <Controller name="network_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.serial_number")}</label>
              <div className="w-340pxr">
                <Controller name="serialize_num" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">ID</label>
              <div className="w-340pxr">
                <Controller name="id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default DeviceDetail;
