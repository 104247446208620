// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 농장 svg 폴리곤에 직접적으로 css를 못씌워서 css 파일 이용 */

.farm-polygon {
  fill: transparent;
  cursor: pointer;
}
.farm-polygon:hover {
  fill: #033687;
}
`, "",{"version":3,"sources":["webpack://./src/pages/1_farmStatus/farmStatus.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,aAAa;AACf","sourcesContent":["/* 농장 svg 폴리곤에 직접적으로 css를 못씌워서 css 파일 이용 */\n\n.farm-polygon {\n  fill: transparent;\n  cursor: pointer;\n}\n.farm-polygon:hover {\n  fill: #033687;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
