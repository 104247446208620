import ReactDOM from "react-dom";

type MemoModalProps = {
  memoState: string; // memoState는 string 값
  setMemoState: React.Dispatch<React.SetStateAction<string>>; // setMemoState는 상태 변경 함수
  setIsMemo: React.Dispatch<React.SetStateAction<boolean>>; // setIsMemo는 boolean 값을 변경하는 함수
  onEdit: () => void; // onEdit은 반환 값이 없는 함수
};

const MemoModal = ({ memoState, setMemoState, setIsMemo, onEdit }: MemoModalProps) => {
  return (
    <div className="absolute z-[1500] p-24pxr ef-main-shadow flex flex-col top-24pxr left-2pxr w-368pxr h-366pxr rounded-md bg-ef-neutral-white">
      <span className="w-full flex items-center justify-center">메모</span>
      <textarea
        value={memoState}
        onChange={(e) => setMemoState(e.target.value)}
        className="flex pt-16pxr px-24pxr h-130pxr w-320pxr my-40pxr rounded-md border border-ef-neutral-150 bg-ef-neutral-white"
      />
      <div className="w-full flex flex-row justify-center gap-16pxr mt-20pxr">
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsMemo(false);
          }}
          className="ef-btn-modal-delete"
        >
          취소
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEdit();
          }}
          className="ef-btn-modal-40"
        >
          저장
        </button>
      </div>
    </div>
  );
};

export default MemoModal;
