import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as Logout } from "assets/icons/general/ef-general-logout.svg";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import HistoryCard from "./HistoryCard";
import { useDeactivateHerd, useEditHerd, useHerdDetail } from "query/herd";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { differenceInDays, format } from "date-fns";
import BtnSort from "components/common/button/BtnSort";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import GroupSingleDatePicker from "./GroupSingleDatePicker";
import "styles/datepicker.css";
import DatePicker from "react-datepicker";
import DeactivateHerdLayer from "./DeactivateHerdLayer";
import noData from "assets/images/report/no_report_data.png";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: number;
  isEditable: boolean;
};

type Inputs = {
  nickname: string;
  birth_date: Date | null;
  arrival_date: Date | null;
  memo: string;
  // gender: number;
  // breed: number | null;
  // avg_weight: string;
  // total_weight: string;
};

function GroupDetail(props: Props) {
  const { isToggle, setIsToggle, groupId, isEditable } = props;
  const { t } = useTranslation();

  const [toggleSort, setToggleSort] = useState(true); // true=최신순, false=오래된순
  // 돈군 시작/종료일시
  const [arrivalTime, setArrivalTime] = useState(new Date());
  const [departureTime, setDepartureTime] = useState(new Date());
  const [deactivateHerdModal, setDeactivateHerdModal] = useState(false);
  const [memo, setMemo] = useState<string>("");

  const queryClient = useQueryClient();

  const { data: groupData, isLoading } = useHerdDetail(groupId);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(event.target.value);
  };

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setArrivalTime(time);
      setDepartureTime(time);
    }
  };

  const messageHandler = (event: MessageEvent<any>) => {
    if (event.data.type === "dataUpdated") {
      queryClient.invalidateQueries(["groupList"]);
      queryClient.invalidateQueries(["groupDetail"]);
    } else if (event.data.type === "dataDeleted") {
      queryClient.invalidateQueries(["groupList"]);
      queryClient.invalidateQueries(["groupDetail"]);
      toast.success(t("common.deletion_completed"), { autoClose: 700, hideProgressBar: true });
    }
  };

  const { mutateAsync: editGroup } = useEditHerd();

  const getAgedDayFormat = () => {
    if (birth_date) return `yyyy-MM-dd (${differenceInDays(new Date(), birth_date)}'${t("common.aged")}')`;
  };
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm<Inputs>();

  const birth_date = watch("birth_date");

  const onClose = (value: CloseReason) => {
    if (value === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  const onDelete = (id: number) => {
    alert(t("manage.delete_herd_alert_comment"));
  };

  const onSubmit = async (data: Inputs) => {
    if (groupData?.active_state === true) {
      const formattedData = {
        nickname: data.nickname === "" ? null : data.nickname,
        birth_date: data.birth_date === null ? null : format(data.birth_date, "yyyy-MM-dd"),
        arrival_date: data.arrival_date === null ? null : format(data.arrival_date, "yyyy-MM-dd"),
        memo: memo !== "" ? memo : "",
      };
      try {
        await editGroup({ payload: formattedData, id: groupId });
        toast.success(t("common.save_completed"));
        onClose("closeButtonClick");
      } catch (error: any) {
        if (error?.response?.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
        console.log(error);
      }
    } else {
    }
  };

  useEffect(() => {
    if (groupData) {
      reset({
        nickname: groupData?.nickname === null ? "" : groupData?.nickname,
        birth_date: groupData?.birth_date !== null ? new Date(groupData?.birth_date) : null,
        arrival_date: groupData?.arrival_date !== null ? new Date(groupData?.arrival_date) : null,
        memo: groupData?.memo !== "" ? groupData?.memo : "",
        // gender: groupData?.gender,
        // breed: groupData?.breed === null ? 0 : groupData?.breed,
        // avg_weight: groupData?.avg_weight !== null ? groupData?.avg_weight.toFixed(2) : "",
        // avg_weight: "",
        // total_weight: "",
        // total_weight: groupData?.avg_weight !== null ? Math.floor(groupData?.avg_weight * groupData?.stock).toString() : "",
      });
    }
  }, [groupData]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return isLoading ? (
    <></>
  ) : (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-fit h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("common.herd")} ${t("common.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        {groupData?.active_state === true ? (
          <div className="relative w-fit h-fit mt-40pxr">
            <button
              onClick={(e) => {
                e.preventDefault();
                setDeactivateHerdModal(true);
              }}
              type="button"
              className="ef-btn-r8-sm w-fit gap-8pxr flex flex-row !ef-label-md"
            >
              <Logout className="stroke-ef-neutral-900" />
              {t("common.quit_herd")}
            </button>
            {deactivateHerdModal
              ? groupData && <DeactivateHerdLayer groupData={groupData} setModalToggle={setIsToggle} herdId={groupData?.id} isOpen={deactivateHerdModal} setIsOpen={setDeactivateHerdModal} roomId={groupData?.room?.id} piggeryId={groupData?.room?.piggery?.id} />
              : ""}
          </div>
        ) : (
          <></>
        )}
        {/* body */}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className={"flex flex-row h-608pxr flex-wrap gap-32pxr w-full " + (isEditable ? "mt-24pxr mb-16pxr" : "mt-60pxr")}>
            <div className="flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.herd_name")}</label>
                <div className="w-340pxr">
                  <InputFieldOnly state={groupData?.name} setState={() => {}} disabled />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.nickname")}</label>
                <div className="w-340pxr">
                  <Controller
                    name="nickname"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("manage.nickname_placeholder")} maxLength={20} disabled={isEditable ? false : true} />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] break-all line-clamp-1">{t("common.birth_date_aged_day")}</label>
                <div className="w-216pxr">
                  <Controller
                    name="birth_date"
                    control={control}
                    render={({ field }) => (
                      <GroupSingleDatePicker
                        date={field.value}
                        setDate={field.onChange}
                        placeholder={`YYYY-MM-DD (-${t("common.aged")})`}
                        dateFormat={getAgedDayFormat()}
                        maxDate={groupData ? new Date(groupData?.arrival_date) : new Date()}
                        disabled={isEditable ? false : true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.herd_start_datetime")}</label>
                <div className="w-175pxr flex mr-8pxr">
                  <Controller
                    name="arrival_date"
                    control={control}
                    render={({ field }) => (
                      <GroupSingleDatePicker
                        disabled
                        date={field.value}
                        setDate={field.onChange}
                        placeholder={groupData?.arrival_date}
                        // dateFormat={getAgedDayFormat()}
                        // maxDate={groupData ? new Date(groupData?.arrival_date) : new Date()}
                        // disabled={isEditable ? false : true}
                      />
                    )}
                  />
                </div>
                <div
                  className="flex items-center bg-ef-neutral-50 text-ef-neutral-500 border-ef-neutral-150 h-40pxr w-144pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-lg ef-title-md"

                  // className="flex items-center bg-ef-neutral-white w-144pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-lg ef-title-md"
                >
                  <DatePicker
                    disabled
                    selected={new Date()}
                    onChange={handleTimePickerChange}
                    showTimeSelect
                    autoComplete="on"
                    showTimeSelectOnly
                    placeholderText={t("common.time")}
                    dateFormat="h:mm"
                    className="outline-none caret-transparent"
                    customInput={
                      <button className="grow flex flex-row ">
                        <span className="grow w-88pxr text-left ef-body-lg ">{format(groupData?.arrival_date ? new Date(groupData?.arrival_date) : new Date(), "HH:mm")}</span>
                        <div className="w-24pxr mx-8pxr">
                          <ArrowDown className="stroke-ef-neutral-900 " />
                        </div>
                      </button>
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.herd_end_datetime")}</label>
                <div className="w-175pxr flex mr-8pxr text-ef-neutral-500">
                  <InputFieldOnly state={groupData?.deactivated_at ? format(new Date(groupData?.deactivated_at), "yyyy-MM-dd") : t("common.no_info")} setState={() => {}} disabled />
                </div>
                <div
                  className="flex items-center bg-ef-neutral-50 text-ef-neutral-500 border-ef-neutral-150 h-40pxr w-144pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-lg ef-title-md"
                  // className="flex items-center bg-ef-neutral-white w-144pxr py-8pxr pr-4pxr pl-16pxr text-left border border-solid rounded-lg ef-title-md"
                >
                  <DatePicker
                    disabled
                    selected={new Date()}
                    onChange={handleTimePickerChange}
                    showTimeSelect
                    autoComplete="on"
                    showTimeSelectOnly
                    placeholderText={t("common.time")}
                    dateFormat="h:mm"
                    customInput={
                      <button className="grow flex flex-row">
                        <span className="grow w-88pxr text-left ef-body-lg">{groupData?.deactivated_at ? format(new Date(groupData?.deactivated_at), "HH:mm") : t("common.no_info")}</span>
                        <div className="w-24pxr mx-8pxr">
                          <ArrowDown className="stroke-ef-neutral-900 " />
                        </div>
                      </button>
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.location")}</label>
                <div className="w-340pxr">
                  <InputFieldOnly state={groupData?.room ? `[${groupData?.room?.piggery?.name}] ${groupData?.room?.name}` : "-"} setState={() => {}} disabled />
                </div>
              </div>
              {/* 현재 무게 값 없음 */}
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.average_weight")}</label>
                <div className="flex flex-row gap-8pxr flex-wrap">
                  <div className="flex flex-row items-center">
                    <div className="w-300pxr">
                      {/* <Controller
                        name="avg_weight"
                        control={control}
                        render={({ field }) => (
                          <InputFieldOnly
                            disabled
                            state={field.value}
                            setState={(value) => {
                              if (groupData) {
                                if (groupData?.stock === 0) setValue("total_weight", Math.floor(Number(value)).toString());
                                else if (groupData?.stock !== 0) setValue("total_weight", Math.floor(Number(value) * Number(groupData?.stock)).toString());
                                field.onChange(value);
                              }
                              if (value === "") setValue("total_weight", "");
                            }}
                            type="number"
                            placeholder={t("common.average_weight")}
                            regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                          />
                        )}
                      /> */}
                      <div className="group rounded-[8px] ef-body-lg border border-solid border-ef-neutral-150 justify-end w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 bg-ef-neutral-50 text-ef-neutral-500">
                        {/* <input
                          type="string"
                          value={groupData?.room?.avg_weight ? groupData?.room?.avg_weight : ""}
                          placeholder={groupData?.room?.avg_weight ? String(groupData?.room?.avg_weight) : t("manage.no_weight_in_move_records")}
                          disabled
                          className="text-right mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                        /> */}
                        {t("common.no_info")}
                      </div>
                    </div>
                    <span className="ml-4pxr">kg</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.heads")}</label>
                <div className="flex flex-row items-center">
                  <div className="w-300pxr">
                    {/* <InputFieldOnly state={groupData?.stock ? groupData?.stock : "0"} setState={() => {}} disabled /> */}
                    <div className="group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 bg-ef-neutral-50 text-ef-neutral-500">
                      <input
                        type="string"
                        value={groupData?.stock}
                        placeholder={groupData?.stock ? String(groupData?.stock) : t("manage.no_number_of_moves_in_records")}
                        disabled
                        className="text-right mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                      />
                    </div>
                  </div>
                  <span className="ml-4pxr">{t("common.head")}</span>
                </div>
              </div>

              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">
                  <span className="line-clamp-1 break-all">{t("common.memo")}</span>
                </label>
                <div className="w-340pxr h-96pxr">
                  <textarea onChange={handleChange} value={memo} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")} disabled={!isEditable}></textarea>
                </div>
              </div>
              {/* <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.gender")}</label>
                <div className="w-146pxr">
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <DropDownSmall
                        disabled={isEditable ? false : true}
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t("common.not_selected")}
                        // options={
                        //   genderList
                        //     ? genderList.map((o) => {
                        //         return { value: o.id, name: t(`common.gender_${o.id}`) };
                        //       })
                        //     : []
                        // }
                        options={[]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.breed")}</label>
                <div className="w-146pxr">
                  <Controller
                    name="breed"
                    control={control}
                    render={({ field }) => (
                      <DropDownSmall
                        disabled={isEditable ? false : true}
                        state={field.value}
                        setState={field.onChange}
                        // options={breedList ? [{ value: 0, name: t("manage.not_selected") }, ...breedList.map((o) => ({ value: o.id, name: o.name }))] : []}
                        options={[]}
                      />
                    )}
                  />
                </div>
              </div> */}
            </div>
            {/* divider */}
            <div className="h-full border-l border-solid border-ef-neutral-150"></div>
            <div className="h-full w-470pxr flex flex-col">
              <div className="w-full flex flex-row items-center">
                <span className="ef-title-md text-ef-neutral-900">{t("manage.history")}</span>
                <BtnSort sortState={toggleSort} setSortState={setToggleSort} />
              </div>
              {/* history section */}
              <div className="mt-16pxr h-full grow overflow-hidden w-full bg-ef-neutral-white border border-solid border-ef-neutral-150 rounded-[8px]">
                <div className="grid w-full h-full overflow-y-auto scrollbar-hide">
                    {groupData && (
                      <div className="flex flex-col gap-4 p-24pxr relative">
                        {(() => {
                          const rawHist = groupData.historys ?? [];
                          if (rawHist.length === 0) {
                            return (
                              <div className="w-full h-full flex flex-col items-center justify-center">
                                <img src={noData} alt="no_data" />
                                <span className="ef-label-md text-ef-neutral-700">
                                  {t("manage.no_history_comment")}
                                </span>
                              </div>
                            );
                          }
                          // 최신 created_at (없으면 현재시간)
                          const lastItem = rawHist[0];
                          const latestCreatedAt = lastItem?.created_at || new Date().toISOString();

                          // 히스토리 복사본
                          let histArray = [...rawHist];
                          // 필요에 따라 오름차순/내림차순
                          if (!toggleSort) histArray.reverse();

                          // 공통 렌더 함수
                          const renderHistory = () =>
                            histArray.map((o, i) => (
                              <HistoryCard
                                key={i}
                                category={Number(o.category.id)}
                                roomData={groupData.room}
                                historyInfo={o}
                                index={i}
                                dataLength={histArray.length + 1}
                                isEditable={isEditable}
                                isActivate={groupData.active_state}
                              />
                            ));
                          const renderCreationCard = (idx: number) => (
                            <HistoryCard
                              key="create"
                              category={0}
                              roomData={groupData?.room}
                              historyInfo={{
                                category: { id: 8 },
                                created_at: groupData?.historys[groupData.historys.length - 1]?.created_at || new Date().toISOString(),
                              }}
                              index={idx}
                              dataLength={groupData?.historys.length + (groupData?.active_state ? 1 : 2)}
                              isEditable={isEditable}
                              isActivate={groupData?.active_state}
                              isBasicCard={true}
                            />
                          );
                          const renderTerminationCard = (idx: number) => (
                            <HistoryCard
                              key="terminate"
                              category={0}
                              roomData={groupData?.room}
                              historyInfo={{
                                category: { id: 9 },
                                created_at: latestCreatedAt,
                              }}
                              index={idx}
                              dataLength={groupData?.historys.length + 2}
                              isEditable={isEditable}
                              isActivate={groupData?.active_state}
                              isBasicCard={true}
                            />
                          );

                          // toggleSort가 true → "생성카드 아래, 종료카드 위"
                          // toggleSort가 false → "생성카드 위, 종료카드 아래"
                          if (toggleSort) {
                            return (
                              <>
                                {!groupData.active_state && renderTerminationCard(0)}
                                {renderHistory()}
                                {renderCreationCard(histArray.length + (groupData.active_state ? 0 : 1))}
                              </>
                            );
                          } else {
                            return (
                              <>
                                {renderCreationCard(0)}
                                {renderHistory().map((elem, i) =>
                                  React.cloneElement(elem, { 
                                    index: i + 1,
                                    dataLength: groupData?.historys.length + (groupData?.active_state ? 1 : 2)
                                  })
                                )}
                                {!groupData.active_state && renderTerminationCard(groupData.historys.length + 1)}
                              </>
                            );
                          }
                        })()}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          {/* {isEditable ? ( */}
          {/* <div className="flex flex-row flex-wrap gap-16pxr mx-auto">
            {groupData?.active_state === true ? (
              <>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeactivateHerdModal(true);
                  }}
                  // type="submit"
                  className="ef-btn-modal-delete !w-240pxr"
                >
                  {t("common.quit_herd")}
                </button>
                <button type="submit" className="ef-btn-modal !w-240pxr">
                  {t("common.save")}
                </button>
              </>
            ) : groupData?.active_state === false ? (
              ""
            ) : (
              <button type="button" onClick={() => onDelete(Number(groupData?.id))} className="ef-btn-modal-delete ">
                {t("common.delete")}
              </button>
            )}
          </div> */}
          {/* ) : (
            <></>
          )} */}
          {groupData?.active_state === true ? (
            <button type="button" onClick={() => onDelete(Number(groupData?.id))} className="flex w-full justify-end ef-label-lg hover:cursor-pointer text-ef-neutral-500 underline underline-offset-2">
              {t("manage.delete_herd")}
            </button>
          ) : (
            <></>
          )}
          {isEditable ? (
              <div className="w-full items-center text-center justify-center mt-60pxr">
                <button type="submit" className="ef-btn-modal ">
                  {t("common.save")}
                </button>
              </div>
          ):
            (
            <></>
          )  
          }
        </form>
      </div>
    </Modal>
  );
}

export default GroupDetail;
