import React, { useEffect, useRef, useState } from "react";
// svgs
import { ReactComponent as Logo } from "assets/logo/ef-header-logo.svg";
import { ReactComponent as Sunny } from "assets/icons/gnb/ef-gnb-sunny.svg";
import { ReactComponent as Rain } from "assets/icons/gnb/ef-gnb-rain.svg";
import { ReactComponent as Cloud } from "assets/icons/gnb/ef-gnb-cloud.svg";
import { ReactComponent as LittleCloud } from "assets/icons/gnb/ef-gnb-littlecloud.svg";
import { ReactComponent as Snow } from "assets/icons/gnb/ef-gnb-snow.svg";
import { ReactComponent as Shower } from "assets/icons/gnb/ef-gnb-shower.svg";
import { ReactComponent as Lighting } from "assets/icons/gnb/ef-gnb-lighting.svg";
import { ReactComponent as Fog } from "assets/icons/gnb/ef-gnb-fog.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
// library
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
// query
import { useWeather } from "query/global";
// routes
import { GnbList } from "./routes";
// context
import { useAuthState } from "context/AuthContext";
import { useEditContext } from "context/EditContext";
// components
import ProductivityPopover from "components/common/gnb/ProductivityPopover";
import LanguageSelector from "components/common/lang/LanguageSelector";
import PlaceSelector from "components/common/gnb/PlaceSelector";
import AlarmComponent from "components/common/gnb/AlarmComponent";

function Gnb() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPage = useLocation();
  const currentGnb = currentPage.pathname.split("/")[1]; //manage, settings...

  // context
  const { user, current_farm } = useAuthState();
  const editContext = useEditContext();

  const [showSubGnb, setShowSubGnb] = useState(false);
  const [hoveredGnbIdx, setHoveredGnbIdx] = useState<number>(0);
  const [closedSub, setClosedSub] = useState<string[]>([]);

  console.log(closedSub);

  // 날씨 설정 state
  const [weatherOpt, setWeatherOpt] = useState({
    log: 0,
    lat: 0,
  });

  // query
  const { data: weatherData } = useWeather(weatherOpt);

  const selectIcon = () => {
    let weatherCode = parseInt(weatherData?.weather[0]?.id);

    let iconProps = {
      width: "35px",
      height: "35px",
      className: "",
      alt: "",
    };

    if (weatherCode === 800) return <Sunny {...iconProps} title="sunny" />;
    else if (weatherCode === 802) return <Cloud {...iconProps} title="cloud" />;
    else {
      const iconId = (weatherCode / 100).toFixed(0);
      switch (iconId) {
        case "2":
          return <Lighting {...iconProps} title="thunder" />;
        case "3":
          return <Shower {...iconProps} title="drizzle" />;
        case "5":
          return <Rain {...iconProps} title="rain" />;
        case "6":
          return <Snow {...iconProps} title="snow" />;
        case "7":
          return <Fog {...iconProps} title="fog" />;
        case "8":
          return <LittleCloud {...iconProps} title="little-cloud" />;
        default:
          return <Sunny {...iconProps} title="sunny" />;
      }
    }
  };

  useEffect(() => {
    const res = user.farms && user.farms.find((v, i) => v.id === +current_farm.id);
    if (!res) {
      return undefined;
    }

    const coordinate = JSON.parse(res.coordinate);

    const latitude = coordinate.lat;
    const longitude = coordinate.lng;

    setWeatherOpt({
      log: longitude ?? 0,
      lat: latitude ?? 0,
    });
  }, [user, weatherData]);

  const handleMouseEnter = () => {
    setShowSubGnb(true);
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    setClosedSub([]);
    setShowSubGnb(false);
  };

  const hanldeHoverGnb = (subLen: number, index: number, disabled: boolean) => {
    setHoveredGnbIdx(index);

    const subindex = GnbList[index].subGnb.findIndex((o) => o.child.some((c) => c.link === currentPage.pathname));

    // setClosedSub();

    if (subLen === 0 || disabled) setShowSubGnb(false);
    else setShowSubGnb(true);
  };

  const handleMoveLocation = (goTo: string) => {
    const currentLocation = currentPage.pathname;

    if (editContext?.editState === true) {
      if (window.confirm(t("common.alert_not_saved"))) {
        if (currentLocation === goTo) {
          editContext?.setEditState(false);
          navigate(0);
        } else navigate(goTo);
      } else return;
    } else {
      if (currentLocation === goTo) {
        editContext?.setEditState(false);
        navigate(0);
      } else navigate(goTo);
    }

    setShowSubGnb(false);
  };

  return (
    <div onMouseLeave={handleMouseLeave} className="w-full h-96pxr min-h-[96px] relative">
      {/* main gnb */}
      <div className="w-full h-full bg-ef-neutral-white flex flex-row px-40pxr items-center border-b border-solid border-ef-neutral-200">
        {/* Logo */}
        <button onClick={() => handleMoveLocation("/")} type="button">
          <Logo />
        </button>
        {/* Gnb List */}
        <div className="ml-120pxr flex flex-row gap-24pxr items-center text-ef-neutral-900 ef-body-xxl">
          {GnbList.map((g, idx) => (
            <Tooltip key={idx} title={g.disabled ? t("common.prepare_comment") : ""}>
              <span onMouseEnter={() => hanldeHoverGnb(g.subGnb.length, idx, g.disabled)}>
                <button
                  onClick={() => {
                    handleMoveLocation(g.link);
                  }}
                  disabled={g.disabled}
                  className={
                    "px-16pxr py-4pxr cursor-pointer border border-solid border-transparent disabled:text-ef-neutral-600 disabled:cursor-not-allowed focus:outline-none hover:text-ef-primary-500 active:text-ef-primary-300 active:border-transparent focus:border-ef-neutral-900 " +
                    (currentGnb === g.prefix ? "underline underline-offset-[6px] ef-label-xl text-ef-primary-500" : "")
                  }
                >
                  {t(`${g.name}`)}
                </button>
              </span>
            </Tooltip>
          ))}
        </div>
        {/* side of gnb */}
        <div onMouseEnter={handleMouseLeave} className="flex flex-row gap-16pxr ml-auto items-center">
          {/* 생산성 지표 */}
          <ProductivityPopover />
          {/* 날씨 */}
          <div className="flex flex-row items-center w-fit px-16pxr py-8pxr gap-6pxr">
            <div className="w-24pxr h-24pxr flex items-center justify-center stroke-ef-neutral-900">{selectIcon()}</div>
            <span className="text-ef-neutral-900 ef-label-lg">
              {isNaN(Math.round(weatherData?.main?.temp)) ? "-" : Math.round(weatherData?.main?.temp)}
              <span className="">°C</span>
            </span>
          </div>
          {/* 언어 선택기 */}
          <LanguageSelector type="main" />
          {/* 알람 컴포넌트 */}
          <AlarmComponent />
          {/* 장소 선택기 */}
          <PlaceSelector />
        </div>
      </div>
      {/* sub gnb */}
      {showSubGnb && (
        <div onMouseEnter={handleMouseEnter} className="bg-ef-neutral-white h-fit pt-24pxr pb-40pxr absolute w-full animate-fade-in z-[9999] border-b border-solid border-ef-neutral-200">
          <div className="mx-auto w-fit grid grid-cols-4 gap-32pxr">
            {Array.from({ length: 4 }, (_, index) => index).map((gridIdx) => (
              <div key={gridIdx} className="grid gap-32pxr auto-rows-min">
                {GnbList[hoveredGnbIdx].subGnb.map(
                  (s, idx) =>
                    idx % 4 === gridIdx && (
                      <div key={idx} className="w-280pxr min-w-[280px] flex flex-col">
                        <button
                          type="button"
                          onClick={() => {
                            // child.length가 0이라면 s.link로 이동, child가 있다면 child open
                            if (s.child.length === 0) handleMoveLocation(s.link);
                            else {
                              if (closedSub.includes(`${GnbList[hoveredGnbIdx].prefix}-${idx}`))
                                setClosedSub((prevClosedSub) => prevClosedSub.filter((sub) => sub !== `${GnbList[hoveredGnbIdx].prefix}-${idx}`));
                              else setClosedSub([...closedSub, `${GnbList[hoveredGnbIdx].prefix}-${idx}`]);
                            }
                          }}
                          className={
                            "group py-7pxr pr-8xr pl-4pxr flex flex-row gap-8pxr items-center cursor-pointer hover:bg-ef-primary-50 hover:text-ef-primary-500 active:text-ef-primary-300 active:bg-transparent " +
                            (s.child.some((o) => o.link === currentPage.pathname) || currentPage.pathname === s.link ? "text-ef-primary-500" : "")
                          }
                        >
                          <div
                            className={
                              "min-w-[24px] w-24pxr h-24pxr stroke-ef-neutral-900 [&>*]:fill-ef-neutral-900 group-hover:stroke-ef-primary-500 group-hover:[&>*]:fill-ef-primary-500 group-active:stroke-ef-primary-300 group-active:[&>*]:fill-ef-primary-300 " +
                              (s.child.some((o) => o.link === currentPage.pathname) || currentPage.pathname === s.link ? "stroke-ef-primary-500 [&>*]:fill-ef-primary-500" : "")
                            }
                          >
                            {s.icon}
                          </div>
                          <span className="text-[18px] text-left font-medium grow leading-[26px] tracking-[-0.45px] whitespace-nowrap overflow-hidden text-ellipsis">{t(`${s.name}`)}</span>
                          {s.child.length > 0 && (
                            <div
                              className={
                                "stroke-ef-neutral-900 group-hover:stroke-ef-primary-500 group-active:stroke-ef-primary-300 " +
                                (s.child.some((o) => o.link === currentPage.pathname) || currentPage.pathname === s.link ? "stroke-ef-primary-500" : "")
                              }
                            >
                              {closedSub.includes(`${GnbList[hoveredGnbIdx].prefix}-${idx}`) ? <ArrowDown /> : <ArrowUp />}
                            </div>
                          )}
                        </button>
                        <div className="w-full mt-4pxr h-1pxr bg-ef-neutral-200"></div>
                        <div className="px-4pxr pt-16pxr flex flex-col gap-16pxr">
                          <p dangerouslySetInnerHTML={{ __html: t(`${s.description}`).replaceAll("\n", "<br/>") }} className="w-full ef-body-lg text-ef-neutral-700"></p>
                          <ul
                            style={{
                              maxHeight: closedSub.includes(`${GnbList[hoveredGnbIdx].prefix}-${idx}`) ? "0px" : 40 * s.child.length + "px",
                            }}
                            className="pl-30pxr flex flex-col gap-16pxr transition-[max-height] duration-300 ease-in-out overflow-hidden"
                          >
                            {s.child.map((c) => (
                              <li key={c.link} className={"list-disc cursor-pointer " + (currentPage.pathname === c.link ? "text-ef-primary-500" : "")} onClick={() => handleMoveLocation(c.link)}>
                                {t(`${c.name}`)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ),
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Gnb;
