import React, { useEffect, useRef, useState } from "react";
import JSpreadsheet from "jspreadsheet-ce";
import "./css/table.css";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEditStandard, useStandardDetail } from "query/farm";
import { DayAgeStandardType, SettingStandard } from "query/farm/type";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  standardList: SettingStandard;
};

interface WeekData {
  week: number;
  // week_range?: string;
  start_day_age: number;
  end_day_age: number;
  lower_weight: number;
  upper_weight: number;
  lower_feeding: number;
  upper_feeding: number;
  lower_temperature: number;
  upper_temperature: number;
  lower_humidity: number;
  upper_humidity: number;
}

type SpreadsheetData = (string | number)[][];

// 기준데이터 상세 모달
function PresetDetailModal(props: Props) {
  const { isToggle, setIsToggle, id, standardList } = props;
  const { t } = useTranslation();
  const { data: standardDetail, refetch } = useStandardDetail(id);
  const { mutateAsync: editStandard } = useEditStandard();

  const buttonRef = useRef<HTMLButtonElement>(null);

  // *** table 별 ref와 instance를 각각 관리해주어야 함 *** //

  // 일령별 데이터
  const spreadsheetRef = useRef<HTMLDivElement | null>(null);
  const [spreadsheetInstance, setSpreadsheetInstance] = useState<any>(null);
  // 등급 기준 설정 데이터
  const weightGradeRef = useRef<HTMLDivElement | null>(null);
  const [weightGradeInstance, setWeightGradeInstance] = useState<any>(null);

  // 일령별 데이터를 담는 arr
  const [data, setData] = useState<SpreadsheetData>([]);
  // 등급 기준 설정 데이터를 담는 arr
  const [weightGradeData, setWeightGradeData] = useState<SpreadsheetData>([]);

  // 원본과 비교 데이터 (변화를 감지하는 데이터)
  const [originalTitle, setOriginalTitle] = useState("");
  const [originalMemo, setOriginalMemo] = useState("");
  const [originalData, setOriginalData] = useState<SpreadsheetData>([]);
  const [originalWeightGradeData, setOriginalWeightGradeData] = useState<SpreadsheetData>([]);

  const [title, setTitle] = useState("");
  const [memo, setMemo] = useState("");

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;

    // 셀 수정 내역이 있는지 확인
    const isDataModified =
      JSON.stringify(originalData) !== JSON.stringify(data) || JSON.stringify(originalWeightGradeData) !== JSON.stringify(weightGradeData) || originalMemo !== memo || originalTitle !== title;

    if (isDataModified) {
      if (window.confirm(t("settings.edit_not_save_comment"))) {
        buttonRef.current!.click();
      } else {
        setIsToggle(false);
      }
    } else {
      setIsToggle(false);
    }
  };

  // 메모 onChange 핸들러
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(event.target.value);
  };

  // excel data -> 일령별 데이터 변환 핸들러
  const convertSpreadsheetDataToDayAgeStandard = (spreadsheetData: (string | number)[][]): DayAgeStandardType[] => {
    return spreadsheetData.slice(2).map((row) => ({
      week: row[0] as number,
      start_day_age: String(row[1]).split("~").map(Number)[0],
      end_day_age: String(row[1]).split("~").map(Number)[1],
      lower_weight: Number(row[2]),
      upper_weight: Number(row[3]),
      lower_feeding: Number(row[4]),
      upper_feeding: Number(row[5]),
      lower_temperature: Number(row[6]),
      upper_temperature: Number(row[7]),
      lower_humidity: Number(row[8]),
      upper_humidity: Number(row[9]),
    }));
  };

  // excel data -> 등급 기준 설정 데이터 변환 핸들러
  const convertSpreadsheetDataToWeightGrade = (spreadsheetData: (string | number)[][]): { grade: string; lower_range: number; upper_range: number }[] => {
    return spreadsheetData.slice(1).map((row) => ({
      grade: row[0] as string,
      lower_range: typeof row[1] === "string" && row[1].includes("%") ? Number(row[1].replace("%", "").trim()) / 100 : Number(row[1]),
      upper_range: typeof row[2] === "string" && row[2].includes("%") ? Number(row[2].replace("%", "").trim()) / 100 : Number(row[2]),
    }));
  };

  console.log(spreadsheetInstance?.getData());
  // 수정 내역이 있을 시 수정 요청 보내는 핸들러
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const latestData = spreadsheetInstance?.getData() || [];
    const latestWeightGradeData = weightGradeInstance?.getData() || [];

    // 변환된 데이터
    const convertedData = convertSpreadsheetDataToDayAgeStandard(latestData);
    const convertedWeightGradeData = convertSpreadsheetDataToWeightGrade(latestWeightGradeData);

    // 변경 여부 확인
    const hasChanges =
      JSON.stringify(originalData) !== JSON.stringify(convertedData) ||
      JSON.stringify(originalWeightGradeData) !== JSON.stringify(convertedWeightGradeData) ||
      originalMemo !== memo ||
      originalTitle !== title;

    console.log(convertedData);
    // 변동이 없으면 기존 값을 사용
    const payload = hasChanges
      ? {
          name: title,
          data: convertedData,
          weight_grade: convertedWeightGradeData,
          memo: memo,
        }
      : {
          name: originalTitle,
          data: originalData,
          weight_grade: originalWeightGradeData,
          memo: originalMemo,
        };

    try {
      console.log(payload);
      // @ts-ignore
      await editStandard({ payload: payload, standard_id: id });
      toast.success(t("common.save_completed"));

      setIsToggle(false);
    } catch (error) {
      toast.error(t("common.save_failed"));
    }
  };

  // 등급 기준 데이터 컨버터
  const convertWeightDataForSpreadsheet = (data: any[]): SpreadsheetData => {
    const header: SpreadsheetData = [
      // 첫 번째 배열이 모두 빈값인 이유는 무료버전이라 셀 서식을 지울 수 없어서 헤더가 겹치는 현상이 발생하기 때문
      ["", "", "", "", ""],
      [t("settings.weight_class_bases"), t("settings.lower_limit"), t("settings.upper_limit")],
    ];

    const convertedData: SpreadsheetData = data.map((item, index) => [
      index === 0 ? t("settings.grade_first_plus") : index === 1 ? t("settings.grade_first") : t("settings.grade_second"),
      item.lower_range * 100 + "%",
      item.upper_range * 100 + "%",
    ]);

    return [...header, ...convertedData];
  };

  // 일령별 데이터 컨버터
  const convertStandardDataForSpreadsheet = (data: WeekData[]): SpreadsheetData => {
    const header: SpreadsheetData = [
      // 첫 번째 배열이 모두 빈값인 이유는 무료버전이라 셀 서식을 지울 수 없어서 헤더가 겹치는 현상이 발생하기 때문
      ["", "", "", "", "", "", "", "", "", ""],
      [t("common.day_age"), "", `${t("settings.live_weight")}(kg)`, "", `${t("settings.intake")}(kg)`, "", `${t("common.temperature")}(℃)`, "", `${t("common.humidity")}(%)`, ""],
      [
        t("settings.week"),
        t("settings.day"),
        t("settings.lower_limit"),
        t("settings.upper_limit"),
        t("settings.lower_limit"),
        t("settings.upper_limit"),
        t("settings.lower_limit"),
        t("settings.upper_limit"),
        t("settings.lower_limit"),
        t("settings.upper_limit"),
      ],
    ];

    // 순서에 유의 (헤더 값 아래 순서대로 데이터 배치 됨)
    const convertedData: SpreadsheetData = data?.map((item) => [
      item.week,
      item.start_day_age + "~" + item.end_day_age,
      item.lower_weight,
      item.upper_weight,
      item.lower_feeding,
      item.upper_feeding,
      item.lower_temperature,
      item.upper_temperature,
      item.lower_humidity,
      item.upper_humidity,
    ]);

    return [...header, ...convertedData];
  };

  type CellChange = [cell: HTMLElement, x: number, y: number, value: string];

  // 일령별 데이터 셀 체인저
  const handleStandardCellChange = (instance: any, changes: any) => {
    if (!Array.isArray(changes)) {
      console.error("Unexpected changes format:", changes);
      return;
    }

    setData((prevData) => {
      const updatedData = [...prevData];

      changes.forEach(([cell, x, y, value]: CellChange) => {
        if (y >= 0) {
          updatedData[y][x] = value;
        } else {
          console.error("Invalid y coordinate detected:", y);
        }
      });

      return updatedData;
    });
  };

  // 등급 데이터 셀 체인저
  const handleWeightCellChange = (instance: any, changes: CellChange[]) => {
    if (!Array.isArray(changes)) {
      console.error("Unexpected changes format:", changes);
      return;
    }

    setWeightGradeData((prevData) => {
      const updatedData = [...prevData];

      changes.forEach(([cell, x, y, value]) => {
        if (y >= 0) {
          updatedData[y][x] = value;
        } else {
          console.error("Invalid y coordinate detected:", y);
        }
      });

      return updatedData;
    });
  };
  useEffect(() => {
    if (isToggle && standardDetail) {
      // @ts-ignore
      const convertedStandardData = convertStandardDataForSpreadsheet(standardDetail.data);
      const convertedWeightGradeData = convertWeightDataForSpreadsheet(standardDetail.weight_grade);

      setData(convertedStandardData);
      setOriginalData(convertedStandardData);
      setWeightGradeData(convertedWeightGradeData);
      setOriginalWeightGradeData(convertedWeightGradeData);

      setTitle(standardDetail.name);
      setMemo(standardDetail.memo || "");
      setOriginalTitle(standardDetail.name);
      setOriginalMemo(standardDetail.memo || "");

      if (weightGradeInstance) {
        weightGradeInstance.destroy();
        setWeightGradeInstance(null);
      }

      const weightTimer = setTimeout(() => {
        refetch();
        if (weightGradeRef.current) {
          const instance = JSpreadsheet(weightGradeRef.current, {
            data: convertedWeightGradeData.slice(1), // 헤더 제외
            minDimensions: [3, 4],
            maxDimensions: [3, 4],
            allowInsertRow: false, // 행 추가 방지
            allowInsertColumn: false, // 열 추가 방지
            columns: [
              { type: "text", title: "", width: 126, readOnly: id === standardList?.flag_standard_id },
              { type: "text", title: "", width: 80, readOnly: id === standardList?.flag_standard_id },
              { type: "text", title: "", width: 80, readOnly: id === standardList?.flag_standard_id },
            ],
            onchange: handleWeightCellChange,
          });
          setWeightGradeInstance(instance);
        }
      }, 100);

      if (spreadsheetInstance) {
        spreadsheetInstance.destroy();
        setSpreadsheetInstance(null);
      }

      const timer = setTimeout(() => {
        if (spreadsheetRef.current) {
          const instance = JSpreadsheet(spreadsheetRef.current, {
            data: convertedStandardData.slice(1),
            minDimensions: [10, 10],
            maxDimensions: [32, 10],
            allowInsertRow: false,
            allowInsertColumn: false,
            mergeCells: {
              A1: [2, 1],
              C1: [2, 1],
              E1: [2, 1],
              G1: [2, 1],
              I1: [2, 1],
            },
            columns: convertedStandardData[0]?.map((title, index) => ({
              type: "text",
              title: title,
              readOnly: index === 0 || index === 1 || id === standardList?.flag_standard_id || !standardDetail?.farm_id,
            })),
            // Pass all changes at once
            onchange: (instance: any, changes: CellChange[]) => handleStandardCellChange(instance, changes),
          });
          setSpreadsheetInstance(instance);
        }
      }, 100);

      return () => {
        clearTimeout(timer);
        if (spreadsheetInstance) {
          spreadsheetInstance.destroy();
          setSpreadsheetInstance(null);
        }

        clearTimeout(weightTimer);
        if (weightGradeInstance) {
          weightGradeInstance.destroy();
          setWeightGradeInstance(null);
        }
      };
    }
  }, [isToggle, standardDetail, id]);

  // 등급 기준 설정 테이블 헤더 css용 이펙트
  useEffect(() => {
    if (weightGradeInstance) {
      setTimeout(() => {
        const table = weightGradeRef?.current?.querySelector("table");
        if (table) {
          const cellA1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(1)");
          const cellA2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(2)");

          const cellB1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(2)");
          const cellB2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(3)");

          const cellC1 = table.querySelector("tbody tr:nth-child(1) td:nth-child(3)");
          const cellC2 = table.querySelector("tbody tr:nth-child(2) td:nth-child(4)");

          if (cellA1) cellA1.classList.add("cell-a1");
          if (cellB1) cellB1.classList.add("cell-b1");
          if (cellC1) cellC1.classList.add("cell-c1");
          if (cellA2) cellA2.classList.add("cell-a2");
          if (cellB2) cellB2.classList.add("cell-b2");
          if (cellC2) cellC2.classList.add("cell-c2");
        }
      }, 100);
    }
  }, [weightGradeInstance]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr  bg-ef-neutral-white outline-none flex flex-col p-40pxr text-ef-neutral-900">
        <div className="w-full relative">
          <p className="ef-title-lg text-center">{`${standardDetail?.name} ${t("common.details")}`}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 absolute right-0pxr top-0pxr">
            <GeneralClose />
          </button>
        </div>
        <form className="mt-40pxr flex flex-col max-h-[1682px]  overflow-y-auto" onSubmit={onSubmit}>
          <div className="flex flex-col gap-24pxr ef-label-md">
            <div className="flex flex-row items-center">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("settings.title")}</span>
                {getRedStar()}
              </label>
              <div className="w-514pxr">
                <InputFieldOnly state={title} setState={setTitle} disabled={id === standardList?.flag_standard_id || !standardDetail?.farm_id} />
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row mt-10pxr">
                <span className="line-clamp-1 break-all">{t("settings.standard_data_by_age")}</span>
                {getRedStar()}
              </label>
              <div className="w-574pxr h-[1056px] max-h-[1056px] flex">
                <div id="spreadsheet" ref={spreadsheetRef}></div>
              </div>
            </div>
            <div className="flex flex-row mt-240pxr">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row mt-24pxr">
                <span className="line-clamp-1 break-all">{t("settings.grading_criteria_settings")}</span>
                {getRedStar()}
              </label>
              <div className="w-286pxr mt-24pxr">
                <div ref={weightGradeRef}></div>
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-94pxr min-w-[94px] mr-16pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("common.memo")}</span>
              </label>
              <textarea
                placeholder={id === standardList?.flag_standard_id || !standardDetail?.farm_id ? t("settings.cannot_enter_memo") : t("common.enter_memo")}
                value={memo}
                name="memo"
                onChange={handleChange}
                disabled={id === standardList?.flag_standard_id || !standardDetail?.farm_id}
                className="ef-textarea !w-514pxr h-130pxr"
              />
            </div>
          </div>
          <button ref={buttonRef} type="submit" className="ef-btn-modal !w-494pxr mx-auto mt-60pxr">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default PresetDetailModal;
