import React from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  streamUrl: string;
  roomName: string;
  piggeryName: string;
  cameraName: string;
  hidePiggeryName?: boolean;
}

function MainStream(props: Props) {
  const { isToggle, setIsToggle, streamUrl, roomName, piggeryName, cameraName, hidePiggeryName } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030",
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={() => setIsToggle(false)}
        className="flex justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-950pxr h-658pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">
              {hidePiggeryName ? `${roomName} - ${cameraName}` : `[${piggeryName}]${roomName} - ${cameraName}`} {t("status.streaming")}
            </span>
            <button className="absolute right-0pxr" onClick={() => setIsToggle(false)}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <div className="mt-60pxr">
            <div className="w-full aspect-video">
              <iframe
                src={streamUrl + "main"}
                className="w-full h-full object-fill"
                allowFullScreen
                allow="camera; microphone; autoplay"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MainStream;