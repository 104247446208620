import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CountCamDetail, CountType, EfcrSingle } from "./type";
import { useAuthState } from "context/AuthContext";

// get count list
export const useCountList = (piggeries?: number[], connected?: boolean[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["countList", piggeries, connected], () => client.get<CountType[]>("/efcr", { params: { piggeries, connected } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get count detail(single)
export const useCountDetail = (id: number, show_location?: boolean) => {
  const query = useQuery(["countDetail", id], () => client.get<EfcrSingle>(`/efcr/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) count detail
export const useEditCount = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editCount", (object: { payload: { name: string }; id: number }) => client.put(`/efcr/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["countList"]);
      queryClient.invalidateQueries(["countDetail"]);
    },
  });
  return mutation;
};

// get camera detail(single)
export const useCountCamDetail = (id: number, show_location?: boolean) => {
  const query = useQuery(["countCamDetail", id], () => client.get<CountCamDetail>(`/efcr/camera/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) count camera detali
export const useEditCountCam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editCountCam", (object: { payload: { name: string }; id: number }) => client.put(`/efcr/camera/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["countList"]);
      queryClient.invalidateQueries(["countCamDetail"]);
    },
  });
  return mutation;
};
