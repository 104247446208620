import App from "App";
import { Outlet, createBrowserRouter } from "react-router-dom";
import Select from "pages/0_select";
import Login from "pages/0_login";
import LayoutPage from "pages";
import Farm from "pages/5_settings/farm/info";
import ReportMovement from "pages/2_manage/stock/movement";
import Account from "pages/5_settings/account";
import ErrorPage from "components/pages/0_common/ErrorPage";
import ReportEnvironment from "pages/2_manage/environment/sensorLog";
import Piggery from "pages/5_settings/farm/piggery";
import ManageGroup from "pages/2_manage/stock/group";
import Efcr from "pages/2_manage/device/efcr";
import Efg from "pages/2_manage/device/efg";
import Feeder from "pages/2_manage/device/feeder";
import Temperature from "pages/2_manage/device/sensor";
import GroupDetailWindow from "components/pages/2_manage/stock/group/GroupDetailWindow";
import Contact from "pages/0_contact";
import WindowDetailReport from "components/pages/2_manage/stock/movement/detail/widnowDetailReport";
import ManageGrow from "pages/2_manage/specification/growth";
import FeedLog from "pages/2_manage/specification/feed";
import StandardSettings from "pages/5_settings/data/standard";
import OperationReport from "pages/3_report/operation";
import StockRecord from "pages/2_manage/stock/record";
import MobileDailyReport from "pages/0_kakao_report/daily";
import StockMap from "pages/1_farmStatus/rooms/StockMap";
import EnvMap from "pages/1_farmStatus/rooms/EnvMap";
import DeviceMap from "pages/1_farmStatus/rooms/DeviceMap";
import PiggeryStatus from "pages/1_farmStatus";
import Notice from "pages/0_notice";
import Streaming from "pages/6_streaming";
import QrStockRecord from "pages/0_qr/stock";
import QrStockStatus from "pages/0_qr/status";

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/daily/report", element: <MobileDailyReport /> },
  { path: "/qr/stock", element: <QrStockRecord /> },
  { path: "/qr/stock/status", element: <QrStockStatus /> },
  {
    path: "",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      // 농장선택
      { path: "select", element: <Select /> },
      { path: "groupDetail/:id/:edit", element: <GroupDetailWindow /> },
      { path: "reportDetail/:id", element: <WindowDetailReport /> },
      // { path: "deviceMap/:id", element: <DeviceMapWindow /> },
      // { path: "deviceMap/:id/:device_id", element: <DeviceMapWindow /> },

      // gnb, footer 들어가는 페이지들
      {
        path: "/",
        element: <LayoutPage />,
        children: [
          // 농장현황(대메뉴)
          { index: true, element: <PiggeryStatus /> },
          {
            path: "status",
            element: <Outlet />,
            children: [
              { path: "stock/:id", element: <StockMap /> },
              { path: "env/:id", element: <EnvMap /> },
              { path: "device/:id", element: <DeviceMap /> },
            ],
          },
          // 관리(대메뉴)
          {
            path: "manage",
            element: <Outlet />,
            children: [
              // 작업관리
              {
                path: "schedule",
                element: <Outlet />,
                children: [
                  // {path:"weekly",element :<ManageSchedule />}
                ],
              },
              // 재고관리
              {
                path: "stock",
                element: <Outlet />,
                children: [
                  { path: "movement", element: <ReportMovement /> },
                  { path: "group", element: <ManageGroup /> },
                  { path: "record", element: <StockRecord /> },
                ],
              },
              // 사양관리
              {
                path: "specification",
                element: <Outlet />,
                children: [
                  { path: "growth", element: <ManageGrow /> },
                  { path: "feed", element: <FeedLog /> },
                ],
              },
              // 환경관리
              { path: "environment", element: <Outlet />, children: [{ path: "sensorLog", element: <ReportEnvironment /> }] },
              // 장치관리
              {
                path: "device",
                children: [
                  { path: "efcr", element: <Efcr /> },
                  { path: "efg", element: <Efg /> },
                  { path: "feeder", element: <Feeder /> },
                  { path: "sensor", element: <Temperature /> },
                ],
              },
            ],
          },
          // 보고서(대메뉴)
          {
            path: "report",
            element: <Outlet />,
            children: [
              // 운영보고서
              { path: "operation", element: <OperationReport />, children: [] },
              // 성적보고서
              { path: "performance", element: <Outlet />, children: [] },

              // { path: "week", element: <ReportWeek /> },
              // { path: "move", element: <ReportMovement /> },
              // { path: "record/health", element: <HealthLog /> },
              // { path: "record/growth", element: <GrowthLog /> },
            ],
          },
          // 알람(대메뉴)
          // {
          //   path: "alarm",
          //   element: <Alarm />,
          // },
          {
            path: "settings",
            element: <Outlet />,
            children: [
              // 농장설정
              {
                path: "farm",
                element: <Outlet />,
                children: [
                  { path: "info", element: <Farm /> },
                  { path: "piggery", element: <Piggery /> },
                ],
              },
              // 데이터설정
              {
                path: "data",
                element: <Outlet />,
                children: [{ path: "standard", element: <StandardSettings /> }],
              },
              // 계정설정
              { path: "account", element: <Account /> },

              // { path: "partner", element: <Partner /> },
              // { path: "worker", element: <Worker /> },
            ],
          },
          { path: "notice", element: <Notice /> },
          {
             path: "streaming",
             element: <Streaming />,
           },
        ],
      },
    ],
  },
]);
