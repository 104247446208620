import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Badge } from "assets/icons/gnb/ef-gnb-badge.svg";
import { ReactComponent as Fcr } from "assets/icons/report/ef-report-fcr.svg";
import { ReactComponent as Growth } from "assets/icons/general/ef-general-growth.svg";
import { ReactComponent as CalendarCheck } from "assets/icons/general/ef-general-calendarCheck.svg";
import { useTranslation } from "react-i18next";
import { Popover } from "@mui/material";
import "./css/ProductivityPopover.css"; // 별도의 CSS 파일로 애니메이션 추가
import { useFarmProductivity } from "query/farm";

function ProductivityPopover() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { data: farmData } = useFarmProductivity();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} className="px-16pxr py-6pxr cursor-default h-40pxr">
      <div className="h-28pxr overflow-hidden">
        <b>
          <div className="stroke-ef-neutral-900 text-ef-neutral-900 ef-body-xl innerText">
            <div className="flex flex-row gap-6pxr items-center h-28pxr min-h-[28px]">
              <Growth className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="w-162pxr max-w-[162px] line-clamp-1 break-all">{`${t("gnb.growing_rate")} : ${
                  farmData && farmData.growing_rate !== null ? `${farmData?.growing_rate.toFixed(2)}%` : t("common.no_info")
                }`}</span>
              </div>
            </div>
            <div className="flex flex-row gap-6pxr items-center h-28pxr min-h-[28px]">
              <CalendarCheck className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="w-162pxr max-w-[162px] line-clamp-1 break-all">{`${t("gnb.shipping_age")} : ${
                  farmData && farmData.avg_shipment_age !== null ? `${farmData?.avg_shipment_age.toFixed(0)}${t("common.aged")}` : t("common.no_info")
                }`}</span>
              </div>
            </div>
            <div className="flex flex-row gap-6pxr items-center h-28pxr min-h-[28px]">
              <Badge className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="w-162pxr max-w-[162px] line-clamp-1 break-all"> {`${t("gnb.grade_1_appearance_rate")} : ${t("common.no_info")}`}</span>
              </div>
            </div>
            <div className="flex flex-row gap-6pxr items-center h-28pxr min-h-[28px]">
              <Fcr className="w-28pxr h-28pxr min-w-[28px]" />
              <div className="flex flex-col">
                <span className="w-162pxr max-w-[162px] line-clamp-1 break-all">{`${t("gnb.fcr")} : ${t("common.no_info")}`}</span>
              </div>
            </div>
          </div>
        </b>
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
          ".MuiPaper-root": {
            boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08) !important",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #DEDEDE",
            // width: "240px",
          },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="flex flex-col w-full h-fit">
          <div
            style={{
              background: "linear-gradient(279deg, #033687 0%, #83B3FF 100%)",
            }}
            className="py-16pxr w-full flex items-center rounded-t-[8px]"
          >
            <p className="w-full text-ef-neutral-white ef-label-lg text-center">{t("gnb.productivity_indicators")}</p>
          </div>
          <div className="px-16pxr py-24pxr flex flex-col gap-8pxr stroke-ef-neutral-900 text-ef-neutral-900 ef-body-lg">
            <div className="flex flex-row gap-6pxr items-start">
              <Growth className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="ef-body-xl mb-2pxr">{`${t("gnb.growing_rate")} : ${farmData && farmData.growing_rate !== null ? `${farmData?.growing_rate.toFixed(2)}%` : t("common.no_info")}`}</span>
                <span className="ef-body-lg text-ef-neutral-500">{`${t("gnb.growing_rate_last_year")} : ${t("common.no_info")}`}</span>
              </div>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-150"></div>

            <div className="flex flex-row gap-6pxr items-start">
              <CalendarCheck className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="ef-body-xl mb-2pxr">{`${t("gnb.shipping_age")} : ${
                  farmData && farmData.avg_shipment_age !== null ? `${farmData?.avg_shipment_age.toFixed(0)}${t("common.aged")}` : t("common.no_info")
                }`}</span>
                <span className="ef-body-lg text-ef-neutral-500">{`${t("gnb.shipping_age_last_year")} : ${t("common.no_info")}`}</span>
              </div>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-150"></div>

            <div className="flex flex-row gap-6pxr items-start">
              <Badge className="min-w-[28px]" />
              <div className="flex flex-col">
                <span className="ef-body-xl mb-2pxr"> {`${t("gnb.grade_1_appearance_rate")} : ${t("common.no_info")}`}</span>
                <span className="ef-body-lg text-ef-neutral-500">{`${t("gnb.appearance_rate_last_year")} : ${t("common.no_info")}`}</span>
              </div>
            </div>

            <div className="w-full h-1pxr bg-ef-neutral-150"></div>
            <div className="flex flex-row gap-6pxr items-start">
              <Fcr className="w-28pxr h-28pxr min-w-[28px]" />
              <div className="flex flex-col">
                <span className="ef-body-xl mb-2pxr">{`${t("gnb.fcr")} : ${t("common.no_info")}`}</span>
                <span className="ef-body-lg text-ef-neutral-500">{`${t("gnb.fcr_last_year")} : ${t("common.no_info")}`}</span>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default ProductivityPopover;
