import { useAuthDispatch } from "context/AuthContext";
import client from "query/client";
import { QueryCache } from "react-query";

export default function useLogout() {
  const AuthDispatch = useAuthDispatch();
  const queryCache = new QueryCache();

  const logout = () => {
    client.defaults.headers.common["Authorization"] = ""; // axios 헤더 제거
    client.defaults.headers.common["farm-id"] = "";
    AuthDispatch({ type: "CLEAR" }); // state 클리어
    localStorage.removeItem("Authorization");
    localStorage.removeItem("farm-id"); // 농장 토큰 제거
    queryCache.clear();
    window.location.replace("/");
  };

  return logout;
}
