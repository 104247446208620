import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePiggeryMonitoring } from "query/piggery";
import { format, setHours } from "date-fns";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import NoImg from "assets/images/report/no_report_data.png";
import CameraDetailViewModal from "./CameraDetailViewModal";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
  selectedPiggery: number;
};

const timeOptions = Array.from({ length: 24 }, (_, value) => {
  const name = `${value.toString().padStart(2, "0")}:00 ~ ${value.toString().padStart(2, "0")}:59`;
  return { value, name };
});

function PiggeryViewModal(props: Props) {
  const { isToggle, setIsToggle, selectedDate, selectedPiggery } = props;
  const { t } = useTranslation();

  const [selectedTime, setSelectedTime] = useState(12);

  // 카메라 상세보기 모달
  const [selectedCamId, setSelectedCamId] = useState(0);
  const [toggleCamDetail, setToggleCamDetail] = useState(false);

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
    }
  };

  const date = new Date(selectedDate);
  const { data: piggeryData, isLoading } = usePiggeryMonitoring(selectedPiggery, { base_datetime: format(setHours(date, selectedTime), "yyyy-MM-dd'T'HH:00:00") });

  const efgList = piggeryData ? piggeryData.efg_camera : [];

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr pb-120pxr overflow-y-auto"
    >
      <div className="w-994pxr h-[920px] max-h-[920px] overflow-y-auto bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        <CameraDetailViewModal isToggle={toggleCamDetail} setIsToggle={setToggleCamDetail} camId={selectedCamId} selectedDate={selectedDate} />
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${piggeryData ? piggeryData.piggery.name : ""} ${t("report.view_all_images")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        {/* filter */}
        <div className="mt-60pxr flex flex-row gap-16pxr mx-auto items-center">
          <span className="ef-label-lg">{t("report.images_by_time")}</span>
          <div className="z-[999]">
            <DropDownFilter title={""} state={selectedTime} setState={setSelectedTime} options={timeOptions} />
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col w-full h-full items-center justify-center">
            <img src={NoImg} alt="no-data-img" className="w-310pxr" />
            <span className="ef-body-lg text-ef-neutral-600">{t("common.data_loading")}</span>
          </div>
        ) : (
          <main className="grid grid-cols-3 gap-16pxr mt-24pxr">
            {efgList.map((o, idx: number) => (
              <div
                key={idx}
                onClick={() => {
                  setSelectedCamId(o.id);
                  setToggleCamDetail(true);
                }}
                className="w-full h-fit cursor-pointer"
              >
                <div className="aspect-video w-full relative">
                  <div className="bg-ef-neutral-50 rounded-t-[8px] absolute top-0pxr left-0pxr w-full h-full flex flex-col items-center justify-center">
                    <img className="w-140pxr h-99pxr" src={NoImg} alt="error-img" />
                    {o.history.length === 0 ? (
                      <span className="ef-body-sm text-ef-neutral-700 text-center">{t("common.no_data")}</span>
                    ) : (
                      <span className="ef-body-sm text-ef-neutral-700 text-center">{t("common.image_path_not_found")}</span>
                    )}
                  </div>
                  <img
                    className="rounded-t-[8px] w-full absolute top-0pxr left-0pxr"
                    src={o.history.length === 0 ? "" : o.history[0].image_path}
                    alt="efg-img"
                    onError={(e) => (e.currentTarget.hidden = true)}
                  />
                </div>

                <div className="flex flex-col gap-4pxr py-16pxr px-24pxr border-x border-b border-solid border-ef-neutral-150 rounded-b-[8px]">
                  <div className="flex flex-row items-center">
                    <span className="ef-body-xl">{o.room.name}</span>
                    <span className="ef-body-lg ml-auto text-ef-neutral-700">{o.history.length > 0 ? format(new Date(o.history[0].created_at), "HH:mm:ss") : ""}</span>
                  </div>
                  <p className="ef-body-lg">{o.name}</p>
                </div>
              </div>
            ))}
          </main>
        )}
      </div>
    </Modal>
  );
}

export default PiggeryViewModal;
