import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import { ReactComponent as Settings } from "assets/icons/general/ef-general-settings.svg";
import { ReactComponent as Remove } from "assets/icons/general/ef-general-remove.svg";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Download } from "assets/icons/report/ef-report-download.svg";
import { ReactComponent as Sort } from "assets/icons/icon16_general/ef-16general-sort.svg";
import Pagination from "components/common/pagination/Pagination";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Popover from "@mui/material/Popover";
import PresetDetailModal from "components/pages/5_settings/data/standard/PresetDetailModal";
import UploadExcelModal from "components/pages/5_settings/data/standard/UploadExcelModal";
import { useCopyStandardList, useDeleteStandard, useSettingFlag, useStandardList } from "query/farm";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";
import { StandardType } from "query/farm/type";
import PreviewModal from "components/pages/5_settings/data/standard/PreviewModal";

const PAGELIMIT = 20;

type SortOrder = "created_at_desc" | "created_at_asc" | "last_updated_desc" | "last_updated_asc";

function StandardSettings() {
  const topRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { data: standardList } = useStandardList();
  const { mutate: copyPreset } = useCopyStandardList();
  const { mutateAsync: deletePreset } = useDeleteStandard();
  const { mutateAsync: settingFlag } = useSettingFlag();
  const [currentPage, setCurrentPage] = useState(1);
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // radio state
  const [isRadioOn, setIsRadioOn] = useState(false);
  const [radioPreset, setRadioPreset] = useState(1);

  // detail state
  const [toggleDetail, setToggleDeatil] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState(0);
  const [togglePreivew, setTogglePreview] = useState(false);

  // upload state
  const [toggleUpload, setToggleUpload] = useState(false);

  // sort
  const [sortedData, setSortedData] = useState<StandardType[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>("created_at_desc");

  const [propsName, setPropsName] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/templates/standard_data.xlsx";
    link.download = "standard.xlsx";
    link.click();
  };

  const handleDelete = async (id: number) => {
    if (window.confirm(t("common.alert_delete"))) {
      try {
        await deletePreset(id);
        toast.success(t("common.deletion_completed"));
      } catch (error) {
        console.log(error);
      }
    } else return;
  };

  const handleCopy = async (id: number) => {
    try {
      const element = topRef.current;

      await copyPreset(id, {
        onSuccess(data) {
          toast.success(t("settings.copy_completed"));
          element?.scrollIntoView({ behavior: "smooth" });
          setCurrentPage(1);
          setHighlightedRow(data.id);

          setTimeout(function () {
            setHighlightedRow(null);
          }, 3000);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveFeault = async () => {
    try {
      // @TODO 전역 default preset 설정
      await settingFlag(radioPreset);
      toast.success(t("common.save_completed"));
      setIsRadioOn(false);
    } catch (error) {}
  };

  const handleTitleSort = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "created_at_desc") return "created_at_asc";
      if (prevOrder === "created_at_asc") return "created_at_desc";
      return "created_at_desc";
    });
  };

  const handleDateSort = (type: "created_at" | "last_updated_at") => {
    setSortOrder((prevOrder) => {
      if (type === "created_at") {
        return prevOrder === "created_at_desc" ? "created_at_asc" : "created_at_desc";
      } else if (type === "last_updated_at") {
        return prevOrder === "last_updated_desc" ? "last_updated_asc" : "last_updated_desc";
      }
      return prevOrder;
    });
  };

  useEffect(() => {
    const sorted = [...(standardList?.standard_data || [])].sort((a, b) => {
      if (sortOrder === "created_at_desc") {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }
      if (sortOrder === "created_at_asc") {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      }
      if (sortOrder === "last_updated_desc") {
        return new Date(b.last_updated_at).getTime() - new Date(a.last_updated_at).getTime();
      }
      if (sortOrder === "last_updated_asc") {
        return new Date(a.last_updated_at).getTime() - new Date(b.last_updated_at).getTime();
      }
      return 0;
    });
    setSortedData(sorted);
  }, [sortOrder, standardList?.standard_data]);

  return (
    <div className="w-full h-full bg-ef-neutral-50" ref={topRef}>
      <UploadExcelModal isToggle={toggleUpload} setIsToggle={setToggleUpload} />
      {standardList && <PresetDetailModal isToggle={toggleDetail} setIsToggle={setToggleDeatil} id={selectedPreset} standardList={standardList} />}
      {standardList && <PreviewModal isToggle={togglePreivew} setIsToggle={setTogglePreview} id={selectedPreset} standardList={standardList} propsName={propsName} />}
      <div className="w-[1440px] max-w-[1440px] mx-auto pt-60pxr pb-200pxr text-ef-neutral-900">
        <header className="flex flex-row gap-8pxr items-center w-full justify-between">
          <span className="flex flex-row ef-headline-lg gap-4pxr">
            <span>{t("gnb.standard_data_settings")}</span>
            <span className="text-ef-neutral-400">({standardList ? standardList?.standard_data.length : "0"})</span>
          </span>
          {isRadioOn ? (
            <div className="flex items-end gap-8pxr">
              <button onClick={() => setIsRadioOn(false)} type="button" className="ef-btn-modal-40 !bg-ef-neutral-600 !w-120pxr ml-auto">
                {t("common.cancel")}
              </button>
              <button onClick={handleSaveFeault} type="button" className="ef-btn-modal-40 !w-120pxr ml-auto">
                {t("common.save")}
              </button>
            </div>
          ) : (
            <div className="flex flex-row grow">
              <button onClick={() => setToggleUpload(true)} type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr">
                <Add />
                {t("settings.upload_data")}
              </button>
              <div className="ml-auto flex flex-row gap-16pxr">
                <button onClick={() => setIsRadioOn(true)} type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr">
                  <Settings />
                  {t("settings.set_default_template")}
                </button>
                <button onClick={handleClick} type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr">
                  <Download />
                  {t("settings.download_form")}
                </button>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    ".MuiPaper-root": {
                      boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08) !important",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      border: "1px solid #DEDEDE",
                      marginTop: "4px",
                      width: "445px",
                    },
                  }}
                >
                  <div className="w-full flex flex-col relative p-24pxr pb-48pxr">
                    <p className="ef-title-md text-center">{t("settings.download_form")}</p>
                    <button onClick={handleClose} type="button" className="absolute top-16pxr right-16pxr bg-ef-neutral-100 rounded-full p-8pxr w-fit stroke-ef-neutral-900">
                      <GeneralClose />
                    </button>
                    <div className="flex flex-col mt-40pxr ef-label-xl">
                      <div className="flex flex-row w-full items-center gap-8pxr">
                        <div className="flex flex-row gap-8pxr items-center grow">
                          <span className="break-all line-clamp-1">{t("settings.standard")}</span>
                          <span
                            className="ef-body-lg text-ef-primary-500 underline underline-offset-4 whitespace-nowrap hover:cursor-pointer"
                            onClick={() => {
                              setTogglePreview(true);
                              setPropsName("표준");
                            }}
                          >
                            {t("settings.preview")}
                          </span>
                        </div>
                        <button type="button" onClick={handleDownload} className="ef-btn-r8-sm flex flex-row gap-8pxr whitespace-nowrap">
                          <Download />
                          <span>{t("settings.download")}</span>
                        </button>
                      </div>
                      {/* <div className="w-full h-1pxr bg-ef-neutral-200 mt-24pxr mb-16pxr"></div>
                      <div className="flex flex-row w-full items-center gap-8pxr">
                        <div className="flex flex-row gap-8pxr items-center grow">
                          <span className="break-all line-clamp-1">저성장기</span>
                          <span
                            className="ef-body-lg text-ef-primary-500 underline underline-offset-4 whitespace-nowrap hover:cursor-pointer"
                            onClick={() => {
                              setTogglePreview(true);
                              setPropsName("저성장기");
                            }}
                          >
                            미리보기
                          </span>
                        </div>
                        <button type="button" onClick={handleDownload} className="ef-btn-r8-sm flex flex-row gap-8pxr whitespace-nowrap">
                          <Download />
                          <span>다운로드</span>
                        </button>
                      </div>
                      <div className="w-full h-1pxr bg-ef-neutral-200 mt-24pxr mb-16pxr"></div>
                      <div className="flex flex-row w-full items-center gap-8pxr">
                        <div className="flex flex-row gap-8pxr items-center grow">
                          <span className="break-all line-clamp-1">고성장기</span>
                          <span
                            className="ef-body-lg text-ef-primary-500 underline underline-offset-4 whitespace-nowrap hover:cursor-pointer"
                            onClick={() => {
                              setTogglePreview(true);
                              setPropsName("고성장기");
                            }}
                          >
                            미리보기
                          </span>
                        </div>
                        <button type="button" onClick={handleDownload} className="ef-btn-r8-sm flex flex-row gap-8pxr whitespace-nowrap">
                          <Download />
                          <span>다운로드</span>
                        </button>
                      </div> */}
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          )}
        </header>
        <main className="mt-40pxr flex flex-col">
          <table className="ef-table-toggle stroke-ef-neutral-900 !w-full">
            <thead>
              <tr className="[&>*]:!p-16pxr">
                <th className="w-369pxr max-w-[369px] cursor-pointer">
                  <div
                    className="flex flex-row gap-2pxr items-center w-full justify-center"
                    // onClick={() => handleTitleSort()}
                  >
                    <span>{t("settings.title")}</span>

                    {/* <Sort /> */}
                  </div>
                </th>
                <th className="w-268pxr max-w-[268px]">{t("settings.note")}</th>
                <th className="w-217pxr max-w-[217px] cursor-pointer">
                  <div
                    className="flex flex-row gap-2pxr items-center w-full justify-center"
                    //  onClick={() => handleDateSort("created_at")}
                  >
                    <span>{t("settings.registration_date")}</span>

                    {/* <Sort /> */}
                  </div>
                </th>
                <th className="w-217pxr max-w-[217px] cursor-pointer">
                  <div
                    className="flex flex-row gap-2pxr items-center w-full justify-center"
                    //  onClick={() => handleDateSort("last_updated_at")}
                  >
                    <span>{t("settings.last_modified_date")}</span>

                    {/* <Sort /> */}
                  </div>
                </th>
                <th className="w-246pxr max-w-[246px]">{t("settings.copy")}</th>
                <th className="w-123pxr max-w-[123px]">{t("common.delete")}</th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.slice(currentPage * PAGELIMIT - PAGELIMIT, currentPage * PAGELIMIT).map((v, i) => (
                <tr key={i} className={highlightedRow === v.id ? "bg-ef-primary-50" : "hover:bg-ef-primary-50"}>
                  <td className="!p-16pxr">
                    <div className="flex flex-row gap-8pxr items-center">
                      {isRadioOn && (
                        <>
                          <input type="radio" name="default" id={String(v.id)} value={v.id} onChange={(e) => setRadioPreset(Number(e.target.value))} checked={radioPreset === v.id} />
                          <label htmlFor={String(v.id)} className="w-24pxr h-24pxr"></label>
                        </>
                      )}

                      {standardList?.flag_standard_id
                        ? v.id === standardList?.flag_standard_id && (
                            <div className="px-8pxr py-2pxr rounded-[10px] bg-ef-primary-500 text-ef-neutral-white ef-label-sm text-center">{t("settings.default")}</div>
                          )
                        : !v.farm_id && <div className="px-8pxr py-2pxr rounded-[10px] bg-ef-primary-500 text-ef-neutral-white ef-label-sm text-center">{t("settings.default")}</div>}
                      <span
                        onClick={() => {
                          setSelectedPreset(v.id);
                          setToggleDeatil(true);
                        }}
                        className="break-all max-w-[292px] whitespace-nowrap text-ellipsis overflow-hidden underline underline-offset-2 cursor-pointer"
                      >
                        {v.name}
                      </span>
                    </div>
                  </td>
                  {/* @todo default 프리셋이라면 메모(비고), 등록 날짜, 최근 수정 날짜 미노출 예외처리 필요 */}
                  <td className="max-w-[268px] text-left !p-16pxr">{v.memo}</td>
                  <td>{v.created_at ? format(new Date(v.created_at), "yyyy-MM-dd") : ""}</td>
                  <td>{v.last_updated_at ? format(new Date(v.last_updated_at), "yyyy-MM-dd") : ""}</td>
                  <td
                    style={{
                      backgroundColor: isRadioOn ? "#F8F8F8" : "",
                    }}
                    className="!py-8pxr"
                  >
                    <button onClick={() => handleCopy(v.id)} disabled={isRadioOn} type="button" className="ef-btn-32-700 disabled:cursor-not-allowed">
                      <span> {t("settings.copy_btn")}</span>
                    </button>
                  </td>
                  <td
                    style={{
                      backgroundColor: v.farm_id === null || isRadioOn ? "#F8F8F8" : "",
                    }}
                    className="!py-12pxr"
                  >
                    {v.farm_id === null ? (
                      <Tooltip title={t("settings.default_delete_disabled_comment")}>
                        <span>
                          <button
                            disabled
                            type="button"
                            className="flex flex-row items-center gap-4pxr w-full justify-center disabled:text-ef-neutral-500 disabled:stroke-ef-neutral-500 cursor-not-allowed"
                          >
                            <Remove />
                            <span>{t("common.delete")}</span>
                          </button>
                        </span>
                      </Tooltip>
                    ) : (
                      <button
                        onClick={() => handleDelete(v.id)}
                        disabled={isRadioOn}
                        type="button"
                        className="flex flex-row items-center gap-4pxr w-full justify-center disabled:text-ef-neutral-500 disabled:stroke-ef-neutral-500 disabled:cursor-not-allowed"
                      >
                        <Remove />
                        <span>{t("common.delete")}</span>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* pagination */}
          <div className="mt-60pxr mx-auto">
            {standardList?.standard_data && <Pagination dataLength={standardList?.standard_data.length} pageLimit={PAGELIMIT} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
          </div>
        </main>
      </div>
    </div>
  );
}

export default StandardSettings;
