import React, { useEffect, useState } from "react";
// library
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
// components
import InputFieldOnly from "components/common/input/InputFieldOnly";
// context
import { useAuthState } from "context/AuthContext";
import { useEditContext } from "context/EditContext";
// query
import { usePiggeryDetail, useUpdatePiggery } from "query/piggery";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { ReactComponent as Modify } from "assets/icons/general/ef-general-modify.svg";
import PiggeryMoveInfoModal from "./PiggeryMoveInfoModal";
import { getRedStar } from "utils/getImportantStar";

type Props = {
  piggeryId: number;
};
type Inputs = {
  name: string;
  floor: number;
};

function PiggeryDetail(props: Props) {
  const { t } = useTranslation();
  const { piggeryId } = props;

  const { current_farm } = useAuthState();
  const editContext = useEditContext();

  const [overlayPoints, setOverlayPoints] = useState("");
  const [toggleModal, setToggleModal] = useState(false);

  const { data: piggeryData } = usePiggeryDetail(piggeryId);
  const { mutateAsync: updatePiggery } = useUpdatePiggery();

  const imgWidth = document.getElementById("farm-image")?.clientWidth;
  const imgHeight = document.getElementById("farm-image")?.clientHeight;
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>();

  const currentName = watch("name");

  useEffect(() => {
    if (typeof piggeryData !== "undefined") {
      reset({
        name: piggeryData.name,
        floor: piggeryData.floor,
      });
      if (imgHeight && imgWidth && piggeryData.overlay) {
        setOverlayPoints(piggeryData.overlay.map((o: { x: number; y: number }) => `${o.x * imgWidth},${o.y * imgHeight}`).join(" "));
      }
    }
  }, [piggeryData, reset, imgWidth, imgHeight]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await updatePiggery({ id: piggeryId, name: data.name });
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (piggeryData && currentName !== piggeryData.name) {
      editContext?.setEditState(true);
    } else editContext?.setEditState(false);
  }, [piggeryData, currentName]);

  return typeof piggeryData !== "undefined" ? (
    <div className="w-full h-fit bg-ef-neutral-white py-40pxr px-50pxr border border-solid border-ef-neutral-150 rounded-[8px]">
      <PiggeryMoveInfoModal piggeryInfo={piggeryData} isToggle={toggleModal} setIsToggle={setToggleModal} />
      <p className="text-center ef-title-lg text-ef-neutral-900">{`${piggeryData?.name} ${t("common.details")}`}</p>
      <form onSubmit={handleSubmit(onSubmit)} className="outline-none">
        <div className="mt-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
          <div className="flex flex-row items-center">
            <label className="w-100pxr min-w-[100px] mr-12pxr flex flex-row">
              <span title={t("common.piggery_name")} className="line-clamp-1 break-all">
                {t("common.piggery_name")}
              </span>
              {getRedStar()}
            </label>
            <div className="w-512pxr relative" onClick={() => clearErrors("name")}>
              {errors.name ? <RequiredAlert /> : <></>}
              <Controller
                name="name"
                control={control}
                rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-100pxr min-w-[100px] line-clamp-1 mr-12pxr">{t("settings.floor")}</label>
            <div className="w-512pxr">
              <Controller name="floor" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
            </div>
          </div>
          <div className="flex flex-row">
            <label title={t("settings.piggery_location")} className="w-100pxr min-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis mr-12pxr mt-10pxr">
              {t("settings.piggery_location")}
            </label>
            <div className="w-512pxr p-16pxr rounded-[8px] border border-solid border-ef-neutral-150 flex flex-col">
              <div id="farm-image" className="w-480pxr h-270pxr relative">
                <img className="w-full h-full rounded-[8px]" src={`${current_farm.image_path}`} alt="" />
                <svg className="absolute top-0pxr left-0pxr w-full h-full">
                  <polygon points={overlayPoints} className="fill-ef-state-red-500 stroke-ef-state-red-500 stroke-[4px]" style={{ fillOpacity: 0.5 }} />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <label title={t("settings.piggery_blueprint")} className="w-100pxr min-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis mr-12pxr mt-10pxr">
              {t("settings.piggery_blueprint")}
            </label>
            <div className="w-512pxr p-16pxr rounded-[8px] border border-solid border-ef-neutral-150 flex flex-col">
              <div id="farm-image" className="w-480pxr h-270pxr relative">
                <img className="w-480pxr h-270pxr rounded-[8px]" src={`${piggeryData.image_path}`} alt="" />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label title={t("settings.enter_move_in_out_information")} className="w-100pxr min-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis mr-12pxr">
              {t("settings.enter_move_in_out_information")
                .split("\n")
                .map((line: string, index: number) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </label>
            <div>
              <button onClick={() => setToggleModal(true)} type="button" className="ef-btn-regular">
                <Modify />
                <span>{t("settings.enter_move_in_out_information_btn")}</span>
              </button>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mt-60pxr mx-auto">
            {t("common.save")}
          </button>
        </div>
      </form>
    </div>
  ) : (
    <></>
  );
}

export default PiggeryDetail;
