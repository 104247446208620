import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "assets/icons/report/ef-report-calendar.svg";
import "styles/datepicker.css";
import { isAfter, isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import { datePickerLocale } from "utils/useDatepicker";

type Props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  maxDate?: Date;
};

function LogRangePicker(props: Props) {
  const { startDate, setStartDate, endDate, setEndDate, maxDate = new Date() } = props;
  const [startOpenState, setStartOpenState] = useState(false);
  const [endOpenState, setEndOpenState] = useState(false);
  const startCalRef = useRef<HTMLDivElement>(null);
  const endCalRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (startCalRef.current && !startCalRef.current.contains(e.target as Node)) {
        setStartOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startCalRef]);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (endCalRef.current && !endCalRef.current.contains(e.target as Node)) {
        setEndOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endCalRef]);

  const handleStartDateChange = (date: Date) => {
    if (isAfter(date, endDate!)) setEndDate(date);
    setStartDate(date);
    setStartOpenState(false);
  };

  const handleEndDateChange = (date: Date) => {
    if (isBefore(date, startDate!)) setStartDate(date);
    setEndDate(date);
    setEndOpenState(false);
  };

  return (
    <div className="flex flex-row gap-4pxr items-center">
      <div
        onClick={() => setStartOpenState((prev) => !prev)}
        className={
          "group cursor-pointer flex w-200pxr h-56pxr rounded-[8px] border border-solid py-16pxr " +
          (startOpenState
            ? "bg-ef-primary-50 border-ef-primary-500"
            : "border-ef-neutral-150 bg-ef-neutral-white hover:ef-main-shadow hover:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:shadow-none disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150")
        }
        ref={startCalRef}
      >
        <div className="flex flex-row flex-wrap gap-4pxr mx-auto">
          <div className="w-91pxr" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              onInputClick={() => setStartOpenState((prev) => !prev)}
              open={startOpenState}
              locale={datePickerLocale()}
              selected={startDate}
              maxDate={maxDate}
              onChange={handleStartDateChange}
              className="outline-none cursor-pointer caret-transparent"
              dateFormat={"yyyy-MM-dd"}
              onKeyDown={(e: any) => {
                if (e.keyCode === 229) e.preventDefault();
                e.preventDefault();
              }}
            />
          </div>
          <div>
            <Calendar className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-300" />
          </div>
        </div>
      </div>
      <span className="ef-body-xxl text-ef-neutral-900">~</span>
      <div
        onClick={() => setEndOpenState((prev) => !prev)}
        className={
          "group cursor-pointer flex w-200pxr h-56pxr rounded-[8px] border border-solid py-16pxr " +
          (endOpenState
            ? "bg-ef-primary-50 border-ef-primary-500"
            : "border-ef-neutral-150 bg-ef-neutral-white hover:ef-main-shadow hover:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:shadow-none disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150")
        }
        ref={endCalRef}
      >
        <div className="flex flex-row flex-wrap gap-4pxr mx-auto">
          <div className="w-91pxr" onClick={(e) => e.stopPropagation()}>
            <DatePicker
              onInputClick={() => setEndOpenState((prev) => !prev)}
              open={endOpenState}
              locale={datePickerLocale()}
              selected={endDate}
              maxDate={maxDate}
              onChange={handleEndDateChange}
              className="outline-none cursor-pointer caret-transparent"
              dateFormat={"yyyy-MM-dd"}
              onKeyDown={(e: any) => {
                if (e.keyCode === 229) e.preventDefault();
                e.preventDefault();
              }}
            />
          </div>
          <div>
            <Calendar className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-300" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogRangePicker;
