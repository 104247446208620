import React, { useEffect, useRef, useState } from "react";
// library
import { Trans, useTranslation } from "react-i18next";
// query

// assets
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
// components
import PiggeryDetail from "components/pages/5_settings/farm/piggery/PiggeryDetail";
import RoomDetail from "components/pages/5_settings/farm/piggery/RoomDetail";
import { usePiggeryRoomList } from "query/farm";
import { PiggeryForStructure, Rooms } from "query/farm/type";

function Piggery() {
  const { t } = useTranslation();
  // 토글용 state
  const [toggledPiggery, setToggledPiggery] = useState(0);
  // 오른쪽 노출용 state
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState(0);

  const isFirstLoad = useRef(true);

  const { data: piggeryList } = usePiggeryRoomList();

  // 데이터 변경시 재로드, 그러나 초기 로드로 인식하지 않아서 0번째가 선택되는걸 방지
  useEffect(() => {
    if (piggeryList && isFirstLoad.current) {
      setToggledPiggery(piggeryList.piggeries[0].id);
      setSelectedPiggery(piggeryList.piggeries[0].id);
      isFirstLoad.current = false;
    }
  }, [piggeryList]);

  return (
    <div className="bg-ef-neutral-50 w-full h-full">
      <div className="pt-60pxr w-[1194px] mx-auto flex flex-col pb-200pxr">
        {/* title */}
        <p className="ef-headline-lg">{t("gnb.piggery_room_settings")}</p>
        <p className="mt-16pxr ef-body-lg text-ef-neutral-700">
          <Trans
            components={{
              1: <span onClick={() => window.open("https://intflow.ai/contact/", "_blank")} className="text-ef-primary-500 ef-label-lg underline underline-offset-4 cursor-pointer" />,
            }}
            i18nKey="settings.add_piggery_contact_comment"
          />
        </p>
        {/* title */}
        <div className="flex flex-row mt-40pxr flex-wrap gap-16pxr">
          {/* accordion */}
          <div className="flex flex-col flex-wrap gap-16pxr w-246pxr">
            {piggeryList?.piggeries.map((p: PiggeryForStructure) => (
              <div className="w-full" key={p.id}>
                <button
                  onClick={() => {
                    setSelectedRoom(0);
                    setSelectedPiggery(p.id);
                  }}
                  key={p.id}
                  className={
                    "w-full max-w-full h-56pxr p-16pxr rounded-[8px] flex flex-row items-center ef-label-lg border border-solid  " +
                    (selectedRoom === 0 && selectedPiggery === p.id
                      ? "stroke-ef-primary-500 border-ef-primary-500 bg-ef-primary-50 text-ef-primary-500"
                      : "stroke-ef-neutral-900 after:text-ef-neutral-900 border-ef-neutral-150 bg-ef-neutral-white disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:shadow-none disabled:text-ef-neutral-500 hover:ef-main-shadow hover:border-ef-primary-500 active:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500")
                  }
                >
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap breka-all">{`${p.floor}층 ${p.name}(${p.rooms?.length})`}</span>
                  <div
                    className="ml-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (toggledPiggery === p.id) {
                        setToggledPiggery(0);
                      } else {
                        setSelectedRoom(0);
                        setToggledPiggery(p.id);
                        setSelectedPiggery(p.id);
                      }
                    }}
                  >
                    {toggledPiggery === p.id ? <ArrowUp /> : <ArrowDown />}
                  </div>
                </button>
                {toggledPiggery === p.id && (
                  <div className="mt-4pxr w-full ef-label-lg ef-main-shadow bg-ef-neutral-white py-8pxr rounded-[8px] border border-solid border-ef-neutral-150">
                    {piggeryList.piggeries
                      .find((o: PiggeryForStructure) => o.id === toggledPiggery)
                      ?.rooms?.filter((j) => j.category?.id === 1)
                      .map((r: Rooms) => (
                        <button
                          className={
                            "w-full h-56pxr p-16pxr " +
                            (selectedRoom === r.id
                              ? "bg-ef-primary-50 text-ef-primary-500"
                              : "hover:bg-ef-primary-50 active:bg-ef-primary-50 text-ef-neutral-900 active:text-ef-neutral-600 focus:outline-ef-primary-500 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-500")
                          }
                          key={r.id}
                          onClick={() => {
                            setSelectedPiggery(p.id);
                            setSelectedRoom(r.id);
                          }}
                        >
                          <div className="w-full h-full text-left">
                            <span className="line-clamp-1 break-all">{r.name}</span>
                          </div>
                        </button>
                      ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* accordion */}
          {/* selected */}
          <div className="grow min-h-[950px]">
            {selectedRoom !== 0 ? (
              <RoomDetail roomId={selectedRoom} selectedPiggery={selectedPiggery} />
            ) : selectedRoom === 0 && selectedPiggery !== 0 ? (
              <PiggeryDetail piggeryId={selectedPiggery} />
            ) : (
              <></>
            )}
          </div>
          {/* selected */}
        </div>
      </div>
    </div>
  );
}

export default Piggery;
