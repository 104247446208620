import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// icons
import { ReactComponent as Error } from "assets/icons/general/ef-general-error.svg";
import { ReactComponent as Remove } from "assets/icons/general/ef-general-remove.svg";
import { ReactComponent as Close } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { ReactComponent as CCTV } from "assets/icons/general/ef-general-cctv.svg";
import { ReactComponent as Pig } from "assets/icons/general/ef-general-pig.svg";
import { ReactComponent as Weigher } from "assets/icons/general/ef-general-weigher.svg";
import { ReactComponent as Weight } from "assets/icons/general/ef-general-weight.svg";
import { ReactComponent as ReportDownLoad } from "assets/icons/report/ef-report-download.svg";
import { ReactComponent as Clock } from "assets/icons/general/ef-general-clock.svg";
//images
import noData from "assets/images/report/no_report_data.png";
import generateImg from "assets/images/report/generate_img.png";
// libs
import { SubmitHandler, useForm } from "react-hook-form";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { format, subDays } from "date-fns";
import { Scatter, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, CategoryScale } from "chart.js";
// utils
import { renderTextWithLineBreaks } from "../util/renderTextWithLineBreaks";
import { RatingDisplay } from "../util/ratingDisplay";
import { datas } from "../util/normalDistribution";
import { datePickerLocale } from "utils/useDatepicker";
// queries
import { MovementDetail } from "query/movement/type";
import { useDeleteMovement, useEditMemo, useMovementDetail } from "query/movement";
//modals & components
import GroupDetail from "components/pages/2_manage/stock/group/GroupDetail";
import DefaultTooltip from "components/common/alert/DefaultTooltip";
import ChangeWorkTimeModal from "../../modal/changeWorkTimeModal";
import ReportErrorModal from "../reportErrorModal";
import WeightListModal from "../../modal/weightListModal";
import ChangeStockModal from "../../modal/changeStockModal";
import ChangeAvgWeightModal from "../../modal/changeAvgWeightModal";
import EnterDayAgeModal from "../../modal/EnterDayAgeModal";
import ChangeCategoryModal from "../../modal/changeCategoryModal";
import ChangeMoveLocationModal from "../../modal/changeMoveLocationModal";
import WeightCorrectionModal from "../WeightCorrectionModal";
import StockCorrectionModal from "../StockCorrectionModal";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);
ChartJS.register(annotationPlugin);

interface Props {
  isWindow?: boolean;
  reportData: MovementDetail;
  isToggle?: boolean;
  setIsToggle?: React.Dispatch<React.SetStateAction<any>>;
}

type Inputs = {
  memo: string;
};

// 뉴동보고서
function NewDetailReport(props: Props) {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isToggle, setIsToggle, reportData, isWindow } = props;
  // const [goalWeight, setGoalWeight] = useState(1);
  // const { data: standardDetail, refetch } = useStandardDetail(goalWeight);

  const { data: detailReportData } = useMovementDetail(Number(reportData.id));
  // const { data: targetWeightList } = useTargetWeightList();
  const { mutateAsync: editMemo } = useEditMemo();
  const { mutateAsync: deleteReport } = useDeleteMovement();
  const [weightListOpen, setWeightListOpen] = useState(false);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isReportErrorModal, setIsReportErrorModal] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [memo, setMemo] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [rating, setRating] = useState(0);
  const [showWeightTooltip, setShowWeightTooltip] = useState(false);
  const [showWeightChangeTooltip, setShowWeightChangeTooltip] = useState(false);
  const [showStarTooltip, setShowStarTooltip] = useState(false);
  const [intervalCounts, setIntervalCounts] = useState<number[]>([]);
  const [intervalPercentages, setIntervalPercentages] = useState<number[]>([]);

  const [weightListModal, setWeightListModal] = useState(false);
  const [dayAgeModal, setDayAgeModal] = useState(false);
  const [weightCorrectModal, setWeightCorrectModal] = useState(false);
  const [stockCorrectModal, setStockCorrectModal] = useState(false);
  const [dayAgeModalIndex, setDayAgeModalIndex] = useState(0);
  const [addHerdModal, setAddHerdModal] = useState(false);
  const [workTimeModal, setWorkTimeModal] = useState(false);
  const [avgWeightModal, setAvgWeightModal] = useState(false);
  const [cntModal, setCntModal] = useState(false);
  const [wrateModal, setWrateModal] = useState(false);
  const [workTypeModal, setWorkTypeModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);

  // const weekAge = Math.round(reportData?.age / 7);
  // const weekAgeData = detailReportData?.standard_data.week === weekAge;
  // const weightData: { [key: string]: { weight: string; body_length: string } } = detailReportData?.unit_details ? detailReportData?.unit_details : [];
  const weightArray = Object.keys(detailReportData?.unit_details!).map((key: any) => detailReportData?.unit_details[key]);
  const rates = detailReportData?.unit_details ? Object.values(detailReportData?.unit_details)?.map((item) => item.body_rate) : [];

  //평균체장비
  const w_rates_average = rates.reduce((acc, rate) => acc + rate, 0) / rates.length;

  // 최소 체중
  const minValue = detailReportData?.min_weight;
  // 최대 체중
  const maxValue = detailReportData?.max_weight;
  // 체중 편차
  const result = Number(maxValue) - Number(minValue);

  // 체장비 구간 설정
  const intervals = [
    // grade_other_arr
    { min: -Infinity, max: 3.0 },
    // grade_2_arr
    { min: 3.0, max: 3.19 },
    // grade_1_arr
    { min: 3.2, max: 3.39 },
    // grade_1plus_arr
    { min: 3.4, max: 3.6 },
    // grade_1_arr
    { min: 3.61, max: 3.8 },
    // grade_2_arr
    { min: 3.81, max: 4.0 },
    // grade_other_arr
    { min: 4.1, max: Infinity },
  ];

  // 체장비분포 구하기
  function countRWrateInRange(dataArray: any[], min: number, max: number) {
    let count = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const weight = parseFloat(dataArray[i].body_rate);
      if (!isNaN(weight) && weight >= min && weight <= max) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    // 구간별 두수와 백분율을 저장할 배열 초기화
    const counts: number[] = [];
    const percentages: number[] = [];

    // 전체 데이터셋의 크기
    const totalWeights = weightArray.length;

    // 각 구간에 대해 두수와 백분율 계산
    intervals.forEach((interval) => {
      const count = countRWrateInRange(weightArray, interval.min, interval.max);
      counts.push(count);
      percentages.push((count / totalWeights) * 100);
    });

    // 결과를 state에 설정
    setIntervalCounts(counts);
    setIntervalPercentages(percentages);
  }, [detailReportData?.unit_details]);

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      memo: "",
    },
  });

  const onSubmit: SubmitHandler<{ memo: string }> = async () => {
    const formattedData = {
      id: reportData?.id,
      memo: memo,
    };

    if (reportData) {
      try {
        await editMemo({ id: formattedData.id, payload: { memo: memo } });
        toast.success(t("common.save_completed"));
        setIsEditing(false);
        setMemo("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  // 보고서 삭제
  const onDelete = async (id: number) => {
    if (window.confirm(t("manage.delete_report_comment"))) {
      try {
        await deleteReport(id);
        // searchParams.delete("report_num");
        // searchParams.delete("report_date");
        // setSearchParams(searchParams);
        toast.success(t("common.deletion_completed"));
        navigate("/manage/stock/movement");
        // setIsToggle(false);
      } catch (error) {}
      navigate("/manage/stock/movement");
    } else return;
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(event.target.value);
  };

  const getTimeLocale = (date: Date) => {
    const locale = localStorage.getItem("i18nextLng");
    switch (locale) {
      case "en":
        // return format(date, "HH:mm aa");
        return format(date, "HH:mm");
      case "ja":
      case "ko":
        return format(date, "HH:mm", { locale: datePickerLocale() });
      // return format(date, "aa HH:mm", { locale: datePickerLocale() });
    }
  };

  const getDateLocale = (date: Date) => {
    const locale = localStorage.getItem("i18nextLng");
    switch (locale) {
      case "en":
        // return format(date, "HH:mm aa");
        return format(date, "yyyy-MM-dd (EEE)");
      case "ja":
      case "ko":
        return format(date, "yyyy-MM-dd (EEE)", { locale: datePickerLocale() });
      // return format(date, "aa HH:mm", { locale: datePickerLocale() });
    }
  };

  const calculateDuration = () => {
    if (reportData) {
      const durationInMinutes = (new Date(reportData.ended_at).getTime() - new Date(reportData.started_at!).getTime()) / (1000 * 60);

      if (!isNaN(durationInMinutes)) {
        return `${t("manage.about_mins", { min: Math.round(durationInMinutes) })}`;
      }
    }

    return "";
  };

  const [gradeGroups, setGradeGroups] = useState<Record<string, { x: number; y: number }[]>>({});

  // 체중을 등급에 따라 분류하는 함수
  const categorizeWeights = () => {
    const groupedWeights: Record<string, { x: number; y: number }[]> = {};

    // 등급 배열 초기화
    // const weightGrades = standardDetail?.weight_grade || []; // 기본값 설정
    const weightGrades = [] as any; // 기본값 설정

    weightGrades.forEach((grade: any) => {
      groupedWeights[grade.grade] = [];
    });

    detailReportData?.unit_details?.forEach((unit) => {
      const weight = unit.weight;
      const bodyRate = unit.body_rate;

      detailReportData.weight_distribution?.forEach((grade) => {
        // if (groupedWeights[grade.name]) {
        //   groupedWeights[grade.name].push({ x: weight, y: bodyRate });
        // } else {
        //   groupedWeights[grade.name] = [{ x: weight, y: bodyRate }];
        // }
        if (weight >= grade.range.lower_weight && weight < grade.range.upper_weight) {
          if (groupedWeights[grade.name]) {
            groupedWeights[grade.name].push({ x: weight, y: bodyRate });
          } else {
            groupedWeights[grade.name] = [{ x: weight, y: bodyRate }];
          }
        }
      });
    });

    setGradeGroups(groupedWeights);
  };

  useEffect(() => {
    categorizeWeights();
  }, []);

  const lineChartData = {
    // labels,
    datasets: [
      {
        label: "",
        data: datas(detailReportData?.avg_weight, detailReportData?.stdev),
        backgroundColor: "rgba(255, 234, 233, 0.5)",
        borderColor: "#FF6861",
        borderWidth: 1,
        // fill: "-1",
      },
      // {
      //   label: "",
      //   data: datas(detailReportData?.past_avg_weight, detailReportData?.past_stdev),
      //   backgroundColor: "rgba(245, 159, 0, 0.5)",
      //   borderColor: "#F59F00",
      //   borderWidth: 1,
      // },
    ],
  };

  // 등급별 배열 생성 및 가공 (점타입 그래프용: x,y로 가공)
  // const grade_1plus_data = grade_1plus_arr.map((item) => ({ x: parseFloat(item.weight), y: parseFloat(item.body_rate) }));
  // const grade_1_data = grade_1_arr.map((item) => ({ x: parseFloat(item.weight), y: parseFloat(item.body_rate) }));
  // const grade_2_data = grade_2_arr.map((item) => ({ x: parseFloat(item.weight), y: parseFloat(item.body_rate) }));
  // const grade_other_data = grade_other_arr.map((item) => ({ x: parseFloat(item.weight), y: parseFloat(item.body_rate) }));

  const data = {
    datasets: [
      {
        label: "",
        data: [
          ...(gradeGroups["second"] ? gradeGroups["second"].map((item) => ({ x: item.x, y: item.y })) : []),
          ...(gradeGroups["lower_second"] ? gradeGroups["lower_second"].map((item) => ({ x: item.x, y: item.y })) : []),
          ...(gradeGroups["upper_second"] ? gradeGroups["upper_second"].map((item) => ({ x: item.x, y: item.y })) : []),
        ],
        backgroundColor: "rgba(245, 159, 0, 0.5)",
        borderColor: "#F59F00",
        borderWidth: 1,
      },
      {
        label: "",
        data: [
          ...(gradeGroups["first"] ? gradeGroups["first"].map((item) => ({ x: item.x, y: item.y })) : []),
          ...(gradeGroups["lower_first"] ? gradeGroups["lower_first"].map((item) => ({ x: item.x, y: item.y })) : []),
          ...(gradeGroups["upper_first"] ? gradeGroups["upper_first"].map((item) => ({ x: item.x, y: item.y })) : []),
        ],
        backgroundColor: "rgba(28, 126, 214, 0.5)",
        borderColor: "#1C7ED6",
        borderWidth: 1,
      },
      {
        label: "",
        data: gradeGroups["first_plus"] ? gradeGroups["first_plus"].map((item) => ({ x: item.x, y: item.y })) : [],
        backgroundColor: "rgba(55, 178, 77, 0.5)",
        borderColor: "#37B24D",
        borderWidth: 1,
      },
      {
        label: "",
        data: [
          ...(gradeGroups["lower_outlier"] ? gradeGroups["lower_outlier"].map((item) => ({ x: item.x, y: item.y })) : []),
          ...(gradeGroups["upper_outlier"] ? gradeGroups["upper_outlier"].map((item) => ({ x: item.x, y: item.y })) : []),
        ],
        backgroundColor: "rgba(179, 179, 179, 0.5)",
        borderColor: "#B3B3B3",
        borderWidth: 1,
      },
    ],
  };

  const annotations = [
    {
      type: "box",
      xScaleID: "x",
      yScaleID: "y",
      xMin: detailReportData?.weight_distribution?.find((grade) => grade.name === "first_plus")?.range.lower_weight || 0,
      xMax: detailReportData?.weight_distribution?.find((grade) => grade.name === "first_plus")?.range.upper_weight || 0,
      // yMin: 3.4,
      // yMax: 3.8,
      backgroundColor: "rgba(227, 244, 230, 0.2)",
      borderWidth: 1,
      borderColor: "#37B24D",
    },
    {
      type: "box",
      xScaleID: "x",
      yScaleID: "y",
      xMin: detailReportData?.weight_distribution?.find((grade) => grade.name === "lower_first")?.range.lower_weight || 0,
      xMax: detailReportData?.weight_distribution?.find((grade) => grade.name === "upper_first")?.range.upper_weight || 0,
      // yMin: 4.0,
      // yMax: 3.2,
      backgroundColor: "rgba(223, 237, 249, 0.2)", // 투명도 조절
      borderWidth: 1,
      borderColor: "#1C7ED6",
    },
    {
      type: "box",
      xScaleID: "x",
      yScaleID: "y",
      xMin: detailReportData?.weight_distribution?.find((grade) => grade.name === "lower_second")?.range.lower_weight || 0,
      xMax: detailReportData?.weight_distribution?.find((grade) => grade.name === "upper_second")?.range.upper_weight || 0,
      // yMin: 4.2,
      // yMax: 2.8,
      backgroundColor: "rgba(253, 234, 221, 0.2)",
      borderWidth: 1,
      borderColor: "#F76707",
    },
    // 등외
    // {
    //   type: "box",
    //   xScaleID: "x",
    //   yScaleID: "y",
    //   xMin: Number(detailReportData?.base_weight) && Number(detailReportData?.base_weight),
    //   xMax: Number(detailReportData?.base_weight) && Number(detailReportData?.base_weight),
    //   yMin: Math.min(...grade_other_data.map((v) => v.y)),
    //   yMax: Math.max(...grade_other_data.map((v) => v.y)),
    //   backgroundColor: "rgba(255, 0, 0, 0)",
    //   borderWidth: 1,
    //   borderColor: "#C9C9C9",
    // },
  ];

  useEffect(() => {
    // 1등급 비율 계산
    const grade_1_ratio = ((Number(detailReportData?.weight_distribution[1].stock!) + Number(detailReportData?.weight_distribution[2].stock!)) / weightArray.length) * 100;

    // 1+등급 비율 계산
    const grade_1plus_ratio = (detailReportData?.weight_distribution[0].stock! / weightArray?.length) * 100;

    console.log(grade_1_ratio, grade_1plus_ratio);
    // 별점 산정 기준에 따라 등급 부여
    if (grade_1_ratio >= 80 && grade_1plus_ratio >= 30) {
      setRating(5);
    } else if (grade_1_ratio >= 80) {
      setRating(4);
    } else if (grade_1_ratio >= 60 && grade_1_ratio < 80) {
      setRating(3);
    } else if (grade_1_ratio >= 40 && grade_1_ratio < 60) {
      setRating(2);
    } else {
      setRating(1);
    }
  }, [rating]);

  useEffect(() => {
    if (detailReportData) {
      setMemo(detailReportData.memo);
    } else {
      setMemo("");
    }
    setVideoError(false);
  }, [detailReportData]);

  const handleDownloadVideo = () => {
    if (detailReportData) {
      const link = document.createElement("a");
      link.href = detailReportData.video_path;
      link.setAttribute("download", `${detailReportData.id}.mp4`); // 다운로드할 파일명
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else return;
  };

  return (
    <>
      <ReportErrorModal isToggle={isReportErrorModal} setIsToggle={setIsReportErrorModal} movementId={detailReportData?.id} setDetailReportToggle={setIsToggle!} />
      {detailReportData && <WeightListModal isToggle={weightListModal} setIsToggle={setWeightListModal} detailReportData={detailReportData} />}
      {detailReportData && <GroupDetail isToggle={isGroupModal} setIsToggle={setIsGroupModal} groupId={detailReportData?.herd ? detailReportData?.herd.id : 0} isEditable={false} />}
      <WeightCorrectionModal
        movement_id={detailReportData ? detailReportData.id : 0}
        stock={detailReportData ? detailReportData.stock : 0}
        currentWeight={detailReportData ? detailReportData.avg_weight : 0}
        isToggle={weightCorrectModal}
        setIsToggle={setWeightCorrectModal}
      />
      <StockCorrectionModal
        currentStock={detailReportData ? detailReportData.stock : 0}
        movementId={detailReportData ? detailReportData.id : 0}
        isToggle={stockCorrectModal}
        setIsToggle={setStockCorrectModal}
      />
      <div ref={divRef} className="w-full h-[3600px] flex flex-col">
        <div className="flex flex-col items-center w-full">
          <div className="flex w-full items-center pt-24pxr">
            <div className="flex flex-row">
              <button disabled={detailReportData?.complaint !== null} onClick={() => setIsReportErrorModal(true)} className="ef-btn-r8-sm bg-ef-neutral-white mr-8pxr flex !px-16pxr !py-12pxr">
                <Error className="" />
                <span className="ml-8pxr ef-label-md">{t("manage.report_an_error")}</span>
              </button>
              <button
                className="ef-btn-r8-sm bg-ef-neutral-white mr-8pxr flex !px-16pxr !py-12pxr"
                onClick={async () => {
                  if (divRef.current) {
                    const padding = 20; // 패딩 크기
                    const widthWithPadding = divRef.current.clientWidth + 2 * padding;
                    const heightWithPadding = divRef.current.clientHeight + 2 * padding;

                    const canvas = await html2canvas(divRef.current, {
                      width: widthWithPadding,
                      height: heightWithPadding,
                      x: -padding,
                      y: -padding,
                    });

                    const img = canvas.toDataURL("image/png");

                    const pdf = new jsPDF("portrait", "mm", "a4");
                    const pageWidth = pdf.internal.pageSize.getWidth();
                    const pageHeight = pdf.internal.pageSize.getHeight();

                    const widthRatio = pageWidth / canvas.width;
                    const customHeight = canvas.height * widthRatio;

                    pdf.addImage(img, "png", 0, 0, pageWidth, customHeight);
                    let heightLeft = customHeight;

                    while (heightLeft >= pageHeight) {
                      pdf.addPage();
                      pdf.addImage(img, "png", 0, -pageHeight, pageWidth, customHeight);
                      heightLeft -= pageHeight;
                    }

                    pdf.save(`${format(new Date(reportData.started_at!), "yyyy-MM-dd")}${t("gnb.movement_record")}.pdf`);
                  }
                }}
              >
                <ReportDownLoad className="" />
                <span className="ml-8pxr ef-label-md">{t("manage.download_pdf")}</span>
              </button>

              <button className="ef-btn-r8-sm bg-ef-neutral-white flex mr-16pxr" onClick={() => onDelete(Number(detailReportData?.id))}>
                <Remove className="" />
                <span className="ml-8pxr ef-label-md">{t("common.delete")}</span>
              </button>
            </div>
            <span className="ef-title-lg flex ml-170pxr mr-auto whitespace-nowrap">{t("manage.report_details")}</span>

            {isWindow ? (
              ""
            ) : (
              <button
                onClick={() => {
                  // setDetailData({ id: 0, name: "", work: "", cnt: 0, move: "", date: "", time: "", weight: "", allWeight: "", memo: "" });
                  if (setIsToggle) {
                    setIsToggle(false);
                  }
                  // searchParams.delete("report_num");
                  // searchParams.delete("report_date");
                  // setSearchParams(searchParams);
                }}
                className="flex ef-btn-r8-sm bg-ef-neutral-white !px-16pxr !py-12pxr"
              >
                <Close className="stroke-ef-neutral-900 mr-8pxr" />
                <span className="ef-label-md">{t("manage.close_report")}</span>
              </button>
            )}
          </div>
        </div>

        <main className="rounded-[8px] bg-ef-primary-50 my-68pxr py-24pxr px-40pxr">
          <div className="flex items-center justify-between mb-24pxr">
            <div className="flex flex-row w-full items-center">
              <span className="text-ef-neutral-700 ef-label-md flex items-center mr-16pxr relative">
                {t("manage.standard_data")}
                <Info onMouseEnter={() => setShowWeightTooltip(true)} onMouseLeave={() => setShowWeightTooltip(false)} className="stroke-ef-neutral-700 ml-4pxr hover:cursor-pointer" />
                {showWeightTooltip && <DefaultTooltip text={t("manage.report_provide_by_target_weight_comment")} />}
              </span>
              <div className="rounded-[8px] flex flex-row items-center gap-8pxr bg-ef-neutral-white border-ef-neutral-900 border border-solid px-12pxr py-8pxr ml-auto text-ef-neutral-900">
                <div className="flex flex-row gap-4pxr items-center">
                  <CCTV className="stroke-ef-neutral-900" />
                  <span className="ef-body-lg">{t("manage.camera_information")}</span>
                </div>
                <div className="w-1pxr h-11pxr bg-ef-neutral-200"></div>
                <span className="ef-body-xl">{detailReportData?.camera === null ? t("common.no_info") : detailReportData?.camera.name}</span>
              </div>
            </div>

            {/* <span className="text-ef-neutral-700 ef-body-lg">
              <span className="ef-body-lg flex flex-row gap-4pxr">
                <span>{format(new Date(reportData?.started_at), "yyyy-MM-dd")}</span>
                <span>
                  {getTimeLocale(new Date(reportData?.started_at))} ~ {getTimeLocale(new Date(reportData?.ended_at))}
                </span>
                <span>{calculateDuration()}</span>
              </span>
            </span> */}
          </div>

          <div className="flex flex-col justify-center items-center mb-40pxr">
            <span className="text-ef-neutral-700 ef-label-md flex items-center relative">
              {t("manage.report_score")}
              <Info onMouseEnter={() => setShowStarTooltip(true)} onMouseLeave={() => setShowStarTooltip(false)} className="stroke-ef-neutral-700 ml-4pxr hover:cursor-pointer" />
              {showStarTooltip && (
                <div
                  style={{
                    boxShadow: "6px 6px 15px 0px rgba(14, 14, 74, 0.08)",
                  }}
                  className="absolute z-[999] pointer-events-none top-[-2px] left-[90px] flex flex-col w-312pxr h-304pxr border rounded-[8px] bg-ef-neutral-white border-ef-neutral-150"
                >
                  <div className="w-full h-full flex flex-col gap-16pxr p-16pxr pt-24pxr rounded-[8px] text-ef-neutral-900">
                    <p className="ef-title-md text-center">{t("manage.star_rating_criteria")}</p>
                    <div className="w-full h-full bg-ef-primary-50 rounded-[8px] flex flex-col ef-body-lg items-center justify-center gap-8pxr">
                      <div className="flex flex-row gap-16pxr">
                        <span>{t("manage.5_points")}</span>
                        <span className="w-110pxr break-all text-ef-primary-500">{t("manage.5_points_standard")}</span>
                      </div>
                      <div className="flex flex-row gap-16pxr">
                        <span>{t("manage.4_points")}</span>
                        <span className="w-110pxr break-all text-ef-primary-500">{t("manage.4_points_standard")}</span>
                      </div>
                      <div className="flex flex-row gap-16pxr">
                        <span>{t("manage.3_points")}</span>
                        <span className="w-110pxr break-all text-ef-primary-500">{t("manage.3_points_standard")}</span>
                      </div>
                      <div className="flex flex-row gap-16pxr">
                        <span>{t("manage.2_points")}</span>
                        <span className="w-110pxr break-all text-ef-primary-500">{t("manage.2_points_standard")}</span>
                      </div>
                      <div className="flex flex-row gap-16pxr">
                        <span>{t("manage.1_point")}</span>
                        <span className="w-110pxr break-all text-ef-primary-500">{t("manage.1_point_standard")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </span>
            {/* <span className="text-[40px] font-semibold text-ef-neutral-900">{detailReportData?.rating! + ".0"}</span> */}
            <span className="flex flex-row mb-16pxr">{RatingDisplay(detailReportData?.rating!)}</span>
            <div className="flex flex-row items-center gap-8pxr mb-12pxr">
              <span className="flex flex-row items-center">
                <span className="w-[8px] h-[8px] rounded-full bg-ef-state-green-500 mr-4pxr"></span> {t("manage.grade_1+")}
                <span className="ef-body-xl ml-4pxr">
                  {Number(detailReportData?.weight_distribution[0].stock!)}
                  {t("common.head")}
                </span>
              </span>
              <span className="border border-solid h-12pxr bg-ef-neutral-200"></span>
              <span className="flex flex-row items-center">
                <span className="w-[8px] h-[8px] rounded-full bg-ef-state-blue-500 mr-4pxr"></span> {t("manage.grade_1")}
                <span className="ef-body-xl ml-4pxr">
                  {Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!)}
                  {t("common.head")}
                </span>
              </span>
              <span className="border border-solid h-12pxr bg-ef-neutral-200"></span>
              <span className="flex flex-row items-center">
                <span className="w-[8px] h-[8px] rounded-full bg-ef-state-orange-500 mr-4pxr"></span>
                {t("manage.grade_2")}
                <span className="ef-body-xl ml-4pxr">
                  {Number(detailReportData?.weight_distribution[3].stock! + detailReportData?.weight_distribution[4].stock!)}
                  {t("common.head")}
                </span>
              </span>
              <span className="border border-solid h-12pxr bg-ef-neutral-200"></span>
              <span className="flex flex-row items-center">
                <span className="w-[8px] h-[8px] rounded-full bg-ef-neutral-500 mr-4pxr"></span>
                {t("manage.etc")}
                <span className="ef-body-xl ml-4pxr">
                  {Number(detailReportData?.weight_distribution[5].stock! + detailReportData?.weight_distribution[6].stock!)}
                  {t("common.head")}
                </span>
              </span>
            </div>
            <div className="flex w-[450px] bg-ef-neutral-50 rounded-full h-20pxr p-4pxr border border-solid border-ef-neutral-150">
              <div
                className="bg-ef-state-green-500 rounded-l-full"
                style={{
                  width: `${
                    isNaN((Number(detailReportData?.weight_distribution[0].stock!) / weightArray.length) * 100)
                      ? 0
                      : (Number(detailReportData?.weight_distribution[0].stock!) / weightArray.length) * 100
                  }%`,
                }}
              ></div>
              <div
                className={"bg-ef-state-blue-500" + (!Number(detailReportData?.weight_distribution[0].stock!) ? " rounded-l-full" : "")}
                style={{
                  width: `${
                    isNaN((Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!) / weightArray.length) * 100)
                      ? 0
                      : (Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!) / weightArray.length) * 100
                  }%`,
                }}
              ></div>
              <div
                className={
                  "bg-ef-state-orange-500" +
                  (!Number(detailReportData?.weight_distribution[0].stock!) && !Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!)
                    ? " rounded-l-full"
                    : "")
                }
                style={{
                  width: `${
                    isNaN((Number(detailReportData?.weight_distribution[3].stock! + detailReportData?.weight_distribution[4].stock!) / weightArray.length) * 100)
                      ? 0
                      : (Number(detailReportData?.weight_distribution[3].stock! + detailReportData?.weight_distribution[4].stock!) / weightArray.length) * 100
                  }%`,
                }}
              ></div>
              <div
                className={
                  "bg-ef-neutral-500" +
                  (!Number(detailReportData?.weight_distribution[0].stock!) &&
                  !Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!) &&
                  !Number(detailReportData?.weight_distribution[3].stock! + detailReportData?.weight_distribution[4].stock!)
                    ? " rounded-full"
                    : " rounded-r-full")
                }
                style={{
                  width: `${
                    isNaN((Number(detailReportData?.weight_distribution[5].stock!) / weightArray.length) * 100)
                      ? 0
                      : (Number(detailReportData?.weight_distribution[5].stock! + detailReportData?.weight_distribution[6].stock!) / weightArray.length) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <div className="flex justify-center">
            <table className="mt-40pxr border-collapse border border-solid border-ef-neutral-200">
              <thead>
                <tr className="[&>*]:!font-semibold ef-title-sm text-left [&>*]:py-14pxr [&>*]:px-12pxr bg-ef-neutral-50 [&>*]:border [&>*]:border-solid [&>*]:border-ef-neutral-200">
                  <th className="w-150pxr">{t("common.work_datetime")}</th>
                  <th className="w-150pxr">{t("common.moved_stock")}</th>
                  <th className="w-230pxr">{t("manage.average_weight_total_weight")}</th>
                  <th className="w-173pxr">{t("manage.weight_list")}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="ef-body-lg text-left bg-ef-neutral-white [&>*]:py-14pxr [&>*]:px-12pxr [&>*]:border [&>*]:border-solid [&>*]:border-ef-neutral-200">
                  <td>
                    {detailReportData && detailReportData.started_at !== null
                      ? format(new Date(detailReportData?.started_at!), "yyyy-MM-dd(eee)", { locale: datePickerLocale() })
                      : t("common.no_info")}
                    <br />
                    {detailReportData && detailReportData.started_at !== null ? format(new Date(detailReportData.started_at), "HH:mm") : t("common.no_info")}
                    {` ~ `}
                    {detailReportData && detailReportData.ended_at !== null ? format(new Date(detailReportData.ended_at), "HH:mm") : t("common.no_info")}
                  </td>
                  <td>
                    {detailReportData && detailReportData.stock !== null ? (
                      <>
                        {`${detailReportData.stock.toLocaleString()}${t("common.head")}`}
                        <br />
                        <span onClick={() => setStockCorrectModal(true)} className="ef-label-lg text-ef-secondary-500 underline underline-offset-4 cursor-pointer">
                          {t("manage.correction")}
                        </span>
                      </>
                    ) : (
                      t("common.no_info")
                    )}
                  </td>
                  <td>
                    {detailReportData && detailReportData.avg_weight !== null && detailReportData.stock ? (
                      <>
                        {`${detailReportData.avg_weight.toFixed(2)}kg(${t("common.total")} ${(detailReportData.stock * detailReportData.avg_weight).toFixed(2)}kg)`}
                        <br />
                        <span onClick={() => setWeightCorrectModal(true)} className="ef-label-lg text-ef-secondary-500 underline underline-offset-4 cursor-pointer">
                          {t("manage.correction")}
                        </span>
                      </>
                    ) : (
                      t("common.no_info")
                    )}
                  </td>
                  <td onClick={() => setWeightListModal(true)} className="ef-label-lg text-ef-primary-500 underline underline-offset-4 cursor-pointer">
                    {t("manage.view_list")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>

        <div className="">
          <div className="flex justify-between items-center mb-16pxr">
            <span className="ef-title-md text-ef-neutral-900">{t("manage.movement_information")}</span>
            {/* <button className="ef-btn-r8-sm flex flex-row" disabled>
              <Modify className=" mr-8pxr" />
              수정하기
            </button> */}
          </div>
          <table className="mb-40pxr">
            <thead className="flex text-ef-neutral-900 bg-ef-neutral-50">
              <tr className="flex">
                {/* <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-180pxr max-w-[180px]">{t("common.group_name")}</th> */}
                <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-150pxr max-w-[150px]">{t("common.work_type")}</th>
                <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-150pxr max-w-[150px]">{t("common.birth_date_aged_day")}</th>
                <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-230pxr max-w-[230px]">{t("common.moved_location")}</th>
                <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-173pxr max-w-[173px]">{t("common.goal_weight")}</th>
                <th className="flex justify-start ef-title-sm border border-solid border-r-transparent px-12pxr py-14pxr w-253pxr max-w-[253px]">{t("common.weight_deviation")}</th>
                <th className="flex justify-start ef-title-sm border border-solid px-12pxr py-14pxr w-150pxr max-w-[150px]">{t("manage.average_body_rate")}</th>
              </tr>
            </thead>
            <tbody className="flex flex-col justify-between ">
              <tr className="flex">
                {/* <td
                  onClick={() => {
                    if (detailReportData?.herd) {
                      setIsGroupModal(true);
                    } else {
                      setAddHerdModal(true);
                    }
                  }}
                  className="flex justify-start hover:cursor-pointer text-ef-primary-500 underline underline-offset-2  ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-180pxr max-w-[180px]"
                >
                  {detailReportData?.herd ? detailReportData?.herd?.name : t("status.herd_not_registered")}
                </td> */}
                <td
                  onClick={() => setWorkTypeModal(true)}
                  className={
                    "flex ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-150pxr max-w-[150px] " +
                    (detailReportData?.category.id ? (detailReportData.category.id === 5 ? "justify-center" : "justify-start hover:cursor-pointer text-ef-primary-500") : "text-ef-neutral-900")
                  }

                  // className="flex justify-start ef-body-lg hover:cursor-pointer text-ef-primary-500 underline underline-offset-2 border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-150pxr max-w-[150px]"
                >
                  {detailReportData?.category.id ? (
                    detailReportData?.category.id === 5 ? (
                      <button className="ef-btn-32-normal !h-32pxr ">{t("manage.select_work_type")}</button>
                    ) : (
                      <span className=" underline underline-offset-2 ">{t(`common.movement_category_${detailReportData?.category.id}`)}</span>
                    )
                  ) : (
                    t("common.no_info")
                  )}
                </td>
                {workTypeModal && detailReportData && <ChangeCategoryModal defaultData={detailReportData} isToggle={workTypeModal} setIsToggle={setWorkTypeModal} />}
                <td
                  onClick={() => setDayAgeModal(true)}
                  className={
                    "flex justify-start ef-body-lg hover:cursor-pointer border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-150pxr max-w-[150px]" +
                    (detailReportData?.age ? " text-ef-primary-500 underline underline-offset-2" : "text-ef-neutral-500")
                  }
                >
                  {detailReportData?.age ? (
                    <>
                      {detailReportData?.age ? format(subDays(detailReportData?.started_at!, detailReportData?.age), "yyyy-MM-dd") : ""}
                      <br />({detailReportData?.age && detailReportData?.age + t("common.day_age")})
                    </>
                  ) : (
                    t("common.no_info")
                  )}
                </td>
                {dayAgeModal && (
                  <EnterDayAgeModal
                    isToggle={dayAgeModal}
                    age={detailReportData?.age!}
                    targetDate={new Date(detailReportData?.started_at!)}
                    setIsToggle={setDayAgeModal}
                    defaultData={detailReportData!}
                  />
                )}
                <td
                  onClick={() => setLocationModal(true)}
                  className={
                    "flex justify-start ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-230pxr max-w-[230px] hover:cursor-pointer underline underline-offset-2 text-ef-primary-500"
                  }
                >
                  {`[${detailReportData?.arrival?.name ? detailReportData?.arrival?.name : t("common.no_info")}]  -> [${
                    detailReportData?.departure?.name ? detailReportData?.departure?.name : t("common.no_info")
                  }] `}
                </td>{" "}
                {locationModal && <ChangeMoveLocationModal isToggle={locationModal} setIsToggle={setLocationModal} defaultData={detailReportData!} />}
                <td className="flex justify-start ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-173pxr max-w-[173px]">
                  {detailReportData?.standard_data?.lower_weight && detailReportData?.standard_data?.upper_weight
                    ? detailReportData?.standard_data?.lower_weight + "kg ~ " + detailReportData?.standard_data?.upper_weight + "kg"
                    : t("common.no_info")}
                </td>
                <td className="flex justify-start ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-253pxr max-w-[253px]">
                  {
                    <div className="flex flex-col">
                      <span className="ef-body-lg mb-4pxr">{result === 0 ? t("common.no_info") : result?.toFixed(2) + "kg"}</span>
                      <div className="flex flex-row ">
                        <span className="ef-body-xs mr-16pxr">
                          <span className="rounded-[4px] bg-ef-state-red-500 py-2pxr px-4pxr text-ef-neutral-white mr-4pxr">{t("common.max")}</span>
                          {detailReportData?.max_weight === 0 ? "0kg" : detailReportData?.max_weight?.toFixed(2) + "kg"}
                        </span>

                        <span className="ef-body-xs">
                          <span className="rounded-[4px] bg-ef-state-blue-500 py-2pxr px-4pxr text-ef-neutral-white mr-4pxr">{t("common.min")}</span>
                          {detailReportData?.min_weight === 0 ? "0kg" : detailReportData?.min_weight?.toFixed(2) + "kg"}
                        </span>
                      </div>
                    </div>
                  }
                </td>
                <td
                  className="flex justify-start ef-body-lg text-ef-primary-900 border border-solid border-t-transparent px-12pxr py-16pxr w-150pxr max-w-[150px]"
                  // className="flex justify-start ef-body-lg hover:cursor-pointer text-ef-primary-500 underline underline-offset-2 border border-solid border-r-transparent border-t-transparent px-12pxr py-16pxr w-150pxr max-w-[150px]"
                >
                  {w_rates_average ? w_rates_average?.toFixed(2) : t("common.no_info")}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="flex flex-row mb-40pxr">
            <div className="ef-card-movementReport-96 !shadow-none hover:!border-[#DEDEDE] !h-368pxr w-545pxr !p-24pxr mr-16pxr">
              <div className="w-full flex flex-row justify-between">
                <span className="ef-title-md">{t("manage.weight_body_rate_distribution_map")}</span>
                <div className="flex items-center gap-8pxr">
                  <span className="ef-body-xs text-ef-neutral-700">{t("manage.x_axis_weight_kg")}</span>
                  <div className="flex h-8pxr w-1pxr bg-ef-neutral-700"></div>
                  <span className="ef-body-xs text-ef-neutral-700">{t("manage.y_axis_body_rate")}</span>
                </div>
              </div>
              <div className="w-full flex flex-row">
                <div style={{ width: "334px", height: "280px" }}>
                  <div className="flex min-h-full w-full" style={{ height: "100%" }}>
                    <Scatter
                      data={data}
                      height={"100%"}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        // scales: {
                        //   x: {
                        //     min: generateWeightRangeByPercentage(Number(detailReportData?.base_weight), Number(detailReportData?.grade_2))[0],
                        //     max: generateWeightRangeByPercentage(Number(detailReportData?.base_weight), Number(detailReportData?.grade_2))[1],
                        //   },
                        // },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          // datalabels: {
                          //   display: false,
                          // },
                          annotation: {
                            // @ts-ignore
                            annotations: annotations,
                          },
                        },
                        elements: {
                          point: {
                            radius: 2,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4pxr p-24pxr w-full">
                  <div className="flex flex-col gap-4pxr ">
                    <div className="flex flex-row gap-8pxr items-center ">
                      <span className="h-8pxr w-8pxr rounded-full bg-ef-state-green-500"></span>
                      <span className="ef-body-xl">{t("manage.grade_1+")}</span>
                    </div>
                    <div className="flex flex-row w-full gap-8pxr items-center ">
                      <span className="ef-body-lg">{detailReportData?.weight_distribution[0].rate}%</span> <span className="h-8pxr w-1pxr bg-ef-neutral-200"></span>{" "}
                      <span className="ef-body-lg flex">
                        {Number(detailReportData?.weight_distribution[0].stock!)}
                        {t("common.head")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4pxr ">
                    <div className="flex flex-row gap-8pxr items-center ">
                      <span className="h-8pxr w-8pxr rounded-full bg-ef-state-blue-500"></span>
                      <span className="ef-body-xl">{t("manage.grade_1")}</span>
                    </div>
                    <div className="flex flex-row w-full gap-8pxr items-center ">
                      <span className="ef-body-lg">{detailReportData?.weight_distribution[1].rate! + detailReportData?.weight_distribution[2].rate!}%</span>{" "}
                      <span className="h-8pxr w-1pxr bg-ef-neutral-200"></span>{" "}
                      <span className="ef-body-lg flex">
                        {Number(detailReportData?.weight_distribution[1].stock! + detailReportData?.weight_distribution[2].stock!)}
                        {t("common.head")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4pxr ">
                    <div className="flex flex-row gap-8pxr items-center ">
                      <span className="h-8pxr w-8pxr rounded-full bg-ef-state-orange-500"></span>
                      <span className="ef-body-xl">{t("manage.grade_2")}</span>
                    </div>
                    <div className="flex flex-row w-full gap-8pxr items-center ">
                      <span className="ef-body-lg">{Number(detailReportData?.weight_distribution[3].rate! + detailReportData?.weight_distribution[4].rate!).toFixed(2)}%</span>{" "}
                      <span className="h-8pxr w-1pxr bg-ef-neutral-200"></span>{" "}
                      <span className="ef-body-lg flex">
                        {Number(detailReportData?.weight_distribution[3].stock! + detailReportData?.weight_distribution[4].stock!)}
                        {t("common.head")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4pxr ">
                    <div className="flex flex-row gap-8pxr items-center ">
                      <span className="h-8pxr w-8pxr rounded-full bg-ef-neutral-500"></span>
                      <span className="ef-body-xl">{t("manage.etc")}</span>
                    </div>
                    <div className="flex flex-row w-full gap-8pxr items-center ">
                      <span className="ef-body-lg">{detailReportData?.weight_distribution[5].rate! + detailReportData?.weight_distribution[6].rate!}%</span>{" "}
                      <span className="h-8pxr w-1pxr bg-ef-neutral-200"></span>{" "}
                      <span className="ef-body-lg flex">
                        {detailReportData?.weight_distribution[5].stock! + detailReportData?.weight_distribution[6].stock!}
                        {t("common.head")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ef-card-movementReport-96 !shadow-none hover:!border-[#DEDEDE] !h-368pxr w-545pxr !p-24pxr">
              <div className="flex flex-row justify-between mb-24pxr">
                <span className="ef-title-md flex flex-row items-center relative">
                  {t("manage.changes_in_weight_distribution")}
                  <Info onMouseEnter={() => setShowWeightChangeTooltip(true)} onMouseLeave={() => setShowWeightChangeTooltip(false)} className="stroke-ef-neutral-500 ml-4pxr hover:cursor-pointer" />
                  {showWeightChangeTooltip && <DefaultTooltip text={t("manage.weight_variation_comment")} />}
                </span>

                <div className="flex flex-row justify-center items-center gap-8pxr">
                  <div className="rounded-full border border-solid border-ef-secondary-500 bg-ef-secondary-100 w-16pxr h-16pxr"></div>
                  <div className="flex justify-center items-center gap-8pxr">
                    <span className="text-ef-secondary-500 ef-label-sm">
                      {reportData.age}
                      {t("common.day_age")}
                    </span>
                    <div className="h-8pxr w-1pxr bg-ef-neutral-200"></div>
                    <span className="ef-label-sm">{format(subDays(detailReportData?.started_at!, detailReportData?.age!), "yyyy-MM-dd")}</span>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <span className="ef-body-xs text-ef-neutral-700">{t("manage.x_axis_weight_kg")}</span>
              </div>
              <div>
                <Line
                  data={lineChartData}
                  options={{
                    scales: {
                      x: {
                        type: "linear",
                        position: "bottom",
                        // title: {
                        //   display: true,
                        //   text: "weight",
                        // },
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        type: "linear",
                        position: "left",
                        display: false,
                        grid: {
                          display: false,
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      // datalabels: {
                      //   display: false,
                      // },
                      // annotation: {
                      //   // @ts-ignore
                      //   annotations: annotations,
                      // },
                    },

                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center mb-16pxr">
            <span className="ef-title-md text-ef-neutral-900">{t("manage.weight_distribution")}</span>
          </div>
          <table className="mb-40pxr">
            <thead className="flex text-ef-neutral-900 bg-ef-neutral-50">
              <tr className="flex">
                <th className="flex items-center justify-start ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-116pxr max-w-[116px]">{t("common.weight")}(kg)</th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  ~{detailReportData?.weight_distribution[5].range.upper_weight}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {`${detailReportData?.weight_distribution[3].range.lower_weight}~${detailReportData?.weight_distribution[3].range.upper_weight}`}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">
                  {`${detailReportData?.weight_distribution[1].range.lower_weight}~${detailReportData?.weight_distribution[1].range.upper_weight}`}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">
                  {`${detailReportData?.weight_distribution[0].range.lower_weight}~${detailReportData?.weight_distribution[0].range.upper_weight}`}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">
                  {`${detailReportData?.weight_distribution[2].range.lower_weight}~${detailReportData?.weight_distribution[2].range.upper_weight}`}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {`${detailReportData?.weight_distribution[4].range.lower_weight}~${detailReportData?.weight_distribution[4].range.upper_weight}`}
                </th>
                <th className="flex items-center justify-center ef-body-xl border border-solid px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {" "}
                  {`${detailReportData?.weight_distribution[6].range.lower_weight}~`}
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col justify-between ">
              <tr className="flex">
                <th className="flex items-center justify-start ef-body-xl border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">
                  {t("manage.grade_criteria")}
                </th>
                <th className="flex items-center justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {t("manage.below_grade_2")}
                </th>
                <th className="flex flex-col items-center justify-center border-t-transparent ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {t("manage.grade_2")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[2]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[1]?.lower_range! * 100}%)
                  </span>
                </th>
                <th className="flex flex-col items-center justify-center border-t-transparent ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {t("manage.grade_1")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[1]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[0]?.lower_range! * 100}%)
                  </span>
                </th>

                <th className="flex  flex-col items-center justify-center border-t-transparent ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {t("manage.grade_1+")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[0]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[0]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  flex-col items-center justify-center border-t-transparent ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {t("manage.grade_1")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[0]?.upper_range! * 100}% ~ {detailReportData?.standard_grade[1]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  flex-col items-center justify-center border-t-transparent ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {t("manage.grade_2")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[1]?.upper_range! * 100}% ~ {detailReportData?.standard_grade[2]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  items-center justify-center ef-body-lg border border-solid border-t-transparent  px-12pxr py-14pxr w-141pxr max-w-[141px]">{t("manage.avobe_grade_2")}</th>
              </tr>
              <tr className="flex">
                <th className="flex justify-start ef-body-xl border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">
                  {t("common.heads")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {detailReportData?.weight_distribution[5].stock}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {detailReportData?.weight_distribution[3].stock}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {detailReportData?.weight_distribution[1].stock}
                  {t("common.head")}
                </th>

                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {detailReportData?.weight_distribution[0].stock}
                  {t("common.head")}
                </th>

                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {detailReportData?.weight_distribution[2].stock}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {detailReportData?.weight_distribution[4].stock}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-t-transparent  px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {detailReportData?.weight_distribution[6].stock}
                  {t("common.head")}
                </th>
              </tr>
              <tr className="flex">
                <th className="flex justify-start ef-body-xl border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">%</th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {detailReportData?.weight_distribution[5].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {detailReportData?.weight_distribution[3].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {detailReportData?.weight_distribution[1].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {detailReportData?.weight_distribution[0].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {detailReportData?.weight_distribution[2].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {detailReportData?.weight_distribution[4].rate}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-t-transparent  px-12pxr py-14pxr w-141pxr max-w-[141px]"> {detailReportData?.weight_distribution[6].rate}%</th>
              </tr>
            </tbody>
          </table>

          <div className="flex items-center mb-16pxr">
            <span className="ef-title-md text-ef-neutral-900">{t("manage.body_rate_distribution")}</span>
          </div>
          <table className="mb-40pxr">
            <thead className="flex text-ef-neutral-900 bg-ef-neutral-50">
              <tr className="flex">
                <th className="flex  items-center justify-start ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-116pxr max-w-[116px]">
                  {t("manage.body_rate")}
                </th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">0 ~ 3.0</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">3.0 ~ 3.19</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">3.2 ~ 3.39</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">3.4 ~ 3.6</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-142pxr max-w-[142px]">3.61 ~ 3.8</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-r-transparent border-b-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">3.81 ~ 4.0</th>
                <th className="flex  items-center justify-center ef-body-xl border border-solid border-b-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">4.0 ~</th>
              </tr>
            </thead>
            <tbody className="flex flex-col justify-between">
              <tr className="flex">
                <th className="flex items-center justify-start ef-body-xl border border-solid border-r-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">
                  {t("manage.grade_criteria")}
                </th>
                <th className="flex items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">{t("manage.below_grade_2")}</th>
                <th className="flex flex-col items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {t("manage.grade_2")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[2]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[1]?.lower_range! * 100}%)
                  </span>
                </th>
                <th className="flex flex-col items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {t("manage.grade_1")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[1]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[0]?.lower_range! * 100}%)
                  </span>
                </th>

                <th className="flex  flex-col items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {t("manage.grade_1+")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[0]?.lower_range! * 100}% ~ {detailReportData?.standard_grade[0]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  flex-col items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {t("manage.grade_1")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[0]?.upper_range! * 100}% ~ {detailReportData?.standard_grade[1]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  flex-col items-center justify-center ef-body-lg border border-solid border-r-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {t("manage.grade_2")}
                  <br />
                  <span className="whitespace-nowrap">
                    ({detailReportData?.standard_grade[1]?.upper_range! * 100}% ~ {detailReportData?.standard_grade[2]?.upper_range! * 100}%)
                  </span>
                </th>
                <th className="flex  items-center justify-center ef-body-lg border border-solid  px-12pxr py-14pxr w-141pxr max-w-[141px]">{t("manage.avobe_grade_2")}</th>
              </tr>

              <tr className="flex">
                <th className="flex justify-start ef-body-xl border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">
                  {t("common.heads")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {intervalCounts[0]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {intervalCounts[1]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {intervalCounts[2]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {intervalCounts[3]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {intervalCounts[4]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {intervalCounts[5]}
                  {t("common.head")}
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-t-transparent  px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {intervalCounts[6]}
                  {t("common.head")}
                </th>
              </tr>
              <tr className="flex">
                <th className="flex justify-start ef-body-xl border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-116pxr max-w-[116px] bg-ef-neutral-50">%</th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px]">
                  {Math.round(intervalPercentages[0])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {Math.round(intervalPercentages[1])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {Math.round(intervalPercentages[2])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-green-50">
                  {Math.round(intervalPercentages[3])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-142pxr max-w-[142px] bg-ef-state-blue-50">
                  {Math.round(intervalPercentages[4])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-r-transparent border-t-transparent px-12pxr py-14pxr w-141pxr max-w-[141px] bg-ef-state-orange-50">
                  {Math.round(intervalPercentages[5])}%
                </th>
                <th className="flex justify-center ef-body-lg border border-solid border-t-transparent  px-12pxr py-14pxr w-141pxr max-w-[141px]">{Math.round(intervalPercentages[6])}%</th>
              </tr>
            </tbody>
          </table>

          {/* <div className="flex items-center mb-16pxr">
            <span className="ef-title-md text-ef-neutral-900">체중구간별 등급분포</span>
          </div> */}

          <div className="flex flex-row mb-40pxr">
            <div className="ef-card-movementReport-96 !shadow-none hover:!border-[#DEDEDE] !h-fit w-545pxr !p-24pxr mr-16pxr">
              <div className="flex justify-between items-center">
                <span className="ef-title-md">{t("manage.work_video")}</span>
                <span className="ef-body-xs">* {t("manage.aggregation_vary_comment")}</span>
              </div>
              {videoError === true ? (
                <div className="flex flex-col items-center overflow-hidden my-20pxr">
                  <img src={generateImg} alt="generateImg" className="w-310pxr h-220pxr" />
                  <span className="text-ef-neutral-600 ef-body-2xl">{t("manage.generate_video")}</span>
                </div>
              ) : (
                <div className="ef-body-2xl w-full h-full flex flex-col items-center text-center justify-center mt-16pxr">
                  <video
                    key={detailReportData?.overlay_path ? detailReportData?.overlay_path : detailReportData?.video_path}
                    // autoPlay
                    controls
                    preload="none"
                    style={{
                      margin: "auto",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                    //에러 트리거
                    onError={() => setVideoError(true)}
                    poster={detailReportData?.image_path}
                    src={detailReportData?.overlay_path ? detailReportData?.overlay_path : detailReportData?.video_path}
                  />
                  <button onClick={handleDownloadVideo} type="button" className="ml-auto ef-btn-r8-sm flex flex-row gap-8pxr mt-16pxr">
                    <ReportDownLoad />
                    {t("manage.download_original_video")}
                  </button>
                </div>
              )}
            </div>
            <div className="ef-card-movementReport-96 !shadow-none hover:!border-[#DEDEDE] !h-fit w-545pxr !p-24pxr">
              <form onSubmit={handleSubmit(onSubmit)} className="outline-none">
                <div className="flex items-center justify-between">
                  <span className="ef-title-md">{t("common.memo")}</span>
                  <button type="submit" className={`${isEditing ? "text-ef-primary-500" : "text-ef-neutral-500"} ef-title-md `}>
                    {t("common.save_btn")}
                  </button>
                </div>
                <textarea
                  name="memo"
                  value={memo}
                  onChange={handleChange}
                  placeholder={memo === "" || memo === null ? t("common.enter_memo") : memo}
                  className={`ef-textarea w-full h-326pxr mt-16pxr ${isEditing ? "text-ef-neutral-900" : "text-ef-neutral-600"}`}
                  onFocus={() => setIsEditing(true)}
                  onBlur={() => setIsEditing(false)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewDetailReport;
