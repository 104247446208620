import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Control, Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { MovementDetail } from "query/movement/type";
import { useEditMovement } from "query/movement";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { getRedStar } from "utils/getImportantStar";
import RoomDropDown from "components/pages/2_manage/device/feeder/RoomDropDown";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<any>>;
  defaultData: MovementDetail;
  // control: Control<MovementConfirmPayload>;
  // category: number | null;
  // departureState: RoomList | undefined;
  // setDepartureState: React.Dispatch<React.SetStateAction<any>>;
  // arrivalState: RoomList | undefined;
  // setArrivalState: React.Dispatch<React.SetStateAction<any>>;
}

type Inputs = {
  arrival_id: number | null;
  departure_id: number | null;
};

function ChangeMoveLocationModal(props: Props) {
  const { isToggle, setIsToggle, defaultData } = props;
  const { t } = useTranslation();

  const { mutateAsync: editReport } = useEditMovement();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    register,
    clearErrors,
  } = useForm<Inputs>();

  const arrival_id = watch("arrival_id");
  const departure_id = watch("departure_id");

  const onSubmit = async () => {
    const payload = {
      id: defaultData.id,
      arrival_id: arrival_id,
      departure_id: departure_id,
    };

    try {
      await editReport({ id: defaultData.id, payload: { arrival_id: Number(payload.arrival_id), departure_id: Number(payload.departure_id) } });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
    } catch (error) {}
  };

  useEffect(() => {
    reset({
      arrival_id: defaultData?.arrival?.id ? null : defaultData?.arrival?.id,
      departure_id: defaultData?.departure?.id ? null : defaultData?.departure?.id,
    });
  }, []);

  // const [piggeryOption, setPiggeryOption] = useState<Array<RoomList>>([]);

  // useEffect(() => {
  //   if (roomList) {
  //     setPiggeryOption(roomList);
  //   }
  // }, [roomList]);

  // useEffect(() => {
  //   if (category === 1) {
  //     setDepartureState(roomList?.find((v) => v.category.id === 2));
  //     setArrivalState(roomList?.find((v) => v.id === defaultData?.arrival?.id));
  //   } else if (category === 2 || category === 4) {
  //     setDepartureState(roomList?.find((v) => v.id === defaultData?.departure?.id));
  //     setArrivalState(roomList?.find((v) => v.category.id === 2));
  //   } else {
  //     setDepartureState(roomList?.find((v) => v.id === defaultData?.departure?.id));
  //     setArrivalState(roomList?.find((v) => v.id === defaultData?.arrival?.id));
  //   }
  // }, [category]);

  return (
    <>
      {/* <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={() => setIsToggle(false)}
        className="flex  justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
      > */}
      <div className="absolute z-[10] mt-46pxr ml-300pxr w-493pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-24pxr border border-solid border-ef-neutral-150 rounded-md">
        <div className="flex relative w-full items-center">
          <span className="flex mx-auto ef-neutral-900 ef-title-md">{t("manage.change_movement_location")}</span>
          <button className="absolute p-8pxr bg-ef-neutral-100 rounded-full bottom-[-7px] right-0pxr" onClick={() => setIsToggle(false)}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>

        <form className="flex flex-col mt-40pxr" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center gap-16pxr">
            <div className="flex flex-row items-center">
              <label className="w-77pxr ef-label-md min-w-[77px] line-clamp-1 mr-12pxr">
                {t("manage.departure_location")}
                {getRedStar()}
              </label>

              <div className="w-340pxr relative" onClick={() => clearErrors("departure_id")}>
                {errors.departure_id ? <RequiredAlert /> : <></>}
                <Controller
                  name="departure_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <RoomDropDown title={t("manage.departure_location")} state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-77pxr ef-label-md min-w-[77px] line-clamp-1 mr-12pxr">
                {t("manage.arrival_location")}
                {getRedStar()}
              </label>

              <div className="w-340pxr relative" onClick={() => clearErrors("departure_id")}>
                {errors.arrival_id ? <RequiredAlert /> : <></>}
                <Controller
                  name="arrival_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <RoomDropDown title={t("manage.arrival_location")} state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-16pxr mt-60pxr mx-72pxr">
            <button type="submit" className="w-300pxr ef-btn-modal">
              {t("common.save")}
            </button>
          </div>
        </form>
      </div>
      {/* </Modal> */}
    </>
  );
}

export default ChangeMoveLocationModal;
