import { useAuthState } from "context/AuthContext";
import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { EfcCamDetail, EfcDetail, EfcListSingle, EfcSingle } from "./type";

// get efc list
export const useEfcList = (piggeries?: number[], state?: boolean[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["efcList", piggeries, state], () => client.get<EfcListSingle[]>("/efc", { params: { piggeries, state } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get efc detail(single)
export const useEfcDetail = (id: number) => {
  const query = useQuery(["efcDetail", id], () => client.get<EfcSingle>(`/efc/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) efc detail
export const useEditEfc = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editefc", (object: { payload: { name: string }; id: number }) => client.put(`/efc/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["efcList"]);
      queryClient.invalidateQueries(["efcDetail"]);
    },
  });
  return mutation;
};

// get efc cam detail(single)
export const useEfcCamDetail = (id: number) => {
  const query = useQuery(["efcCamDetail", id], () => client.get<EfcDetail>(`/efc/camera/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) count efc cam detali
export const useEditEfcCam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editEfcCam", (object: { payload: { name: string }; id: number }) => client.put(`/efc/camera/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["efcList"]);
      queryClient.invalidateQueries(["efcCamDetail"]);
    },
  });
  return mutation;
};
