import React from "react";
import { ReactComponent as CloseCircle } from "assets/icons/general/ef-general-closeCircle.svg";

type Props = {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  regex?: RegExp;
  maxLength?: number;
  textAlign?: "right" | "left" | "center";
};
function InputFieldOnly(props: Props) {
  const { state, setState, placeholder, disabled = false, type = "text", regex, textAlign = "left", maxLength = 524288 } = props;

  const clearInput = () => {
    setState("");
  };

  return (
    <div
      className={
        "group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
        (disabled ? "bg-ef-neutral-50 text-ef-neutral-500" : "text-ef-neutral-900 bg-ef-neutral-white")
      }
    >
      <input
        style={{
          textAlign: textAlign,
        }}
        type={type}
        placeholder={placeholder}
        value={state !== null && typeof state !== "undefined" ? state : ""}
        disabled={disabled}
        maxLength={maxLength}
        spellCheck={false}
        onKeyDown={(e) => {
          // 엔터 사용시 clearInput이 작동되는것을 막음
          if (e.key === "Enter") e.preventDefault();
          if (type === "number") {
            if (e.key === "e" || e.key === "-" || e.key === "+") e.preventDefault();
          }
        }}
        onChange={(e) => {
          if (regex) {
            if (regex.test(e.target.value)) setState(e.target.value);
          } else setState(e.target.value);
        }}
        className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
      />
      {!disabled && state !== "" && state !== undefined ? (
        <button type="button" onClick={(e) => clearInput()} className="cursor-pointer group-focus-within:block hidden">
          <CloseCircle />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default InputFieldOnly;
