import { Menu, MenuItem, Modal, Select } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as RoundError } from "assets/icons/general/ef-general-error.svg";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { DataforConfirm } from "pages/1_farmStatus/rooms/StockMap";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getRedStar } from "utils/getImportantStar";
import BirthDatePicker from "../BirthDatePicker";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useAddHerd, useDeactivateHerd, useHerdHistoryCategory } from "query/herd";
import WorkDatetimePicker from "../WorkDatetimePicker";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { format } from "date-fns";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentQueueIdx: React.Dispatch<React.SetStateAction<number>>;
  data: DataforConfirm[];
  currentIdx: number;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Inputs = {
  location: string;
  nickname: string;
  birth_date: Date;
  work_type: number;
  work_date: Date;
  avg_weight: number;
  stock: number;
  memo: string;
};

function ConfirmModal(props: Props) {
  const { isToggle, setIsToggle, data, currentIdx, setCurrentQueueIdx } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const currentData = data.find((o) => o.index === currentIdx);

  const { data: historyCategory } = useHerdHistoryCategory();
  const { mutateAsync: addHerd } = useAddHerd();
  const { mutateAsync: quitHerd } = useDeactivateHerd();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    else {
      queryClient.invalidateQueries(["StockDashboard"]);
      queryClient.invalidateQueries(["autoHerdData"]);
      setIsToggle(false);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onSubmit = async (inputData: Inputs) => {
    if (currentData) {
      try {
        // 전입일때
        if (currentData.type === "in") {
          await addHerd({
            nickname: inputData.nickname.trim() === "" ? null : inputData.nickname.trim(),
            birth_date: format(inputData.birth_date, "yyyy-MM-dd"),
            arrival_date: format(inputData.work_date, "yyyy-MM-dd'T'HH:mm:ss"),
            room_id: currentData.room.id,
            memo: inputData.memo === "" ? null : inputData.memo,
            history: {
              category_id: inputData.work_type,
              change: inputData.stock,
              weight: inputData.avg_weight,
              auto_herd_id: currentData.id,
            },
          });
          toast.success(t("common.save_completed"));
          // 전출일때
        } else {
          await quitHerd({
            payload: {
              category_id: inputData.work_type,
              change: inputData.stock * -1,
              created_at: format(inputData.work_date, "yyyy-MM-dd'T'HH:mm:ss"),
              auto_herd_id: currentData.id,
              weight: inputData.avg_weight,
            },
            id: currentData.herd_id ? currentData.herd_id : 0,
          });
          toast.success(t("common.save_completed"));
        }
        if (currentData.index + 1 < data.length) setCurrentQueueIdx(currentData.index + 1);
        else onClose("closeButtonClick");
      } catch (error) {
        console.log(error);
        toast.error(t("common.an_error_occurred"));
      }
    }
  };

  useEffect(() => {
    if (currentData) {
      reset({
        location: `[${currentData.piggery.name}] ${currentData.room.name}`,
        nickname: "",
        birth_date: currentData.birth_date ? new Date(currentData.birth_date) : new Date(),
        work_type: currentData.type === "in" ? 2 : 3,
        work_date:
          currentData.type === "in" ? (currentData.arrival_date ? new Date(currentData.arrival_date) : new Date()) : currentData.deactivated_at ? new Date(currentData.deactivated_at) : new Date(),
        avg_weight: currentData.avg_weight !== null ? currentData.avg_weight : 0,
        stock: currentData.stock,
        memo: "",
      });
    }
  }, [currentData]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr pb-120pxr overflow-y-auto"
    >
      <div className="w-[980px] h-918pxr bg-ef-neutral-white outline-none flex flex-col p-40pxr relative text-ef-neutral-900">
        <button
          onClick={() => {
            queryClient.invalidateQueries(["StockDashboard"]);
            queryClient.invalidateQueries(["autoHerdData"]);
            navigate("/");
          }}
          type="button"
          className="absolute top-40pxr right-40pxr"
        >
          <GeneralClose className="stroke-ef-neutral-900" />
        </button>
        {typeof currentData !== "undefined" && (
          <>
            <p className="ef-title-lg text-center">{`${currentData.type === "in" ? t("status.confirmation_of_move_in") : t("status.confirmation_of_move_out")}(${currentData.index + 1}/${
              data.length
            })`}</p>
            <div className="mt-60pxr flex flex-row gap-8pxr items-center justify-center">
              <RoundError className="stroke-ef-state-red-500" />
              <span className="ef-body-sm text-ef-state-red-500">{currentData.type === "in" ? t("status.move_in_confirm_comment") : t("status.move_out_confirm_comment")}</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <div className="mt-40pxr flex flex-col gap-12pxr ef-label-md">
                <div className="flex flex-row gap-12pxr items-center">
                  <span title={t("common.location")} className="line-clamp-1 min-w-[77px] max-w-[77px]">
                    {t("common.location")}
                  </span>
                  <div className="w-340pxr">
                    <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
                  </div>
                </div>
                {currentData.type === "in" && (
                  <div className="flex flex-row gap-12pxr items-center">
                    <span title={t("common.nickname")} className="line-clamp-1 min-w-[77px] max-w-[77px]">
                      {t("common.nickname")}
                    </span>
                    <div className="w-340pxr">
                      <Controller
                        name="nickname"
                        control={control}
                        render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("manage.nickname_placeholder")} />}
                      />
                    </div>
                  </div>
                )}
                {currentData.type === "in" && (
                  <div className="flex flex-row gap-12pxr items-center">
                    <span className="flex flex-row min-w-[77px] max-w-[77px]">
                      <span title={t("common.birth_date_aged_day")} className="line-clamp-1 break-all">
                        {t("common.birth_date_aged_day")}
                      </span>
                      {getRedStar()}
                    </span>
                    <div className="w-248pxr">
                      <Controller
                        name="birth_date"
                        control={control}
                        render={({ field }) => <BirthDatePicker date={field.value} setDate={field.onChange} maxDate={currentData.arrival_date ? new Date(currentData.arrival_date) : new Date()} />}
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-row gap-12pxr items-center">
                  <span className="flex flex-row min-w-[77px] max-w-[77px]">
                    <span title={t("common.work_type")} className="line-clamp-1 break-all">
                      {t("common.work_type")}
                    </span>
                    {getRedStar()}
                  </span>
                  <div className="w-340pxr">
                    <Controller
                      name="work_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                borderRadius: "8px",
                                marginTop: "4px",
                                boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
                                border: "1px solid #DEDEDE",
                              },
                            },
                          }}
                          style={{
                            backgroundColor: "#FFF6F6",
                            border: "1px solid #F03E3E",
                            fontFamily: "Pretendard",
                            fontSize: 16,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: -0.4,
                            width: "100%",
                            borderRadius: 8,
                            height: 40,
                          }}
                          sx={{
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          value={field.value}
                          onChange={field.onChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {historyCategory
                            ? historyCategory
                                .filter((c) => (currentData.type === "in" ? c.id === 1 || c.id === 2 : c.id === 3 || c.id === 4 || c.id === 5))
                                .map((o) => {
                                  return (
                                    <MenuItem className="ef-body-lg !font-pre" value={o.id}>
                                      {o.name}
                                    </MenuItem>
                                  );
                                })
                            : []}
                        </Select>
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-12pxr items-center">
                  <span className="flex flex-row min-w-[77px] max-w-[77px]">
                    <span title={t("common.work_datetime")} className="line-clamp-1 break-all">
                      {t("common.work_datetime")}
                    </span>
                    {getRedStar()}
                  </span>
                  <div>
                    <Controller name="work_date" control={control} render={({ field }) => <WorkDatetimePicker date={field.value} setDate={field.onChange} />} />
                  </div>
                </div>
                <div className="flex flex-row gap-12pxr items-center">
                  <span className="flex flex-row min-w-[77px] max-w-[77px]">
                    <span title={t("common.average_weight")} className="line-clamp-1 break-all">
                      {t("common.average_weight")}
                    </span>
                    {getRedStar()}
                  </span>
                  <div className="flex flex-row items-center gap-4pxr">
                    <div className="w-120pxr">
                      <Controller
                        name="avg_weight"
                        control={control}
                        render={({ field }) => (
                          <input
                            value={field.value}
                            onChange={(e) => {
                              const regex = /^(?:[1-9]\d*|0)?(?:\.\d{1,3})?$/;
                              if (regex.test(e.target.value)) field.onChange(e.target.value);
                            }}
                            type="number"
                            className="h-40pxr w-full bg-ef-state-red-50 border bordersolid border-ef-state-red-500 focus:outline-none rounded-[8px] px-16pxr py-8pxr ef-body-lg"
                          />
                        )}
                      />
                    </div>
                    <span className="ef-label-md">kg</span>
                  </div>
                </div>
                <div className="flex flex-row gap-12pxr items-center">
                  <span className="flex flex-row min-w-[77px] max-w-[77px]">
                    <span title={t("common.heads")} className="line-clamp-1 break-all">
                      {t("common.heads")}
                    </span>
                    {getRedStar()}
                  </span>
                  <div className="flex flex-row items-center gap-4pxr">
                    <div className="w-120pxr">
                      <Controller
                        name="stock"
                        control={control}
                        render={({ field }) => (
                          <input
                            value={field.value}
                            disabled={currentData.type === "out"}
                            onChange={(e) => {
                              const regex = /^(?:[1-9]\d*|0)?$/;
                              if (regex.test(e.target.value)) field.onChange(e.target.value);
                            }}
                            type="number"
                            className="h-40pxr w-full bg-ef-state-red-50 border border-solid border-ef-state-red-500 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 focus:outline-none rounded-[8px] px-16pxr py-8pxr ef-body-lg"
                          />
                        )}
                      />
                    </div>
                    <span className="ef-label-md">{t("common.head")}</span>
                  </div>
                </div>
                <div className="flex flex-row gap-12pxr">
                  <span title={t("common.memo")} className="line-clamp-1 min-w-[77px] max-w-[77px] mt-10pxr">
                    {t("common.memo")}
                  </span>
                  <textarea {...register("memo")} className="ef-textarea w-514pxr h-130pxr" placeholder={t("common.enter_memo")} />
                </div>
              </div>
              <button type="submit" className="ef-btn-modal mx-auto mt-60pxr">
                {t("common.save")}
              </button>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmModal;
