import { useAuthDispatch, useAuthState } from "context/AuthContext";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as GeneralFarm } from "assets/icons/general/ef-general-farm.svg";
import { ReactComponent as GeneralLogout } from "assets/icons/general/ef-general-logout.svg";
import { ReactComponent as Checkmark } from "assets/icons/general/ef-general-checkmark.svg";
import { ReactComponent as Settings } from "assets/icons/general/ef-general-settings.svg";
import { useNavigate } from "react-router-dom";
import NoImage1 from "assets/images/select/Farm Select_01.png";
import NoImage2 from "assets/images/select/Farm Select_02.png";
import NoImage3 from "assets/images/select/Farm Select_03.png";
import NoImage4 from "assets/images/select/Farm Select_04.png";
import useLogout from "hooks/useLogout";
import { useEditContext } from "context/EditContext";
import client from "query/client";

const photoArr = [NoImage1, NoImage2, NoImage3, NoImage4];

function PlaceSelector() {
  const [toggleAccount, setToggleAccount] = useState(false);
  const accountRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const logout = useLogout();

  const { current_farm, user } = useAuthState();
  const editContext = useEditContext();
  const authDispatch = useAuthDispatch();

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (accountRef.current && !accountRef.current.contains(e.target as Node)) {
        setToggleAccount(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accountRef]);

  const handleFarmChange = (farmId: number) => {
    if (farmId === current_farm.id) return;
    else {
      const selectedFarm = user.farms.find((o) => o.id === farmId);
      if (editContext?.editState === true) {
        if (window.confirm(t("common.alert_not_saved"))) {
          if (selectedFarm) {
            authDispatch({
              type: "SET_FARM",
              payload: selectedFarm,
            });

            localStorage.setItem("farm-id", farmId.toString());
            client.defaults.headers.common["farm-id"] = `${farmId}`;
            window.location.href = "/";
          }
        } else return;
      } else {
        if (selectedFarm) {
          authDispatch({
            type: "SET_FARM",
            payload: selectedFarm,
          });

          localStorage.setItem("farm-id", farmId.toString());
          client.defaults.headers.common["farm-id"] = `${farmId}`;
          window.location.href = "/";
        }
      }
    }
  };

  return (
    <div className="flex flex-col relative " ref={accountRef}>
      <button
        onClick={() => setToggleAccount((prev) => !prev)}
        className={
          "group w-144pxr px-16pxr py-8pxr my-20pxr h-full rounded-[8px] border border-solid flex flex-row items-start border-ef-neutral-900 " +
          (toggleAccount
            ? "stroke-ef-neutral-900 bg-ef-neutral-white text-ef-neutral-900"
            : "stroke-ef-neutral-900 active:stroke-ef-neutral-900 hover:stroke-ef-neutral-900 text-ef-neutral-white hover:text-ef-neutral-900 active:text-ef-neutral-white hover:bg-ef-neutral-50 active:bg-transparent active:border-ef-primary-900 border-ef-neutral-900")
        }
      >
        <div className="mr-8pxr">
          <GeneralFarm />
        </div>
        <span className="ef-label-lg line-clamp-1 w-71pxr text-ef-neutral-900">{current_farm.name}</span>
      </button>
      {toggleAccount ? (
        <div className="w-292pxr z-[2000] h-fit bg-ef-neutral-white absolute top-64pxr right-0pxr flex flex-col ef-main-shadow rounded-[8px] text-ef-neutral-900 stroke-ef-neutral-900">
          <div className="w-full h-56pxr flex flex-row px-16pxr pt-20pxr pb-12pxr justify-center bg-ef-neutral-50 rounded-t-[8px]">
            <span className="ef-label-lg">{t("gnb.account_information")}</span>
            <button
              onClick={() => {
                navigate("/settings/account");
                setToggleAccount(false);
              }}
              className="ml-auto"
            >
              <Settings />
            </button>
          </div>
          <div className="w-full flex flex-col py-12pxr pl-32pxr">
            <span className="ef-body-xl">{user.name}</span>
            <span className="ef-body-lg">{user.username}</span>
          </div>
          <div className="w-full border-t border-solid border-ef-neutral-150 h-48pxr py-12pxr px-16pxr flex flex-row bg-ef-neutral-50">
            <span className="ef-label-lg">{t("gnb.farm_list")}</span>
            <button
              onClick={() => {
                navigate("/settings/farm/info");
                setToggleAccount(false);
              }}
              className="ml-auto"
            >
              <Settings />
            </button>
          </div>
          <div className="w-full h-fit max-h-384pxr overflow-y-auto">
            {user.farms.map((o, i) => (
              <button onClick={() => handleFarmChange(o.id)} key={o.id} className="w-full h-48pxr py-12pxr pl-32pxr pr-16pxr ef-label-lg flex flex-row hover:bg-ef-primary-50">
                <img
                  className="w-24pxr h-24pxr mr-8pxr"
                  // src={o.image_path ? o.image_path : photoArr[i % photoArr.length]}
                  src=""
                  alt={o.name}
                  onError={(e) => (e.currentTarget.src = photoArr[i % photoArr.length])}
                />
                <span className="line-clamp-1 break-all max-w-[185px]">{o.name}</span>
                {o.name === current_farm.name && <Checkmark className="ml-auto min-w-[24px] stroke-ef-primary-500" />}
              </button>
            ))}
          </div>
          <button onClick={logout} className="w-full border-t border-solid border-ef-neutral-150 pt-12pxr pl-16pxr pb-20pxr flex flex-row">
            <GeneralLogout className="stroke-ef-neutral-700 fill-ef-neutral-700 mr-8pxr" />
            <span className="text-ef-neutral-700 ef-label-lg">{t("signin.logout")}</span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PlaceSelector;
