import React from "react";
import { ReactComponent as Scanner } from "assets/icons/general/ef-general-scanner.svg";
import { ReactComponent as Stock } from "assets/icons/general/ef-general-stock.svg";
import { useTranslation } from "react-i18next";

interface NavigationBarProps {
  activeTab: 'scan' | 'status';
  onTabChange: (tab: 'scan' | 'status') => void;
}

function NavigationBar({ activeTab, onTabChange }: NavigationBarProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-80pxr flex flex-row items-center border-t border-ef-neutral-50 bg-ef-neutral-white">
      <div 
        onClick={() => onTabChange('scan')} 
        className="flex-1 flex flex-col py-16pxr gap-8pxr items-center cursor-pointer"
      >
        <div className="relative">
          {activeTab === 'scan' && (
            <div className="absolute w-56pxr h-32pxr bg-ef-primary-500 rounded-[20px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
          <div className="relative z-10">
            <Scanner className={activeTab === 'scan' ? 'stroke-ef-neutral-white' : 'stroke-ef-neutral-900'} />
          </div>
        </div>
        <span className="ef-label-sm text-ef-neutral-900">{t('qr.qr_scan')}</span>
      </div>
      <div 
        onClick={() => onTabChange('status')} 
        className="flex-1 flex flex-col py-16pxr gap-8pxr items-center cursor-pointer"
      >
        <div className="relative">
          {activeTab === 'status' && (
            <div className="absolute w-56pxr h-32pxr bg-ef-primary-500 rounded-[20px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
          <div className="relative z-10">
            <Stock className={activeTab === 'status' ? 'stroke-ef-neutral-white' : 'stroke-ef-neutral-900'} />
          </div>
        </div>
        <span className="ef-label-sm text-ef-neutral-900">{t('qr.piggery_stock_status')}</span>
      </div>
    </div>
  );
}

export default NavigationBar;
