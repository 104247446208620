import React from "react";
import { ReactComponent as CloseCircle } from "assets/icons/general/ef-general-closeCircle.svg";

type Props = {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  displayValue?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  regex?: RegExp;
  maxLength?: number;
  textAlign?: "right" | "left" | "center";
};

function InputFieldOnly(props: Props) {
  const {
    state,
    setState,
    displayValue,
    placeholder,
    disabled = false,
    type = "text",
    regex,
    textAlign = "left",
    maxLength = 524288
  } = props;

  const clearInput = () => {
    setState("");
  };

  return (
    <div
      className={
        "group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 " +
        (disabled ? "bg-ef-neutral-50 text-ef-neutral-500" : "text-ef-neutral-900 bg-ef-neutral-white")
      }
    >
      <input
        style={{
          textAlign: textAlign,
        }}
        type={type}
        placeholder={placeholder}
        value={displayValue || state}
        disabled={disabled}
        maxLength={maxLength}
        spellCheck={false}
        onClick={(e) => {
          if (displayValue) {
            const currentNumber = displayValue.split(' ')[0];
            const target = e.target as HTMLInputElement;
            const cursorPosition = target.selectionStart || 0;

            if (cursorPosition > currentNumber.length) {
              target.setSelectionRange(currentNumber.length, currentNumber.length);
            }
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
          if (type === "number") {
            if (e.key === "e" || e.key === "-" || e.key === "+") e.preventDefault();
          }
          if (!/^[0-9-]$/.test(e.key) &&
            !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          const cursorPosition = e.target.selectionStart || 0;
          const inputValue = e.target.value;

          if (displayValue) {
            const currentNumber = displayValue.split(' ')[0];

            if (inputValue.length < displayValue.length) {
              const beforeCursor = currentNumber.slice(0, cursorPosition);
              const afterCursor = currentNumber.slice(cursorPosition + 1);
              const newValue = beforeCursor + afterCursor;
              setState(newValue);

              setTimeout(() => {
                e.target.setSelectionRange(cursorPosition, cursorPosition);
              }, 0);
              return;
            }

            if (inputValue.length > currentNumber.length &&
              cursorPosition > 0 &&
              !/^[-0-9]+$/.test(inputValue[cursorPosition - 1])) {
              return;
            }

            const lastChar = inputValue[cursorPosition - 1];
            if (/^[-0-9]+$/.test(lastChar)) {
              const beforeCursor = currentNumber.slice(0, cursorPosition - 1);
              const afterCursor = currentNumber.slice(cursorPosition - 1);
              const newValue = beforeCursor + lastChar + afterCursor;
              setState(newValue);

              setTimeout(() => {
                e.target.setSelectionRange(cursorPosition, cursorPosition);
              }, 0);
            }
          } else {
            const numbersOnly = inputValue.replace(/[^-0-9]/g, '');
            setState(numbersOnly);

            setTimeout(() => {
              e.target.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
          }
        }}
        className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
      />
      {!disabled && state !== "" && state !== undefined ? (
        <button type="button" onClick={clearInput} className="cursor-pointer group-focus-within:block hidden">
          <CloseCircle />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default InputFieldOnly;
