import React from "react";
import { useTranslation } from "react-i18next";
type Props = {
  sortState: boolean;
  setSortState: React.Dispatch<React.SetStateAction<boolean>>;
};

function BtnSort(props: Props) {
  // ******true가 기본값이며 최신순입니다. true=최신순 / false=오래된순******
  const { sortState, setSortState } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-row ml-auto flex-wrap gap-4pxr ef-label-md">
      <button
        onClick={() => setSortState(true)}
        type="button"
        className={
          "rounded-[4px] py-4pxr w-80pxr border border-solid " +
          (sortState
            ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
            : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
        }
      >
        {t("common.latest")}
      </button>
      <button
        onClick={() => setSortState(false)}
        type="button"
        className={
          "rounded-[4px] py-4pxr w-80pxr border border-solid " +
          (!sortState
            ? "bg-ef-primary-50 text-ef-primary-500 border-ef-primary-500"
            : "border-ef-neutral-200 text-ef-neutral-500 bg-ef-neutral-white hover:border-ef-primary-500 hover:text-ef-primary-500 active:border-ef-primary-500 active:text-ef-primary-500 active:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:bg-ef-neutral-50 disabled:text-ef-neutral-300 focus:outline-none focus:border-ef-neutral-900")
        }
      >
        {t("common.oldest")}
      </button>
    </div>
  );
}

export default BtnSort;
