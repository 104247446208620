import React, { createContext, useContext, useState, ReactNode } from "react";

interface ReportContextProps {
  isReportCreated: boolean;
  setReportCreated: React.Dispatch<React.SetStateAction<boolean>>;
  workDate: Date | null;
  setWorkDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startTime: Date | null;
  setStartTime: React.Dispatch<React.SetStateAction<Date | null>>;
  endTime: Date | null;
  setEndTime: React.Dispatch<React.SetStateAction<Date | null>>;
}

const ReportContext = createContext<ReportContextProps | undefined>(undefined);

interface ReportProviderProps {
  children: ReactNode;
}

const ReportProvider: React.FC<ReportProviderProps> = ({ children }) => {
  const [isReportCreated, setReportCreated] = useState(false);
  const [workDate, setWorkDate] = useState<Date | null>(new Date());
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  return <ReportContext.Provider value={{ isReportCreated, setReportCreated, workDate, setWorkDate, startTime, setStartTime, endTime, setEndTime }}>{children}</ReportContext.Provider>;
};

const useReportContext = () => {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }

  return context;
};

export { ReportProvider, useReportContext };
