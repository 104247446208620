import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { useMonitoringList } from "query/monitoring";
import MainStream from "components/pages/6_monitoring/modal/MainStream";
import { useTranslation } from "react-i18next";
import StreamOverlay from "components/pages/6_monitoring/StreamOverlay";
import { MonitoringList } from "query/monitoring/type";
import { Camera } from "query/monitoring/type";

function Streaming() {
  const { t } = useTranslation();
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [isStreamModalOpen, setIsStreamModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCamera, setSelectedCamera] = useState<{
    streamUrl: string;
    roomName: string;
    piggeryName: string;
    cameraName: string;
    camera: Camera;
    roomData: MonitoringList;
    category: number;
  } | null>(null);
  const [isOverlayOn, setIsOverlayOn] = useState(true);
  const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
  
  const { data: monitoringList } = useMonitoringList();
  // 선택된 방의 모든 카메라 목록 생성
  const selectedCameras = monitoringList
    ?.filter(room => selectedRooms.includes(room.id))
    .flatMap(room => 
      room.cameras.map(camera => ({
        ...camera,
        roomName: room.name,
        piggeryName: room.piggery.name,
        category: room.category.id
      }))
    ) || [];

  // 현재 페이지에 표시할 카메라 목록
  const currentCameras = selectedCameras.slice((currentPage - 1) * 9, currentPage * 9);
  // 초기 로딩 시 전체 방 선택
  useEffect(() => {
    if (monitoringList) {
      setSelectedRooms(monitoringList.map(room => room.id));
    }
  }, [monitoringList]);

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(selectedCameras.length / 9);
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  // 개별 방 체크박스 핸들러
  const handleRoomCheck = (roomId: number) => {
    setSelectedRooms(prev => {
      if (prev.includes(roomId)) {
        return prev.filter(id => id !== roomId);
      } else {
        return [...prev, roomId];
      }
    });
    setCurrentPage(1);
  };

  // 전체 선택 체크박스 핸들러
  const handleAllCheck = () => {
    if (monitoringList) {
      if (selectedRooms.length === monitoringList.length) {
        setSelectedRooms([]);
      } else {
        setSelectedRooms(monitoringList.map(room => room.id));
      }
      setCurrentPage(1);
    }
  };

  const toggleSection = (piggeryName: string) => {
    setCollapsedSections(prev => {
      if (prev.includes(piggeryName)) {
        return prev.filter(name => name !== piggeryName);
      } else {
        return [...prev, piggeryName];
      }
    });
  };

  return (
    <div className="w-full h-full flex flex-row">
      <MainStream 
        isToggle={isStreamModalOpen}
        setIsToggle={setIsStreamModalOpen}
        streamUrl={selectedCamera?.streamUrl || ""}
        roomName={selectedCamera?.roomName || ""}
        piggeryName={selectedCamera?.piggeryName || ""}
        cameraName={selectedCamera?.cameraName || ""}
        category={selectedCamera?.category || 0}
        roomData={selectedCamera?.roomData!}
        camera={selectedCamera?.camera!}
      />
      {/* <div className="w-full bg-ef-neutral-white border-b border-solid border-ef-neutral-200">
        <div className="px-240pxr pt-60pxr pb-40pxr">
          <div className="ef-headline-lg ef-neutral-900">{t("status.streaming")}</div>
          <div className="mt-40pxr">
            <div className="flex flex-wrap gap-24pxr">
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  id="allCheckbox"
                  checked={monitoringList && monitoringList.length > 0 && selectedRooms.length === monitoringList.length}
                  onChange={handleAllCheck}
                />
                <label htmlFor="allCheckbox" className="!pl-36pxr ef-body-lg ef-neutral-900">
                  {t("status.total")}
                </label>
              </div>
              {monitoringList?.map((room) => (
                <div key={room.id} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`room-${room.id}`}
                    checked={selectedRooms.includes(room.id)}
                    onChange={() => handleRoomCheck(room.id)}
                  />
                  <label htmlFor={`room-${room.id}`} className="!pl-36pxr ef-body-lg ef-neutral-900 truncate">
                    {`[${room.piggery.name}] ${room.name}`}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* 필터 */}
      <div className="min-w-[376px] h-full bg-ef-neutral-white border-r border-solid border-ef-neutral-150">
        <div className="w-full h-[1450px] flex flex-col pt-60pxr overflow-y-auto scrollbar-hide">
          <div className="flex flex-col gap-24pxr">
            {monitoringList?.reduce((acc, room) => {
              // piggery별로 그룹화
              const piggeryName = room.piggery.name;
              if (!acc[piggeryName]) {
                acc[piggeryName] = [];
              }
              acc[piggeryName].push(room);
              return acc;
            }, {} as Record<string, MonitoringList[]>)
            // 그룹화된 데이터를 렌더링
            && Object.entries(monitoringList.reduce((acc, room) => {
              const piggeryName = room.piggery.name;
              if (!acc[piggeryName]) {
                acc[piggeryName] = [];
              }
              acc[piggeryName].push(room);
              return acc;
            }, {} as Record<string, MonitoringList[]>)).map(([piggeryName, rooms]) => (
              <div key={piggeryName}>
                <div className={`h-60pxr flex flex-row items-center ${
                  collapsedSections.includes(piggeryName) ? 'border-b border-solid border-ef-neutral-150' : ''
                }`}>
                  <div className={`flex-1 pr-24pxr flex flex-row items-center justify-between ${
                    collapsedSections.includes(piggeryName) ? 'pl-64pxr' : 'ml-64pxr'
                  }`}>
                    <span className="ef-title-lg text-ef-neutral-900">{piggeryName}</span>
                    <button 
                      onClick={() => toggleSection(piggeryName)}
                      className="p-8pxr hover:bg-ef-neutral-50 rounded-8pxr"
                    >
                      {collapsedSections.includes(piggeryName) ? (
                        <ArrowDown className="stroke-ef-neutral-400" />
                      ) : (
                        <ArrowUp className="stroke-ef-neutral-400" />
                      )}
                    </button>
                  </div>
                </div>
                {!collapsedSections.includes(piggeryName) && (
                  <>
                    <div className="h-60pxr flex flex-row items-center justify-start">
                      <div className="h-full ml-64pxr flex-1 flex items-center border-b border-solid border-ef-neutral-150">
                        <input 
                          type="checkbox" 
                          id={`${piggeryName}_all`}
                          checked={rooms.every(room => selectedRooms.includes(room.id))}
                          onChange={(e) => {
                            rooms.forEach(room => {
                              if (e.target.checked && !selectedRooms.includes(room.id)) {
                                handleRoomCheck(room.id);
                              } else if (!e.target.checked && selectedRooms.includes(room.id)) {
                                handleRoomCheck(room.id);
                              }
                            });
                          }}
                        />
                        <label htmlFor={`${piggeryName}_all`} className="!pl-36pxr ef-label-lg text-ef-neutral-900">
                          {t("common.all")}
                        </label>
                      </div>
                    </div>
                    {rooms.map((room, index) => (
                      <div key={room.id} className="h-60pxr flex flex-row items-center justify-start">
                        <div className={`h-full flex-1 flex items-center border-b border-solid border-ef-neutral-150 ${
                          index === rooms.length - 1 ? 'pl-64pxr' : 'ml-64pxr'
                        }`}>
                          <input 
                            type="checkbox" 
                            id={`room_${room.id}`}
                            checked={selectedRooms.includes(room.id)}
                            onChange={() => handleRoomCheck(room.id)}
                          />
                          <label htmlFor={`room_${room.id}`} className="!pl-36pxr ef-label-lg text-ef-neutral-900">
                            {room.name === "복도" ? t('streaming.hallway') : room.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* 필터 끝 */}
      <div className="w-full h-full bg-ef-neutral-50 flex flex-col">
        <div className="pl-40pxr pr-64pxr pt-60pxr">
          <div className="ef-headline-lg ef-neutral-900">{t("status.streaming")}</div>
          {selectedRooms.length === 0 || selectedCameras.length === 0 ? (
            <div className="flex flex-col items-center justify-center pt-120pxr pb-246pxr">
              <img src={require("assets/images/report/no_report_data.png")} className="w-310pxr h-220pxr" alt="no-data" />
              <span className="mt-16pxr ef-body-xxl text-ef-neutral-600">
                {t("common.no_info")}
              </span>
            </div>
          ) : (
            <>
              <div className="flex justify-between">
                <div className="flex flex-row items-center">
                  {currentCameras.some(camera => {
                    const roomData = monitoringList?.find(room => 
                      room.cameras.some(cam => cam.id === camera.id && room.category.id === camera.category)
                    );
                    return roomData?.category.id === 1;
                  }) && (
                    <>
                      <div className="pt-32pxr ef-label-lg ef-neutral-900">
                        {t('common.overlay')}
                      </div>
                      <div className="pl-16pxr pt-32pxr">
                        <button
                          type="button"
                          onClick={() => setIsOverlayOn(prev => !prev)}
                          className="w-120pxr h-40pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white p-4pxr flex flex-row text-ef-neutral-200 relative ef-label-sm"
                        >
                          <div
                            className={
                              "w-55pxr h-30pxr rounded-[6px] absolute transition-transform duration-300 " + 
                              (isOverlayOn ? "bg-ef-primary-500 translate-x-full" : "bg-ef-neutral-400 translate-x-0")
                            }
                          ></div>
                          <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (!isOverlayOn && "text-ef-neutral-white")}>OFF</div>
                          <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (isOverlayOn && "text-ef-neutral-white")}>ON</div>
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-167pxr pt-24pxr flex flex-row justify-between items-center">
                  <button 
                    className="w-40pxr h-40pxr ef-btn-calendar-arrow-sm ef-bg-neutral-150"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    <ArrowLeft />
                  </button>
                  <div className="ef-body-lg ef-neutral-900">
                    {currentPage}/{totalPages || 1}
                  </div>
                  <button 
                    className="w-40pxr h-40pxr ef-btn-calendar-arrow-sm ef-bg-neutral-150"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-24pxr pt-24pxr pb-200pxr">
                {currentCameras.map((camera) => {
                  // 현재 카메라가 속한 방 데이터 찾기
                  const roomData = monitoringList?.find(room => 
                    room.cameras.some(cam => cam.id === camera.id && room.category.id === camera.category)
                  );
                  return (
                    <div key={camera.id} 
                      className="w-464pxr h-fit px-16pxr py-24pxr flex flex-col rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white"
                    >
                      <div className="flex flex-row justify-between items-center">
                        <div className="ef-body-xl ef-neutral-900">
                          [{camera.piggeryName}]{camera.roomName === "복도" ? t('streaming.hallway') : camera.roomName}
                        </div>
                        <div className="ef-body-lg ef-neutral-900">
                          {camera.name}
                        </div>
                      </div>
                      <div 
                        className="w-full aspect-video mt-16pxr cursor-pointer relative" 
                        onClick={() => {
                          if (camera.connected) {
                            setSelectedCamera({
                              streamUrl: camera.stream_url,
                              roomName: camera.roomName,
                              piggeryName: camera.piggeryName,
                              cameraName: camera.name,
                              camera: camera,
                              roomData: roomData!,
                              category: roomData!.category.id
                            });
                            setIsStreamModalOpen(true);
                          }
                        }}
                      >
                        {camera.connected ? (
                          <div className="relative w-full aspect-video">
                            <iframe
                              src={camera.stream_url}
                              className="absolute top-0 left-0 w-full h-full object-cover rounded-[8px] pointer-events-none"
                              allowFullScreen
                              allow="camera; microphone; autoplay"
                              title={`camera-${camera.id}`}
                            />
                            {roomData && roomData.category.id === 1 && (
                              <div className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-[8px]">
                                <StreamOverlay 
                                  monitoringData={roomData}
                                  camera={camera}
                                  isOverlayOn={isOverlayOn}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="w-full h-full flex flex-col items-center justify-center bg-ef-neutral-50 rounded-[8px] cursor-default">
                            <img 
                              src={require("assets/images/report/no_report_data.png")} 
                              className="w-140pxr h-99pxr" 
                              alt="no-data" 
                            />
                            <span className="mt-8pxr ef-body-lg text-ef-neutral-700">
                              {t("common.no_data")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>  
  );
}

export default Streaming;
