import { useAuthDispatch } from "../../context/AuthContext";
import client from "../client";
import { MemberEditPayload, MemberType } from "./type";
import { useMutation } from "react-query";

// get /member
export const useMemberInfo = () => {
  const getMembers = () => client.get<MemberType>("/members").then((res) => res.data);
  const authDispatch = useAuthDispatch();
  const mutation = useMutation("initMember", getMembers, {
    onSuccess(data) {
      // 로그인 외에 useInit 사용시에 farm을 이미 선택한 상태라면, 해당 farm 상태를 context의 최신으로 반영함.
      const farmToken = localStorage.getItem("farm-id");
      if (farmToken) {
        const farm = data.farms.find((v) => v.id === Number(farmToken));
        client.defaults.headers.common["farm-id"] = `${farmToken}`;
        if (farm) {
          authDispatch({
            type: "SET_FARM",
            payload: farm,
          });
        }
      }
      authDispatch({
        type: "SET_INIT",
        payload: data,
      });
    },
    onError(error, variables, context) {
      // 토큰 검증 실패시
      localStorage.removeItem("Authorization");
      client.defaults.headers.common["Authorization"] = ""; // axios 헤더 제거
      console.log(error);
    },
  });
  return mutation;
};

export const useUpdateMember = () => {
  const { mutateAsync: init } = useMemberInfo();
  const mutation = useMutation("updateMember", (payload: MemberEditPayload) => client.put("/members", payload).then((res) => res.data), {
    onSuccess: async () => {
      await init();
    },
  });
  return mutation;
};

export const useToggleMemberAlert = () => {
  const { mutateAsync: init } = useMemberInfo();
  const mutation = useMutation("toggleMemberAlert", (payload: { alarm_status: boolean }) => client.put("/members/alarm", payload).then((res) => res.data), {
    onSuccess: async () => {
      await init();
    },
  });
  return mutation;
};
