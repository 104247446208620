import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "locale/en.json";
import translationKo from "locale/ko.json";
import translationJa from "locale/ja.json";
// import translationEs from "locale/es.json";

const resources: any = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  ja: {
    translation: translationJa,
  },
  // es: {
  //   translation: translationEs,
  // },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // 지원하는 언어(혹시 언어코드가 안맞아 날 오류 방지)
    supportedLngs: ["ko", "en", "ja", "es"],
    resources,
    // 지원언어가 없을때 기본 지원
    fallbackLng: "en",
    debug: false,
    // lanugage detector로 인해 자동인식 되므로 lng 부분은 작성하지 않는다.
  });

export default i18n;
