import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";

import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { Controller, useForm } from "react-hook-form";
import { HerdHistoryPayload } from "query/herd/type";
import { format } from "date-fns";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import DatePicker from "react-datepicker";
import { useAddHerdHistory, useEditStockHistoryDetail, useHerdHistoryCategory } from "query/herd";
import GroupSingleDatePicker from "components/pages/2_manage/stock/group/GroupSingleDatePicker";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { RoomHistoryType } from "query/rooms/type";

type Props = {
  historyId: number;
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  historyData: RoomHistoryType;
};

type Inputs = {
  category_id: number;
  change?: number;
  stock?: number;
  created_at: Date | null;
  memo: string;
};

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

const initialValue = {
  category_id: 0,
  change: 0,
  stock: 0,
  created_at: new Date(),
  memo: "",
};

function EditStockHistory(props: Props) {
  const { isToggle, setIsToggle, historyId, historyData } = props;
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState(new Date());

  const data = historyData?.active_herd?.historys?.find((v) => v.id === historyId);
  const [memoState, setMemoState] = useState(data?.memo ? String(data?.memo) : "");

  const { mutateAsync: editHerdHistory } = useEditStockHistoryDetail();

  const {
    register,
    // handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({ defaultValues: initialValue });

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    setIsToggle(false);
  };

  const handleTimePickerChange = (time: Date) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const handleSubmit = async () => {
    try {
      await editHerdHistory({
        history_id: Number(data?.id),
        payload: {
          memo: memoState,
        },
      });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
      setMemoState("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setMemoState(data.memo);
    }
  }, [isToggle]);

  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={(_, reason) => onClose(reason)}
        className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-[980px] bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("status.edit_herd_inventory_history")}</span>
            <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>

          <form className="flex flex-col">
            <div className="my-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_type")}</span>
                </label>

                <div
                  className="rounded-[8px] bg-ef-neutral-50 ef-body-lg text-ef-neutral-500 border border-solid border-ef-neutral-150 w-340pxr h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500"
                  onClick={() => clearErrors("category_id")}
                >
                  {data ? t(`common.herd_history_${data?.category.id}`) : "-"}
                </div>
              </div>

              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.moved_stock")}</span>
                </label>
                <div className="flex flex-row items-center relative w-full">
                  {errors.stock ? <RequiredAlert /> : <></>}
                  <div className="flex flex-row items-center" onClick={() => clearErrors("stock")}>
                    <div className="w-300pxr flex flex-col gap-8pxr">
                      {/* <Controller
                        name="stock"
                        // @todo 히스토리 데이터 필요, 작업종류 알 수 없음일 경우 수정 불가능
                        // disabled={}
                        disabled
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => ( */}
                      <InputFieldOnly
                        disabled
                        state={data?.change}
                        setState={() => {}}
                        // state={field.value}
                        // setState={(value) => {
                        //   field.onChange(value);
                        // }}
                        type="number"
                        placeholder={t("common.heads")}
                        regex={/^(?:[1-9]\d*|0)?$/}
                      />
                      {/* )}
                      /> */}
                    </div>
                    <span className="ml-4pxr">{t("common.head")}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.number_of_stocks")}</span>
                </label>
                <div className="flex flex-row items-center relative w-full">
                  {errors.change ? <RequiredAlert /> : <></>}
                  <div className="flex flex-row items-center" onClick={() => clearErrors("change")}>
                    <div className="w-300pxr flex flex-col gap-8pxr">
                      {/* <Controller
                        name="change"
                        control={control}
                        // @todo 히스토리 데이터 필요, 작업종류 입출기폐인 경우 수정 불가
                        // disabled={}
                        disabled
                        rules={{ required: true }}
                        render={({ field }) => ( */}
                      <InputFieldOnly
                        disabled
                        state={data?.stock}
                        setState={() => {}}
                        // state={field.value}
                        // setState={(value) => {
                        //   field.onChange(value);
                        // }}
                        type="number"
                        placeholder={t("common.heads")}
                        regex={/^(?:[1-9]\d*|0)?$/}
                      />
                      {/* )}
                      /> */}
                    </div>
                    <span className="ml-4pxr">{t("common.head")}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row">
                  <span className="line-clamp-1 break-all">{t("common.work_datetime")}</span>
                </label>
                <div className="w-full flex flex-row relative">
                  {/* {errors.created_at ? <RequiredAlert /> : <></>} */}
                  <div className="w-248pxr" onClick={() => clearErrors("created_at")}>
                    {/* <Controller
                      disabled
                      name="created_at"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => 
                      <GroupSingleDatePicker disabled date={new Date(field.value)} setDate={field.onChange} />
                      }
                      /> */}
                    <GroupSingleDatePicker disabled date={data?.created_at ? new Date(format(new Date(data?.created_at!), "yyyy-MM-dd")) : new Date()} setDate={() => {}} />
                  </div>
                  <div
                    className={
                      "ml-8pxr rounded-[8px] bg-ef-neutral-50 ef-body-lg text-ef-neutral-500 border border-solid border-ef-neutral-150 w-144pxr h-40pxr py-8pxr px-16pxr flex flex-row items-center focus-within:border-ef-primary-500"
                    }
                    onClick={(e) => e.stopPropagation()}
                  >
                    {data?.created_at && format(new Date(data?.created_at), "HH:mm")}
                    {/* <DatePicker
                      disabled
                      selected={data?.created_at ? new Date(format(new Date(data?.created_at), "yyyy-MM-dd'T'HH:mm:ss")) : new Date()}
                      // selected={format(new Date(), "HH:mm:ss")}
                      onChange={handleTimePickerChange}
                      showTimeSelect
                      autoComplete="on"
                      showTimeSelectOnly
                      dateFormat="HH:mm"
                      placeholderText="시간선택"
                      timeCaption="시간선택"
                      onKeyDown={(e: any) => {
                        if (e.keyCode === 229) e.preventDefault();
                        e.preventDefault();
                      }}
                      customInput={
                        <button className="grow flex flex-row items-center ">
                          <span className="grow w-88pxr text-left ef-body-lg">{format(selectedTime ? selectedTime : new Date(), "HH:mm")}</span>
                          <div className="w-24pxr h-24pxr mx-8pxr">
                            <ArrowDown className="stroke-ef-neutral-900 " />
                          </div>
                        </button>
                      }
                    /> */}
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <label className="w-77pxr min-w-[77px] mr-12pxr flex flex-row mt-11pxr">
                  <span className="line-clamp-1 break-all">{t("common.memo")}</span>
                </label>
                <div
                  className="w-514pxr h-130pxr"
                  //  onClick={() => clearErrors("memo")}
                >
                  <textarea value={memoState} onChange={(e) => setMemoState(e.target.value)} className="ef-textarea w-full h-full" placeholder={t("common.enter_memo")}></textarea>
                </div>
              </div>
            </div>
            <button type="button" disabled={memoState === data?.memo} onClick={handleSubmit} className="ef-btn-modal mx-auto">
              {t("common.save_btn")}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default EditStockHistory;
