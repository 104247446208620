import React, { useState } from "react";
import * as XLSX from "xlsx";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { ReactComponent as FileSend } from "assets/icons/general/ef-general-fileSend.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { useAddStandardList } from "query/farm";
import { toast } from "react-toastify";
import { ReactComponent as CloseCircle } from "assets/icons/general/ef-general-closeCircle.svg";
import { toBeRequired } from "@testing-library/jest-dom/matchers";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

type Inputs = {
  title: string;
  memo: string;
  file: FileList;
};

function UploadExcelModal(props: Props) {
  const { isToggle, setIsToggle } = props;
  const { t } = useTranslation();
  const { mutateAsync: addStandard } = useAddStandardList();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [fileBuffer, setFileBuffer] = useState({
    data: [],
    weight_grade: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const file = watch("file");

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      setFileBuffer({
        data: [],
        weight_grade: [],
      });
      setFileName(undefined);
      clearFile();
      reset();
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      alert("No file selected or file is not accessible.");
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);

        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json<any[]>(workbook.Sheets[sheetName], { header: 1 });

        const transformedData = transformData(worksheet);
        setFileName(file.name);

        // @ts-ignore
        setFileBuffer(transformedData);
      } catch (error) {
        toast.error(t("settings.file_not_fit_comment"));
      }
    };

    reader.onerror = () => {
      toast.error(t("settings.file_not_fit_comment"));
    };

    reader.readAsArrayBuffer(file);
  };

  const onSubmit = async (data: Inputs) => {
    const payload = {
      data: fileBuffer?.data!,
      weight_grade: fileBuffer?.weight_grade!,
      name: data.title,
      memo: data.memo,
    };
    try {
      //@ts-ignore
      await addStandard(payload);
      toast.success(t("common.save_completed"));
      setIsToggle(false);
      setFileBuffer({
        data: [],
        weight_grade: [],
      });
      setFileName(undefined);
      clearFile();
      reset();
      // setMemo("");
      // setTitle("");
    } catch (error) {}
  };

  const transformData = (worksheet: any[][]) => {
    const data = [];
    const weight_grade = [];

    for (let i = 3; i < 33; i++) {
      const row = worksheet[i];

      data.push({
        week: i - 3,
        start_day_age: row[1].split("~").map(Number)[0],
        end_day_age: row[1].split("~").map(Number)[1],
        lower_weight: row[2],
        upper_weight: row[3],
        lower_feeding: row[4],
        upper_feeding: row[5],
        lower_temperature: row[6],
        upper_temperature: row[7],
        lower_humidity: row[8],
        upper_humidity: row[9],
      });
    }

    for (let i = 37; i < 40; i++) {
      const row = worksheet[i];

      weight_grade.push({
        grade: row[0],
        lower_range: row[2],
        upper_range: row[3],
      });
    }

    return {
      data,
      weight_grade,
    };
  };

  const clearFile = () => {
    setFileName("");
    setFileBuffer({
      data: [],
      weight_grade: [],
    });

    const fileInput = document.getElementById("upload_file") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit max-h-[800px] overflow-y-auto bg-ef-neutral-white outline-none flex flex-col p-40pxr text-ef-neutral-900">
        <div className="w-full relative">
          <p className="ef-title-lg text-center">{t("settings.upload_data")}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 absolute right-0pxr top-0pxr">
            <GeneralClose />
          </button>
        </div>
        <form className="mt-40pxr flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-24pxr ef-label-md">
            <div className="flex flex-row items-center">
              <label className="w-101pxr min-w-[101px] mr-16pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("settings.title")}</span>
                {getRedStar()}
              </label>

              <div className="w-514pxr relative" onClick={() => clearErrors("title")}>
                {errors.title ? <RequiredAlert /> : <></>}
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <InputFieldOnly placeholder={t("settings.title")} state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-101pxr min-w-[101px] mr-16pxr flex flex-row mt-10pxr">
                <span className="line-clamp-1 break-all">{t("settings.upload_file")}</span>
                {getRedStar()}
              </label>
              <div className="w-514pxr flex flex-col gap-16pxr">
                <div className="flex flex-row gap-16pxr items-center">
                  <label htmlFor="upload_file" className="inline-block cursor-pointer">
                    <div className="ef-btn-r8-sm flex flex-row gap-8pxr">
                      <FileSend />
                      <span>{t("settings.attach_file")}</span>
                    </div>
                  </label>
                  <input id="upload_file" {...register("file", { required: true })} type="file" accept=".xlsx,.xls" className="hidden" onChange={(e) => handleFileUpload(e)} />
                  <span className="text-ef-neutral-700 ef-body-lg">{t("settings.file_format_comment")}</span>
                </div>

                <div
                  className="group rounded-[8px] border border-solid border-ef-neutral-150 w-full h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 stroke-ef-neutral-700 relative"
                  onClick={() => clearErrors("file")}
                >
                  {errors.file ? <RequiredAlert /> : <></>}
                  <div
                    // value={fileName}
                    spellCheck={false}
                    // onKeyDown={(e) => {
                    //   e.preventDefault();
                    // }}
                    className="mr-8pxr w-0pxr grow focus:outline-none caret-ef-primary-900 ef-body-lg placeholder-ef-neutral-300 disabled:bg-ef-neutral-50"
                  />
                  <p className="flex w-full">{fileName && `${fileName}`}</p>
                  {fileBuffer.data.length !== 0 ? (
                    <button type="button" onClick={(e) => clearFile()} className="cursor-pointer">
                      <CloseCircle />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="px-18pxr py-24pxr rounded-[8px] bg-ef-neutral-50 ef-body-sm">
                  <ul className="[&>*]:list-disc pl-18pxr">
                    <li>{t("settings.file_quide_1")}</li>
                    <li>{t("settings.file_quide_2")}</li>
                    <li>{t("settings.file_quide_3")}</li>
                    <li>{t("settings.file_quide_4")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <label className="w-101pxr min-w-[101px] mr-16pxr flex flex-row mt-10pxr">
                <span className="line-clamp-1 break-all">{t("common.memo")}</span>
              </label>
              <textarea {...register("memo")} className="ef-textarea !w-514pxr h-80pxr" placeholder={t("common.enter_memo")}></textarea>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal !w-494pxr mx-auto mt-60pxr">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default UploadExcelModal;
