import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";

type Props = {
  title: any;
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  options: Option[] | [];
  disabled?: boolean;
  placeholder?: string;
};

type Option = {
  value: string | number;
  name: any;
};

function DropDownFilter(props: Props) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const { title, options, state, setState, disabled = false, placeholder = "" } = props;
  const [toggleSelect, setToggleSelect] = useState(false);

  // 외부클릭 반응
  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setToggleSelect(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="relative" ref={divRef}>
      <button
        disabled={disabled}
        onClick={() => setToggleSelect((prev) => !prev)}
        className={
          "group w-197pxr h-56pxr border border-solid rounded-[8px] flex flex-row py-8pxr pr-4pxr pl-16pxr items-center text-left " +
          (toggleSelect
            ? "border-ef-primary-500 bg-ef-primary-50"
            : "bg-ef-neutral-white border-ef-neutral-150 hover:border-ef-primary-500 hover:ef-main-shadow active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:hover:shadow-none")
        }
      >
        <div className="grow overflow-hidden flex flex-col">
          <span className="ef-label-sm text-ef-primary-500 group-disabled:text-ef-neutral-500 whitespace-nowrap text-ellipsis overflow-hidden">{title}</span>
          <div className="grow ef-body-xl text-ef-neutral-900 group-disabled:text-ef-neutral-500 line-clamp-1">{state === null ? placeholder : options?.find((o) => o.value === state)?.name}</div>
        </div>
        <div className="w-24pxr h-24pxr mx-8pxr">
          {toggleSelect ? <ArrowUp className="stroke-ef-neutral-900" /> : <ArrowDown className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-500" />}
        </div>
      </button>
      {toggleSelect && (
        <div className="z-[1500] w-197pxr py-8pxr h-fit max-h-[464px] overflow-y-auto rounded-[8px] ef-body-xl bg-ef-neutral-white ef-main-shadow absolute mt-4pxr border border-solid border-ef-neutral-150">
          {options.length > 0 &&
            options.map((o) => (
              <button
                type="button"
                key={o.value}
                className="w-full cursor-pointer p-16pxr hover:bg-ef-primary-50"
                onClick={() => {
                  setToggleSelect(false);
                  setState(o.value);
                }}
              >
                <div className="w-full h-full text-left">
                  <span className="line-clamp-1 break-all">{o.name}</span>
                </div>
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

export default DropDownFilter;
