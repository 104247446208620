import ko from "date-fns/locale/ko";
import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";

// 달력 혹은 format 다국어 처리시 현재 선택 언어에 맞춰 넣어주는 함수
export function datePickerLocale() {
  const locale = localStorage.getItem("i18nextLng");
  switch (locale) {
    case "ko":
      return ko;
    case "ja":
      return ja;
    default:
      return enUS;
  }
}
