import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DailyReport, EfgCamDetailView, PiggeryView, ReportCategory } from "./type";

// 오류신고 생성
export const useReportError = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("reportError", (object: { id: number; complaint: string }) => client.put(`/movement/${object.id}/complaint`, { complaint: object.complaint }).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["movementList"]);
      queryClient.invalidateQueries(["movementDetail"]);
    },
  });
  return mutation;
};

// 카카오 일간보고서용 ===================================================================
export const useDailyReport = (token: string) => {
  const query = useQuery(["dailyReport", token], () => client.get<DailyReport>("/reports/piggeries/operation-reports/share", { params: { token } }).then((res) => res.data), {
    enabled: token !== "",
    onSuccess(data) {},
  });
  return query;
};

// 카톡 일간보고서 전체이미지
export const useReportPiggeryView = (token: string, base_datetime?: string) => {
  const query = useQuery(["reportPiggeryView", token, base_datetime], () => client.get<PiggeryView>("/reports/piggeries/monitoring", { params: { token, base_datetime } }).then((res) => res.data), {
    enabled: token !== "" && base_datetime !== "",
    onSuccess(data) {},
  });
  return query;
};

// 카톡 일간보고서 카메라 상세
export const useReportCamView = (token: string, efg_camera_id: number) => {
  const query = useQuery(["reportCamView", token, efg_camera_id], () => client.get<EfgCamDetailView>(`/reports/efg/camera/${efg_camera_id}`, { params: { token } }).then((res) => res.data), {
    enabled: token !== "" && efg_camera_id !== 0,
    onSuccess(data) {},
  });
  return query;
};
