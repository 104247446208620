import { AxiosError } from "axios";
import ComponentSpinner from "components/common/spinner/spinner";
import DetailDailyReport from "components/pages/0_kakao_report/daily/DetailDailyReport";
import SummaryDailyReport from "components/pages/0_kakao_report/daily/SummaryDailyReport";
import { format } from "date-fns";
import { useDailyReport, useReportPiggeryView } from "query/report";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { datePickerLocale } from "utils/useDatepicker";

function MobileDailyReport() {
  const [selectedMenu, setSelectedMenu] = useState<"summary" | "detail">("summary");
  const [isUnAuthorized, setIsUnAuthorized] = useState(false);
  const location = useLocation();
  const urlToken = new URLSearchParams(location.search).get("token");

  const { t } = useTranslation();

  const { data: reportData, error: reportError, isLoading } = useDailyReport(urlToken ?? "");
  const { error: imgError } = useReportPiggeryView(urlToken ?? "");

  // 401 unauthorized에러일때 안내문구 띄우기
  useEffect(() => {
    if ((reportError as AxiosError)?.response?.status === 401) {
      setIsUnAuthorized(true);
    } else if ((imgError as AxiosError)?.response?.status === 401) {
      setIsUnAuthorized(true);
    } else {
      setIsUnAuthorized(false);
    }
  }, [reportError, imgError]);

  return (
    <div className="w-screen h-screen overflow-x-hidden">
      <div className="w-full max-w-[360px] h-full mx-auto flex flex-col relative">
        {isLoading && <ComponentSpinner />}
        {isUnAuthorized ? (
          <div className="w-full h-full flex items-center justify-center">
            <p className="text-center ef-display-sm">
              {t("report.cannot_view_report_comment")
                .split("\n")
                .map((line: any, index: number) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
        ) : (
          <>
            <div className="w-full h-fit bg-ef-neutral-white sticky top-0pxr z-[15]">
              <p className="my-16pxr ef-title-md text-center">
                {reportData
                  ? `[${reportData.piggery.name}] ${format(new Date(reportData.date), "MMM do", {
                      locale: datePickerLocale(),
                    })} ${t("report.report")}`
                  : t("common.no_info")}
              </p>
              <div className="w-full border-b border-solid border-ef-neutral-150"></div>
              <div className="mt-16pxr flex flex-row ef-label-lg text-ef-neutral-500 relative cursor-pointer border-b border-solid border-ef-neutral-150">
                <div className={"w-1/2 h-3pxr absolute bottom-0pxr bg-ef-primary-500 transition-all ease-out duration-300 " + (selectedMenu === "summary" ? "" : "translate-x-full")}></div>
                <div
                  onClick={() => setSelectedMenu("summary")}
                  style={{
                    color: selectedMenu === "summary" ? "#033687" : "",
                  }}
                  className="w-1/2 flex justify-center py-12pxr"
                >
                  {t("report.summary_information")}
                </div>
                <div
                  onClick={() => setSelectedMenu("detail")}
                  style={{
                    color: selectedMenu === "detail" ? "#033687" : "",
                  }}
                  className="w-1/2 flex justify-center py-12pxr"
                >
                  {t("report.detailed_information")}
                </div>
              </div>
            </div>
            <div className="pt-40pxr px-20pxr pb-100pxr">
              {selectedMenu === "summary" ? <SummaryDailyReport data={reportData} token={urlToken ?? ""} /> : selectedMenu === "detail" ? <DetailDailyReport data={reportData} /> : <></>}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MobileDailyReport;
