import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRoomDropdownListQR } from "query/rooms";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCancelHerdHistoryQR } from "query/herd";

function StockStatus() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const piggeryId = state?.piggeryId;
  const cancelData = state?.cancelData || [];
  const [timeLeft, setTimeLeft] = useState(() => {
    if (!state?.createdAt) return 0;
    
    const createdTime = new Date(state.createdAt).getTime();
    const currentTime = new Date().getTime();
    const elapsedSeconds = Math.floor((currentTime - createdTime) / 1000);
    const remainingSeconds = Math.max(30 - elapsedSeconds, 0);
    
    return remainingSeconds;
  });
  const [showCancelBar, setShowCancelBar] = useState(() => {
    return !!cancelData && timeLeft > 0;
  });
  
  const { data: roomList } = useRoomDropdownListQR(Number(piggeryId), [1]);
  const { mutate: cancelHerdHistoryQR } = useCancelHerdHistoryQR();

  const handleRoomClick = (roomId: number) => {
    navigate(`/qr/stock?piggery_id=${piggeryId}&room_id=${roomId}`);
  };
  console.log(state)
  // 타이머 설정
  useEffect(() => {
    if (!cancelData || timeLeft === 0) return;

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowCancelBar(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [cancelData, timeLeft]);

  // 취소 요청 처리
  const handleCancel = async () => {
    try {
      await cancelHerdHistoryQR({payload: cancelData});
      toast.success(t('qr.cancel_completed'));
    } catch (error: any) {
      if (error.response) {
        toast.error(t("common.an_error_occurred"));
      }
    }
  };

  const getSnackbarText = () => {
    if (cancelData) {
      if (state?.categoryId === 1 || state?.categoryId === 2) {
        return `${t(`common.herd_history_${state?.categoryId}`)} +${state?.change}`
      } else if(state?.categoryId === 7 && state?.change > 0){
        return `${t(`common.herd_history_${state?.categoryId}`)} +${state?.change}`
      } else {
        return `${t(`common.herd_history_${state?.categoryId}`)} ${state?.change}`
      }
    }
  }

  useEffect(() => {
    // 새로고침으로 진입한 경우 cancelData만 초기화
    const navEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    if (navEntry.type === 'reload') {
      navigate('/qr/stock/status', { 
        replace: true, 
        state: { ...state, cancelData: [], createdAt: null }
      });
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col min-h-0 relative">
      {/* snackbar */}
      {showCancelBar && cancelData && (
        <div className="absolute bottom-16pxr left-1/2 -translate-x-1/2 w-328pxr h-48pxr flex flex-row gap-8pxr px-16pxr justify-start items-center rounded-[8px] bg-ef-primary-50"
              onClick={() => {setShowCancelBar(false);}}>
          <span className="ef-body-md text-ef-primary-500">
            {state?.roomName}
          </span>
          <span className="ef-body-sm text-ef-primary-500">
            {getSnackbarText()}
          </span>
          <button
            className="ml-auto ef-body-md text-ef-secondary-500"
            onClick={handleCancel}
          >
            {t('common.cancel')} (00:{timeLeft.toString().padStart(2, '0')})
          </button>
        </div>
      )}
      <div className="flex-none w-full h-56pxr flex items-center justify-center">
        <span className="ef-title-md text-ef-neutral-900">{t('qr.piggery_stock_status')}</span>
      </div>
      <div className="flex-none w-full h-1pxr bg-ef-neutral-150" />
      <div className="flex-none h-56pxr px-24pxr flex items-center justify-between">
        <span className="ef-body-xl text-ef-neutral-900">{roomList?.[0]?.piggery.name} {t('qr.total_stock')}</span>
        <span className="ef-body-xl text-ef-primary-500">{roomList?.reduce((acc, room) => acc + room.stock, 0)}</span>
      </div>
      <div className="flex-none w-full h-1pxr bg-ef-neutral-150" />
      
      {/* 스크롤 가능한 목록 영역 */}
      <div className="flex-1 overflow-y-auto min-h-0 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <div className="w-full pl-40pxr flex flex-col">
          {roomList?.map((room) => (
            <React.Fragment key={room.id}>
              <div 
                className="h-56pxr pr-24pxr flex items-center justify-between cursor-pointer"
                onClick={() => handleRoomClick(room.id)}
              >
                <span className="ef-body-lg text-ef-neutral-900">[{room.piggery.name}] {room.name}</span>
                <span className="ef-body-lg text-ef-primary-500">{room.stock}</span>
              </div>
              <div className="w-full h-1pxr bg-ef-neutral-150" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StockStatus;