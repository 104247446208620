import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { format, setHours } from "date-fns";
import NoImg from "assets/images/report/no_report_data.png";
import CamDetailModal from "./CamDetailModal";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useReportPiggeryView } from "query/report";
import { useTranslation } from "react-i18next";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: string;
  token: string;
};

const timeOptions = Array.from({ length: 24 }, (_, value) => {
  const name = `${value.toString().padStart(2, "0")}:00 ~ ${value.toString().padStart(2, "0")}:59`;
  return { value, name };
});

function ViewAllModal(props: Props) {
  const { isToggle, setIsToggle, selectedDate, token } = props;

  const { t } = useTranslation();

  const [selectedTime, setSelectedTime] = useState(12);
  const [toggleCamModal, setToggleCamModal] = useState(false);
  const [selectedCam, setSelectedCam] = useState(0);

  const { data: piggeryImgList } = useReportPiggeryView(token ?? "-", selectedDate !== "" ? format(setHours(new Date(selectedDate), selectedTime), "yyyy-MM-dd'T'HH:00:00") : "");
  const imgArr = piggeryImgList ? piggeryImgList.efg_camera : [];

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setSelectedTime(0);
      setIsToggle(false);
    }
  };
  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center"
    >
      <div className="w-full max-w-[360px] overflow-y-auto h-full mx-auto bg-ef-neutral-white flex flex-col outline-none">
        <CamDetailModal isToggle={toggleCamModal} setIsToggle={setToggleCamModal} camId={selectedCam} token={token ?? "-"} />
        <div className="z-[99] sticky top-0pxr bg-ef-neutral-white p-16pxr flex flex-row ef-title-md text-ef-neutral-900 border-b border-solid border-ef-neutral-150">
          <span className="break-all line-clamp-1">{`[${piggeryImgList?.piggery.name ?? t("common.piggery")}] ${t("report.view_all_images")}`}</span>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 ml-auto">
            <GeneralClose />
          </button>
        </div>
        <div className="flex flex-row mx-auto items-center mt-24pxr gap-16pxr">
          <span className="ef-label-lg">{t("report.images_by_time")}</span>
          <DropDownSmall state={selectedTime} setState={setSelectedTime} options={timeOptions} />
        </div>
        <div className="grid grid-cols-1 gap-16pxr pt-24pxr px-20pxr pb-180pxr">
          {imgArr.map((o) => (
            <div
              onClick={() => {
                setSelectedCam(o.id);
                setToggleCamModal(true);
              }}
              key={o.id}
              className="w-full h-fit cursor-pointer"
            >
              <div className="w-full aspect-video rounded-t-[8px] relative">
                <div className="w-full h-full bg-ef-neutral-50 absolute top-0pxr rounded-t-[8px] flex flex-col items-center justify-center">
                  <img src={NoImg} className="w-140pxr" alt="no-data" />
                  <span className="mt-10pxr ef-body-sm text-ef-neutral-700">{o.history.length === 0 ? t("common.no_data") : t("common.image_path_not_found")}</span>
                </div>
                <img src={o.history[0] ? o.history[0].image_path : ""} onError={(e) => (e.currentTarget.hidden = true)} className="w-full rounded-t-[8px] relative top-0pxr" alt={o.id.toString()} />
              </div>
              <div className="w-full border-b border-x border-solid border-ef-neutral-150 p-16pxr flex flex-row items-center rounded-b-[8px]">
                <div className="flex flex-row gap-8pxr items-center">
                  <span className="ef-body-xl">{o.room.name}</span>
                  <span className="ef-body-lg">{o.name}</span>
                </div>
                <span className="ml-auto text-ef-neutral-700 ef-body-lg">{typeof o.history[0] !== "undefined" ? format(new Date(o.history[0].created_at), "HH:mm:ss") : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ViewAllModal;
