import { Modal } from "@mui/material";
import React from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RoomDropDown from "./RoomDropDown";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { toast } from "react-toastify";
import { useAddFeeder, useFeederCompanyList } from "query/feeder";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

type Inputs = {
  name: string;
  location: {
    piggery: number;
    room: number;
  };
  company: number | null;
};

function AddFeederModal(props: Props) {
  const { isToggle, setIsToggle } = props;
  const { t } = useTranslation();

  const { mutateAsync: addFeeder } = useAddFeeder();
  const { data: companyList } = useFeederCompanyList(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      company: null,
    },
  });

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };
  const onSubmit = async (data: Inputs) => {
    const formatted = {
      name: data.name,
      room_id: data.location.room,
      company_id: data.company!,
      // serial_number: null,
      // location: [],
    };

    try {
      await addFeeder(formatted);
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("manage.add_feeder")}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <span className="mt-24pxr ef-body-lg text-ef-neutral-700 mx-auto w-528pxr text-center">
          <Trans
            components={{ 1: <span onClick={() => window.open("https://intflow.ai/contact/", "_blank")} className="text-ef-primary-500 ef-label-lg cursor-pointer underline underline-offset-4" /> }}
            i18nKey="manage.contact_feeder_comment"
          />
        </span>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="mt-40pxr mb-60pxr flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("common.feeder")} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span title={t("common.feeder_company")} className="line-clamp-1 break-all">
                  {t("common.feeder_company")}
                </span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("location")}>
                {errors.company ? <RequiredAlert /> : <></>}
                <Controller
                  name="company"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DropDownSmall
                      state={field.value}
                      setState={field.onChange}
                      placeholder={t("common.feeder_company")}
                      options={
                        companyList
                          ? companyList.map((o) => {
                              return { value: o.id, name: o.name };
                            })
                          : []
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span title={t("manage.installed_location")} className="line-clamp-1 break-all">
                  {t("manage.installed_location")}
                </span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("location")}>
                {errors.location ? <RequiredAlert /> : <></>}
                <Controller
                  name="location"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <RoomDropDown title={t("common.room")} state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default AddFeederModal;
