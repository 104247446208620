import React from "react";
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

type Props = {
  text: string;
};

function DefaultTooltip(props: Props) {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <div className="absolute z-[999] top-[-28px] rounded-[8px] h-36pxr bg-[#F8f8f8] flex flex-row items-center stroke-ef-neutral-700 text-ef-neutral-700 py-8pxr px-16pxr border border-solid border-ef-neutral-700 ef-sub-shadow ef-label-md">
      <Info className=" min-w-[16px] mr-4pxr" />
      <span className="whitespace-nowrap">{text}</span>
    </div>
  );
}

export default DefaultTooltip;
