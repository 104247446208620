import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { toast } from "react-toastify";
import { useEditFeeder, useFeederDetail } from "query/feeder";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  deviceId: number;
};

type Inputs = {
  name: string;
  type: string;
  location: string;
  network_status: string;
  id: number;
};

function FeederDetail(props: Props) {
  const { isToggle, setIsToggle, deviceId } = props;
  const { t } = useTranslation();

  const { data: feederData } = useFeederDetail(deviceId);
  const { mutateAsync: editFeeder } = useEditFeeder();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  const onSubmit = async (data: Inputs) => {
    try {
      await editFeeder({ payload: { name: data.name }, feederId: deviceId });
      onClose("closeButtonClick");
      toast.success(t("common.save_completed"));
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  useEffect(() => {
    if (feederData) {
      reset({
        name: feederData.name,
        type: feederData.company.name,
        location: `[${feederData.room.piggery.name}] ${feederData.room.name}`,
        network_status: feederData.connected === null ? t("common.not_supported") : feederData.connected ? t("common.normal") : t("common.abnormal"),
        id: feederData.id,
      });
    }
  }, [feederData]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("common.feeder")} ${t("common.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="my-60pxr flex flex-col ef-label-md text-ef-neutral-900 flex-wrap gap-24pxr">
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                  render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("common.feeder_company")}</label>
              <div className="w-340pxr">
                <Controller name="type" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.installed_location")}</label>
              <div className="w-340pxr">
                <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label title={t("common.device_connection_status")} className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr break-all">
                {t("common.device_connection_status")}
              </label>
              <div className="w-340pxr">
                <Controller name="network_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">ID</label>
              <div className="w-340pxr">
                <Controller name="id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default FeederDetail;
