import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthState } from "context/AuthContext";
import { SensorType, SensorHistory } from "./type";

// 환경센서 list get
export const useEnvSensorList = (piggeries?: number[], connected?: (boolean | string)[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["envSensorList", piggeries, connected], () => client.get<SensorType[]>("/sensors", { params: { piggeries, connected } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 환경센서 기록 get
export const useSensorHistory = (obj: { piggery_id: number; rooms: number[]; start_date: string; end_date: string }) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["sensorHistory", obj], () => client.get<SensorHistory[]>("/sensors/historys", { params: obj }).then((res) => res.data), {
    enabled: current_farm.id !== 0 && obj.piggery_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 환경센서 단일 get
export const useSensorDetail = (sensorId: number) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["sensorDetail", sensorId], () => client.get<SensorType>(`/sensors/${sensorId}`).then((res) => res.data), {
    enabled: current_farm.id !== 0 && sensorId !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 환경센서 수정
export const useEditSensor = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editSensor", (obj: { sensorId: number; payload: { name: string } }) => client.put(`/sensors/${obj.sensorId}`, obj.payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["envSensorList"]);
      queryClient.invalidateQueries(["sensorDetail"]);
    },
  });
  return mutation;
};
