import React, { useEffect, useState } from "react";
// library
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useSSR, useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// component
import InputFieldOnly from "components/common/input/InputFieldOnly";
// context
import { useAuthState } from "context/AuthContext";
import { useEditContext } from "context/EditContext";
// query
import { useToggleMemberAlert, useUpdateMember } from "query/member";
import RequiredAlert from "components/common/alert/RequiredAlert";
// icon
import { ReactComponent as Info } from "assets/icons/general/ef-general-info.svg";
import { getRedStar } from "utils/getImportantStar";

type Inputs = {
  id: string;
  name: string;
  phone: string;
  email: string;
};

const GrayTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F8F8F8",
    color: "#5C5C5C",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #5C5C5C",
    borderRadius: 8,
    padding: "8px 16px",
    fontFamily: "Pretendard",
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F5FAFF",
    color: "#033687",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #033687",
    borderRadius: 8,
    padding: "8px 16px",
    fontFamily: "Pretendard",
  },
}));

function Account() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const editContext = useEditContext();
  const { mutateAsync: updateMember } = useUpdateMember();
  const { mutateAsync: toggleAlarm } = useToggleMemberAlert();

  const initialState = {
    id: user.username,
    name: user.name,
    phone: user.phone ? user.phone : "",
    email: user.email ? user.email : "",
  };

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: initialState });

  const phone = watch("phone");
  const currentobj = { id: watch("id"), name: watch("name"), phone, email: watch("email") };

  // 폼 제출시 데이터 put
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const payload = {
      name: data.name,
      phone: data.phone === "" ? null : data.phone,
      email: data.email === "" ? null : data.email,
    };
    try {
      await updateMember(payload);
      if (payload.phone === null) {
        await toggleAlarm({
          alarm_status: false,
        });
      }
      toast.success(t("common.save_completed"));
    } catch (error) {
      console.log(error);
    }
  };

  // 알림톡 수신설정 토글
  const handleToggleAlarm = async () => {
    let state: boolean;
    if (user.alarm_status) state = false;
    else state = true;

    try {
      await toggleAlarm({
        alarm_status: state,
      });
      toast.success(state ? t("settings.kakao_abled_alert") : t("settings.kakao_disabled_alert"));
    } catch (error) {
      console.log(error);
    }
  };

  // 전화번호 입력시 자동으로 슬래시 입력
  useEffect(() => {
    if (phone) {
      const numericPhone = phone.replace(/\D/g, "");

      if (numericPhone.length === 10) {
        setValue("phone", numericPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      } else if (numericPhone.length === 11) {
        setValue("phone", numericPhone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      }
    }
  }, [phone]);

  // 수정후 이동시 edit context에 추가하여 알려주기용
  useEffect(() => {
    if (JSON.stringify(initialState) === JSON.stringify(currentobj)) {
      editContext?.setEditState(false);
    } else editContext?.setEditState(true);
  }, [currentobj, initialState]);

  useEffect(() => {
    const { username: id, name, phone, email } = user;
    reset({
      id,
      name,
      phone: phone ? phone : "",
      email: email ? email : "",
    });
  }, [user, reset]);

  return (
    <div className="bg-ef-neutral-50 w-full h-full">
      <div className="w-948pxr mx-auto pt-60pxr pb-200pxr">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            {/* title+btn */}
            <div className="flex flex-row">
              <span className="ef-headline-lg text-ef-neutral-900">{t("gnb.account_settings")}</span>
            </div>
            {/* body */}
            <div className="mt-40pxr flex flex-col flex-wrap gap-24pxr ef-body-xl text-ef-neutral-900 mb-60pxr">
              <div className="flex flex-row items-center">
                <label className="w-147pxr mr-12pxr line-clamp-1 break-all">{t("settings.id")}</label>
                <div className="w-789pxr">
                  <Controller name="id" control={control} rules={{ required: true }} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-147pxr min-w-[147px] mr-12pxr flex flex-row">
                  <span className="break-all line-clamp-1">{t("settings.member_name")}</span>
                  {getRedStar()}
                </label>
                <div className="w-789pxr relative" onClick={() => clearErrors("name")}>
                  {errors.name ? <RequiredAlert /> : <></>}
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true, validate: { nameTrim: (value: string) => value.trim() !== "" } }}
                    render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-147pxr mr-12pxr line-clamp-1 break-all">{t("settings.email")}</label>
                <div className="w-789pxr">
                  <Controller name="email" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} type="email" />} />
                </div>
              </div>
              {/* divider */}
              <div className="w-full h-1pxr bg-ef-neutral-150"></div>
              <div className="flex flex-row items-center">
                <label className="w-147pxr mr-12pxr line-clamp-1 break-all">{t("settings.contact")}</label>
                <div className="w-789pxr">
                  <Controller name="phone" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} regex={/^[0-9\b -]{0,13}$/} />} />
                </div>
              </div>
              <div className="flex flex-row items-center ">
                <div className="flex flex-row gap-4pxr items-center w-147pxr mr-12pxr">
                  <label title={t("settings.kakaotalk_reception_settings")} className="w-102pxr line-clamp-1 break-all">
                    {t("settings.kakaotalk_reception_settings")}
                  </label>
                  <HtmlTooltip
                    placement="top-start"
                    title={
                      <div className="ef-label-md whitespace-pre-line">
                        {t("settings.talk_tooltip_1")}
                        <br />
                        {t("settings.talk_tooltip_2")}
                        <br />
                        {t("settings.talk_tooltip_3")}
                        <br />
                        {t("settings.talk_tooltip_4")}
                      </div>
                    }
                  >
                    <div className="cursor-pointer stroke-ef-neutral-700">
                      <Info />
                    </div>
                  </HtmlTooltip>
                </div>
                <GrayTooltip
                  placement="top"
                  title={
                    user.phone === "" || user.phone === null ? (
                      <div className="ef-label-md flex flex-row gap-4pxr items-center">
                        <Info className="stroke-ef-neutral-700" />
                        <span>{t("settings.contact_not_saved_alert")}</span>
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>
                    <button
                      type="button"
                      disabled={user.phone === "" || user.phone === null}
                      onClick={() => handleToggleAlarm()}
                      className="w-120pxr h-40pxr rounded-[8px] border border-solid border-ef-neutral-150 bg-ef-neutral-white p-4pxr flex flex-row text-ef-neutral-200 relative ef-label-sm disabled:cursor-not-allowed"
                    >
                      <div
                        className={
                          "w-55pxr h-30pxr rounded-[6px] absolute transition-transform duration-300 " + (user.alarm_status ? "bg-ef-primary-500 translate-x-full" : "bg-ef-neutral-400 translate-x-0")
                        }
                      ></div>
                      <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (!user.alarm_status && "text-ef-neutral-white")}>OFF</div>
                      <div className={"w-1/2 flex items-center justify-center h-full z-[10] " + (user.alarm_status && "text-ef-neutral-white")}>ON</div>
                    </button>
                  </span>
                </GrayTooltip>
              </div>
            </div>
            <button className="ef-btn-modal mx-auto" type="submit">
              {t("common.save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Account;
