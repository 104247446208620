import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/general/ef-general-arrowDown.svg";
import { ReactComponent as ArrowUp } from "assets/icons/general/ef-general-arrowUp.svg";
import { ReactComponent as Search } from "assets/icons/general/ef-general-search.svg";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { usePiggeryStructure } from "query/piggery";

type Props = {
  title: string;
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
};

function RoomDropDown(props: Props) {
  const { title, state, setState } = props;

  const openRef = useRef<HTMLDivElement>(null);
  const [openState, setOpenState] = useState(false);
  const [titleState, setTitleState] = useState(title);
  const [searchState, setSearchState] = useState("");
  const { t } = useTranslation();
  const { data: piggeryOption } = usePiggeryStructure();

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (openRef.current && !openRef.current.contains(e.target as Node)) {
        setOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openRef]);

  const debouncedHandleChange = _.debounce((value) => {
    setSearchState(value.toLowerCase().trim());
  }, 300);

  return (
    <div className="realtive ef-body-lg text-ef-neutral-900" ref={openRef}>
      <button
        type="button"
        onClick={() => setOpenState((prev) => !prev)}
        className={
          "group px-16pxr py-8pxr w-340pxr h-40pxr flex flex-row rounded-[8px] border border-solid " +
          (openState
            ? "bg-ef-primary-50 border-ef-primary-500"
            : "border-ef-neutral-150 hover:border-ef-primary-500 hover:ef-main-shadow active:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150 disabled:hover:shadow-none disabled:text-ef-neutral-500 bg-ef-neutral-white")
        }
      >
        <span className={"overflow-hidden text-ellipsis whitespace-nowrap mr-8pxr break-all " + (titleState === title ? "text-ef-neutral-300" : "")}>{titleState}</span>
        {openState ? <ArrowUp className="stroke-ef-neutral-700 ml-auto min-w-[24px]" /> : <ArrowDown className="stroke-ef-neutral-700 ml-auto group-disabled:stroke-ef-neutral-500 min-w-[24px]" />}
      </button>
      {openState ? (
        <div className="z-[1500] absolute mt-4pxr w-480pxr h-fit rounded-[8px] bg-ef-neutral-white border border-solid border-ef-neutral-150 p-16pxr ef-main-shadow flex flex-col flex-wrap gap-8pxr">
          <div className="flex flex-row py-8pxr px-16pxr bg-ef-neutral-white border border-solid border-ef-neutral-150 rounded-[8px]">
            <input
              type="text"
              onChange={(e) => debouncedHandleChange(e.target.value)}
              className="grow mr-8pxr ef-body-lg placeholder:text-ef-neutral-300 text-ef-neutral-900 focus:outline-none"
              placeholder={t("manage.select_place_comment")}
            />
            <Search className="stroke-ef-neutral-700" />
          </div>
          <table className="ef-table-sm block w-full">
            <thead>
              <tr>
                <th className="w-240pxr max-w-[240px]">{t("common.piggery_name")}</th>
                <th className="w-240pxr max-w-[240px]">{t("common.room_name")}</th>
              </tr>
            </thead>
            <tbody className="cursor-pointer block max-h-[320px] overflow-y-auto scrollbar-hide rounded-[8px]">
              {piggeryOption?.map(
                (p) =>
                  p.rooms
                    ?.filter((j) => j.category?.id === 1)
                    .filter((i) => p.name.toLowerCase().includes(searchState) || i.name.toLowerCase().includes(searchState))
                    .map((o) => (
                      <tr
                        key={o.id}
                        className="hover:!bg-ef-primary-50"
                        onClick={() => {
                          setTitleState(`[${p.name}] ${o.name}`);
                          setState({ piggery: p.id, room: o.id });
                          setOpenState(false);
                        }}
                      >
                        <td className="w-240pxr max-w-[240px]">{p.name}</td>
                        <td className="w-240pxr max-w-[240px]">{o.name}</td>
                      </tr>
                    )),
              )}
              {piggeryOption?.every((o) => o.rooms?.every((j) => !j.name.toLowerCase().includes(searchState)) && !o.name.toLowerCase().includes(searchState)) && (
                <tr key="no-results">
                  <td className="!h-320pxr w-575pxr">{t("manage.no_result_found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RoomDropDown;
