import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import client from "query/client";
import useLogout from "hooks/useLogout";
import { useMemberInfo } from "query/member";
import { useEditContext } from "context/EditContext";

function App() {
  const logout = useLogout();
  const navigate = useNavigate();
  const { mutateAsync: init } = useMemberInfo();
  const token = localStorage.getItem("Authorization");
  const farmToken = localStorage.getItem("farm-id");
  const location = useLocation();
  const editContext = useEditContext();

  useEffect(() => {
    editContext?.setEditState(false);
  }, [location.pathname]);

  useEffect(() => {
    const verifyLogin = async () => {
      try {
        if (token && farmToken) {
          client.defaults.headers.common["Authorization"] = `bearer ${token}`;
          client.defaults.headers.common["farm-id"] = `${farmToken}`;
          await init();
        } else if (token && farmToken === null) {
          client.defaults.headers.common["Authorization"] = `bearer ${token}`;
          await init();
          navigate("/select");
        } else if (token === null && farmToken) {
          client.defaults.headers.common["farm-id"] = "";
          localStorage.removeItem("farm-id"); // 농장 토큰 제거
        }
      } catch (e) {
        console.log("something wrong");
        // logout();
      }
    };

    verifyLogin();
  }, [token, farmToken]);

  return token ? <Outlet /> : <Navigate to="/login" />;
}

export default App;
