import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { useTranslation } from "react-i18next";
import { getRedStar } from "utils/getImportantStar";
import { Controller, useForm } from "react-hook-form";
import { useEditReportCalibrate } from "query/movement";
import { toast } from "react-toastify";
import RequiredAlert from "components/common/alert/RequiredAlert";
import animationData from "assets/images/spinner/spinner_animation.json";
import Lottie from "lottie-react";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  currentWeight: number;
  stock: number;
  movement_id: number;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Inputs = {
  avg: number;
  total: string;
};

function WeightCorrectionModal(props: Props) {
  const { isToggle, setIsToggle, currentWeight, stock, movement_id } = props;

  const { t } = useTranslation();

  const { mutateAsync: editWeight } = useEditReportCalibrate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const [isLoading, setIsLoading] = useState(false);

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      reset();
      setIsToggle(false);
    }
  };

  const onSubmit = async (data: Inputs) => {
    if (movement_id !== 0) {
      setIsLoading(true);
      try {
        await editWeight({
          id: movement_id,
          payload: {
            calibrated_avg_weight: data.avg,
          },
        });
        toast.success(t("common.save_completed"));
        onClose("closeButtonClick");
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const avg_weight = watch("avg");

  useEffect(() => {
    setValue("avg", Number(currentWeight.toFixed(2)));
  }, [isToggle]);

  useEffect(() => {
    setValue("total", (avg_weight * stock).toFixed(2));
  }, [avg_weight]);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030",
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-580pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        <div className="w-full relative flex flex-col items-center">
          <p className="ef-title-lg text-center">{t("manage.average_weight_correction")}</p>
          <button onClick={() => onClose("closeButtonClick")} type="button" className="stroke-ef-neutral-900 right-0pxr top-0pxr absolute">
            <GeneralClose />
          </button>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-[271px]">
              <Lottie animationData={animationData} style={{ width: 200, height: 200 }} />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <div className="mt-60pxr flex flex-col gap-24pxr">
                <div className="flex flex-row items-center justify-center mx-auto">
                  <span className="ef-label-md w-89pxr mr-12pxr flex flex-row">
                    <span title={t("common.average_weight")} className="max-w-[70px] line-clamp-1 break-all">
                      {t("common.average_weight")}
                    </span>
                    {getRedStar()}
                  </span>
                  <div className="flex flex-row gap-4pxr items-center">
                    <div onClick={() => clearErrors("avg")} className="w-146pxr relative">
                      {errors.avg && <RequiredAlert />}
                      <Controller
                        name="avg"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} type="number" regex={/^(?:[1-9]\d*|0)?(?:\.\d{1,2})?$/} />}
                      />
                    </div>
                    <span className="ef-label-md">kg</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center mx-auto">
                  <span className="ef-label-md w-89pxr mr-12pxr">
                    <span title={t("common.total_weight")} className="max-w-[70px] line-clamp-1 break-all">
                      {t("common.total_weight")}
                    </span>
                  </span>
                  <div className="flex flex-row gap-4pxr items-center">
                    <div className="w-146pxr">
                      <Controller name="total" control={control} rules={{ required: true }} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
                    </div>
                    <span className="ef-label-md">kg</span>
                  </div>
                </div>
              </div>

              <button type="submit" className="ef-btn-modal mt-60pxr">
                {t("common.save")}
              </button>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default WeightCorrectionModal;
