import React, { useState } from "react";
import { ReactComponent as DoubleArrowLeft } from "assets/icons/general/ef-general-roundDoubleArrowLeft.svg";
import { ReactComponent as DoubleArrowRight } from "assets/icons/general/ef-general-roundDoubleArrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-roundArrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-roundArrowRight.svg";

type Props = {
  // totalCount: number;
  dataLength: number;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

function Pagination(props: Props) {
  // currenpage sholud be start from 1
  const { pageLimit, dataLength, currentPage, setCurrentPage } = props;
  const [pageArrIdx, setPageArrIdx] = useState(0);
  // 5페이지 단위로 끊어서 totalpage 길이에 맞춰 배열 생성
  const totalCount = dataLength % pageLimit === 0 ? Math.floor(dataLength / pageLimit) : Math.floor(dataLength / pageLimit) + 1;
  const pageArr = makePageArr(totalCount);

  function makePageArr(total: number) {
    if (total === 0) return [[]];
    const result = [];
    let start = 1;
    while (start <= total) {
      let end = start + 4;
      if (end > total) end = total;

      const subArray = [];
      for (let i = start; i <= end; i++) {
        subArray.push(i);
      }
      result.push(subArray);
      start += 5;
    }
    return result;
  }

  return (
    <div className="w-fit stroke-ef-neutral-600 flex flex-row items-center ef-label-lg">
      <button
        disabled={pageArrIdx === 0}
        className="mr-8pxr w-24pxr h-24pxr flex items-center justify-center hover:stroke-ef-neutral-900 active:stroke-ef-neutral-600 focus:outline-ef-neutral-900 disabled:stroke-ef-neutral-150"
        onClick={() => {
          setCurrentPage(1);
          setPageArrIdx(0);
        }}
      >
        <DoubleArrowLeft className="cursor-pointer" />
      </button>
      <button
        disabled={pageArrIdx === 0}
        className="w-24pxr h-24pxr flex items-center justify-center hover:stroke-ef-neutral-900 active:stroke-ef-neutral-600 focus:outline-ef-neutral-900 disabled:stroke-ef-neutral-150"
        onClick={() => {
          setCurrentPage(pageArr[pageArrIdx - 1][4]);
          setPageArrIdx((prev) => prev - 1);
        }}
      >
        <ArrowLeft className="cursor-pointer" />
      </button>
      <div className="flex flex-row flex-wrap gap-4pxr mx-24pxr items-center">
        {pageArr[pageArrIdx].map((o) => (
          <button
            key={o}
            onClick={() => setCurrentPage(o)}
            className={"w-24pxr h-24pxr " + (currentPage === o ? "text-ef-primary-500" : "text-ef-neutral-300 hover:text-ef-neutral-700 active:text-ef-neutral-300 focus:outline-ef-neutral-900")}
          >
            {o}
          </button>
        ))}
      </div>
      <button
        disabled={pageArrIdx === pageArr.length - 1}
        className="mr-8pxr w-24pxr h-24pxr flex items-center justify-center hover:stroke-ef-neutral-900 active:stroke-ef-neutral-600 focus:outline-ef-neutral-900 disabled:stroke-ef-neutral-150"
        onClick={() => {
          setCurrentPage(pageArr[pageArrIdx + 1][0]);
          setPageArrIdx((prev) => prev + 1);
        }}
      >
        <ArrowRight className="cursor-pointer" />
      </button>
      <button
        disabled={pageArrIdx === pageArr.length - 1}
        className="w-24pxr h-24pxr flex items-center justify-center hover:stroke-ef-neutral-900 active:stroke-ef-neutral-600 focus:outline-ef-neutral-900 disabled:stroke-ef-neutral-150"
        onClick={() => {
          setCurrentPage(totalCount);
          setPageArrIdx(pageArr.length - 1);
        }}
      >
        <DoubleArrowRight className="cursor-pointer" />
      </button>
    </div>
  );
}

export default Pagination;
