import { useAuthState } from "context/AuthContext";
import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AutoHerdData, Herd, HerdDeactivatedPayload, HerdDetail, HerdEditPayload, HerdHistoryPayload, HerdPayload, QrCancelPayload, QrHerdPayload, StockHistoryDetail } from "./type";

// get group list
export const useHerdList = (start_date?: string | Date | null, end_date?: string | Date | null, state?: boolean[], piggeries?: number[]) => {
  const { current_farm } = useAuthState();
  const params = {
    start_date,
    end_date,
    state,
    piggeries,
  };
  const query = useQuery(["herdList", params], () => client.get<Herd[]>("/herds", { params }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 돈군 생성 post
export const useAddHerd = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addHerd", (payload: HerdPayload) => client.post("/herds", payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["RoomHistory"]);
      queryClient.invalidateQueries(["herdList"]);
      queryClient.invalidateQueries(["groupDetail"]);
      queryClient.invalidateQueries(["RoomList"]);
      queryClient.invalidateQueries(["StockDashboard"]);
    },
  });
  return mutation;
};

// QR 전용 돈군 생성 post
export const useAddHerdQR = () => {
  const mutation = useMutation("addHerdQR", (payload: HerdPayload) => client.post("/qr/herd", payload).then((res) => res.data), {
    onSuccess() {
    },
  });
  return mutation;
};

export const useAddHerdHistory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addHerdHistory", (object: { payload: HerdHistoryPayload; id: number }) => client.post(`/herds/${object.id}/history`, object.payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["herdList"]);
      queryClient.invalidateQueries(["RoomHistory"]);
      queryClient.invalidateQueries(["StockDashboard"]);
    },
  });
  return mutation;
};

// qr코드용 돈군 히스토리 추가
export const useAddHerdHistoryQR = () => {
  const mutation = useMutation("addHerdHistoryQR", (object: { payload: HerdHistoryPayload; id: number }) => client.post(`/qr/${object.id}/history`, object.payload).then((res) => res.data), {
    onSuccess() {
    },
  });
  return mutation;
};

// QR코드용 요청 취소
export const useCancelHerdHistoryQR = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("cancelHerdHistoryQR", (object: { payload: QrCancelPayload[]; }) => client.put(`/qr/cancel`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["RoomDropdownQR"]);
    },
  });
  return mutation;
};

// get single group detail
export const useHerdDetail = (id: number) => {
  const query = useQuery(["groupDetail", id], () => client.get<HerdDetail>(`/herds/${id}`).then((res) => res.data), {
    enabled: id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// put(edit) group detail
export const useEditHerd = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editGroup", (object: { payload: HerdEditPayload; id: number }) => client.put(`/herds/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["herdList"]);
      queryClient.invalidateQueries(["groupDetail"]);
      queryClient.invalidateQueries(["RoomList"]);
    },
  });
  return mutation;
};

// 돈군종료
export const useDeactivateHerd = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("deactivateGroup", (object: { payload: HerdDeactivatedPayload; id: number }) => client.put(`/herds/${object.id}/deactivate`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["RoomHistory"]);
      queryClient.invalidateQueries(["herdList"]);
      queryClient.invalidateQueries(["groupDetail"]);
      queryClient.invalidateQueries(["RoomList"]);
      queryClient.invalidateQueries(["StockDashboard"]);
    },
  });
  return mutation;
};

// QR 돈군종료
export const useDeactivateHerdQR = () => {
  const mutation = useMutation("deactivateGroupQR", (object: { payload: HerdDeactivatedPayload; id: number }) => client.put(`/qr/${object.id}/deactivate`, object.payload).then((res) => res.data), {
    onSuccess(data) {
    },
  });
  return mutation;
};

// 자동 생성 돈군 정보 get
export const useAutoHerdData = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["autoHerdData"], () => client.get<AutoHerdData>("/herds/auto").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 재고기록 카테고리
export const useHerdHistoryCategory = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["herdHistoryCategory"], () => client.get<{ id: number; name: string }[]>("/herds/history/category").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 재고기록 상세
export const useStockHistoryDetail = (history_id: number) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["stockHistoryDetail", history_id], () => client.get<StockHistoryDetail>(`/herds/history/${history_id}`).then((res) => res.data), {
    enabled: current_farm.id !== 0 && history_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 재고기록 상세 수정
export const useEditStockHistoryDetail = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    "editStockHistoryDetail",
    (obj: {
      history_id: number;
      payload: {
        memo: string;
      };
    }) => client.put(`/herds/history/${obj.history_id}`, obj.payload).then((res) => res.data),
    {
      onSuccess() {
        console.log("??");
        queryClient.invalidateQueries(["stockHistoryDetail"]);
        queryClient.invalidateQueries(["groupDetail"]);
        queryClient.invalidateQueries(["RoomHistory"]);
      },
    },
  );
  return mutation;
};

// delete herd
export const useDeleteStockHistory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("deleteStockHistory", (id: number) => client.delete(`/herds/history/${id}`).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["stockHistoryDetail"]);
      queryClient.invalidateQueries(["groupDetail"]);
      queryClient.invalidateQueries(["RoomHistory"]);
      queryClient.invalidateQueries(["StockDashboard"]);
    },
  });
  return mutation;
};
