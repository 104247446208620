import React, { useState } from "react";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import AddGroupDropDown from "components/pages/2_manage/stock/group/AddGroupDropDown";
import { useAddMovementReport } from "query/movement";
import { usePartnerList } from "query/partner";
import { useAuthState } from "context/AuthContext";
import { useTranslation } from "react-i18next";
import { differenceInDays, format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useReportContext } from "context/reportContext";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { MovementPayload } from "query/movement/type";
// import { MovementReportPayload } from "query/movement/type";
// 품종, 성별 보류
// import { useBreedList, useGenderList } from "query/common";

interface Props {
  workDate: Date | null;
  workStartTime: Date;
  workEndTime: Date;
  workType: string | number | null;
  isToggle: boolean;
  setIsToggle: (isToggle: boolean) => void;
}

const initialReportValue = {
  stock: 0,
  avg_weight: 0,
  // body_rate: data?.body_rate,
  memo: "",
  started_at: "",
  ended_at: "",
  category_id: 1,
  departure_id: 0,
  arrival_id: 0,
};

// worktype 입식
function ArrivalComponent(props: Props) {
  const { t } = useTranslation();
  const { workDate, workStartTime, workEndTime, setIsToggle } = props;
  const { current_farm } = useAuthState();
  // 생성된 보고서 색상 주는 context
  const { isReportCreated, setReportCreated, setWorkDate } = useReportContext();
  const { mutateAsync: addReport } = useAddMovementReport();
  // const { data: partnerList } = usePartnerList();
  // const { data: genderList } = useGenderList();
  // const { data: breedList } = useBreedList();

  const combinedDateStartTime = workDate && new Date(workDate.getFullYear(), workDate.getMonth(), workDate.getDate(), workStartTime.getHours(), workStartTime.getMinutes(), workStartTime.getSeconds());
  const combinedDateEndTime = workDate && new Date(workDate.getFullYear(), workDate.getMonth(), workDate.getDate(), workEndTime.getHours(), workEndTime.getMinutes(), workEndTime.getSeconds());

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    clearErrors,
    reset,
  } = useForm<MovementPayload>();

  const arrival = watch("arrival_id");
  const departure = watch("departure_id");
  const heads = watch("stock");

  const isFormComplete = arrival && departure && heads;

  const onSubmit = async (data: MovementPayload) => {
    const reportData = {
      stock: data?.stock,
      avg_weight: data?.avg_weight,
      // body_rate: data?.body_rate,
      memo: data?.memo,
      started_at: format(combinedDateStartTime!, "yyyy-MM-dd'T'HH:mm:ss"),
      ended_at: format(combinedDateEndTime!, "yyyy-MM-dd'T'HH:mm:ss"),
      category_id: 1,
      departure_id: data?.departure_id,
      arrival_id: data?.arrival_id,
    };

    try {
      if (isFormComplete) {
        // const confirmShipment =
        //   reportData.stock === groupData?.stock
        //     ? window.confirm(`${groupData?.name}의 모든 두수가 입력되었습니다.${groupData?.name}을 전체 출하처리하고 ${groupData?.name}의 운영을 종료하시겠습니까?`)
        //     : true;

        console.log(reportData);
        await addReport(reportData);
        // if (confirmShipment) {
        setIsToggle(false);
        toast.success("보고서 작성이 완료되었습니다");
        reset();
        setReportCreated(true);
        setWorkDate(workDate);
        setTimeout(() => {
          setReportCreated(false);
        }, 3000);
      } else {
        setReportCreated(false);
        // alert(t("common.required_comment"));
      }
      // } else {
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* 돈군이름, 별칭, 출생일*, 입식일*, 입식장소*, 입식두수*, 성별*, 위축여부, 품종, 체중 */}
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
          <div className="flex flex-row items-center">
            <label className="w-89pxr ef-label-md line-clamp-1">
              {t("report.departure_location")}
              <span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="w-514pxr">
              <div className="w-340pxr relative" onClick={() => clearErrors("departure_id")}>
                {errors.departure_id ? <RequiredAlert /> : <></>}
                <Controller
                  name="departure_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <AddGroupDropDown title={t("report.departure_location")} state={field.value} setState={field.onChange} distinct={false} />}
                />
              </div>
            </div>
          </div>{" "}
          <div className="flex flex-row items-center">
            <label className="w-89pxr ef-label-md line-clamp-1">
              {t("report.arrival_location")}
              <span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="w-514pxr">
              <div className="w-340pxr relative" onClick={() => clearErrors("arrival_id")}>
                {errors.arrival_id ? <RequiredAlert /> : <></>}
                <Controller
                  name="arrival_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <AddGroupDropDown title={t("report.arrival_location")} state={field.value} setState={field.onChange} distinct={true} />}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-89pxr ef-label-md">
              {t("common.heads")}
              <span className="ef-label-md text-ef-secondary-500">*</span>
            </label>
            <div className="relative flex flex-row items-center w-340pxr" onClick={() => clearErrors("stock")}>
              {errors.stock ? <RequiredAlert /> : <></>}
              <div className="w-146pxr">
                <Controller
                  name="stock"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputFieldOnly
                      state={field.value}
                      setState={(value) => {
                        field.onChange(value);
                      }}
                      type="number"
                      placeholder={t("common.heads")}
                      regex={/^(?:[1-9]\d*|0)?$/}
                    />
                  )}
                />
              </div>
              <span className="ml-4pxr">{t("common.head")}</span>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">{t("common.average_weight")}</label>
            <div className="flex flex-row flex-wrap gap-8pxr">
              <div className="flex flex-row items-center justify-center">
                <div className="w-146pxr">
                  <Controller
                    name="avg_weight"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly
                        state={field.value}
                        setState={(value) => {
                          // if (heads ===0) setValue("total_weight", Math.floor(Number(value)).toString());
                          // else if (heads !==0) setValue("total_weight", Math.floor(Number(value) * Number(heads)).toString());
                          field.onChange(value);
                        }}
                        type="number"
                        placeholder={t("common.average_weight")}
                        // 정수 이하 소수점 두 자리 까지
                        regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                      />
                    )}
                  />
                </div>
                <span className="ml-4pxr">kg</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">{t("report.average_body_length")}</label>
            <div className="flex flex-row flex-wrap gap-8pxr">
              <div className="flex flex-row items-center justify-center">
                <div className="w-146pxr">
                  <Controller
                    name="body_rate"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly
                        state={field.value}
                        setState={(value) => {
                          // if (heads ===0) setValue("total_weight", Math.floor(Number(value)).toString());
                          // else if (heads !==0) setValue("total_weight", Math.floor(Number(value) * Number(heads)).toString());
                          field.onChange(value);
                        }}
                        type="number"
                        placeholder={t("report.avg_wrate")}
                        regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="w-77pxr min-w-[77px] line-clamp-1 mr-12pxr ef-label-md">
              <span className="line-clamp-1 break-all">{t("manage.memo")}</span>
            </label>
            <div className="w-514pxr h-130pxr">
              <textarea {...register("memo")} className="ef-textarea w-full h-full" placeholder={t("manage.memo")}></textarea>
            </div>
          </div>
          <div className="flex justify-center mt-36pxr">
            <button className={`w-494pxr ef-btn-modal`} type="submit">
              {t("common.save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ArrivalComponent;
