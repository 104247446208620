import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorPig from "assets/images/error/error_img.png";
import ErrorText from "assets/images/error/page_not_found_text.png";
import { useTranslation } from "react-i18next";

function ErrorPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="w-screen h-screen flex items-center justify-center flex-col bg-ef-neutral-50">
      <div className="w-fit h-fit mx-auto my-auto flex flex-col">
        <div className="flex flex-col w-547pxr flex-wrap gap-4pxr mx-auto">
          <img src={ErrorPig} alt="error pig" />
          <img src={ErrorText} alt="page not found" className="w-508pxr mx-auto" />
        </div>
        <div className="mt-40pxr flex flex-col text-center font-pre text-[24px] font-normal leading-9 tracking-[-0.6px]">
          <span>{t("error.page_not_found")}</span>
          <span>{t("error.page_error_comment")}</span>
        </div>
        <button onClick={() => navigate("/")} className="mx-auto mt-60pxr w-fit px-40pxr py-16pxr ef-title-lg text-ef-neutral-white rounded-[40px] bg-ef-secondary-500">
          {t("error.go_to_home")}
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
