import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Controller, Control, useForm } from "react-hook-form";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useTranslation } from "react-i18next";
import { useEditMovement, useMovementCategories } from "query/movement";
import { MovementDetail } from "query/movement/type";
import { toast } from "react-toastify";
import { getRedStar } from "utils/getImportantStar";

interface Props {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<any>>;
  // control: Control<MovementConfirmPayload>;
  defaultData: MovementDetail;
}

type Inputs = {
  category_id: number | null;
};

function ChangeCategoryModal(props: Props) {
  const { isToggle, setIsToggle, defaultData } = props;
  const { t } = useTranslation();
  const { data: categoryList } = useMovementCategories();
  const { mutateAsync: editReport } = useEditMovement();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    register,
  } = useForm<Inputs>();

  const category_id = watch("category_id");

  const onSubmit = async () => {
    const payload = {
      id: defaultData.id,
      category_id: category_id,
    };

    try {
      await editReport({ id: defaultData.id, payload: { category_id: Number(payload.category_id) } });
      toast.success(t("common.save_completed"));
      setIsToggle(false);
    } catch (error) {}
  };

  useEffect(() => {
    reset({
      category_id: defaultData.category.id === 5 ? null : defaultData.category.id,
    });
  }, []);

  return (
    <>
      {/* <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={() => setIsToggle(false)}
        className="flex  justify-center min-w-[1070px] pt-120pxr overflow-y-auto pb-120pxr"
      > */}
      <div className="absolute z-[10] mt-46pxr w-493pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-24pxr border border-solid border-ef-neutral-150 rounded-md">
        <div className="flex relative w-full items-center">
          <span className="flex mx-auto text-ef-neutral-900 ef-title-md">{t("manage.change_work_type")}</span>
          <button className="absolute p-8pxr bg-ef-neutral-100 rounded-full bottom-[-7px] right-0pxr" onClick={() => setIsToggle(false)}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>

        <form className="flex flex-col mt-40pxr" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row items-center">
            <div className="flex items-center">
              <label className="w-89pxr ef-label-md line-clamp-1 text-ef-neutral-900">
                {t("common.work_type")} {getRedStar()}
              </label>
              <div className="flex flex-col">
                <div className="w-340pxr flex flex-col">
                  <Controller
                    name="category_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DropDownSmall
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t(`common.movement_category_${defaultData.category.id}`)}
                        options={categoryList ? categoryList.filter((v) => v.id !== 5).map((o) => ({ value: o.id, name: t(`common.movement_category_${o.id}`) })) : []}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-16pxr mt-60pxr mx-72pxr">
            <button type="submit" className="w-300pxr ef-btn-modal">
              {t("common.save")}
            </button>
          </div>
        </form>
      </div>
      {/* </Modal> */}
    </>
  );
}

export default ChangeCategoryModal;
