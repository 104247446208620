import React, { useEffect, useState } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { Modal } from "@mui/material";
import { format, parse, isValid } from "date-fns";
import ArrivalComponent from "./arrivalComponent";
import DeathComponent from "./deathComponent";
import MovementComponent from "./movementComponent";
import ShipmentComponent from "./shipmentComponent";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
import { useMovementCategories } from "query/movement";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useEditContext } from "context/EditContext";
import { useLocation } from "react-router-dom";
import { datePickerLocale } from "utils/useDatepicker";
import MovementDatePicker from "./MovementDatePicker";
import "styles/datepicker.css";

interface Props {
  isToggle: boolean;
  setIsToggle: (isToggle: boolean) => void;
  // undefinedReport?: UndefinedReportType;
}

function MovementModal(props: Props) {
  const { t } = useTranslation();
  const editContext = useEditContext();
  const currentPage = useLocation().pathname;
  const {
    isToggle,
    setIsToggle,
    // undefinedReport
  } = props;

  const { data: workList } = useMovementCategories();

  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();
  const [workDate, setWorkDate] = useState<Date | null>(new Date());
  const [category, setCategory] = useState<number | null>(null);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);

  const handleStartTimeChange = (date: Date | null) => {
    setStartTime(date);
    setIsStartTimeSelected(!!date);
  };

  const handleEndTimeChange = (date: Date | null) => {
    if (isStartTimeSelected) {
      if (!startTime || (date && date.getTime() >= startTime.getTime())) {
        setEndTime(date);
      } else {
        setEndTime(startTime);
      }
    } else {
    }
  };

  // worktype별 component return
  const renderComponentBasedOnWork = (work: number) => {
    if (startTime && endTime && work) {
      switch (work) {
        case 1:
          return category !== 0 && <ArrivalComponent isToggle={isToggle} setIsToggle={setIsToggle} workDate={workDate} workType={category} workStartTime={startTime} workEndTime={endTime} />;
        case 2:
          return category !== 0 && <MovementComponent isToggle={isToggle} setIsToggle={setIsToggle} workDate={workDate} workType={category} workStartTime={startTime} workEndTime={endTime} />;
        case 3:
          return category !== 0 && <ShipmentComponent isToggle={isToggle} setIsToggle={setIsToggle} workDate={workDate} workType={category} workStartTime={startTime} workEndTime={endTime} />;
        case 4:
          return category !== 0 && <DeathComponent isToggle={isToggle} setIsToggle={setIsToggle} workDate={workDate} workType={category} workStartTime={startTime} workEndTime={endTime} />;
        default:
          return "";
      }
    }
  };

  useEffect(() => {
    if (workDate) {
      // const lastConfirmMovementEndedAt = new Date();
      // const firstUnconfirmedMovementStartedAt = new Date();

      // // 선택 가능한 작업일자 설정
      // const minDate = lastConfirmMovementEndedAt;
      // const maxDate = firstUnconfirmedMovementStartedAt;

      // const selectedDate = workDate;

      // // 선택된 날짜의 시작 시간과 종료 시간 설정
      // const minStartTime = selectedDate.getTime() === minDate.getTime() ? minDate.getTime() : selectedDate.setHours(0, 0, 0);
      // const maxStartTime = minDate.getTime() === selectedDate.getTime() ? minDate.setHours(23, 59, 59) : maxDate.getTime();
      // const minEndTime = minStartTime;
      // const maxEndTime = maxStartTime;

      // // 상태 업데이트
      // setStartTime(new Date(minStartTime));
      // setEndTime(new Date(maxEndTime));
      setIsStartTimeSelected(true);
    }
  }, [workDate]);

  useEffect(() => {
    editContext?.setEditState(false);
  }, [currentPage, editContext]);
  console.log(category);
  return (
    <>
      <Modal
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "#303030", // neutral-900
            opacity: "0.3 !important",
          },
        }}
        open={isToggle}
        onClose={() => setIsToggle(false)}
        className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
      >
        <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
          <div className="flex relative w-full">
            <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("report.report_input_by_hand")}</span>
            <button
              className="absolute right-0pxr"
              onClick={() => {
                if (editContext?.editState === true) {
                  if (window.confirm(t("common.alert_not_saved"))) {
                    setIsToggle(false);
                  } else return;
                } else {
                  setIsToggle(false);
                }
              }}
            >
              <GeneralClose className="stroke-ef-neutral-900" />
            </button>
          </div>
          <div className="mt-60pxr flex flex-col flex-wrap gap-24pxr text-ef-neutral-900">
            <div className="flex flex-row items-center">
              <label className="w-89pxr ef-label-md line-clamp-1">
                {t("report.work_date")}
                <span className="ef-label-md text-ef-secondary-500">*</span>
              </label>
              <div className="w-168pxr flex items-start">{<MovementDatePicker date={workDate} setDate={setWorkDate} placeholder={`YYYY-MM-DD`} />}</div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-89pxr ef-label-md line-clamp-1">
                {t("report.work_time")}
                <span className="ef-label-md text-ef-secondary-500">*</span>
              </label>
              <div className="flex flex-row items-center">
                <div className="items-start justify-start cursor-pointer ef-body-lg text-ef-neutral-300 rounded-[8px] border border-solid border-ef-neutral-150 h-40pxr py-8pxr px-16pxr flex flex-row focus-within:border-ef-primary-500 ">
                  <div
                    className="grow max-w-[280px]"
                    style={{
                      textAlignLast: "start",
                      fontWeight: "400",
                    }}
                  >
                    <DatePicker
                      id="singleDate"
                      // minDate={new Date()}
                      // maxDate={new Date()}
                      selected={startTime}
                      onChange={handleStartTimeChange}
                      showTimeSelect
                      autoComplete="on"
                      locale={datePickerLocale()}
                      showTimeSelectOnly
                      //timeIntervals={15}
                      timeCaption="시작시간"
                      dateFormat="aa h:mm"
                      placeholderText={`${t("report.hour")}/${t("report.minute")}`}
                      className="ef-body-lg text-ef-neutral-300"
                    />
                  </div>
                </div>
                <span className="px-8pxr">~</span>
                <div
                  style={{
                    textAlignLast: "start",
                    fontWeight: "400",
                  }}
                  className="items-start justify-start cursor-pointer ef-body-lg text-ef-neutral-300 rounded-[8px] border border-solid border-ef-neutral-150  h-40pxr py-8pxr px-16pxr flex flex-row  focus-within:border-ef-primary-500 "
                >
                  <DatePicker
                    id="singleDate"
                    selected={endTime}
                    onChange={handleEndTimeChange}
                    showTimeSelect
                    autoComplete="on"
                    showTimeSelectOnly
                    locale={datePickerLocale()}
                    //timeIntervals={15}
                    timeCaption="종료시간"
                    dateFormat="aa h:mm"
                    disabled={!isStartTimeSelected}
                    placeholderText={`${t("report.hour")}/${t("report.minute")}`}
                    className=" "
                    minDate={new Date()}
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-89pxr ef-label-md">
                {t("report.work_type")}
                <span className="ef-label-md text-ef-secondary-500">*</span>
              </label>
              <div className="w-168pxr ">
                <DropDownSmall
                  state={category}
                  setState={setCategory}
                  placeholder={t("report.select_work_type")}
                  // 폐사 임시 예외처리
                  options={workList ? workList.map((o) => ({ value: o.id, name: t(`common.herd_history_${o.id}`) })) : []}
                />
              </div>
            </div>
            {renderComponentBasedOnWork(Number(category))}
          </div>
          {/* footer */}
          {Number(category) !== 0 ? (
            ""
          ) : (
            <div className="flex justify-center mt-60pxr">
              <button onClick={() => alert(t("common.required_comment"))} className={`w-494pxr ef-btn-modal`}>
                {t("common.save")}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default MovementModal;
