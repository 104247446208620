import { useQuery } from "react-query";
import client from "../client";
import { MonitoringList } from "./type";
import { useAuthState } from "../../context/AuthContext";

// 모니터링 리스트 조회
export const useMonitoringList = () => {
  const { current_farm } = useAuthState();
  
  const query = useQuery(
    ["monitoringList"], 
    () => client.get<MonitoringList[]>("/monitoring").then((res) => res.data),
    {
      enabled: current_farm.id !== 0,
      onSuccess(data) {
      },
    }
  );

  return query;
};
