import NavFilters from "components/pages/1_farmStatus/NavFilters";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Filter } from "assets/icons/general/ef-general-filter.svg";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import { useEnvDashboard } from "query/piggery";
import BackHomeBtn from "components/pages/1_farmStatus/BackHomeBtn";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import ComponentSpinner from "components/common/spinner/spinner";
import { format } from "date-fns";
import { ReactComponent as GridSvg } from "assets/images/status/grid.svg";
import { ReactComponent as NoSensor } from "assets/icons/status/noSensor.svg";
import { ReactComponent as Temperature } from "assets/icons/general/ef-general-temperature.svg";
import { ReactComponent as Rain } from "assets/icons/gnb/ef-gnb-rain.svg";
import { ReactComponent as Flame } from "assets/icons/general/ef-general-flame.svg";
import { getHeatIndexColorCode } from "utils/useHeatIndex";
import _ from "lodash";
import IndoorAtmosphere from "components/pages/1_farmStatus/modal/environment/IndoorAtmosphere";

// 기본 툴팁용 css
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
    padding: "16px 16px 24px 16px",
    fontFamily: "Pretendard",
  },
}));

function EnvMap() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // state for room div load
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const imageRef = useRef<HTMLDivElement | null>(null);

  // 방별 온습도 상세 모달 토글용
  const [selectedRoomId, setSelectedRoomId] = useState(0);
  const [tolggleModal, setToggleModal] = useState(false);

  // query
  const { data: roomData, isLoading: dataLoading } = useEnvDashboard(typeof id !== "undefined" ? Number(id) : 0);

  // state for filter
  const [selectedFilter, setSelectedFilter] = useState(["temp", "humid", "heat"]);

  // fuction for filter
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // fuction for filter
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  // handle filter checkboxes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setSelectedFilter((prev) => (checked ? [...prev, id] : prev.filter((filter) => filter !== id)));
  };

  // handle filter reset btn
  const handleFilterReset = () => {
    setSelectedFilter(["temp", "humid", "heat"]);
  };

  // get divs' poisitions
  const getPoints = (overlay: { x: number; y: number }[] | null) => {
    if (!overlay || overlay.length < 2) return { x1: 0, y1: 0, width: 0, height: 0 };

    // x와 y의 최소값과 최대값 계산
    const minX = Math.min(...overlay.map((point) => point.x));
    const minY = Math.min(...overlay.map((point) => point.y));
    const maxX = Math.max(...overlay.map((point) => point.x));
    const maxY = Math.max(...overlay.map((point) => point.y));

    // 부모 요소에 맞춰 절대 위치 계산
    const x1 = minX * parentWidth;
    const y1 = minY * parentHeight;
    const x2 = maxX * parentWidth;
    const y2 = maxY * parentHeight;

    // 사각형의 너비와 높이 계산
    const width = x2 - x1;
    const height = y2 - y1;

    return { x1, y1, width, height };
  };

  // set size for room divs
  useEffect(() => {
    if (!imageRef.current) return;

    const updateSize = () => {
      setParentWidth(imageRef.current!.clientWidth);
      setParentHeight(imageRef.current!.clientHeight);
    };

    updateSize();

    const resizeObserver = new ResizeObserver(() => updateSize());
    resizeObserver.observe(imageRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="bg-ef-primary-50 w-full h-full relative">
      <IndoorAtmosphere roomId={selectedRoomId} isToggle={tolggleModal} setIsToggle={setToggleModal} />
      <div className="absolute h-full">
        <GridSvg />
      </div>
      <div className="w-full h-[1080px] pt-60pxr px-80pxr pb-40pxr flex flex-col relative">
        <div className="flex flex-row items-center">
          <NavFilters piggeryId={typeof id !== "undefined" ? Number(id) : 0} />
          <div className="rounded-[8px] ef-main-shadow bg-ef-neutral-white border border-solid border-ef-neutral-150 min-w-[224px] w-fit ml-auto p-12pxr flex flex-col gap-8pxr h-fit ef-body-sm text-ef-neutral-700">
            <div className="flex flex-row items-center gap-4pxr">
              <span>{t("status.stocks_in_piggery")}</span>
              <span className="text-ef-primary-500 ef-body-md">{`${roomData ? roomData.summary.today_stock.toLocaleString() : 0}${t("common.head")}`}</span>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-200"></div>
            <div>{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</div>
          </div>
          {/* <button onClick={handleClick} type="button" className={"ef-btn-map-light ml-auto " + (anchorEl ? "!text-ef-neutral-white !bg-ef-primary-500 !stroke-ef-neutral-white" : "")}>
            <Filter />
            {t("status.filter")}
          </button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPaper-root": {
                boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08) !important",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid #DEDEDE",
                marginTop: "4px",
                minWidth: "270px",
              },
            }}
          >
            <div className="p-16pxr flex flex-col ef-label-md">
              <div className="flex flex-row items-center">
                <span className="ef-title-md">{t("status.environment")}</span>
                <button onClick={() => handleFilterReset()} type="button" className="text-ef-secondary-500 ml-auto cursor-pointer ef-label-md">
                  {t("common.reset")}
                </button>
              </div>
              <div className="mt-8pxr mb-12pxr w-full h-1pxr bg-ef-neutral-150"></div>
              <div className="flex flex-col gap-8pxr">
                <input type="checkbox" id="temp" checked={selectedFilter.includes("temp")} onChange={handleCheckboxChange} />
                <label htmlFor="temp" className="!pl-32pxr h-24pxr flex items-center">
                  {t("common.temperature")}
                </label>
                <input type="checkbox" id="humid" checked={selectedFilter.includes("humid")} onChange={handleCheckboxChange} />
                <label htmlFor="humid" className="!pl-32pxr h-24pxr flex items-center">
                  {t("common.humidity")}
                </label>
                <input type="checkbox" id="heat" checked={selectedFilter.includes("heat")} onChange={handleCheckboxChange} />
                <label htmlFor="heat" className="!pl-32pxr h-24pxr flex items-center">
                  {t("common.heat_index")}
                </label>
              </div>
            </div>
          </Popover> */}
        </div>
        <div className="mt-66pxr">
          <BackHomeBtn piggeryName={roomData ? roomData.name : ""} />
        </div>

        <div className="w-full grow flex justify-center relative mt-55pxr">
          {dataLoading && <ComponentSpinner />}
          {/* map the rooms */}
          <div ref={imageRef} className="w-fit h-fit relative">
            <img src={roomData && roomData.image_path !== null ? roomData.image_path : ""} alt="" />
            {roomData?.rooms
              .filter((o) => o.overlay !== null)
              .map((r) => (
                <HtmlTooltip
                  disableInteractive
                  title={
                    <div className="flex flex-col">
                      <p className="flex flex-row justify-center">
                        <span className="ef-label-lg">{r.name}</span>
                      </p>
                      <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                      <div className="flex flex-col gap-8pxr">
                        <div className="flex flex-row gap-8pxr items-center">
                          <div className="w-142pxr max-w-[142px]">
                            <p className="line-clamp-1 break-all ef-title-sm">{t("common.temperature")}</p>
                            <p className="line-clamp-1 break-all ef-body-sm text-ef-neutral-500">
                              {r.range !== null && r.range.lower_temperature !== null && r.range.upper_temperature !== null
                                ? `(${t("common.proper")} : ${r.range.lower_temperature}℃ ~ ${r.range.upper_temperature}℃)`
                                : `(${t("common.no_info")})`}
                            </p>
                          </div>
                          <div
                            style={{
                              color:
                                r.summary !== null && r.summary.temperature !== null && r.range !== null && !_.inRange(r.summary.temperature, r.range.lower_temperature, r.range.upper_temperature)
                                  ? "#F03E3E"
                                  : r.summary === null || r.summary.temperature === null
                                    ? "#878787"
                                    : "",
                            }}
                            className="w-227pxr max-w-[227px] py-10pxr px-8pxr border border-solid border-ef-neutral-200 ef-body-sm line-clamp-1"
                          >
                            {r.summary === null || r.summary.temperature === null ? t("common.no_info") : `${t("common.average")} ${r.summary.temperature.toFixed(2)}℃`}
                          </div>
                        </div>
                        <div className="flex flex-row gap-8pxr items-center">
                          <div className="w-142pxr max-w-[142px]">
                            <p className="line-clamp-1 break-all ef-title-sm">{t("common.humidity")}</p>
                            <p className="line-clamp-1 break-all ef-body-sm text-ef-neutral-500">
                              {r.range !== null && r.range.lower_humidity !== null && r.range.upper_humidity !== null
                                ? `(${t("common.proper")} : ${r.range.lower_humidity}% ~ ${r.range.upper_humidity}%)`
                                : `(${t("common.no_info")})`}
                            </p>
                          </div>
                          <div
                            style={{
                              color:
                                r.summary !== null && r.summary.humidity !== null && r.range !== null && !_.inRange(r.summary.humidity, r.range.lower_humidity, r.range.upper_humidity)
                                  ? "#F03E3E"
                                  : r.summary === null || r.summary.humidity === null
                                    ? "#878787"
                                    : "",
                            }}
                            className="w-227pxr max-w-[227px] py-10pxr px-8pxr border border-solid border-ef-neutral-200 ef-body-sm line-clamp-1"
                          >
                            {r.summary === null || r.summary.humidity === null ? t("common.no_info") : `${t("common.average")} ${Math.floor(r.summary.humidity)}%`}
                          </div>
                        </div>
                        <div className="flex flex-row gap-8pxr items-center">
                          <div className="w-142pxr max-w-[142px]">
                            <p className="line-clamp-1 break-all ef-title-sm">{t("common.heat_index")}</p>
                            <p className="line-clamp-1 break-all ef-body-sm text-ef-neutral-500">
                              {r.range !== null && r.range.lower_heat_index !== null && r.range.upper_heat_index !== null
                                ? `(${t("common.proper")} : ${r.range.lower_heat_index.toLocaleString()} ~ ${r.range.upper_heat_index.toLocaleString()})`
                                : `(${t("common.no_info")})`}
                            </p>
                          </div>
                          <div
                            style={{
                              color:
                                r.summary !== null && r.summary.heat_index !== null && r.range !== null && !_.inRange(r.summary.heat_index.index, r.range.lower_heat_index, r.range.upper_heat_index)
                                  ? "#F03E3E"
                                  : r.summary === null || r.summary.heat_index === null
                                    ? "#878787"
                                    : "",
                            }}
                            className="w-227pxr max-w-[227px] py-10pxr px-8pxr border border-solid border-ef-neutral-200 ef-body-sm line-clamp-1"
                          >
                            {r.summary === null || r.summary.heat_index === null
                              ? t("common.no_info")
                              : `${Math.floor(r.summary.heat_index.index)}(${t(`common.heat_${r.summary.heat_index.category}`)})`}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  key={r.id}
                >
                  <div
                    style={{
                      left: getPoints(r.overlay).x1,
                      top: getPoints(r.overlay).y1,
                      width: getPoints(r.overlay).width,
                      height: getPoints(r.overlay).height,
                    }}
                    className="absolute flex flex-col group cursor-pointer"
                    onClick={() => {
                      setSelectedRoomId(r.id);
                      setToggleModal(true);
                    }}
                  >
                    <div
                      className={
                        "h-32pxr max-h-32pxr px-4pxr py-2pxr flex items-center justify-center ef-label-sm text-ef-neutral-white " +
                        (r.summary === null ||
                        r.summary.number_of_sensors === null ||
                        r.summary.number_of_sensors === 0 ||
                        r.summary.number_of_sensors === r.summary.number_of_disconnected_sensors ||
                        r.summary.number_of_sensors === r.summary.number_of_suspected_fault_sensors
                          ? "bg-ef-neutral-500"
                          : "bg-ef-primary-500 group-hover:bg-ef-secondary-500")
                      }
                    >
                      <p title={r.name} className="w-full max-w-[100%] break-all line-clamp-1 text-center">
                        {r.name}
                      </p>
                    </div>
                    {r.summary === null ||
                    r.summary.number_of_sensors === null ||
                    r.summary.number_of_sensors === 0 ||
                    r.summary.number_of_sensors === r.summary.number_of_disconnected_sensors ||
                    r.summary.number_of_sensors === r.summary.number_of_suspected_fault_sensors ? (
                      <div className="flex grow w-full bg-ef-neutral-500 bg-opacity-30 items-center justify-center">
                        <div className="flex flex-col items-center justify-center gap-8pxr px-4pxr">
                          <NoSensor />
                          <span className="ef-label-md text-ef-neutral-700 break-all text-center">
                            {r.summary === null || r.summary.number_of_sensors === null || r.summary.number_of_sensors === 0
                              ? t("status.environmental_sensor_not_installed")
                              : r.summary !== null && r.summary.number_of_sensors === r.summary.number_of_suspected_fault_sensors
                                ? t("status.suspected_environmental_sensor_failure")
                                : t("status.network_connection_error")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex grow w-full bg-ef-primary-500 group-hover:bg-ef-secondary-500 group-hover:bg-opacity-30  bg-opacity-30 px-4pxr flex-col gap-4pxr items-center justify-center text-ef-primary-500 ef-body-xs">
                        {selectedFilter.includes("temp") && (
                          <div
                            style={{
                              backgroundColor: r.range !== null && !_.inRange(r.summary.temperature, r.range.lower_temperature, r.range.upper_temperature) ? "#FFF6F6" : "#FFFFFF",
                              border: r.range !== null && !_.inRange(r.summary.temperature, r.range.lower_temperature, r.range.upper_temperature) ? "1px solid #F03E3E" : "",
                            }}
                            className="rounded-[4px] w-full h-fit p-4pxr min-h-[32px] flex flex-row gap-4pxr items-center"
                          >
                            <Temperature
                              className="w-24pxr h-24pxr min-w-[24px]"
                              style={{
                                stroke:
                                  r.summary.temperature === null
                                    ? "#878787"
                                    : r.range !== null && !_.inRange(r.summary.temperature, r.range.lower_temperature, r.range.upper_temperature)
                                      ? "#F03E3E"
                                      : "#033687",
                              }}
                            />

                            <span
                              style={{
                                color:
                                  r.summary.temperature === null
                                    ? "#878787"
                                    : r.range !== null && !_.inRange(r.summary.temperature, r.range.lower_temperature, r.range.upper_temperature)
                                      ? "#F03E3E"
                                      : "#033687",
                              }}
                              className="ef-body-xs break-all"
                            >
                              {r.summary.temperature === null ? t("common.no_info") : `${r.summary.temperature.toFixed(2)}℃`}
                            </span>
                          </div>
                        )}
                        {selectedFilter.includes("humid") && (
                          <div
                            style={{
                              backgroundColor: r.range !== null && !_.inRange(r.summary.humidity, r.range.lower_humidity, r.range.upper_humidity) ? "#FFF6F6" : "#FFFFFF",
                              border: r.range !== null && !_.inRange(r.summary.humidity, r.range.lower_humidity, r.range.upper_humidity) ? "1px solid #F03E3E" : "",
                            }}
                            className="rounded-[4px] w-full h-fit p-4pxr min-h-[32px] flex flex-row gap-4pxr items-center"
                          >
                            <Rain
                              className="w-24pxr h-24pxr min-w-[24px]"
                              style={{
                                stroke:
                                  r.summary.humidity === null ? "#878787" : r.range !== null && !_.inRange(r.summary.humidity, r.range.lower_humidity, r.range.upper_humidity) ? "#F03E3E" : "#033687",
                              }}
                            />
                            <span
                              style={{
                                color:
                                  r.summary.humidity === null ? "#878787" : r.range !== null && !_.inRange(r.summary.humidity, r.range.lower_humidity, r.range.upper_humidity) ? "#F03E3E" : "#033687",
                              }}
                              className="ef-body-xs break-all"
                            >
                              {r.summary.humidity === null ? t("common.no_info") : `${Math.floor(r.summary.humidity)}%`}
                            </span>
                          </div>
                        )}
                        {selectedFilter.includes("heat") && (
                          <div className="bg-ef-neutral-white rounded-[4px] w-full h-fit p-4pxr min-h-[32px] flex flex-row gap-4pxr items-center">
                            <Flame
                              style={{
                                stroke: r.summary.heat_index === null ? "#878787" : getHeatIndexColorCode(r.summary.heat_index.category),
                              }}
                            />
                            <span
                              style={{
                                color: r.summary.heat_index === null ? "#878787" : getHeatIndexColorCode(r.summary.heat_index.category),
                              }}
                              className="ef-body-xs"
                            >
                              {r.summary.heat_index === null ? t("common.no_info") : `${t(`common.heat_${r.summary.heat_index.category}`)}`}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </HtmlTooltip>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnvMap;
