import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "assets/icons/general/ef-general-calendar.svg";
import "styles/datepicker.css";
import { datePickerLocale } from "utils/useDatepicker";

type Props = {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedType: number;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
};

// @J-TODO 운영보고서 상단 필터 월간,연간 등에 따라서 datepicker 모양이 바뀌어야 함...(selectedType)
// 주간이 제일 심란함............................
// start-end체재로 가야될수도 있다.
function OperationDatePicker(props: Props) {
  const { disabled = false, date, setDate, dateFormat = "yyyy-MM-dd", placeholder, maxDate = new Date(), minDate, selectedType = 1 } = props;
  const dateRef = useRef<HTMLDivElement>(null);
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (dateRef.current && !dateRef.current.contains(e.target as Node)) {
        setOpenState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRef]);

  return (
    <div
      ref={dateRef}
      onClick={() => {
        if (!disabled) {
          setOpenState((prev) => !prev);
        } else return;
      }}
      className={
        "group cursor-pointer flex w-200pxr h-56pxr rounded-[8px] border border-solid py-16pxr " +
        (openState
          ? "bg-ef-primary-50 border-ef-primary-500"
          : "border-ef-neutral-150 bg-ef-neutral-white hover:ef-main-shadow hover:border-ef-primary-500 active:shadow-none focus:outline-none focus:border-ef-primary-500 disabled:shadow-none disabled:bg-ef-neutral-50 disabled:border-ef-neutral-150")
      }
    >
      <div className="flex flex-row flex-wrap gap-4pxr mx-auto">
        <div className="w-91pxr" onClick={(e) => e.stopPropagation()}>
          <DatePicker
            open={openState}
            disabled={disabled}
            autoComplete="off"
            onInputClick={() => {
              if (!disabled) {
                setOpenState((prev) => !prev);
              } else return;
            }}
            locale={datePickerLocale()}
            minDate={minDate}
            maxDate={maxDate}
            selected={date}
            onChange={(date) => {
              date && setDate(date);
              setOpenState(false);
            }}
            className="outline-none cursor-pointer caret-transparent"
            dateFormat={dateFormat}
            onKeyDown={(e: any) => {
              if (e.keyCode === 229) e.preventDefault();
              e.preventDefault();
            }}
            placeholderText={placeholder}
          />
        </div>
        <div>
          <Calendar className="stroke-ef-neutral-900 group-disabled:stroke-ef-neutral-300" />
        </div>
      </div>
    </div>
  );
}

export default OperationDatePicker;
