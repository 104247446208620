import { useAuthState } from "context/AuthContext";
import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GrowCamDetail, GrowDetail, GrowType } from "./type";

// get grow list
export const useGrowList = (piggeries?: number[], state?: (boolean | string)[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["growList", piggeries, state], () => client.get<GrowType[]>("/efg", { params: { piggeries, state } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// get grow detail
export const useGrowDetail = (id: number) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["growDetail", id], () => client.get<GrowDetail>(`/efg/${id}`).then((res) => res.data), {
    enabled: current_farm.id !== 0 && id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) grow detail
export const useEditGrow = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editGrow", (object: { payload: { name: string }; id: number }) => client.put(`/efg/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["growList"]);
      queryClient.invalidateQueries(["growDetail"]);
    },
  });
  return mutation;
};

// get grow camera detail
export const useGrowCamDetail = (id: number, base_date?: string) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["growCamDetail", id, base_date], () => client.get<GrowCamDetail>(`/efg/camera/${id}`, { params: { base_date } }).then((res) => res.data), {
    enabled: current_farm.id !== 0 && id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// edit(put) grow camera detail
export const useEditGrowCam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editGrowCam", (object: { payload: { name: string }; id: number }) => client.put(`/efg/camera/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["growList"]);
      queryClient.invalidateQueries(["growCamDetail"]);
    },
  });
  return mutation;
};

// edit(put) grow camera angle status to true
export const useEditGrowCamAngle = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editGrowCamAngle", (id: number) => client.put(`/efg/camera/${id}/angle`).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["growList"]);
      queryClient.invalidateQueries(["growCamDetail"]);
      queryClient.invalidateQueries(["DeviceDashboard"]);
    },
  });
  return mutation;
};

// efg 체중 보정
export const useEditGrowWeight = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    "EditGrowWeight", 
    (data: { herd_id: number; answer_weight: number; answer_date: string }) => 
      client.put(`/efg/answer_weight`, data).then((res) => res.data), 
    {
      onSuccess(data) {
        queryClient.invalidateQueries(["growthRecord"]);
      },
    }
  );
  return mutation;
};