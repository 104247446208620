import React, { useState } from "react";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import NoImg from "assets/images/report/no_report_data.png";
import ViewAllModal from "./ViewAllModal";
import CamDetailModal from "./CamDetailModal";
import { DailyReport } from "query/report/type";
import { useReportPiggeryView } from "query/report";
import { useTranslation } from "react-i18next";
import { datePickerLocale } from "utils/useDatepicker";
import { format } from "date-fns";

type Props = {
  data: DailyReport | undefined;
  token: string;
};

function SummaryDailyReport(props: Props) {
  const { data, token } = props;
  const { t } = useTranslation();

  const [toggleCamDetail, setToggleCamDetail] = useState(false);
  const [selcetedCam, setSelectedCam] = useState(0);

  const [toggleViewAll, setToggleViewAll] = useState(false);

  const { data: piggeryImgList, isError: isImgError } = useReportPiggeryView(token ?? "-", data ? `${data.date}T12:00:00` : "");
  const imgArr = piggeryImgList ? piggeryImgList.efg_camera : [];

  return (
    <div className="flex flex-col gap-40pxr">
      <ViewAllModal isToggle={toggleViewAll} setIsToggle={setToggleViewAll} selectedDate={data ? data.date : ""} token={token ?? "-"} />
      <CamDetailModal isToggle={toggleCamDetail} setIsToggle={setToggleCamDetail} camId={selcetedCam} token={token ?? "-"} />
      {/* 이미지 스와이퍼 */}
      <div className="flex flex-col gap-16pxr">
        <div className="flex flex-row">
          <span className="ef-title-md">{t("report.time_room_image", { time: 12 })}</span>
          <button onClick={() => setToggleViewAll(true)} type="button" className="ml-auto flex flex-row items-center gap-4pxr ef-label-lg text-ef-neutral-700 stroke-ef-neutral-700">
            <span>{t("report.see_all")}</span>
            <ArrowRight />
          </button>
        </div>
        <div className="w-full relative">
          <button type="button" className="arrow-left rounded-full p-8pxr bg-ef-neutral-900 bg-opacity-70 absolute top-91pxr left-8pxr z-[10] cursor-pointer">
            <ArrowLeft className="stroke-ef-neutral-white" />
          </button>
          <button type="button" className="arrow-right rounded-full p-8pxr bg-ef-neutral-900 bg-opacity-70 absolute top-91pxr right-8pxr z-[10] cursor-pointer">
            <ArrowRight className="stroke-ef-neutral-white" />
          </button>
          <Swiper
            navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
          >
            {imgArr.length > 0 ? (
              imgArr.map((d, idx) => (
                <SwiperSlide key={idx}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCam(d.id);
                      setToggleCamDetail(true);
                    }}
                    className="w-full flex flex-col cursor-pointer"
                  >
                    <div className="rounded-t-[8px] aspect-video relative">
                      <div className="w-full h-full rounded-t-[8px] bg-ef-neutral-50 flex flex-col absolute top-0pxr left-0pxr items-center justify-center">
                        <img src={NoImg} className="w-140pxr h-99pxr mx-auto" alt="error-img" />
                        <span className="mx-auto text-ef-neutral-700 text-center ef-body-sm">{t("common.image_path_not_found")}</span>
                      </div>
                      <img className="absolute top-0pxr left-0pxr rounded-t-[8px]" src={d.history[0]?.image_path} alt={d.id.toString()} onError={(e) => (e.currentTarget.hidden = true)} />
                    </div>
                    <div className="p-16pxr flex flex-row gap-8pxr items-center border-b border-x border-solid border-ef-neutral-150 rounded-b-[8px]">
                      <span className="ef-body-xl">{d.room.name}</span>
                      <span className="ef-body-lg">{d.name}</span>
                      <span className="ef-body-lg ml-auto">
                        <span className="ef-body-xl text-ef-primary-500">{idx + 1}</span>
                        <span className="text-ef-neutral-700">/{imgArr.length}</span>
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            ) : isImgError ? (
              <SwiperSlide>
                <div className="w-full h-240pxr flex flex-col items-center justify-center">
                  <img src={NoImg} alt="no-img" className="w-243pxr h-172pxr" />
                  <p className="pt-18pxr ef-body-lg text-ef-neutral-600 text-center">{t("common.no_info")}</p>
                </div>
              </SwiperSlide>
            ) : (
              <div className="w-full h-240pxr"></div>
            )}
          </Swiper>
        </div>
      </div>
      {/* 재고 변화 */}
      <div className="flex flex-col gap-16pxr">
        <span className="ef-title-md whitespace-nowrap overflow-hidden text-ellipsis">{t("report.inventory_change")}</span>
        <table className="!w-full ef-table-toggle">
          <tbody className="[&>*]:text-left">
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td title={t("report.total_heads_yesterday")} className="bg-ef-neutral-100 w-134pxr max-w-[134px] rounded-tl-[8px]">
                {t("report.total_heads_yesterday")}
              </td>
              <td>{`${data?.stock.yesterday ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("report.arrival_heads")}</td>
              <td>{`${data?.stock.arrival ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("report.shipment_heads")}</td>
              <td>{`${data?.stock.shipment ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("report.move_in_heads")}</td>
              <td>{`${data?.stock.in ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("report.move_out_heads")}</td>
              <td>{`${data?.stock.out ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("report.death_heads")}</td>
              <td>{`${data?.stock.death ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-100">{t("common.unknown")}</td>
              <td>{`${data?.stock.none ?? 0}${t("common.head")}`}</td>
            </tr>
            <tr className="[&>*]:!px-16pxr [&>*]:!py-10pxr">
              <td className="bg-ef-neutral-500 text-ef-neutral-white">{t("report.total_heads_raised")}</td>
              <td className="ef-body-md">{`${data?.stock.total ?? 0}${t("common.head")}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* 실내대기 */}
      <div className="flex flex-col gap-16pxr">
        <span className="ef-title-md whitespace-nowrap overflow-hidden text-ellipsis">{t("report.indoor_atmosphere")}</span>
        <div className="w-full flex flex-col">
          <table className="!w-full ef-table-toggle">
            <thead>
              <tr className="bg-ef-neutral-200 [&>*]:!py-10pxr">
                <th colSpan={2} className="w-124pxr max-w-[124px]">
                  {t("report.item")}
                </th>
                <th className="w-196pxr max-w-[196px]">{data ? format(new Date(data.date), "MMM do", { locale: datePickerLocale() }) : ""}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="[&>*]:!py-10pxr">
                <td rowSpan={3} className="bg-ef-neutral-100">
                  {t("common.temperature")}
                </td>
                <td className="bg-ef-neutral-100">{t("common.average")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.temperature.avg !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.temperature.avg !== null
                        ? `${data.sensor.this_year.temperature.avg.toFixed(2)}℃`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.highest")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.temperature.upper !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.temperature.upper !== null
                        ? `${data.sensor.this_year.temperature.upper.toFixed(2)}℃`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.lowest")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.temperature.lower !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.temperature.lower !== null
                        ? `${data.sensor.this_year.temperature.lower.toFixed(2)}℃`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td rowSpan={3} className="bg-ef-neutral-100">
                  {t("common.humidity")}
                </td>
                <td className="bg-ef-neutral-100">{t("common.average")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.humidity.avg !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.humidity.avg !== null
                        ? `${data.sensor.this_year.humidity.avg.toFixed(2)}%`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.highest")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.humidity.upper !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.humidity.upper !== null
                        ? `${data.sensor.this_year.humidity.upper.toFixed(2)}%`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td className="bg-ef-neutral-100">{t("common.lowest")}</td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.humidity.lower !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.humidity.lower !== null
                        ? `${data.sensor.this_year.humidity.lower.toFixed(2)}%`
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
              <tr className="[&>*]:!py-10pxr">
                <td colSpan={2} className="bg-ef-neutral-100">
                  {t("common.heat_index")}
                </td>
                <td
                  style={{
                    color: data && data.sensor.this_year.has_sensor && data.sensor.this_year.heat_index !== null ? "" : "#878787",
                  }}
                >
                  {data
                    ? data.sensor.this_year.has_sensor
                      ? data.sensor.this_year.heat_index.index !== null
                        ? Math.floor(data.sensor.this_year.heat_index.index)
                        : t("common.no_info")
                      : t("common.no_device")
                    : t("common.no_info")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SummaryDailyReport;
