import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthState } from "context/AuthContext";
import { AddFeederPayload, AddFeedHistoryPayload, Feeder, FeederHistory } from "./type";

// get feeder list 농장 내 급이기 전체 정보
export const useFeederList = (connected?: (boolean | string)[], piggerys?: number[], companys?: number[]) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["feederList", connected, piggerys, companys], () => client.get<Feeder[]>("/feeders", { params: { connected, piggerys, companys } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// feeder 상세
export const useFeederDetail = (feederId: number) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["feederDetail", feederId], () => client.get<Feeder>(`/feeders/${feederId}`).then((res) => res.data), {
    enabled: current_farm.id !== 0 && feederId !== 0,
    onSuccess(data) {},
  });
  return query;
};

// feeder 수정
export const useEditFeeder = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editFeeder", (obj: { feederId: number; payload: { name: string } }) => client.put(`/feeders/${obj.feederId}`, obj.payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["feederList"]);
      queryClient.invalidateQueries(["feederDetail"]);
    },
  });
  return mutation;
};

// feeder 생성
export const useAddFeeder = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addFeeder", (payload: AddFeederPayload) => client.post("/feeders", payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["feederList"]);
    },
  });
  return mutation;
};

// feeder company get
export const useFeederCompanyList = (linked?: boolean) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["feederCompanyList"], () => client.get<{ id: number; name: string }[]>("/feeders/company", { params: { linked } }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 급이기 전체 기록 get
export const useFeedLogHistory = (obj: { piggery_id: number; rooms: number[]; period: string; target_date: string }) => {
  const { current_farm } = useAuthState();
  const query = useQuery(["feedLogHistory", obj], () => client.get<FeederHistory[]>("/feeders/historys", { params: obj }).then((res) => res.data), {
    enabled: current_farm.id !== 0 && obj.piggery_id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 급이기록 새로 생성
export const useAddFeedHistory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addFeedHistory", (payload: AddFeedHistoryPayload) => client.post("/feeders/history", payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries(["feedLogHistory"]);
    },
  });
  return mutation;
};

// 급이기록 수정
export const useEditFeedHistory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    "editFeedHistory",
    (object: { payload: { amount: number }; historyId: number }) => client.put(`/feeders/history/${object.historyId}`, object.payload).then((res) => res.data),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(["feedLogHistory"]);
      },
    },
  );
  return mutation;
};
