import Footer from "components/template/Footer";
import Gnb from "components/template/Gnb";
import React from "react";
import { Outlet } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function LayoutPage() {
  return (
    <OverlayScrollbarsComponent id="page-container-scroll" element="div" options={{ scrollbars: { autoHide: "scroll" } }} className="h-screen w-[1920px] mx-auto" defer>
      <div className="w-full mx-auto h-full flex flex-col">
        <Gnb />
        <div className="grow w-full">
          <Outlet />
        </div>
        <Footer />
      </div>
    </OverlayScrollbarsComponent>
  );
}
