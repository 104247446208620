import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import GrowthRangePicker from "components/pages/2_manage/specification/growth/GrowthRangePicker";
import { ReactComponent as AltArrowUp } from "assets/icons/general/ef-general-altArrowUp.svg";
import { ReactComponent as AltArrowDown } from "assets/icons/general/ef-general-altArrowDown.svg";
import { ReactComponent as Forward } from "assets/icons/general/ef-general-forward.svg";
import { ReactComponent as CCTV } from "assets/icons/general/ef-general-cctv.svg";
import { ReactComponent as Info } from "assets/icons/icon16_general/ef-16general-info.svg";
import { ReactComponent as Pen } from "assets/icons/icon16_general/ef-16general-pen.svg";
import ComponentSpinner from "components/common/spinner/spinner";
import { useGrowthRecord, useIsAutoHerdExists, usePiggeries } from "query/piggery";
import { useRoomDropdownList } from "query/rooms";
import { addDays, differenceInDays, format, subDays } from "date-fns";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import _ from "lodash";
import NoData from "assets/images/report/no_report_data.png";
import { ReactComponent as Download } from "assets/icons/report/ef-report-download.svg";
import { DownloadTableExcel } from "react-export-table-to-excel";
import MonitoringModal from "components/pages/2_manage/specification/growth/roomMonitoringModal";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import WeightCorrectionModal from "components/pages/2_manage/specification/growth/WeightCorrectionModal";

type ToggleState = {
  rooms: string[]; // 열려 있는 방들의 roomId를 저장
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: 8,
    padding: "12px 16px",
    fontFamily: "Pretendard",
  },
}));

const tooltipDivider = <div className="h-12pxr w-1pxr bg-ef-neutral-200"></div>;

export default function ManageGrow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // 필터용 state
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState<Date | null>(subDays(new Date(), 1));
  const dayGap = startDate && endDate ? differenceInDays(endDate, startDate) : 0;

  // 돈방 사진 모니터링 모달용 state
  const [toggleRoomModal, setToggleRoomModal] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(0);
  const [selectedDate, setSelectedDate] = useState<string>("");

  // 자동돈군 alert용 state
  const [openAutoAlert, setOpenAutoAlert] = useState(false);
  const handleAlertClose = () => {
    setOpenAutoAlert(false);
  };

  // 엑셀 다운로드용 ref
  const tableRef = useRef<HTMLTableElement | null>(null);

  // 방 토글 state(원래 date가 같이 있었는데 기획 변경으로 모양이 딕셔너리로 남아있음)
  const [toggleState, setToggleState] = useState<ToggleState>({
    rooms: [],
  });

  const { data: piggeryList } = usePiggeries();
  const { data: isAutoHerdExists } = useIsAutoHerdExists(selectedPiggery);
  const { data: roomList } = useRoomDropdownList(selectedPiggery, [1]);
  const {
    data: growthData,
    isLoading: isDataLoading,
    isError: isDataError,
  } = useGrowthRecord(selectedPiggery, selectedRooms, startDate ? format(startDate, "yyyy-MM-dd") : undefined, endDate ? format(endDate, "yyyy-MM-dd") : undefined);

  // mount시 0번째 돈사 선택
  useEffect(() => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
  }, [piggeryList]);

  // 돈사 바꿀때마다 돈방 reset
  useEffect(() => {
    setSelectedRooms([]);
  }, [selectedPiggery]);

  // 시작일자 변경시 종료일보다 180일 이전이면 종료일-180으로 시작일자 변경
  useEffect(() => {
    if (startDate && endDate) {
      if (differenceInDays(endDate, startDate) > 180) {
        alert(t("common.max_view_date_comment", { day: 180 }));
        setStartDate(subDays(endDate, 180));
      }
    }
  }, [startDate]);

  // // 종료일자 변경시 시작일보다 180일 이후면 시작일 +180으로 종료일 변경
  useEffect(() => {
    if (startDate && endDate) {
      if (differenceInDays(endDate, startDate) > 180) {
        alert(t("common.max_view_date_comment", { day: 180 }));
        setEndDate(addDays(startDate, 180));
      }
    }
  }, [endDate]);

  // fuction for reste btn
  const handleReset = () => {
    setSelectedPiggery(piggeryList ? piggeryList[0].id : 0);
    setSelectedRooms([]);
    setStartDate(subDays(new Date(), 7));
    setEndDate(subDays(new Date(), 1));
  };

  // 방 토글 함수
  const handleToggleRoom = (roomCode: string) => {
    setToggleState((prevState) => {
      const isRoomOpen = prevState.rooms.includes(roomCode);

      return {
        ...prevState,
        rooms: isRoomOpen
          ? prevState.rooms.filter((id) => id !== roomCode) // 열려 있으면 닫기
          : [...prevState.rooms, roomCode], // 닫혀 있으면 열기
      };
    });
  };

  // 데이터 변경시 room togle state 리셋
  useEffect(() => {
    if (typeof growthData !== "undefined" && growthData.length > 0) {
      if (dayGap === 0) {
        setToggleState({
          rooms: [],
        });
      } else if (dayGap >= 30) {  // 30일 이상일 때는 첫번째 돈방만 펼침
        const expandedRooms = growthData
          .filter(item => item.room_id === growthData[0].room_id)
          .map((item, index) => `${index}-${item.room_id}-${item.herd_id}`);

        setToggleState({
          rooms: expandedRooms,
        });
      } else {  // 30일 미만일 때는 모든 돈방 펼침
        const allRooms = growthData
          .map((item, index) => `${index}-${item.room_id}-${item.herd_id}`);

        setToggleState({
          rooms: allRooms,
        });
      }
    }
  }, [growthData]);

  // 툴팁과 td 같이 불러오기
  const getTdWithTooltip = (
    data: number | string, 
    tooltipContent: ReactElement | string, 
    css?: string, 
    icon?: any,
    onClick?: () => void,
    style?: React.CSSProperties  // 스타일 객체 파라미터 추가
  ) => {
    return (
      <HtmlTooltip
        disableInteractive
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
        title={tooltipContent}
      >
        <td 
          className={`${css ? css : "hover:text-ef-neutral-white hover:bg-ef-primary-500"} ${icon ? "relative" : ""}`}
          onClick={onClick}
          style={style}  // 스타일 객체 적용
        >
          {data}
          {icon && <Pen className="absolute right-8pxr top-1/2 -translate-y-1/2" />}
        </td>
      </HtmlTooltip>
    );
  };

  // 자동돈군이 존재할때 alert 띄우기용
  useEffect(() => {
    if (typeof isAutoHerdExists !== "undefined") {
      if (isAutoHerdExists === true) setOpenAutoAlert(true);
    }
  }, [isAutoHerdExists]);

  // 새로운 state 추가
  const [weightModalOpen, setWeightModalOpen] = useState(false);
  const [selectedWeight, setSelectedWeight] = useState<{
    weight: number;
    herd_id: number;
    date: string;
  }>({
    weight: 0,
    herd_id: 0,
    date: ''
  });

  return (
    <div className="w-full h-full bg-ef-neutral-50 relative">
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "0px",
            boxShadow: "none",
            marginTop: "120px",
          },
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
        open={openAutoAlert}
        onClose={handleAlertClose}
      >
        <div className="p-40pxr relative min-w-[440px]">
          <button type="button" className="absolute top-40pxr right-32pxr" onClick={handleAlertClose}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
          <p className="text-center ef-title-lg">{t("manage.detection_of_changes_of_herd")}</p>
          <p className="text-center ef-body-lg mt-40pxr">
            {t("manage.auto_alert_comment")
              .split("\n")
              .map((line: any, index: number) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </p>
          <button
            type="button"
            onClick={() => {
              navigate(`/status/stock/${selectedPiggery}`);
            }}
            className="ef-btn-modal !w-full mt-60pxr"
          >
            {t("manage.go_to_status_page")}
          </button>
        </div>
      </Dialog>
      <MonitoringModal isToggle={toggleRoomModal} setIsToggle={setToggleRoomModal} roomId={selectedRoomId} selectedDate={selectedDate} />
      <WeightCorrectionModal 
        isToggle={weightModalOpen}
        setIsToggle={setWeightModalOpen}
        currentWeight={selectedWeight.weight}
        herd_id={selectedWeight.herd_id}
        date={selectedWeight.date}
      />
      <div className="w-[1840px] max-w-[1840px] mx-auto pt-60pxr">
        <header className="flex flex-row items-center">
          <span className="ef-headline-lg">{t("gnb.growth_record")}</span>
          <DownloadTableExcel
            filename={`${startDate !== null ? format(startDate, "yyyy-MM-dd") : ""} ~ ${endDate !== null ? format(endDate, "yyyy-MM-dd") : ""} ${t("gnb.growth_record")}`}
            sheet=""
            currentTableRef={tableRef.current}
          >
            <button type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr ml-16pxr">
              <Download />
              {t("manage.download_excel")}
            </button>
          </DownloadTableExcel>
          <div className="flex flex-row ml-auto gap-24pxr items-center">
            <div className="gap-8pxr flex flex-row">
              <GrowthRangePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} maxDate={subDays(new Date(), 1)} />
              <DropDownFilter
                title={t("common.piggery")}
                state={selectedPiggery}
                setState={setSelectedPiggery}
                options={
                  piggeryList
                    ? piggeryList.map((o) => {
                        return { value: o.id, name: o.name };
                      })
                    : []
                }
              />
              <DropDownCheckBox
                title={t("common.room")}
                state={selectedRooms}
                setState={setSelectedRooms}
                options={
                  roomList
                    ? roomList.map((o) => {
                        return { value: o.id, name: o.name };
                      })
                    : []
                }
              />
            </div>
            <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center">
              <Refresh />
              {t("common.reset")}
            </button>
          </div>
        </header>
        <div className="relative w-full h-fit pb-200pxr">
          {isDataLoading && <ComponentSpinner />}
          {/* divider */}
          <div className="mt-60pxr mb-24pxr w-full h-1pxr bg-ef-neutral-200"></div>
          {isDataLoading ? (
            <div className="w-full h-300pxr"></div>
          ) : isDataError || (growthData && growthData.length === 0) ? (
            <div className="mt-96pxr flex flex-col items-center">
              <img src={NoData} alt="no-data-img" className="w-243pxr" />
              <p className="ef-body-xxl text-ef-neutral-600 mt-18pxr">{t("common.no_info")}</p>
            </div>
          ) : (
            // main
            <div className="flex flex-col gap-24pxr">
              <span className="ml-auto mr-135pxr ef-body-lg text-ef-neutral-700">{`${t("common.view_date")}(${format(new Date(), "yyyy-MM-dd HH:mm")})`}</span>
              <table ref={tableRef} className="ef-table-toggle mx-auto">
                <thead className="sticky top-0pxr z-[2]">
                  <tr>
                    <th rowSpan={2} className="w-160pxr min-w-[160px] max-w-[160px]"></th>
                    <th title={t("common.breeding_status")} rowSpan={2} className="w-102pxr min-w-[102px] max-w-[102px]">
                      {t("common.breeding_status")}
                    </th>
                    <th title={`${t("common.heads")}(${t("common.head")})`} rowSpan={2} className="w-102pxr min-w-[73px] max-w-[73px]">
                      {t("common.heads")}
                      <br />
                      {`(${t("common.head")})`}
                    </th>
                    <th title={`${t("common.day_age")}(${t("common.move_in_date")})`} rowSpan={2} className="w-73pxr min-w-[73px] max-w-[73px]">
                      {t("common.day_age")}
                      <br />
                      {`(${t("common.move_in_date")})`}
                    </th>
                    <th title={t("common.average_weight")} rowSpan={2} className="w-114pxr min-w-[114px] max-w-[114px]">
                      {t("common.average_weight")}
                      <br />
                      (kg)
                    </th>
                    <th title={t("common.weight_gain")} rowSpan={2} className="w-81pxr min-w-[81px] max-w-[81px]">
                      {t("common.weight_gain")}
                      <br />
                      (kg)
                    </th>
                    <th title={t('manage.growth_trend')} rowSpan={2} className="w-81pxr min-w-[81px] max-w-[81px]">
                      {t('manage.growth_trend')}
                      <br />
                      (kg)
                    </th>
                    <th title={`${t("common.vitality")}(m)`} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("common.vitality")}
                      <br />
                      (m)
                      <HtmlTooltip placement="top" title={<span className="ef-body-sm">{t("manage.activity_tooltip_comment")}(m)</span>}>
                        <Info className=" stroke-ef-neutral-700 fill-ef-neutral-700 mx-auto mt-4pxr" />
                      </HtmlTooltip>
                    </th>
                    <th title={`${t("common.feeding_time")}(${t("common.minutes")})`} rowSpan={2} className="w-80pxr min-w-[80px] max-w-[80px]">
                      {t("common.feeding_time")}
                      <br />
                      {`(${t("common.minutes")})`}
                    </th>
                    <th title={`${t("manage.feeder_approach_count")}(${t("common.count_time")})`} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("manage.feeder_approach_count")
                        .split("\n")
                        .map((line: any, index: number) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      {`(${t("common.count_time")})`}
                    </th>
                    <th title={t("common.feeding_amount")} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("common.feeding_amount")}
                      <br />
                      (kg)
                    </th>
                    <th title={t("manage.fcr")} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("manage.fcr")
                        .split("\n")
                        .map((line: any, index: number) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </th>
                    <th title={t("common.heat_index")} rowSpan={2} className="w-80pxr min-w-[80px] max-w-[80px]">
                      {t("common.heat_index")}
                    </th>
                    <th title={t("common.disease_signs")} rowSpan={2} className="w-142pxr min-w-[142px] max-w-[142px]">
                      {t("common.disease_signs")}
                    </th>
                    <th title={`${t("common.breeding_density")}(%)`} rowSpan={2} className="w-80pxr min-w-[80px] max-w-[80px]">
                      {t("common.breeding_density")}
                      <br />
                      (%)
                    </th>
                    <th title={`${t("common.death")}(${t("common.head")})`} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("common.death")}
                      <br />({t("common.head")})
                    </th>
                    <th title={`${t("common.culling")}(${t("common.head")})`} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("common.culling")}
                      <br />({t("common.head")})
                    </th>
                    <th title={`${t("gnb.growing_rate")}(%)`} rowSpan={2} className="w-72pxr min-w-[72px] max-w-[72px]">
                      {t("manage.growing_rate")
                        .split("\n")
                        .map((line: any, index: number) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      (%)
                    </th>
                    {/* <th colSpan={4}>{`${t("common.out_of_standard")}(${t("common.head")})`}</th> */}
                  </tr>
                  {/* <tr>
                    <th title={t("common.overweight")} className="w-76pxr min-w-[76px] max-w-[76px]">
                      {t("common.overweight")}
                    </th>
                    <th title={t("common.underweight")} className="w-76pxr min-w-[76px] max-w-[76px]">
                      {t("common.underweight")}
                    </th>
                    <th title={t("common.suspected_death")} className="w-76pxr min-w-[76px] max-w-[76px]">
                      {t("common.suspected_death")}
                    </th>
                    <th title={t("common.move_recommendation")} className="w-76pxr min-w-[76px] max-w-[76px] !border-r-0">
                      {t("common.move_recommendation")}
                    </th>
                  </tr> */}
                </thead>
                <tbody>
                  {growthData && growthData.length > 0 ? (
                    growthData.map((d, dataIdx) => (
                      <React.Fragment key={`${dataIdx}-${d.room_id}-${d.herd_id}`}>
                        <tr>
                          <td
                            colSpan={dayGap > 0 ? 21 : 1}
                            style={{
                              backgroundColor: dayGap > 0 ? "#F5FAFF" : "#FFFFFF",
                              cursor: dayGap !== 0 ? "pointer" : "default",
                              padding: "12px 8px",
                            }}
                            className={"!text-ef-neutral-900 w-160pxr min-w-[160px] max-w-[160px] " + (dayGap > 0 ? "ef-body-md" : "ef-body-sm")}
                            onClick={() => {
                              if (dayGap === 0) return;
                              else handleToggleRoom(`${dataIdx}-${d.room_id}-${d.herd_id}`);
                            }}
                          >
                            <div className="flex flex-row items-center max-w-[144px] gap-4pxr">
                              <span className="text-left max-w-[116px] break-all whitespace-pre-wrap">
                                {d.room_name}
                                <br />
                                {`(${d.herd_nickname ?? d.herd_name ?? t("manage.herd_not_registered")})`}
                              </span>
                              {dayGap !== 0 ? (
                                <div className="ml-auto">
                                  {toggleState.rooms.includes(`${dataIdx}-${d.room_id}-${d.herd_id}`) ? (
                                    <AltArrowUp className="fill-ef-neutral-700" />
                                  ) : (
                                    <AltArrowDown className="fill-ef-neutral-700" />
                                  )}
                                </div>
                              ) : (
                                <CCTV
                                  onClick={() => {
                                    setSelectedRoomId(d.room_id);
                                    setSelectedDate(d.period[0].date);
                                    setToggleRoomModal(true);
                                  }}
                                  className="stroke-ef-neutral-900 ml-auto cursor-pointer"
                                />
                              )}
                            </div>
                          </td>
                          {dayGap === 0 && (
                            <>
                              <td>
                                {d.period[0].herd_active_status !== null && (
                                  <div
                                    style={{
                                      backgroundColor: d.period[0].herd_active_status ? "#E3F4E6" : "#F4F4F4",
                                      color: d.period[0].herd_active_status ? "#37B24D" : "#878787",
                                    }}
                                    className="rounded-[14px] w-78pxr min-w-[78px] py-4pxr ef-body-md mx-auto"
                                  >
                                    {d.period[0].herd_active_status ? t("common.breeding") : t("common.closed")}
                                  </div>
                                )}
                              </td>
                              {getTdWithTooltip(
                                d.period[0].stock ?? "-",
                                d.period[0].stock ? (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span className="ef-body-md">{t("common.stock_updated")}</span>
                                    {tooltipDivider}
                                    <span className="ef-body-sm">{d.period[0].stock_last_updated_at ? format(new Date(d.period[0].stock_last_updated_at), "yyyy-MM-dd") : t("common.no_info")}</span>
                                  </div>
                                ) : (
                                  ""
                                ),
                              )}
                              {getTdWithTooltip(
                                `${d.period[0].age === null ? "-" : d.period[0].age}\r\n${d.period[0].arrival_age === null ? "" : `(+${d.period[0].arrival_age})`}`,
                                d.period[0].age !== null && d.birth_date !== null ? <span className="ef-body-sm">{`${d.birth_date}(${d.period[0].age}${t("common.aged")})`}</span> : "",
                                "!py-0pxr !whitespace-pre-wrap hover:bg-ef-primary-500 hover:text-ef-neutral-white",
                              )}
                              {/* 평균체중 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].weight.avg !== null ? d.period[0].weight.avg.toFixed(2) : "-",
                                d.period[0].has_efg_cameras ? (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    {d.herd_id !== null ? (
                                      <>
                                        <span className="ef-body-md">{t("common.proper_weight")}</span>
                                        {tooltipDivider}
                                        <span className="ef-body-sm">{`${d.period[0].weight.lower_weight}kg ~ ${d.period[0].weight.upper_weight}kg`}</span>
                                      </>
                                    ) : (
                                      <span className="ef-body-sm">{t("manage.register_herd_display_appropriate_weight_comment")}</span>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                ),
                                `${!d.period[0].has_efg_cameras
                                  ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                  : d.period[0].answer_weight !== null
                                    ? "bg-ef-primary-50 text-ef-neutral-900 hover:bg-ef-primary-500"
                                    : d.herd_id !== null && d.period[0].weight.avg !== null && !_.inRange(d.period[0].weight.avg, d.period[0].weight.lower_weight, d.period[0].weight.upper_weight)
                                  ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                  : d.period[0].weight.avg !== null ? `text-ef-neutral-900 hover:bg-ef-primary-500` : `text-ef-neutral-300 fill-ef-neutral-300 hover:bg-ef-primary-500`
                              } hover:text-ef-neutral-white hover:fill-ef-neutral-white text-left ${d.period[0].weight.avg !== null && d.herd_id !== null ? 'cursor-pointer' : ''}`,
                                d.herd_id !== null,
                                () => {
                                  if (d.period[0].weight.avg !== null && d.herd_id !== null) {
                                    setSelectedWeight({
                                      weight: d.period[0].weight.avg,
                                      herd_id: d.herd_id,
                                      date: d.period[0].date || ''
                                    });
                                    setWeightModalOpen(true);
                                  }
                                },
                                d.period[0].answer_weight !== null ? {
                                  outline: '1px solid #033687',
                                  outlineOffset: '-1px'
                                } : undefined
                              )}
                              {/* 증체량 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].weight_gain !== null ? (d.period[0].weight_gain > 0 ? `+${d.period[0].weight_gain.toFixed(2)}` : d.period[0].weight_gain.toFixed(2)) : "-",
                                "",
                                `${!d.period[0].has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                              )}
                              {/* 성장추이 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].growth_weight !== null ? (d.period[0].growth_weight > 0 ? `${d.period[0].growth_weight.toFixed(2)}` : d.period[0].growth_weight.toFixed(2)) : "-",
                                "",
                                `${!d.period[0].has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                              )}
                              {/* 활력도 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].activity.avg !== null ? d.period[0].activity.avg.toFixed(1) : "-",
                                d.period[0].has_efg_cameras ? (
                                  <div className="flex flex-col gap-8pxr ef-body-sm">
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].activity.dawn !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].activity.dawn !== null ? `${d.period[0].activity.dawn.toFixed(1)}m` : t("common.no_info")}
                                      </span>
                                    </div>
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].activity.date_time !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].activity.date_time !== null ? `${d.period[0].activity.date_time.toFixed(1)}m` : t("common.no_info")}
                                      </span>
                                    </div>
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].activity.night !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].activity.night !== null ? `${d.period[0].activity.night.toFixed(1)}m` : t("common.no_info")}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                ),
                                `${!d.period[0].has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                              )}
                              {/* 식사시간 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].feed_time.avg !== null ? Math.round(d.period[0].feed_time.avg) : "-",
                                <div className="flex flex-col gap-8pxr ef-body-sm">
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                    {tooltipDivider}
                                    <span
                                      style={{
                                        color: d.period[0].feed_time.dawn !== null ? "" : "#878787",
                                      }}
                                    >
                                      {d.period[0].feed_time.dawn !== null ? `${Math.round(d.period[0].feed_time.dawn)}분` : t("common.no_info")}
                                    </span>
                                  </div>
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                    {tooltipDivider}
                                    <span
                                      style={{
                                        color: d.period[0].feed_time.date_time !== null ? "" : "#878787",
                                      }}
                                    >
                                      {d.period[0].feed_time.date_time !== null ? `${Math.round(d.period[0].feed_time.date_time)}분` : t("common.no_info")}
                                    </span>
                                  </div>
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                    {tooltipDivider}
                                    <span
                                      style={{
                                        color: d.period[0].feed_time.night !== null ? "" : "#878787",
                                      }}
                                    >
                                      {d.period[0].feed_time.night !== null ? `${Math.round(d.period[0].feed_time.night)}분` : t("common.no_info")}
                                    </span>
                                  </div>
                                </div>,
                                `text-${!d.period[0].has_efg_cameras ? "ef-neutral-500 hover:bg-ef-primary-500" : "ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                              )}
                              {/* 급이기 접근 */}
                              {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].feeder_access_count !== null ? Math.round(d.period[0].feeder_access_count) : "-",
                                "",
                                `text-${!d.period[0].has_efg_cameras ? "ef-neutral-500 hover:bg-ef-primary-500" : "ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                              )}
                              {/* 급이량 */}
                              {getTdWithTooltip(
                                !d.period[0].feeder.has_feeder ? t("common.no_device") : d.period[0].feeder.total_mount !== null ? d.period[0].feeder.total_mount.toFixed(2) : "-",
                                d.period[0].feeder.has_feeder ? (
                                  <div className="flex flex-col gap-8pxr ef-body-sm">
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span className="ef-body-md">{t("common.proper_feeding_amount")}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].feeder.lower_feeding !== null && d.period[0].feeder.upper_feeding !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].feeder.lower_feeding !== null && d.period[0].feeder.upper_feeding !== null
                                          ? `${d.period[0].feeder.lower_feeding.toFixed(2)}kg ~ ${d.period[0].feeder.upper_feeding.toFixed(2)}kg`
                                          : t("common.no_info")}
                                      </span>
                                    </div>
                                    <div className="w-full h-1pxr bg-ef-neutral-150 my-4pxr"></div>
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].feeder.dawn !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].feeder.dawn !== null ? `${d.period[0].feeder.dawn.toFixed(2)}kg` : t("common.no_info")}
                                      </span>
                                    </div>
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].feeder.date_time !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].feeder.date_time !== null ? `${d.period[0].feeder.date_time.toFixed(2)}kg` : t("common.no_info")}
                                      </span>
                                    </div>
                                    <div className="flex flex-row gap-8pxr items-center">
                                      <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                      {tooltipDivider}
                                      <span
                                        style={{
                                          color: d.period[0].feeder.night !== null ? "" : "#878787",
                                        }}
                                      >
                                        {d.period[0].feeder.night !== null ? `${d.period[0].feeder.night.toFixed(2)}kg` : t("common.no_info")}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                ),
                                `${
                                  !d.period[0].feeder.has_feeder
                                    ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                    : d.period[0].feeder.total_mount !== null &&
                                        d.period[0].feeder.lower_feeding !== null &&
                                        d.period[0].feeder.upper_feeding !== null &&
                                        !_.inRange(d.period[0].feeder.total_mount, d.period[0].feeder.lower_feeding, d.period[0].feeder.upper_feeding)
                                      ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                      : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                } hover:text-ef-neutral-white`,
                              )}
                              {/* @J-TODO fcr */}
                              {getTdWithTooltip(
                              !d.period[0].has_efg_cameras ? t("common.no_device") : d.period[0].fcr != null ? d.period[0].fcr.toFixed(2) : "-",
                              "",
                              `${!d.period[0].has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                            )}
                              {/* <td>-</td> */}
                              {/* 열지수 */}
                              {getTdWithTooltip(
                                !d.period[0].heat_index.has_sensor || !d.period[0].has_efg_cameras
                                  ? t("common.no_device")
                                  : d.period[0].heat_index.index !== null
                                    ? Math.floor(d.period[0].heat_index.index)
                                    : "-",
                                d.period[0].heat_index.has_sensor && d.period[0].heat_index.index !== null ? (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span className="ef-body-md">{t("common.proper_heat_index")}</span>
                                    {tooltipDivider}
                                    <span className="ef-body-sm">
                                      {d.period[0].heat_index.lower_index !== null && d.period[0].heat_index.upper_index !== null
                                        ? `${Math.floor(d.period[0].heat_index.lower_index)} ~ ${Math.floor(d.period[0].heat_index.upper_index)}`
                                        : t("common.no_info")}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                ),

                                `${
                                  !d.period[0].heat_index.has_sensor || !d.period[0].has_efg_cameras
                                    ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                    : d.period[0].heat_index.lower_index !== null &&
                                        d.period[0].heat_index.upper_index !== null &&
                                        d.period[0].heat_index.index !== null &&
                                        !_.inRange(d.period[0].heat_index.index, d.period[0].heat_index.lower_index, d.period[0].heat_index.upper_index)
                                      ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                      : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                } hover:text-ef-neutral-white`,
                              )}
                              {/* @J-TODO 질병징후 WIP */}
                              {getTdWithTooltip("-", "")}
                              {/* 밀사율 */}
                              {getTdWithTooltip(
                                d.period[0].breeding_density !== null ? d.period[0].breeding_density.toFixed(2) : "-",
                                d.period[0].breeding_density === null && d.herd_id !== null ? <div className="ef-body-sm">{t("manage.no_area_information")}</div> : "",
                                `${d.period[0].breeding_density !== null && d.period[0].breeding_density > 100 
                                  ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500" 
                                  : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                } hover:text-ef-neutral-white`
                              )}
                              {/* 폐사 */}
                              {getTdWithTooltip(d.herd_id === null ? "-" : d.period[0].number_of_death ?? 0, "")}
                              {/* 도태 */}
                              {getTdWithTooltip(d.herd_id === null ? "-" : d.period[0].number_of_culling ?? 0, "")}
                              {/* 이유 후 육성률 */}
                              {getTdWithTooltip(d.period[0].growing_rate === null || d.herd_id === null ? "-" : `${Math.floor(d.period[0].growing_rate)}`, "")}
                              {/* @J-TODO 규격 외 정보들 WIP */}
                              {/* 과체중 */}
                              {/* {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.herd_id === null || d.period[0].over_weight === null ? "-" : Math.floor(d.period[0].over_weight),
                                !d.period[0].has_efg_cameras || d.herd_id === null ? (
                                  ""
                                ) : (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span className="ef-body-md">{t("common.overweight")}</span>
                                    {tooltipDivider}
                                    <span
                                      style={{
                                        color: d.period[0].over_weight_range === null ? "#878787" : "",
                                      }}
                                      className="ef-body-sm"
                                    >
                                      {d.period[0].over_weight_range === null ? t("common.no_info") : t("manage.over", { data: `${d.period[0].over_weight_range.toFixed(2)}kg` })}
                                    </span>
                                  </div>
                                ),
                              )} */}
                              {/* 저체중 */}
                              {/* {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.herd_id === null || d.period[0].under_weight === null ? "-" : Math.floor(d.period[0].under_weight),
                                !d.period[0].has_efg_cameras || d.herd_id === null ? (
                                  ""
                                ) : (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span className="ef-body-md">{t("common.underweight")}</span>
                                    {tooltipDivider}
                                    <span
                                      style={{
                                        color: d.period[0].under_weight_range === null ? "#878787" : "",
                                      }}
                                      className="ef-body-sm"
                                    >
                                      {d.period[0].under_weight_range === null ? t("common.no_info") : t("manage.under", { data: `${d.period[0].under_weight_range.toFixed(2)}kg` })}
                                    </span>
                                  </div>
                                ),
                              )} */}
                              {/* 폐사의심 */}
                              {/* <td>-</td> */}
                              {/* 이동추천 */}
                              {/* {getTdWithTooltip(
                                !d.period[0].has_efg_cameras ? t("common.no_device") : d.herd_id === null || d.period[0].ship_recommend === null ? "-" : Math.floor(d.period[0].ship_recommend),
                                !d.period[0].has_efg_cameras || d.herd_id === null ? (
                                  ""
                                ) : (
                                  <div className="flex flex-row gap-8pxr items-center">
                                    <span
                                      style={{
                                        color: d.period[0].ship_recommend_lower_weight !== null && d.period[0].ship_recommend_upper_weight !== null ? "" : "#878787",
                                      }}
                                      className="ef-body-md"
                                    >
                                      {t("manage.move_recommendation_range")}
                                    </span>
                                    {tooltipDivider}
                                    <span className="ef-body-sm">
                                      {d.period[0].ship_recommend_lower_weight !== null && d.period[0].ship_recommend_upper_weight !== null
                                        ? `${d.period[0].ship_recommend_lower_weight.toFixed(2)}kg ~ ${d.period[0].ship_recommend_upper_weight.toFixed(2)}kg`
                                        : t("common.no_info")}
                                    </span>
                                  </div>
                                ),
                              )} */}
                            </>
                          )}
                        </tr>
                        {toggleState.rooms.includes(`${dataIdx}-${d.room_id}-${d.herd_id}`) &&
                          d.period.map((o) => (
                            <React.Fragment key={`${d.room_id}-${d.herd_id}-${o.date}`}>
                              {/* 아래로 날짜별 */}
                              {dayGap > 0 && (
                                <tr key={`${d.room_id}-${d.herd_id}-${o.date}`}>
                                  {/* 날짜 */}
                                  <td className="!py-12pxr !text-ef-neutral-900 !text-left">
                                    <div className="flex flex-row items-center">
                                      {o.date}
                                      <CCTV
                                        onClick={() => {
                                          setSelectedRoomId(d.room_id);
                                          setSelectedDate(o.date);
                                          setToggleRoomModal(true);
                                        }}
                                        className="ml-auto stroke-ef-neutral-900 cursor-pointer"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {o.herd_active_status !== null && (
                                      <div
                                        style={{
                                          backgroundColor: o.herd_active_status ? "#E3F4E6" : "#F4F4F4",
                                          color: o.herd_active_status ? "#37B24D" : "#878787",
                                        }}
                                        className="rounded-[14px] w-78pxr min-w-[78px] py-4pxr ef-body-md mx-auto"
                                      >
                                        {o.herd_active_status ? t("common.breeding") : t("common.closed")}
                                      </div>
                                    )}
                                  </td>
                                  {getTdWithTooltip(
                                    o.stock ?? "-",
                                    o.stock ? (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span className="ef-body-md">{t("common.stock_updated")}</span>
                                        {tooltipDivider}
                                        <span className="ef-body-sm">{o.stock_last_updated_at ? format(new Date(o.stock_last_updated_at), "yyyy-MM-dd") : t("common.no_info")}</span>
                                      </div>
                                    ) : (
                                      ""
                                    ),
                                  )}
                                  {getTdWithTooltip(
                                    `${o.age === null ? "-" : o.age}\r\n${o.arrival_age === null ? "" : `(+${o.arrival_age})`}`,
                                    d.birth_date !== null && o.age !== null ? <span className="ef-body-sm">{`${d.birth_date}(${o.age}${t("common.aged")})`}</span> : "",
                                    "!py-0pxr !whitespace-pre-wrap hover:bg-ef-primary-500 hover:text-ef-neutral-white",
                                  )}
                                  {/* 평균체중 */}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.weight.avg !== null ? o.weight.avg.toFixed(2) : "-",
                                    o.has_efg_cameras ? (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        {d.herd_id !== null ? (
                                          <>
                                            <span className="ef-body-md">{t("common.proper_weight")}</span>
                                            {tooltipDivider}
                                            <span className="ef-body-sm">{`${o.weight.lower_weight}kg ~ ${o.weight.upper_weight}kg`}</span>
                                          </>
                                        ) : (
                                          <span className="ef-body-sm">{t("manage.register_herd_display_appropriate_weight_comment")}</span>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    ),
                                    `${!o.has_efg_cameras
                                      ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                      : o.answer_weight !== null
                                        ? "bg-ef-primary-50 text-ef-neutral-900 hover:bg-ef-primary-500"
                                        : d.herd_id !== null && o.weight.avg !== null && !_.inRange(o.weight.avg, o.weight.lower_weight, o.weight.upper_weight)
                                          ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                          : o.weight.avg !== null ? `text-ef-neutral-900 hover:bg-ef-primary-500` : `text-ef-neutral-300 fill-ef-neutral-300 hover:bg-ef-primary-500`
                                    } hover:text-ef-neutral-white hover:fill-ef-neutral-white text-left ${o.weight.avg !== null && d.herd_id !== null ? 'cursor-pointer' : ''}`,
                                    d.herd_id !== null,  // 돈군이 있을 때만 아이콘 표시
                                    () => {
                                      if (o.weight.avg !== null && d.herd_id !== null) {  // 돈군이 있을 때만 클릭 이벤트 실행
                                        setSelectedWeight({
                                          weight: o.weight.avg,
                                          herd_id: d.herd_id,
                                          date: o.date || ''
                                        });
                                        setWeightModalOpen(true);
                                      }
                                    },
                                    o.answer_weight !== null ? {
                                      outline: '1px solid #033687',
                                      zIndex: 1
                                    } : undefined
                                  )}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.weight_gain !== null ? (o.weight_gain > 0 ? `+${o.weight_gain.toFixed(2)}` : o.weight_gain.toFixed(2)) : "-",
                                    "",
                                    `${!o.has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                  )}
                                  {/* 성장추이 */}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.growth_weight !== null ? (o.growth_weight > 0 ? `${o.growth_weight.toFixed(2)}` : o.growth_weight.toFixed(2)) : "-",
                                    "",
                                    `${!o.has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                  )}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.activity.avg !== null ? o.activity.avg.toFixed(1) : "-",
                                    o.has_efg_cameras ? (
                                      <div className="flex flex-col gap-8pxr ef-body-sm">
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.activity.dawn !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.activity.dawn !== null ? `${o.activity.dawn.toFixed(1)}m` : t("common.no_info")}
                                          </span>
                                        </div>
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.activity.date_time !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.activity.date_time !== null ? `${o.activity.date_time.toFixed(1)}m` : t("common.no_info")}
                                          </span>
                                        </div>
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.activity.night !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.activity.night !== null ? `${o.activity.night.toFixed(1)}m` : t("common.no_info")}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    ),
                                    `${!o.has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                  )}
                                  {/* 식사시간 */}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.feed_time.avg !== null ? Math.round(o.feed_time.avg) : "-",
                                    <div className="flex flex-col gap-8pxr ef-body-sm">
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                        {tooltipDivider}
                                        <span
                                          style={{
                                            color: o.feed_time.dawn !== null ? "" : "#878787",
                                          }}
                                        >
                                          {o.feed_time.dawn !== null ? `${Math.round(o.feed_time.dawn)}분` : t("common.no_info")}
                                        </span>
                                      </div>
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                        {tooltipDivider}
                                        <span
                                          style={{
                                            color: o.feed_time.date_time !== null ? "" : "#878787",
                                          }}
                                        >
                                          {o.feed_time.date_time !== null ? `${Math.round(o.feed_time.date_time)}분` : t("common.no_info")}
                                        </span>
                                      </div>
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                        {tooltipDivider}
                                        <span
                                          style={{
                                            color: o.feed_time.night !== null ? "" : "#878787",
                                          }}
                                        >
                                          {o.feed_time.night !== null ? `${Math.round(o.feed_time.night)}분` : t("common.no_info")}
                                        </span>
                                      </div>
                                    </div>,
                                    `text-${!o.has_efg_cameras ? "ef-neutral-500 hover:bg-ef-primary-500" : "ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                  )}
                                  {/* 급이기 접근 횟수 */}
                                  {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : o.feeder_access_count !== null ? Math.round(o.feeder_access_count) : "-",
                                    "",
                                    `text-${!o.has_efg_cameras ? "ef-neutral-500 hover:bg-ef-primary-500" : "ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                  )}
                                  {/* 급이량 */}
                                  {getTdWithTooltip(
                                    !o.feeder.has_feeder ? t("common.no_device") : o.feeder.total_mount !== null ? o.feeder.total_mount.toFixed(2) : "-",
                                    o.feeder.has_feeder ? (
                                      <div className="flex flex-col gap-8pxr ef-body-sm">
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span className="ef-body-md">{t("common.proper_feeding_amount")}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.feeder.lower_feeding !== null && o.feeder.upper_feeding !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.feeder.lower_feeding !== null && o.feeder.upper_feeding !== null
                                              ? `${o.feeder.lower_feeding.toFixed(2)}kg ~ ${o.feeder.upper_feeding.toFixed(2)}kg`
                                              : t("common.no_info")}
                                          </span>
                                        </div>
                                        <div className="w-full h-1pxr bg-ef-neutral-150 my-4pxr"></div>
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.dawn")}(${t("manage.from_to_hour", { from: 0, to: 8 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.feeder.dawn !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.feeder.dawn !== null ? `${o.feeder.dawn.toFixed(2)}kg` : t("common.no_info")}
                                          </span>
                                        </div>
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.daytime")}(${t("manage.from_to_hour", { from: 8, to: 16 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.feeder.date_time !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.feeder.date_time !== null ? `${o.feeder.date_time.toFixed(2)}kg` : t("common.no_info")}
                                          </span>
                                        </div>
                                        <div className="flex flex-row gap-8pxr items-center">
                                          <span>{`${t("common.evening")}(${t("manage.from_to_hour", { from: 16, to: 24 })})`}</span>
                                          {tooltipDivider}
                                          <span
                                            style={{
                                              color: o.feeder.night !== null ? "" : "#878787",
                                            }}
                                          >
                                            {o.feeder.night !== null ? `${o.feeder.night.toFixed(2)}kg` : t("common.no_info")}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    ),
                                    `${
                                      !o.feeder.has_feeder
                                        ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                        : o.feeder.total_mount !== null &&
                                            o.feeder.lower_feeding !== null &&
                                            o.feeder.upper_feeding !== null &&
                                            !_.inRange(o.feeder.total_mount, o.feeder.lower_feeding, o.feeder.upper_feeding)
                                          ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                          : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                    } hover:text-ef-neutral-white`,
                                  )}
                                  {/* @J-TODO fcr */}
                                  {getTdWithTooltip(
                                  !o.has_efg_cameras ? t("common.no_device") : o.fcr != null ? o.fcr.toFixed(2) : "-",
                                  "",
                                  `${!o.has_efg_cameras ? "text-ef-neutral-500 hover:bg-ef-primary-500" : "text-ef-neutral-900 hover:bg-ef-primary-500"} hover:text-ef-neutral-white`,
                                )}
                                  {/* 사료 요구율 */}
                                  {/* <td>-</td> */}
                                  {/* 열지수 */}
                                  {getTdWithTooltip(
                                    !o.heat_index.has_sensor || !o.has_efg_cameras ? t("common.no_device") : o.heat_index.index !== null ? Math.floor(o.heat_index.index) : "-",
                                    o.heat_index.has_sensor && o.heat_index.index !== null ? (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span className="ef-body-md">{t("common.proper_heat_index")}</span>
                                        {tooltipDivider}
                                        <span className="ef-body-sm">
                                          {o.heat_index.lower_index !== null && o.heat_index.upper_index !== null
                                            ? `${Math.floor(o.heat_index.lower_index)} ~ ${Math.floor(o.heat_index.upper_index)}`
                                            : t("common.no_info")}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    ),

                                    `${
                                      !o.heat_index.has_sensor || !o.has_efg_cameras
                                        ? "text-ef-neutral-500 hover:bg-ef-primary-500"
                                        : o.heat_index.lower_index !== null &&
                                            o.heat_index.upper_index !== null &&
                                            o.heat_index.index !== null &&
                                            !_.inRange(o.heat_index.index, o.heat_index.lower_index, o.heat_index.upper_index)
                                          ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500"
                                          : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                    } hover:text-ef-neutral-white`,
                                  )}
                                  {/* @J-TODO 질병징후 WIP */}
                                  {getTdWithTooltip("-", "")}
                                  {/* 밀사율 */}
                                  {getTdWithTooltip(
                                    o.breeding_density !== null ? o.breeding_density.toFixed(2) : "-",
                                    o.breeding_density === null && d.herd_id !== null ? <div className="ef-body-sm">{t("manage.no_area_information")}</div> : "",
                                    `${o.breeding_density !== null && o.breeding_density > 100 
                                      ? "text-ef-state-red-500 bg-ef-state-red-50 hover:bg-ef-state-red-500" 
                                      : "text-ef-neutral-900 hover:bg-ef-primary-500"
                                    } hover:text-ef-neutral-white`
                                  )}
                                  {/* 폐사 */}
                                  {getTdWithTooltip(d.herd_id === null ? "-" : o.number_of_death ?? 0, "")}
                                  {/* 도태 */}
                                  {getTdWithTooltip(d.herd_id === null ? "-" : o.number_of_culling ?? 0, "")}
                                  {/* 이유 후 육성률 */}
                                  {getTdWithTooltip(o.growing_rate === null || d.herd_id === null ? "-" : `${Math.floor(o.growing_rate)}%`, "")}
                                  {/* @J-TODO 규격 외 정보들 WIP */}
                                  {/* 과체중 */}
                                  {/* {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : d.herd_id === null || o.over_weight === null ? "-" : Math.floor(o.over_weight),
                                    !o.has_efg_cameras || d.herd_id === null ? (
                                      ""
                                    ) : (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span className="ef-body-md">{t("common.overweight")}</span>
                                        {tooltipDivider}
                                        <span
                                          style={{
                                            color: o.over_weight_range === null ? "#878787" : "",
                                          }}
                                          className="ef-body-sm"
                                        >
                                          {o.over_weight_range === null ? t("common.no_info") : t("manage.over", { data: `${o.over_weight_range.toFixed(2)}kg` })}
                                        </span>
                                      </div>
                                    ),
                                  )} */}
                                  {/* 저체중 */}
                                  {/* {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : d.herd_id === null || o.under_weight === null ? "-" : Math.floor(o.under_weight),
                                    !o.has_efg_cameras || d.herd_id === null ? (
                                      ""
                                    ) : (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span className="ef-body-md">{t("common.underweight")}</span>
                                        {tooltipDivider}
                                        <span
                                          style={{
                                            color: o.under_weight_range === null ? "#878787" : "",
                                          }}
                                          className="ef-body-sm"
                                        >
                                          {o.under_weight_range === null ? t("common.no_info") : t("manage.under", { data: `${o.under_weight_range.toFixed(2)}kg` })}
                                        </span>
                                      </div>
                                    ),
                                  )} */}
                                  {/* 폐사의심 */}
                                  {/* <td>-</td> */}
                                  {/* 이동추천 */}
                                  {/* {getTdWithTooltip(
                                    !o.has_efg_cameras ? t("common.no_device") : d.herd_id === null || o.ship_recommend === null ? "-" : Math.floor(o.ship_recommend),
                                    !o.has_efg_cameras || d.herd_id === null ? (
                                      ""
                                    ) : (
                                      <div className="flex flex-row gap-8pxr items-center">
                                        <span
                                          style={{
                                            color: o.ship_recommend_lower_weight !== null && o.ship_recommend_upper_weight !== null ? "" : "#878787",
                                          }}
                                          className="ef-body-md"
                                        >
                                          {t("manage.move_recommendation_range")}
                                        </span>
                                        {tooltipDivider}
                                        <span className="ef-body-sm">
                                          {o.ship_recommend_lower_weight !== null && o.ship_recommend_upper_weight !== null
                                            ? `${o.ship_recommend_lower_weight.toFixed(2)}kg ~ ${o.ship_recommend_upper_weight.toFixed(2)}kg`
                                            : t("common.no_info")}
                                        </span>
                                      </div>
                                    ),
                                  )} */}
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
