import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as GeneralClose } from "assets/icons/general/ef-general-close.svg";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import RequiredAlert from "components/common/alert/RequiredAlert";
import { getRedStar } from "utils/getImportantStar";

type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type Props = {
  isToggle: boolean;
  setIsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  deviceId: number;
};

type Inputs = {
  name: string;
  location: string;
  network_status: string;
  serialize_num: string;
  id: number;
};

function SprayDetail(props: Props) {
  const { isToggle, setIsToggle, deviceId } = props;
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<Inputs>();

  const onClose = (reason: CloseReason) => {
    if (reason === "backdropClick") return;
    else {
      setIsToggle(false);
      reset();
    }
  };

  const onSubmit = (data: Inputs) => {
    console.log(data);
  };

  useEffect(() => {
    reset({
      name: "spray111",
      location: "intflow",
      network_status: true ? t("manage.normal") : t("manage.abnormal"),
      serialize_num: "2398lkds",
      id: 234,
    });

    // @TODO reset data comes
  }, []);

  return (
    <Modal
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "#303030", // neutral-900
          opacity: "0.3 !important",
        },
      }}
      open={isToggle}
      onClose={(_, reason) => onClose(reason)}
      className="flex justify-center pt-120pxr overflow-y-auto pb-120pxr"
    >
      <div className="w-980pxr h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{`${t("manage.spray")} ${t("manage.details")}`}</span>
          <button className="absolute right-0pxr" onClick={() => onClose("closeButtonClick")}>
            <GeneralClose className="stroke-ef-neutral-900" />
          </button>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {/* body */}
          <div className="my-60pxr flex flex-col ef-label-md text-ef-neutral-900 flex-wrap gap-24pxr">
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] mr-12pxr flex flex-row">
                <span className="line-clamp-1 break-all">{t("manage.device_name")}</span>
                {getRedStar()}
              </label>
              <div className="w-340pxr relative" onClick={() => clearErrors("name")}>
                {errors.name ? <RequiredAlert /> : <></>}
                <Controller name="name" control={control} rules={{ required: true }} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.installation_location")}</label>
              <div className="w-340pxr">
                <Controller name="location" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("common.device_connection_status")}</label>
              <div className="w-340pxr">
                <Controller name="network_status" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">{t("manage.serial_number")}</label>
              <div className="w-340pxr">
                <Controller name="serialize_num" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
            <div className="flex flex-row items-center">
              <label className="w-107pxr min-w-[107px] line-clamp-1 mr-12pxr">ID</label>
              <div className="w-340pxr">
                <Controller name="id" control={control} render={({ field }) => <InputFieldOnly state={field.value} setState={field.onChange} disabled />} />
              </div>
            </div>
          </div>
          <button type="submit" className="ef-btn-modal mx-auto">
            {t("common.save")}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default SprayDetail;
