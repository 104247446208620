import NormalDistribution from "normal-distribution";

export function datas(mean: number | undefined, std: number | undefined) {
  const result = [];
  const nbiter = 100;
  const alpha = 3; //3

  const normalDistribution = new NormalDistribution(mean, std);
  if (mean && std) {
    for (let i = 0; i <= nbiter; i++) {
      const y = normalDistribution.pdf(mean - std * alpha + (std * (alpha * 2) * i) / nbiter);
      result.push({
        x: mean - std * alpha + (std * (alpha * 2) * i) / nbiter,
        y: isNaN(y) ? 0 : y,
      });
    }
  }
  return result;
}
