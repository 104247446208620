import NavFilters from "components/pages/1_farmStatus/NavFilters";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Filter } from "assets/icons/general/ef-general-filter.svg";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import { useDeviceDashboard } from "query/piggery";
import BackHomeBtn from "components/pages/1_farmStatus/BackHomeBtn";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import ComponentSpinner from "components/common/spinner/spinner";
import { format, subHours } from "date-fns";
import { ReactComponent as GridSvg } from "assets/images/status/grid.svg";
import { ReactComponent as Entrance } from "assets/icons/icon16_general/ef-16general-entrance.svg";
import { ReactComponent as EFCIcon } from "assets/icons/status/point_efc.svg";
import { ReactComponent as EFGIcon } from "assets/icons/status/point_efg.svg";
import { ReactComponent as FeederIcon } from "assets/icons/status/point_feeder.svg";
import { ReactComponent as SensorIcon } from "assets/icons/status/point_sensor.svg";
import { ReactComponent as Warning } from "assets/icons/status/point_warning.svg";
import { ReactComponent as EfcLegend } from "assets/icons/status/device_efc.svg";
import { ReactComponent as EfgLegend } from "assets/icons/status/device_efg.svg";
import { ReactComponent as FeederLegend } from "assets/icons/status/device_feeder.svg";
import { ReactComponent as SensorLegend } from "assets/icons/status/device_sensor.svg";

import NoDataImg from "assets/images/report/no_report_data.png";
import { isBefore } from "date-fns";
import EfcModal from "components/pages/1_farmStatus/modal/device/EfcModal";
import EfgModal from "components/pages/1_farmStatus/modal/device/EfgModal";

// 툴팁 기본 css
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: "none",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
    padding: "16px 16px 24px 16px",
    fontFamily: "Pretendard",
  },
}));

// 장치 이상 빨간색 점
const getAbnormal = () => {
  return <div className="w-6pxr min-w-[6px] h-6pxr rounded-full bg-ef-state-red-500"></div>;
};

// 장치 정상 초록색 점
const getNormal = () => {
  return <div className="w-6pxr min-w-[6px] h-6pxr rounded-full bg-ef-state-green-500"></div>;
};

function DeviceMap() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // state for room div load
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);
  const imageRef = useRef<HTMLDivElement | null>(null);

  // state for filter
  const [selectedFilter, setSelectedFilter] = useState(["efc", "efg", "feeder", "sensor"]);

  // state for efc modal
  const [toggleEfcModal, setToggleEfcModal] = useState(false);
  const [selectedEfc, setSelectedEfc] = useState(0);

  // state for efg modal
  const [toggleEfgModal, setToggleEfgModal] = useState(false);
  const [selectedEfg, setSelectedEfg] = useState(0);

  // query
  const { data: roomData, isLoading: dataLoading, isError } = useDeviceDashboard(typeof id !== "undefined" ? Number(id) : 0);

  // 아래 미사용 함수 4개는 filter 용 함수(혹시 되살릴지 몰라서 킵)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  // handle filter checkboxes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setSelectedFilter((prev) => (checked ? [...prev, id] : prev.filter((filter) => filter !== id)));
  };

  // handle filter reset btn
  const handleFilterReset = () => {
    setSelectedFilter(["efc", "efg", "feeder", "sensor"]);
  };

  // get divs' poisitions
  const getPoints = (overlay: { x: number; y: number }[] | null) => {
    if (!overlay || overlay.length < 2) return { x1: 0, y1: 0, width: 0, height: 0 };

    // x와 y의 최소값과 최대값 계산
    const minX = Math.min(...overlay.map((point) => point.x));
    const minY = Math.min(...overlay.map((point) => point.y));
    const maxX = Math.max(...overlay.map((point) => point.x));
    const maxY = Math.max(...overlay.map((point) => point.y));

    // 부모 요소에 맞춰 절대 위치 계산
    const x1 = minX * parentWidth;
    const y1 = minY * parentHeight;
    const x2 = maxX * parentWidth;
    const y2 = maxY * parentHeight;

    // 사각형의 너비와 높이 계산
    const width = x2 - x1;
    const height = y2 - y1;

    return { x1, y1, width, height };
  };

  // set size for room divs
  useEffect(() => {
    if (!imageRef.current) return;

    const updateSize = () => {
      setParentWidth(imageRef.current!.clientWidth);
      setParentHeight(imageRef.current!.clientHeight);
    };

    updateSize();

    const resizeObserver = new ResizeObserver(() => updateSize());
    resizeObserver.observe(imageRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  // divice의 위치를 찍어줄 함수
  const getDevicePoint = (overlay: { x: number; y: number }) => {
    const x = overlay.x * parentWidth;
    const y = overlay.y * parentHeight;

    return { x, y };
  };

  return (
    <div className="bg-ef-primary-50 w-full h-full relative">
      <EfcModal efcId={selectedEfc} isToggle={toggleEfcModal} setIsToggle={setToggleEfcModal} />
      <EfgModal efgId={selectedEfg} isToggle={toggleEfgModal} setIsToggle={setToggleEfgModal} />
      <div className="absolute h-full">
        <GridSvg />
      </div>
      <div className="w-full h-[1080px] pt-60pxr px-80pxr pb-40pxr flex flex-col relative">
        <div className="flex flex-row items-center">
          <NavFilters piggeryId={typeof id !== "undefined" ? Number(id) : 0} />
          <div className="rounded-[8px] ef-main-shadow bg-ef-neutral-white border border-solid border-ef-neutral-150 min-w-[224px] w-fit ml-auto p-12pxr flex flex-col gap-8pxr h-fit ef-body-sm text-ef-neutral-700">
            <div className="flex flex-row items-center gap-4pxr">
              <span>{t("status.stocks_in_piggery")}</span>
              <span className="text-ef-primary-500 ef-body-md">{`${roomData ? roomData.summary.today_stock.toLocaleString() : 0}${t("common.head")}`}</span>
            </div>
            <div className="w-full h-1pxr bg-ef-neutral-200"></div>
            <div>{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</div>
          </div>
          {/* <button onClick={handleClick} type="button" className={"ef-btn-map-light ml-auto " + (anchorEl ? "!text-ef-neutral-white !bg-ef-primary-500 !stroke-ef-neutral-white" : "")}>
            <Filter />
            {t("status.filter")}
          </button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPaper-root": {
                boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08) !important",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid #DEDEDE",
                marginTop: "4px",
                minWidth: "270px",
              },
            }}
          >
            <div className="p-16pxr flex flex-col ef-label-md">
              <div className="flex flex-row items-center">
                <span className="ef-title-md">{t("status.device")}</span>
                <button onClick={() => handleFilterReset()} type="button" className="text-ef-secondary-500 ml-auto cursor-pointer ef-label-md">
                  {t("common.reset")}
                </button>
              </div>
              <div className="mt-8pxr mb-12pxr w-full h-1pxr bg-ef-neutral-150"></div>
              <div className="flex flex-col gap-8pxr">
                <input type="checkbox" id="efc" checked={selectedFilter.includes("efc")} onChange={handleCheckboxChange} />
                <label htmlFor="efc" className="!pl-32pxr h-24pxr flex items-center">
                  {t("status.efc_camera")}
                </label>
                <input type="checkbox" id="efg" checked={selectedFilter.includes("efg")} onChange={handleCheckboxChange} />
                <label htmlFor="efg" className="!pl-32pxr h-24pxr flex items-center">
                  {t("status.efg_camera")}
                </label>
                <input type="checkbox" id="feeder" checked={selectedFilter.includes("feeder")} onChange={handleCheckboxChange} />
                <label htmlFor="feeder" className="!pl-32pxr h-24pxr flex items-center">
                  {t("common.feeder")}
                </label>
                <input type="checkbox" id="sensor" checked={selectedFilter.includes("sensor")} onChange={handleCheckboxChange} />
                <label htmlFor="sensor" className="!pl-32pxr h-24pxr flex items-center">
                  {t("common.environmental_sensor")}
                </label>
              </div>
            </div>
          </Popover> */}
        </div>
        <div className="mt-66pxr flex flex-row w-full items-center">
          <BackHomeBtn piggeryName={roomData ? roomData.name : ""} />
          <div className="flex flex-row gap-8pxr ml-auto ef-label-md text-ef-neutral-900">
            <div className="w-fit h-fit rounded-[8px] p-8pxr pr-12pxr flex flex-row gap-8pxr items-center bg-ef-neutral-white border border-solid border-ef-neutral-150">
              <EfcLegend />
              {t("status.efc_camera")}
            </div>
            <div className="w-fit h-fit rounded-[8px] p-8pxr pr-12pxr flex flex-row gap-8pxr items-center bg-ef-neutral-white border border-solid border-ef-neutral-150">
              <EfgLegend />
              {t("status.efg_camera")}
            </div>
            <div className="w-fit h-fit rounded-[8px] p-8pxr pr-12pxr flex flex-row gap-8pxr items-center bg-ef-neutral-white border border-solid border-ef-neutral-150">
              <FeederLegend />
              {t("common.feeder")}
            </div>
            <div className="w-fit h-fit rounded-[8px] p-8pxr pr-12pxr flex flex-row gap-8pxr items-center bg-ef-neutral-white border border-solid border-ef-neutral-150">
              <SensorLegend />
              {t("common.environmental_sensor")}
            </div>
          </div>
        </div>
        <div className="w-full grow flex justify-center relative mt-55pxr">
          {dataLoading && <ComponentSpinner />}
          {/* map the rooms */}
          <div ref={imageRef} className="w-fit h-fit relative">
            <img src={roomData && roomData.image_path !== null ? roomData.image_path : ""} alt="" />
            {roomData?.rooms
              .filter((o) => o.overlay.length > 0)
              .map((r) =>
                r.category.id === 1 ? (
                  <div
                    key={r.id}
                    style={{
                      left: getPoints(r.overlay).x1,
                      top: getPoints(r.overlay).y1,
                      width: getPoints(r.overlay).width,
                      height: getPoints(r.overlay).height,
                    }}
                    className="absolute flex flex-col"
                  >
                    <div className="h-32pxr max-h-32pxr px-4pxr py-2pxr flex items-center justify-center ef-label-sm text-ef-neutral-white bg-ef-primary-500">
                      <p title={r.name} className="w-full max-w-[100%] break-all line-clamp-1 text-center">
                        {r.name}
                      </p>
                    </div>
                    <div className="flex grow w-full bg-ef-primary-500 group-hover:bg-ef-secondary-500 group-hover:bg-opacity-30  bg-opacity-30 px-4pxr flex-col gap-4pxr items-center justify-center text-ef-primary-500 ef-body-xs"></div>
                  </div>
                ) : (
                  <div
                    style={{ left: getPoints(r.overlay).x1, top: getPoints(r.overlay).y1 }}
                    className="absolute bg-ef-neutral-50 ef-label-sm border border-solid border-ef-neutral-150 flex flex-row w-fit h-fit gap-4pxr p-8pxr pr-12pxr items-center text-ef-neutral-700 stroke-ef-neutral-700 rounded-[8px]"
                  >
                    <Entrance />
                    <span>{r.name}</span>
                  </div>
                ),
              )}
            {/* 여기서부터 장치 point map */}
            {selectedFilter.includes("efc") && (
              <>
                {/* EFCR hover+deivce point */}
                {roomData?.rooms
                  .filter((o) => o.efcr.length > 0)
                  .map((r) =>
                    r.efcr.map((cr) =>
                      typeof cr.location.x !== "undefined" && typeof cr.location.y !== "undefined" ? (
                        <HtmlTooltip
                          disableInteractive
                          key={cr.id}
                          title={
                            <div className="flex flex-col text-ef-neutral-900 ef-body-sm">
                              <p className="ef-label-lg text-center">{cr.name}</p>
                              <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                              <div className="flex flex-col gap-8pxr">
                                <div className="flex flex-row gap-8pxr">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.image")}</div>
                                  <div className="w-158pxr aspect-video relative">
                                    <div className="absolute top-0pxr left-0pxr w-full h-full flex flex-col justify-center items-center bg-ef-neutral-50">
                                      <img src={NoDataImg} alt="no-data" className="w-87pxr" />
                                      <span className="text-ef-neutral-700">{t("common.no_data")}</span>
                                    </div>
                                    <img src={cr.image_path ? cr.image_path : ""} alt="efcr-img" className="absolute top-0pxr left-0pxr" onError={(e) => (e.currentTarget.hidden = true)} />
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.location")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{r.name}</div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_type")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {"EFCR " + t("manage.camera")}
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_connection_status")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {cr.last_access_at === null ? (
                                      t("common.no_info")
                                    ) : isBefore(new Date(cr.last_access_at), subHours(new Date(), 1)) ? (
                                      <span className="flex flex-row gap-4pxr items-center">
                                        {getAbnormal()}
                                        {t("common.abnormal")}
                                      </span>
                                    ) : (
                                      <span className="flex flex-row gap-4pxr items-center">
                                        {getNormal()}
                                        {t("common.normal")}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.device_connection_datetime")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {cr.last_access_at === null ? t("common.no_info") : format(new Date(cr.last_access_at), "yyyy-MM-dd HH:mm")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <div className="w-fit h-fit absolute transform -translate-x-1/2 -translate-y-1/2" style={{ left: getDevicePoint(cr.location).x, top: getDevicePoint(cr.location).y }}>
                            {cr.last_access_at !== null && isBefore(new Date(cr.last_access_at), subHours(new Date(), 1)) ? (
                              <Warning className="rounded-full hover:shadow-[0_0_10px_#F03E3E]" />
                            ) : (
                              <EFCIcon className="rounded-full hover:shadow-[0_0_10px_#37B24D]" />
                            )}
                          </div>
                        </HtmlTooltip>
                      ) : (
                        <></>
                      ),
                    ),
                  )}
                {/* EFC hover+points */}
                {roomData?.rooms
                  .filter((o) => o.efc.length > 0)
                  .map((r) =>
                    r.efc.map((c) =>
                      typeof c.location.x !== "undefined" && typeof c.location.y !== "undefined" ? (
                        <HtmlTooltip
                          disableInteractive
                          key={c.id}
                          title={
                            <div className="flex flex-col text-ef-neutral-900 ef-body-sm">
                              <p className="ef-label-lg text-center">{c.name}</p>
                              <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                              <div className="flex flex-col gap-8pxr">
                                <div className="flex flex-row gap-8pxr">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.image")}</div>
                                  <div className="w-158pxr aspect-video relative">
                                    <div className="absolute top-0pxr left-0pxr w-full h-full flex flex-col justify-center items-center bg-ef-neutral-50">
                                      <img src={NoDataImg} alt="no-data" className="w-87pxr" />
                                      <span className="text-ef-neutral-700">{t("common.no_data")}</span>
                                    </div>
                                    <img src={c.image_path ? c.image_path : ""} alt="efcr-img" className="absolute top-0pxr left-0pxr" onError={(e) => (e.currentTarget.hidden = true)} />
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.location")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{r.name}</div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_type")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {"EFC " + t("manage.camera")}
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_connection_status")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {c.last_access_at === null ? (
                                      t("common.no_info")
                                    ) : isBefore(new Date(c.last_access_at), subHours(new Date(), 1)) ? (
                                      <span className="flex flex-row gap-4pxr items-center">
                                        {getAbnormal()}
                                        {t("common.abnormal")}
                                      </span>
                                    ) : (
                                      <span className="flex flex-row gap-4pxr items-center">
                                        {getNormal()}
                                        {t("common.normal")}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="flex flex-row gap-8pxr items-center">
                                  <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.device_connection_datetime")}</div>
                                  <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                    {c.last_access_at === null ? t("common.no_info") : format(new Date(c.last_access_at), "yyyy-MM-dd HH:mm")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <div
                            onClick={() => {
                              setSelectedEfc(c.id);
                              setToggleEfcModal(true);
                            }}
                            className="w-fit h-fit absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                            style={{ left: getDevicePoint(c.location).x, top: getDevicePoint(c.location).y }}
                          >
                            {c.last_access_at !== null && isBefore(new Date(c.last_access_at), subHours(new Date(), 1)) ? (
                              <Warning className="rounded-full hover:shadow-[0_0_10px_#F03E3E]" />
                            ) : (
                              <EFCIcon className="rounded-full hover:shadow-[0_0_10px_#37B24D]" />
                            )}
                          </div>
                        </HtmlTooltip>
                      ) : (
                        <></>
                      ),
                    ),
                  )}
              </>
            )}
            {/* EFG hover+points */}
            {selectedFilter.includes("efg") &&
              roomData?.rooms
                .filter((o) => o.efg.length > 0)
                .map((r) =>
                  r.efg.map((g) =>
                    typeof g.location.x !== "undefined" && typeof g.location.y !== "undefined" ? (
                      <HtmlTooltip
                        disableInteractive
                        key={g.id}
                        title={
                          <div className="flex flex-col text-ef-neutral-900 ef-body-sm">
                            <p className="ef-label-lg text-center">{g.name}</p>
                            <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                            <div className="flex flex-col gap-8pxr">
                              <div className="flex flex-row gap-8pxr">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.image")}</div>
                                <div className="w-158pxr aspect-video relative">
                                  <div className="absolute top-0pxr left-0pxr w-full h-full flex flex-col justify-center items-center bg-ef-neutral-50">
                                    <img src={NoDataImg} alt="no-data" className="w-87pxr" />
                                    <span className="text-ef-neutral-700">{t("common.no_data")}</span>
                                  </div>
                                  <img src={g.image_path ? g.image_path : ""} alt="efcr-img" className="absolute top-0pxr left-0pxr" onError={(e) => (e.currentTarget.hidden = true)} />
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.location")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{r.name}</div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_type")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {t("status.efg_camera")}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_connection_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {g.last_access_at === null ? (
                                    t("common.no_info")
                                  ) : isBefore(new Date(g.last_access_at), subHours(new Date(), 1)) ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.abnormal")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.camera_contamination_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {g.pollution === null ? (
                                    t("common.no_info")
                                  ) : g.pollution === true ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.polluted")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.angle_changed_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {g.changed_angle === null ? (
                                    t("common.no_info")
                                  ) : g.changed_angle === true ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.abnormal")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.device_connection_datetime")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {g.last_access_at === null ? t("common.no_info") : format(new Date(g.last_access_at), "yyyy-MM-dd HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div
                          onClick={() => {
                            setSelectedEfg(g.id);
                            setToggleEfgModal(true);
                          }}
                          className="w-fit h-fit absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                          style={{ left: getDevicePoint(g.location).x, top: getDevicePoint(g.location).y }}
                        >
                          {(g.last_access_at !== null && isBefore(new Date(g.last_access_at), subHours(new Date(), 1))) || g.pollution === true || g.changed_angle === true ? (
                            <Warning className="rounded-full hover:shadow-[0_0_10px_#F03E3E]" />
                          ) : (
                            <EFGIcon className="rounded-full hover:shadow-[0_0_10px_#1C7ED6]" />
                          )}
                        </div>
                      </HtmlTooltip>
                    ) : (
                      <></>
                    ),
                  ),
                )}

            {selectedFilter.includes("feeder") &&
              roomData?.rooms
                .filter((o) => o.feeder.length > 0)
                .map((r) =>
                  r.feeder.map((f) =>
                    typeof f.location.x !== "undefined" && typeof f.location.y !== "undefined" ? (
                      <HtmlTooltip
                        disableInteractive
                        key={f.id}
                        title={
                          <div className="flex flex-col text-ef-neutral-900 ef-body-sm">
                            <p className="ef-label-lg text-center">{f.name}</p>
                            <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                            <div className="flex flex-col gap-8pxr">
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.room_name")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{r.name}</div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_type")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{t("common.feeder")}</div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.feeder_company")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{f.company.name}</div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_connection_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {f.connected === null ? (
                                    t("common.no_info")
                                  ) : f.connected === false ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.abnormal")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.device_connection_datetime")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {f.last_access_at === null ? t("common.no_info") : format(new Date(f.last_access_at), "yyyy-MM-dd HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className="w-fit h-fit absolute transform -translate-x-1/2 -translate-y-1/2" style={{ left: getDevicePoint(f.location).x, top: getDevicePoint(f.location).y }}>
                          {f.last_access_at !== null && isBefore(new Date(f.last_access_at), subHours(new Date(), 1)) ? (
                            <Warning className="rounded-full hover:shadow-[0_0_10px_#F03E3E]" />
                          ) : (
                            <FeederIcon className="rounded-full hover:shadow-[0_0_10px_#F59F00]" />
                          )}
                        </div>
                      </HtmlTooltip>
                    ) : (
                      <></>
                    ),
                  ),
                )}
            {selectedFilter.includes("sensor") &&
              roomData?.rooms
                .filter((o) => o.sensor.length > 0)
                .map((r) =>
                  r.sensor.map((s) =>
                    typeof s.location.x !== "undefined" && typeof s.location.y !== "undefined" ? (
                      <HtmlTooltip
                        disableInteractive
                        key={s.id}
                        title={
                          <div className="flex flex-col text-ef-neutral-900 ef-body-sm">
                            <p className="ef-label-lg text-center">{s.name}</p>
                            <div className="mt-8pxr mb-16pxr w-full h-1pxr bg-ef-neutral-150"></div>
                            <div className="flex flex-col gap-8pxr">
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.room_name")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">{r.name}</div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_type")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {t("common.environmental_sensor")}
                                </div>
                              </div>

                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.device_connection_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {s.connected === null ? (
                                    t("common.no_info")
                                  ) : s.connected === false ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.abnormal")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("common.breakdown_status")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {s.suspected_fault === null ? (
                                    t("common.no_info")
                                  ) : s.suspected_fault === true ? (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getAbnormal()}
                                      {t("common.suspected_breakdown")}
                                    </span>
                                  ) : (
                                    <span className="flex flex-row gap-4pxr items-center">
                                      {getNormal()}
                                      {t("common.normal")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row gap-8pxr items-center">
                                <div className="w-142pxr max-w-[142px] h-40pxr line-clamp-2 break-all ef-title-sm flex items-center">{t("status.device_connection_datetime")}</div>
                                <div className="w-227pxr max-w-[227px] px-8pxr h-40pxr border border-solid border-ef-neutral-200 flex items-center line-clamp-2 break-all">
                                  {s.last_access_at === null ? t("common.no_info") : format(new Date(s.last_access_at), "yyyy-MM-dd HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className="w-fit h-fit absolute transform -translate-x-1/2 -translate-y-1/2" style={{ left: getDevicePoint(s.location).x, top: getDevicePoint(s.location).y }}>
                          {s.connected === false || s.suspected_fault === true ? (
                            <Warning className="rounded-full hover:shadow-[0_0_10px_#F03E3E]" />
                          ) : (
                            <SensorIcon className="rounded-full hover:shadow-[0_0_10px_#7048E8]" />
                          )}
                        </div>
                      </HtmlTooltip>
                    ) : (
                      <></>
                    ),
                  ),
                )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceMap;
