import client from "query/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MovementCategoryType, MovementDetail, MovementList, MovementPayload, MovementSummary } from "./type";
import { useAuthState } from "context/AuthContext";

// get movement/category
export const useMovementCategories = () => {
  const { current_farm } = useAuthState();
  const movementCategoryAPI = () => client.get<MovementCategoryType[]>(`/movement/category`).then((res) => res.data);
  const query = useQuery("movementCategory", movementCategoryAPI, {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// post(add) new Report : 입식
export const useAddMovementReport = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("addMovementReport", (payload: MovementPayload) => client.post("/movement", payload).then((res) => res.data), {
    onSuccess() {
      queryClient.invalidateQueries("movementList");
      queryClient.invalidateQueries("movementSummary");
      queryClient.invalidateQueries(["herdList"]);
    },
  });
  return mutation;
};

// 이동기록 월별요약(캘린더용)
export const useMovementSummary = (target_month: string, categories: number[], piggeries: number[], rooms: number[], auto: number[], cameras: number[]) => {
  const { current_farm } = useAuthState();
  const params = {
    target_month,
    categories,
    piggeries,
    rooms,
    auto,
    cameras,
  };

  const query = useQuery(["movementSummary", params], () => client.get<MovementSummary[]>("/movement/summary", { params }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 리스트용
export const useMovementList = (target_date: string, categories: number[], piggeries: number[], rooms: number[], auto: number[], cameras: number[]) => {
  const { current_farm } = useAuthState();
  const params = {
    target_date,
    categories,
    piggeries,
    rooms,
    auto,
    cameras,
  };

  const query = useQuery(["movementList", params], () => client.get<MovementList[]>("/movement/list", { params }).then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

export const useDeathCauseList = () => {
  const { current_farm } = useAuthState();
  const query = useQuery(["deathCauseList"], () => client.get("/movement/deathcause").then((res) => res.data), {
    enabled: current_farm.id !== 0,
    onSuccess(data) {},
  });
  return query;
};

// 상세보고서
export const useMovementDetail = (id: number) => {
  const { current_farm } = useAuthState();
  const params = {
    id,
  };
  const query = useQuery(["movementDetail", params], () => client.get<MovementDetail>(`/movement/details/${params.id}`, { params }).then((res) => res.data), {
    enabled: current_farm.id !== 0 && id !== 0,
    onSuccess(data) {},
  });
  return query;
};

export const useDeleteMovement = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("deleteMovement", (id: number) => client.delete(`/movement/${id}`).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["movementList"]);
    },
  });
  return mutation;
};

// put(edit)
export const useEditMovement = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editMovement", (object: { payload: any; id: number }) => client.put(`/movement/${object.id}`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["movementList"]);
      queryClient.invalidateQueries(["movementDetail"]);
      queryClient.invalidateQueries(["movementSummary"]);
    },
  });
  return mutation;
};

export const useEditMemo = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation("editMovementMemo", (object: { payload: { memo: string }; id: number }) => client.put(`/movement/${object.id}/memo`, object.payload).then((res) => res.data), {
    onSuccess(data) {
      queryClient.invalidateQueries(["movementList"]);
      queryClient.invalidateQueries(["movementDetail"]);
    },
  });
  return mutation;
};

// 이동기록 보정
export const useEditReportCalibrate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    "editReportCalibrate",
    (object: { id: number; payload: { calibrated_stock?: number; calibrated_avg_weight?: number } }) => client.put(`/movement/${object.id}/calibrate`, object.payload).then((res) => res.data),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(["movementList"]);
        queryClient.invalidateQueries(["movementDetail"]);
        queryClient.invalidateQueries(["movementSummary"]);
      },
    },
  );
  return mutation;
};
