import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Download } from "assets/icons/report/ef-report-download.svg";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { useTranslation } from "react-i18next";
import StockRecordDatepicker from "components/pages/2_manage/stock/record/StockRecordDatepicker";
import { usePiggeries, useStockHistory } from "query/piggery";
import { useRoomDropdownList } from "query/rooms";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import "./recordTable.css";
import { format } from "date-fns";
import ComponentSpinner from "components/common/spinner/spinner";
import { ReactComponent as ArrowRight } from "assets/icons/general/ef-general-arrowRight.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/general/ef-general-arrowLeft.svg";
import _ from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";
import RecordHistoryModal from "components/pages/2_manage/stock/record/RecordHistoryModal";
import { useHerdHistoryCategory } from "query/herd";
import { useLocation } from "react-router-dom";

const ITEMS_PER_PAGE = 5;

function StockRecord() {
  const { state } = useLocation();

  // 다른페이지에서 받아온 날짜(현황 등)
  const passedRoom = state ? [state.roomId] : null;
  const passedPiggery = state ? state.piggeryId : null;

  const { t } = useTranslation();
  // 필터용 states
  const [filterDate, setFilterDate] = useState<"year" | "month">("month");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [selectedWorkType, setSelectedWorkType] = useState<number[]>([]);
  // 연도별용 room state
  const [selectedRoom, setSelectedRoom] = useState<number>(0);

  // 모달용
  const [selectedHistoryId, setSelectedHistoryId] = useState(0);
  const [toggleHistory, setToggleHistory] = useState(false);

  // 페이지네이션용
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  // 엑셀 다운로드용 테이블 명시
  const tableRef = useRef<HTMLTableElement | null>(null);

  // queries
  const { data: piggeryList } = usePiggeries();
  const { data: roomList } = useRoomDropdownList(selectedPiggery, [1]);
  const { data: categoryList } = useHerdHistoryCategory();
  const { data: recordData, isLoading: isRecordLoading } = useStockHistory(selectedPiggery, selectedRooms, format(selectedDate, "yyyy-MM"));
  const tableData = useMemo(() => {
    if (recordData) return recordData.rooms;
    else return [];
  }, [recordData]);

  // 1일당 최대 row를 구하는 함수
  const maxRowsPerDay = tableData.reduce((acc, room) => {
    room.period.forEach((period, dayIndex) => {
      if (acc[dayIndex]) {
        acc[dayIndex] = Math.max(acc[dayIndex], period.data.length);
      } else {
        acc[dayIndex] = period.data.length;
      }
    });
    return acc;
  }, [] as number[]);

  // 월별-년도별 radio handler
  const radioHandler = (e: any) => {
    setFilterDate(e.target.value);
  };

  // function for reset btn
  const handleReset = () => {
    setFilterDate("month");
    setSelectedDate(new Date());
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
    setSelectedRoom(0);
    setSelectedRooms([]);
    setSelectedWorkType([]);
  };

  // mount시 0번째 돈사 선택
  useEffect(() => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
  }, [piggeryList]);

  // 돈사 바꿀때마다 돈방 reset
  useEffect(() => {
    setSelectedRooms([]);
    if (roomList) {
      setSelectedRoom(roomList[0].id);
    }
  }, [selectedPiggery, roomList]);

  // 데이터에 따라 pagination 총 페이지 계산
  useEffect(() => {
    if (recordData) {
      setTotalPage(Math.ceil(recordData.rooms.length / ITEMS_PER_PAGE));
    }
  }, [recordData]);

  // 돈방을 선택해서 넘겨왔다면 선택처리
  useEffect(() => {
    if (passedPiggery) {
      setSelectedPiggery(passedPiggery);
    }
    if (passedRoom) {
      setSelectedRooms(passedRoom);
    }
  }, [selectedRoom]);

  // 현재 행이 페이지네이션 상 현재페이지에 해당되는지 확인
  const isCurrentRowVisible = (currentIdx: number) => {
    const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    return currentIdx >= startIdx && currentIdx < endIdx;
  };

  return (
    <div className="w-full h-full bg-neutral-50">
      <RecordHistoryModal isToggle={toggleHistory} setIsToggle={setToggleHistory} historyId={selectedHistoryId} />
      <div className="px-40pxr pt-52pxr">
        <div className="w-full h-full flex flex-col">
          {/* title and filter */}
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center gap-16pxr">
              <span className="ef-headline-lg">{t("gnb.stock_history")}</span>
              <DownloadTableExcel
                filename={
                  filterDate === "month"
                    ? `${format(selectedDate, "yyyy-MM")} ${piggeryList?.find((o) => o.id === selectedPiggery)?.name} ${t("gnb.stock_history")}`
                    : `${format(selectedDate, "yyyy")} ${roomList?.find((o) => o.id === selectedRoom)?.name} ${t("gnb.stock_history")}`
                }
                sheet=""
                currentTableRef={tableRef.current}
              >
                <button type="button" className="ef-btn-r8-sm flex flex-row gap-8pxr">
                  <Download />
                  {t("manage.download_excel")}
                </button>
              </DownloadTableExcel>
            </div>
            <div className="flex flex-row items-center ml-auto">
              <div className="flex flex-row gap-16pxr ef-label-md items-center min-h-[24px] h-24pxr mr-40pxr">
                {/* <input type="radio" name="range" id="year" value={"year"} checked={filterDate === "year"} onChange={radioHandler} />
                <label htmlFor="year" className="!pl-28pxr h-24pxr flex items-center">
                  연도별
                </label> */}
                <input type="radio" name="range" id="month" value={"month"} checked={filterDate === "month"} onChange={radioHandler} />
                <label htmlFor="month" className="!pl-28pxr h-24pxr flex items-center">
                  {t("manage.monthly")}
                </label>
              </div>
              <div className="flex flex-row gap-8pxr">
                <StockRecordDatepicker date={selectedDate} setDate={setSelectedDate} type={filterDate} dateFormat={filterDate === "year" ? "yyyy" : "yyyy-MM"} />
                <DropDownFilter
                  title={t("common.piggery")}
                  state={selectedPiggery}
                  setState={setSelectedPiggery}
                  options={
                    piggeryList
                      ? piggeryList.map((o) => {
                          return { value: o.id, name: o.name };
                        })
                      : []
                  }
                />
                {filterDate === "year" ? (
                  <DropDownFilter
                    title={t("common.room")}
                    state={selectedRoom}
                    setState={setSelectedRoom}
                    options={
                      roomList
                        ? roomList.map((o) => {
                            return { value: o.id, name: o.name };
                          })
                        : []
                    }
                  />
                ) : (
                  <DropDownCheckBox
                    title={t("common.room")}
                    state={selectedRooms}
                    setState={setSelectedRooms}
                    options={
                      roomList
                        ? roomList.map((o) => {
                            return { value: o.id, name: o.name };
                          })
                        : []
                    }
                  />
                )}
                <DropDownCheckBox
                  title={t("common.work_type")}
                  state={selectedWorkType}
                  setState={setSelectedWorkType}
                  options={
                    categoryList
                      ? categoryList.map((o) => {
                          return { value: o.id, name: t(`common.herd_history_${o.id}`) };
                        })
                      : []
                  }
                />
              </div>
              <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center ml-24pxr">
                <Refresh />
                {t("common.reset")}
              </button>
            </div>
          </div>
          <div className="w-full h-fit relative mt-52pxr pb-200pxr">
            {isRecordLoading && <ComponentSpinner />}
            <div className="w-full h-1pxr bg-ef-neutral-150"></div>
            <table ref={tableRef}>
              {/* 요약 */}
              <div className="w-full mt-24pxr py-32pxr px-40pxr bg-ef-neutral-white rounded-[8px] border border-solid border-ef-neutral-150 flex flex-col gap-16pxr">
                <p className="ef-title-lg line-clamp-1 break-all">{`[${recordData ? recordData.name : ""}] ${
                  selectedRooms.length === 0 || selectedRooms.length === roomList?.length ? t("manage.total") : tableData.map((o) => o.room_name).join(", ")
                } ${t("manage.inventory_summary")}`}</p>
                <table className="ef-table-toggle table-fixed !w-full">
                  <thead>
                    <tr>
                      {filterDate === "month" && <th>{t("manage.carryover_inventory")}</th>}
                      <th>{t("common.herd_history_1")}</th>
                      <th>{t("common.herd_history_4")}</th>
                      <th>{t("common.herd_history_2")}</th>
                      <th>{t("common.herd_history_3")}</th>
                      <th>{t("common.herd_history_5")}</th>
                      <th>{t("common.herd_history_6")}</th>
                      <th>{t("common.herd_history_7")}</th>
                      {filterDate === "month" && <th>{t("manage.current_month_inventory")}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {filterDate === "month" && <td className="ef-body-md text-ef-primary-500">{`${recordData ? recordData.summary.month_begin : "-"}${t("common.head")}`}</td>}
                      <td>{`${recordData ? recordData.summary.arrival : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.shipment : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.movein : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.moveout : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.death : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.culling : "-"}${t("common.head")}`}</td>
                      <td>{`${recordData ? recordData.summary.error : "-"}${t("common.head")}`}</td>
                      {filterDate === "month" && <td className="ef-body-md text-ef-primary-500">{`${recordData ? recordData.summary.month_end : "-"}${t("common.head")}`}</td>}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full mt-24pxr mb-16pxr flex">
                <div className="ml-auto flex flex-row gap-32pxr items-center">
                  <button
                    onClick={() => {
                      if (currentPage === 1) return;
                      else setCurrentPage(currentPage - 1);
                    }}
                    type="button"
                    className="ef-btn-calendar-arrow-sm"
                  >
                    <ArrowLeft />
                  </button>
                  <span className="flex flex-row ef-body-lg">
                    <span className="ef-body-xl text-ef-primary-500">{currentPage}</span>
                    {`/${totalPage}`}
                  </span>
                  <button
                    onClick={() => {
                      if (currentPage === totalPage) return;
                      else setCurrentPage(currentPage + 1);
                    }}
                    type="button"
                    className="ef-btn-calendar-arrow-sm"
                  >
                    <ArrowRight />
                  </button>
                </div>
              </div>
              {/* main */}
              <div className="w-full h-fit">
                <div className="w-full max-w-[1840px] overflow-clip py-2pxr px-1pxr">
                  <table id="record-table" className="ef-table-toggle">
                    <thead className="top-0pxr sticky">
                      <tr>
                        <th rowSpan={2} className="border-divide w-98pxr min-w-[98px] max-w-[98px] bg-ef-neutral-100">
                          {t("manage.division")}
                        </th>
                        {tableData.map((d, idx) => (
                          <th
                            style={{
                              display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                            }}
                            key={d.room_name}
                            colSpan={6}
                            className="w-348pxr min-w-[348px] max-w-[348px]"
                          >
                            {d.room_name}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <th
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              title={t("common.work_type")}
                              colSpan={2}
                              className="min-w-[116px] max-w-[116px]"
                            >
                              {t("common.work_type")}
                            </th>
                            <th
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              title={t("common.moved_stock")}
                              colSpan={2}
                              className="min-w-[116px] max-w-[116px]"
                            >
                              {t("common.moved_stock")}
                            </th>
                            <th
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              title={t("common.number_of_stocks")}
                              colSpan={2}
                              className="min-w-[116px] max-w-[116px]"
                            >
                              {t("common.number_of_stocks")}
                            </th>
                          </React.Fragment>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {maxRowsPerDay.map((maxRows, dayIndex) => {
                        return Array.from({ length: maxRows }).map((_, rowIndex) => {
                          return (
                            <tr key={`${dayIndex}-${rowIndex}`}>
                              {/* 날짜 열 */}
                              {rowIndex === 0 && (
                                <td rowSpan={maxRows} className="border-divide bg-ef-neutral-100">
                                  {t("manage.day", { day: dayIndex + 1 })}
                                </td>
                              )}
                              {tableData.map((room, roomIndex) => {
                                console.log(roomIndex);
                                const rowData = room.period[dayIndex]?.data[rowIndex];

                                // 비어있는 데이터만큼 얼만큼 rowSpan할지 계산
                                let emptyData = 0;
                                for (let i = 0; i < maxRows; i++) {
                                  if (typeof room.period[dayIndex]?.data[i] === "undefined") {
                                    emptyData++;
                                  }
                                }

                                const rowSpanAmt = emptyData + 1;

                                return rowData ? (
                                  <React.Fragment key={roomIndex}>
                                    <td
                                      // 최대 행 - Rowspan해야할 수 해서 현재 행이라면 rowSpan
                                      rowSpan={maxRows - rowSpanAmt === rowIndex ? rowSpanAmt : 1}
                                      onClick={() => {
                                        if (rowData.category !== null) {
                                          setSelectedHistoryId(rowData.id ? rowData.id : 0);
                                          setToggleHistory(true);
                                        }
                                      }}
                                      style={{
                                        backgroundColor: rowData.category && selectedWorkType.includes(rowData.category) ? "#F5FAFF" : "",
                                        cursor: rowData.category !== null ? "pointer" : "default",
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      className="underline text-ef-primary-500"
                                      colSpan={2}
                                      key={`${roomIndex}-category`}
                                    >
                                      {rowData.category !== null ? t(`common.herd_history_${rowData.category}`) : ""}
                                    </td>
                                    <td
                                      rowSpan={maxRows - rowSpanAmt === rowIndex ? rowSpanAmt : 1}
                                      style={{
                                        backgroundColor: rowData.category && selectedWorkType.includes(rowData.category) ? "#F5FAFF" : "",
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      colSpan={2}
                                      key={`${roomIndex}-moved`}
                                    >
                                      {rowData.moved}
                                    </td>
                                    <td
                                      rowSpan={maxRows - rowSpanAmt === rowIndex ? rowSpanAmt : 1}
                                      style={{
                                        backgroundColor: rowData.category && selectedWorkType.includes(rowData.category) ? "#F5FAFF" : "",
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      colSpan={2}
                                      className="border-divide"
                                      key={`${roomIndex}-stock`}
                                    >
                                      {rowData.stock}
                                    </td>
                                  </React.Fragment>
                                ) : maxRows - rowSpanAmt === rowIndex + 1 ? (
                                  // 빈 데이터일 때, rowSpan 적용
                                  <React.Fragment key={roomIndex}>
                                    <td
                                      style={{
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      colSpan={2}
                                    />
                                    <td
                                      style={{
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      colSpan={2}
                                    />
                                    <td
                                      style={{
                                        display: isCurrentRowVisible(roomIndex) ? "table-cell" : "none",
                                      }}
                                      colSpan={2}
                                    />
                                  </React.Fragment>
                                ) : null;
                              })}
                            </tr>
                          );
                        });
                      })}
                      <tr className="[&>*]:!py-10pxr">
                        <td title={t("manage.inventory_summary")} rowSpan={8} className="border-divide bg-ef-neutral-200 !rounded-bl-[8px] min-w-[98px] max-w-[98px]">
                          {t("manage.inventory_summary")}
                        </td>
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              title={t("manage.carryover_inventory")}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[174px] max-w-[174px]"
                            >
                              {t("manage.carryover_inventory")}
                            </td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              title={t("manage.current_month_inventory")}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[174px] max-w-[174px] border-divide"
                            >
                              {t("manage.current_month_inventory")}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                            >{`${d.summary.month_begin}${t("common.head")}`}</td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                              className="border-divide"
                            >{`${d.summary.month_end}${t("common.head")}`}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px]"
                            >
                              {t("common.herd_history_1")}
                            </td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px]"
                            >
                              {t("common.herd_history_4")}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                            >{`${d.summary.arrival}${t("common.head")}`}</td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                            >{`${d.summary.shipment}${t("common.head")}`}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px]"
                            >
                              {t("common.herd_history_2")}
                            </td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px]"
                            >
                              {t("common.herd_history_3")}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                            >{`${d.summary.movein}${t("common.head")}`}</td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={3}
                            >{`${d.summary.moveout}${t("common.head")}`}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px]"
                            >
                              {t("common.herd_history_5")}
                            </td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px] border-divide"
                            >
                              {t("common.herd_history_6")}
                            </td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                              className="bg-ef-neutral-200 min-w-[116px] max-w-[116px] border-divide"
                            >
                              {t("common.herd_history_7")}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr className="[&>*]:!py-10pxr">
                        {tableData.map((d, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                            >{`${d.summary.death}${t("common.head")}`}</td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                            >{`${d.summary.culling}${t("common.head")}`}</td>
                            <td
                              style={{
                                display: isCurrentRowVisible(idx) ? "table-cell" : "none",
                              }}
                              colSpan={2}
                              className="border-divide"
                            >{`${d.summary.error}${t("common.head")}`}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockRecord;
