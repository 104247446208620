import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import HistoryCard from "./HistoryCard";
import InputFieldOnly from "components/common/input/InputFieldOnly";
import { useEditHerd, useHerdDetail } from "query/herd";
import { differenceInDays, format } from "date-fns";
import DropDownSmall from "components/common/dropdown/DropDownSmall";
// import { useBreedList, useGenderList } from "query/common";
import BtnSort from "components/common/button/BtnSort";
import { toast } from "react-toastify";
import GroupSingleDatePicker from "./GroupSingleDatePicker";

type Inputs = {
  nickname: string;
  birth_date: Date | null;
  // gender: number;
  // breed: number | null;
  avg_weight: string;
  arrival_date: string;
  memo: string | null;
};

function GroupDetailWindow() {
  const { id, edit } = useParams();
  const groupId = typeof id === "undefined" ? 0 : Number(id);
  const { t } = useTranslation();
  const [toggleSort, setToggleSort] = useState(true); // true=최신순, false=오래된순

  // const { data: genderList } = useGenderList();
  // const { data: breedList } = useBreedList();
  const { data: groupData, isLoading } = useHerdDetail(groupId);
  const { mutateAsync: editGroup } = useEditHerd();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const birth_date = watch("birth_date");

  const onSubmit = async (data: Inputs) => {
    const formattedData = {
      nickname: data.nickname === "" ? null : data.nickname,
      birth_date: data.birth_date === null ? null : format(data.birth_date, "yyyy-MM-dd"),
      arrival_date: data.arrival_date,
      memo: data?.memo === null ? null : data?.memo,
      // gender: data.gender,
      // breed: data.breed === 0 ? null : data.breed,
      // avg_weight: data.avg_weight === "" ? null : Number(data.avg_weight),
    };
    try {
      await editGroup({ payload: formattedData, id: groupId });
      toast.success(t("common.save_completed"));
      window.opener.postMessage({ type: "dataUpdated" }, "*");
    } catch (error: any) {
      if (error.response.status === 409) toast.error(t("common.already_use_comment"), { autoClose: 2000, hideProgressBar: true });
      console.log(error);
    }
  };

  const handleDeleteGroup = async () => {
    if (window.confirm(t("manage.delete_group_comment", { name: groupData?.name }))) {
      try {
        // await deleteGroup(groupId);
        window.opener.postMessage({ type: "dataDeleted" }, "*");
        window.close();
      } catch (error) {
        console.log(error);
      }
    } else return;
  };

  const getAgedDayFormat = () => {
    if (birth_date) return `yyyy-MM-dd (${differenceInDays(new Date(), birth_date)}${t("manage.age")})`;
  };

  useEffect(() => {
    if (groupData) {
      reset({
        nickname: groupData.nickname === null ? "" : groupData.nickname,
        birth_date: groupData.birth_date !== null ? new Date(groupData.birth_date) : null,
        memo: groupData?.memo === null ? null : groupData?.memo,
        // gender: groupData.gender,
        // breed: groupData.breed === null ? 0 : groupData.breed,
        // avg_weight: groupData.avg_weight !== null ? groupData.avg_weight.toFixed(2) : "",
        // total_weight: groupData.avg_weight !== null ? Math.floor(groupData.avg_weight * groupData.stock).toString() : "",
      });
    }
  }, [groupData]);

  return isLoading ? (
    <></>
  ) : (
    <div className="min-w-[1070px]">
      <div className="mx-auto w-fit h-fit bg-ef-neutral-white outline-none flex flex-col p-40pxr">
        {/* header */}
        <div className="flex relative w-full">
          <span className="flex mx-auto ef-neutral-900 ef-title-lg">{t("manage.group_detail")}</span>
        </div>
        {/* body */}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="my-60pxr flex flex-row h-552pxr flex-wrap gap-32pxr w-full">
            <div className="flex flex-col flex-wrap gap-24pxr ef-label-md text-ef-neutral-900">
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.group_name")}</label>
                <div className="w-340pxr">
                  <InputFieldOnly state={groupData?.name} setState={() => {}} disabled />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.nickname")}</label>
                <div className="w-340pxr">
                  <Controller
                    name="nickname"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("manage.enter_nickname_comment")} maxLength={20} disabled={Number(edit) === 0 ? true : false} />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{`${t("manage.birthdate")}(${t("manage.age")})`}</label>
                <div className="w-216pxr">
                  <Controller
                    name="birth_date"
                    control={control}
                    render={({ field }) => (
                      <GroupSingleDatePicker
                        disabled={Number(edit) === 0 ? true : false}
                        date={field.value}
                        setDate={field.onChange}
                        placeholder={`YYYY-MM-DD (-${t("manage.age")})`}
                        dateFormat={getAgedDayFormat()}
                        maxDate={groupData ? new Date(groupData.arrival_date) : new Date()}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("common.arrival_date")}</label>
                <div className="w-216pxr">
                  <Controller
                    name="arrival_date"
                    control={control}
                    render={({ field }) => (
                      <InputFieldOnly state={field.value} setState={field.onChange} placeholder={t("manage.enter_nickname_comment")} maxLength={20} disabled={Number(edit) === 0 ? true : false} />
                    )}
                  />

                  {/* <InputFieldOnly state={groupData?.arrival_date} setState={() => {}} disabled /> */}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.location")}</label>
                <div className="w-340pxr">{/* <InputFieldOnly state={groupData?.room ? `[${groupData?.piggery?.name}] ${groupData?.room?.name}` : "-"} setState={() => {}} disabled /> */}</div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.heads")}</label>
                <div className="flex flex-row items-center">
                  <div className="w-146pxr">
                    <InputFieldOnly state={groupData?.stock ? groupData.stock : "0"} setState={() => {}} disabled />
                  </div>
                  <span className="ml-4pxr">{t("common.head")}</span>
                </div>
              </div>
              {/* <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.gender")}</label>
                <div className="w-146pxr">
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <DropDownSmall
                        disabled={Number(edit) === 0 ? true : false}
                        state={field.value}
                        setState={field.onChange}
                        placeholder={t("common.not_selected")}
                        // options={
                        //   genderList
                        //     ? genderList.map((o) => {
                        //         return { value: o.id, name: t(`common.gender_${o.id}`) };
                        //       })
                        //     : []
                        // }
                        options={[]}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.breed")}</label>
                <div className="w-146pxr">
                  <Controller
                    name="breed"
                    control={control}
                    render={({ field }) => (
                      <DropDownSmall
                        disabled={Number(edit) === 0 ? true : false}
                        state={field.value}
                        setState={field.onChange}
                        // options={breedList ? [{ value: 0, name: t("manage.not_selected") }, ...breedList.map((o) => ({ value: o.id, name: o.name }))] : []}
                        options={[]}
                      />
                    )}
                  />
                </div>
              </div> */}
              <div className="flex flex-row items-center">
                <label className="w-77pxr mr-12pxr min-w-[77px] line-clamp-1">{t("manage.weight")}</label>
                <div className="flex flex-row gap-8pxr flex-wrap">
                  <div className="flex flex-row items-center">
                    {/* <div className="w-146pxr">
                      <Controller
                        name="avg_weight"
                        control={control}
                        render={({ field }) => (
                          <InputFieldOnly
                            state={field.value}
                            setState={(value) => {
                              if (groupData) {
                                if (groupData.stock === 0) setValue("total_weight", Math.floor(Number(value)).toString());
                                else if (groupData.stock !== 0) setValue("total_weight", Math.floor(Number(value) * Number(groupData.stock)).toString());
                                field.onChange(value);
                              }
                              if (value === "") setValue("total_weight", "");
                            }}
                            type="number"
                            placeholder={t("common.average_weight")}
                            regex={/^(?:[0-9]+(\.[0-9]{1,2})?)?$/}
                            disabled
                          />
                        )}
                      />
                    </div> */}
                    <span className="ml-4pxr">kg</span>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="w-146pxr">
                      {/* <Controller
                        name="total_weight"
                        control={control}
                        render={({ field }) => (
                          <InputFieldOnly
                            state={field.value}
                            setState={(value) => {
                              if (groupData) {
                                if (groupData.stock === 0) setValue("avg_weight", value);
                                else if (groupData.stock !== 0) setValue("avg_weight", (Number(value) / Number(groupData.stock)).toFixed(2));
                                field.onChange(value);
                              }
                              if (value === "") setValue("avg_weight", "");
                            }}
                            type="number"
                            placeholder={t("common.total_weight")}
                            regex={/^(?:[1-9]\d*|0)?$/}
                            disabled
                          />
                        )}
                      /> */}
                    </div>
                    <span className="ml-4pxr">kg</span>
                  </div>
                </div>
              </div>
            </div>
            {/* divider */}
            <div className="h-full border-l border-solid border-ef-neutral-150"></div>
            <div className="h-full w-470pxr flex flex-col">
              <div className="w-full flex flex-row items-center">
                <span className="ef-title-md text-ef-neutral-900">{t("manage.history")}</span>
                <BtnSort sortState={toggleSort} setSortState={setToggleSort} />
              </div>
              {/* history section */}
              <div className="mt-16pxr grow overflow-hidden w-full bg-ef-neutral-white border border-solid border-ef-neutral-150 rounded-[8px]">
                <div className="w-full h-full overflow-y-auto scrollbar-hide">
                  {groupData?.room && (
                    <div className="flex flex-col flex-wrap gap-4pxr p-24pxr">
                      {!toggleSort
                        ? groupData.historys?.map((o, i) => (
                            <HistoryCard
                              key={i}
                              category={Number(o?.id)}
                              roomData={groupData?.room!}
                              historyInfo={o}
                              index={i}
                              dataLength={groupData.historys ? groupData.historys.length : 0}
                              isEditable={Number(edit) === 1 ? true : false}
                              isActivate={groupData?.active_state}
                            />
                          ))
                        : Array.from(groupData.historys)
                            .reverse()
                            .map((o, i) => (
                              <HistoryCard
                                key={i}
                                category={Number(o?.id)}
                                roomData={groupData?.room!}
                                historyInfo={o}
                                index={i}
                                dataLength={groupData.historys ? groupData.historys.length : 0}
                                isEditable={Number(edit) === 1 ? true : false}
                                isActivate={groupData?.active_state}
                              />
                            ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {Number(edit) === 1 ? (
            <div className="flex flex-row flex-wrap gap-16pxr mx-auto">
              {/* {groupData?.group_history_list.length === 1 ? (
                <>
                  <button className="ef-btn-modal-delete !w-239pxr" onClick={handleDeleteGroup}>
                    {t("common.delete")}
                  </button>
                  <button type="submit" className="ef-btn-modal !w-239pxr">
                    {t("common.save")}
                  </button>
                </>
              ) : (
                <>*/}
              <button type="submit" className="ef-btn-modal">
                {t("common.save")}
              </button>
              {/* </>
              )} */}
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
}

export default GroupDetailWindow;
