import React, { useState } from "react";
import { ReactComponent as Add } from "assets/icons/general/ef-general-add.svg";
import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import { useTranslation } from "react-i18next";
import { useEnvSensorList } from "query/sensor";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import SensorDetail from "components/pages/2_manage/device/sensor/SensorDetail";
import { format } from "date-fns";
import { usePiggeries } from "query/piggery";
import NoImg from "assets/images/report/no_report_data.png";

function Temperature() {
  const { t } = useTranslation();
  const [detailToggle, setDetailToggle] = useState(false);
  const [sensorId, setSensorId] = useState(0);

  // state for filters
  const [deviceStatus, setDeviceStatus] = useState<(boolean | string)[]>([]);
  const [selectedPiggery, setSelectedPiggery] = useState<number[]>([]);

  // query for filter list
  const { data: piggeryList } = usePiggeries();

  // query for sensor list
  const { data: sensorList } = useEnvSensorList(selectedPiggery, deviceStatus);

  // function for reste btn
  const handleReset = () => {
    setDeviceStatus([]);
    setSelectedPiggery([]);
  };

  return (
    <div className="w-full h-full bg-ef-neutral-50">
      <SensorDetail isToggle={detailToggle} setIsToggle={setDetailToggle} deviceId={sensorId} />
      <div className="w-[948px] min-w-[948px] mx-auto h-fit pt-60pxr pb-200pxr">
        <header className="flex flex-row gap-16pxr items-center">
          <div className="flex flex-row gap-4pxr items-center ef-headline-lg">
            <span className="text-ef-neutral-900">{`${t("gnb.environmental_sensor")} ${t("manage.list")}`}</span>
            <span className="text-ef-neutral-400">{`(${sensorList ? sensorList.length : 0})`}</span>
          </div>
          <button
            onClick={() => {
              if (window.confirm(t("manage.add_device_comment"))) {
                window.open("https://intflow.ai/contact/", "_blank");
              } else return;
            }}
            type="button"
            className="ef-btn-r8-sm flex flex-row gap-8pxr"
          >
            <Add />
            {t("manage.add_device")}
          </button>
          <span className="ml-auto text-ef-neutral-500 ef-body-sm">{`${t("common.last_updated")} : ${format(new Date(), "MM-dd HH:mm")}`}</span>
        </header>
        {/* filter starts here */}
        <section className="flex flex-row gap-24pxr mt-40pxr">
          <div className="flex flex-row gap-8pxr">
            <DropDownCheckBox
              title={t("manage.device_status")}
              state={deviceStatus}
              setState={setDeviceStatus}
              options={[
                { value: true, name: t("common.normal") },
                { value: false, name: t("common.abnormal") },
                { value: "fault", name: t("common.suspected_breakdown") },
              ]}
            />
            <DropDownCheckBox
              title={t("common.piggery")}
              state={selectedPiggery}
              setState={setSelectedPiggery}
              options={
                piggeryList
                  ? piggeryList.map((o) => {
                      return { value: o.id, name: o.name };
                    })
                  : []
              }
            />
          </div>
          <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center">
            <Refresh />
            {t("common.reset")}
          </button>
        </section>
        {/* divider */}
        <div className="w-full h-1pxr bg-ef-neutral-200 my-32pxr"></div>
        {sensorList && (
          <>
            {sensorList.length === 0 ? (
              <div className="mt-80pxr flex flex-col gap-8pxr w-fit mx-auto">
                <img src={NoImg} alt="no-device" />
                <p className="ef-body-xxl text-ef-neutral-600 mx-auto">{t("manage.there_is_no_device_registered")}</p>
              </div>
            ) : (
              <div className="flex flex-col gap-16pxr">
                {sensorList.map((o) => (
                  <div
                    key={o.id}
                    onClick={() => {
                      setSensorId(o.id);
                      setDetailToggle(true);
                    }}
                    className="w-full border border-solid border-ef-neutral-150 bg-ef-neutral-white rounded-[8px] ef-body-xl text-ef-neutral-900 flex flex-row cursor-pointer"
                  >
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("manage.device_name")}</p>
                      <p>{o.name}</p>
                    </div>
                    <div className="w-176pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("manage.installed_location")}</p>
                      <p>{`[${o.room.piggery.name}] ${o.room.name}`}</p>
                    </div>
                    <div className="w-142pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("common.device_connection_status")}</p>
                      <div className="flex flex-row items-center gap-4pxr">
                        <div
                          style={{
                            backgroundColor: o.connected ? "#37B24D" : "#F03E3E",
                          }}
                          className="rounded-full w-6pxr h-6pxr min-w-[6px]"
                        ></div>
                        <span>{o.connected ? t("common.normal") : t("common.abnormal")}</span>
                      </div>
                    </div>
                    <div className="w-142pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("common.breakdown_status")}</p>
                      <div className="flex flex-row items-center gap-4pxr">
                        <div
                          style={{
                            backgroundColor: o.suspected_fault ? "#F03E3E" : "#37B24D",
                          }}
                          className="rounded-full w-6pxr h-6pxr min-w-[6px]"
                        ></div>
                        <span>{o.suspected_fault ? t("common.suspected_breakdown") : t("common.normal")}</span>
                      </div>
                    </div>
                    <div className="w-226pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">{t("manage.serial_number")}</p>
                      <p>{o.serial_number}</p>
                    </div>
                    <div className="w-86pxr py-26pxr pl-24pxr pr-16pxr flex flex-col gap-4pxr">
                      <p className="ef-label-sm text-ef-primary-500">ID</p>
                      <p>{o.id}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Temperature;
