import DropDownCheckBox from "components/common/dropdown/DropDownCheckBox";
import DropDownFilter from "components/common/dropdown/DropDownFilter";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "assets/icons/general/ef-general-refresh.svg";
import { ReactComponent as AltArrowUp } from "assets/icons/general/ef-general-altArrowUp.svg";
import { ReactComponent as AltArrowDown } from "assets/icons/general/ef-general-altArrowDown.svg";
import { ReactComponent as Forward } from "assets/icons/general/ef-general-forward.svg";
import FeedLogDatePicker from "components/pages/2_manage/specification/feed/FeedLogDatePicker";
import { usePiggeries } from "query/piggery";
import { useRoomDropdownList } from "query/rooms";
import { useFeedLogHistory } from "query/feeder";
import { format, lastDayOfMonth, setHours, setMinutes } from "date-fns";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import FeedLogDetailModal from "components/pages/2_manage/specification/feed/FeedLogDetailModal";
import ComponentSpinner from "components/common/spinner/spinner";
import { FeedData } from "query/feeder/type";
import { datePickerLocale } from "utils/useDatepicker";
import NoData from "assets/images/report/no_report_data.png";

const tableRows: number[] = Array.from({ length: 31 }, (_, i) => i + 1);

// 정보없음 툴팁용 css
const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    fontSize: 14,
    padding: "12px 16px",
    fontFamily: "Pretendard",
    border: "1px solid #DEDEDE",
    borderRadius: 8,
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.35px",
    whiteSpace: "nowrap",
    width: "fit-content",
    maxWidth: "none",
  },
}));

// 기본 툴팁용 css
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#303030",
    boxShadow: "6px 6px 15px 0px rgba(14, 44, 74, 0.08)",
    maxWidth: 190,
    width: 190,
    border: "1px solid #DEDEDE",
    borderRadius: 8,
    padding: "16px",
    fontFamily: "Pretendard",
  },
}));

function FeedLog() {
  const { t } = useTranslation();

  // state for filters
  const [filterDate, setFilterDate] = useState("month");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPiggery, setSelectedPiggery] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);

  // state for toggle rooms in table
  const [toggledRooms, setToggledRooms] = useState<number[]>([]);

  // 급이 추가 모달용 state
  const [toggleModal, setToggleModal] = useState(false);
  const [modalProps, setModalProps] = useState<{ feederName: string; historyId: number | null; amount: number | null; selectedDate: Date; feederId: number }>({
    feederName: "",
    historyId: null,
    amount: null,
    selectedDate: new Date(),
    feederId: 0,
  });

  const { data: piggeryList } = usePiggeries();
  const { data: roomList } = useRoomDropdownList(selectedPiggery);
  const {
    data: feedLog,
    isLoading: isLogLoading,
    isError: isLogError,
  } = useFeedLogHistory({ piggery_id: selectedPiggery, rooms: selectedRooms, period: filterDate, target_date: format(selectedDate, "yyyy-MM-dd") });

  // mount시 0번째 돈사 선택
  useEffect(() => {
    if (piggeryList) {
      setSelectedPiggery(piggeryList[0].id);
    }
  }, [piggeryList]);

  // 돈사 바꿀때마다 돈방 reset
  useEffect(() => {
    setSelectedRooms([]);
  }, [selectedPiggery]);

  // 일별 시간대별 radio 조절
  const radioHandler = (e: any) => {
    setFilterDate(e.target.value);
  };

  // function for reset btn
  const handleReset = () => {
    setSelectedPiggery(piggeryList ? piggeryList[0].id : 0);
    setSelectedRooms([]);
    setFilterDate("month");
    setSelectedDate(new Date());
  };

  // table 방별 토글
  const handleToggleRoom = (roomId: number) => {
    setToggledRooms((prevToggledRooms) => {
      if (prevToggledRooms.includes(roomId)) {
        return prevToggledRooms.filter((id) => id !== roomId);
      } else {
        return [...prevToggledRooms, roomId];
      }
    });
  };

  // 테이블 데이터 로드
  const getTableCell = (isDeviceExist: boolean, data: number | null | undefined, index: number, feedData: FeedData, isSummury: boolean, feeder: { name: string; id: number }) => {
    const TooltipComponent = data === null ? LightTooltip : HtmlTooltip;
    let innerData;

    if (typeof data === "undefined") innerData = "-";
    else {
      if (!isDeviceExist) {
        innerData = (
          <>
            {t("manage.no_device")
              .split("\n")
              .map((line: any, index: number) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </>
        );
      } else {
        if (data === null) innerData = "-";
        else innerData = data;
      }
    }

    return (
      <React.Fragment key={index}>
        <TooltipComponent
          disableInteractive
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
          title={getTooltipContent(isDeviceExist, data, index, feedData)}
        >
          <td
            onClick={() => {
              if (filterDate === "month") return;
              else {
                if (isSummury) return;
                else {
                  const props = {
                    feederName: feeder.name,
                    historyId: feedData.id,
                    amount: typeof data === "undefined" ? null : data,
                    selectedDate: new Date(setHours(setMinutes(selectedDate, 0), index)),
                    feederId: feeder.id,
                  };
                  setModalProps(props);
                  setToggleModal(true);
                }
              }
            }}
            style={{
              cursor: filterDate === "month" || isSummury ? "default" : "pointer",
              padding: !isDeviceExist ? "0px 4px" : "14px 8px",
            }}
            className={"w-51pxr min-w-[51px] max-w-[51px] hover:text-ef-neutral-white hover:bg-ef-primary-500 " + (!isDeviceExist ? "text-ef-neutral-300" : "")}
          >
            {innerData}
          </td>
        </TooltipComponent>
      </React.Fragment>
    );
  };

  // 데이터당 툴팁 내용 로드
  const getTooltipContent = (isDeviceExist: boolean, data: number | null | undefined, index: number, feedData: FeedData) => {
    if (typeof data === "undefined") {
      return "";
    } else if (data !== null) {
      const herd = feedData?.herd;
      return (
        <div className="flex flex-col gap-8pxr">
          <div className="flex flex-col gap-4pxr">
            <p className="ef-label-sm text-ef-primary-500">{t("common.herd")}</p>
            <p className="ef-body-md">{herd ? (herd.nickname !== null ? herd.nickname : herd.name) : t("common.no_info")}</p>
          </div>
          <div className="flex flex-col gap-4pxr">
            <p className="ef-label-sm text-ef-primary-500">{t("common.heads")}</p>
            <p className="ef-body-md">{herd ? `${herd.stock}${t("common.head")}` : t("common.no_info")}</p>
          </div>
          <div className="flex flex-col gap-4pxr">
            <p className="ef-label-sm text-ef-primary-500">{t("common.birth_date_aged_day")}</p>
            <p className="ef-body-md">{herd ? `${herd.birth_date}(${herd.age}${t("common.aged")})` : t("common.no_info")}</p>
          </div>
          <div className="flex flex-col gap-4pxr">
            <p className="ef-label-sm text-ef-primary-500">{t("common.feeding_amount")}</p>
            <p className="ef-body-md">{data}kg</p>
          </div>
        </div>
      );
    } else {
      if (!isDeviceExist) {
        return t("common.no_device");
      } else {
        return t("common.no_info");
      }
    }
  };

  return (
    <div className="w-full h-full bg-ef-neutral-50 text-ef-neutral-900 relative">
      <FeedLogDetailModal isToggle={toggleModal} setIsToggle={setToggleModal} data={modalProps} />
      <div className="w-full h-fit pt-52pxr px-40pxr">
        <header className="flex flex-row items-center">
          <span className="ef-headline-lg">{t("gnb.feeding_records")}</span>
          <div className="flex flex-row ml-auto gap-16pxr items-center">
            <div className="flex flex-row gap-8pxr">
              <DropDownFilter
                title={t("common.piggery")}
                state={selectedPiggery}
                setState={setSelectedPiggery}
                options={
                  piggeryList
                    ? piggeryList.map((o) => {
                        return { value: o.id, name: o.name };
                      })
                    : []
                }
              />
              <DropDownCheckBox
                title={t("common.room")}
                state={selectedRooms}
                setState={setSelectedRooms}
                options={
                  roomList
                    ? roomList.map((o) => {
                        return { value: o.id, name: o.name };
                      })
                    : []
                }
              />
            </div>
            <div className="flex flex-row gap-16pxr ef-label-md items-center min-h-[24px] h-24pxr">
              <input type="radio" name="range" id="month" value={"month"} checked={filterDate === "month"} onChange={radioHandler} />
              <label htmlFor="month" className="!pl-28pxr h-24pxr flex items-center">
                {t("manage.daily")}
              </label>
              <input type="radio" name="range" id="day" value={"day"} checked={filterDate === "day"} onChange={radioHandler} />
              <label htmlFor="day" className="!pl-28pxr h-24pxr flex items-center">
                {t("manage.by_time")}
              </label>
            </div>
            <FeedLogDatePicker isMonth={filterDate === "month"} dateFormat={filterDate === "month" ? "yyyy-MM" : "yyyy-MM-dd"} date={selectedDate} setDate={setSelectedDate} />
            <button onClick={handleReset} type="button" className="ef-btn-reset flex flex-row gap-4pxr items-center ml-8pxr">
              <Refresh />
              {t("common.reset")}
            </button>
          </div>
        </header>
        <div className="w-full h-fit relative pb-200pxr">
          {isLogLoading && <ComponentSpinner />}
          <div className="w-full h-1pxr bg-ef-neutral-200 mt-52pxr mb-24pxr"></div>
          {isLogLoading ? (
            <div className="w-full h-300pxr"></div>
          ) : isLogError || (feedLog && feedLog.length === 0) ? (
            <div className="mt-96pxr flex flex-col items-center">
              <img src={NoData} alt="no-data-img" className="w-243pxr" />
              <p className="ef-body-xxl text-ef-neutral-600 mt-18pxr">{t("common.no_info")}</p>
            </div>
          ) : (
            <main className="flex flex-col w-fit mx-auto">
              <div className="flex flex-row ef-body-lg text-ef-neutral-700 items-center">
                {filterDate === "day" && <span>* {t("manage.edit_feeding_details_comment")}</span>}
                <span className="ml-auto">({t("manage.feeding_amount_unit")} : kg)</span>
              </div>
              <table className="ef-table-toggle mt-24pxr !w-full">
                <thead>
                  <tr>
                    <th rowSpan={2} className="min-w-[160px] w-160pxr"></th>
                    <th colSpan={filterDate === "month" ? 32 : 25}>{filterDate === "month" ? format(selectedDate, "MMM", { locale: datePickerLocale() }) : t("common.time")}</th>
                  </tr>
                  <tr>
                    {tableRows.slice(0, filterDate === "month" ? 31 : 24).map((o, i) => (
                      <th key={o} className="w-51pxr min-w-[51px]">
                        {filterDate === "month" ? (lastDayOfMonth(selectedDate).getDate() >= o ? o : "-") : i}
                      </th>
                    ))}
                    <th className="w-99pxr min-w-[99px] bg-ef-neutral-700 !ef-body-md text-ef-neutral-100">{t("common.total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {feedLog?.map((l) => (
                    <React.Fragment key={l.id}>
                      <tr>
                        <td
                          onClick={() => {
                            handleToggleRoom(l.id);
                          }}
                          className="cursor-pointer !pl-28pxr"
                        >
                          <div className="flex flex-row w-full items-center max-w-[144px]">
                            <span className="max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap">{l.name}</span>
                            {l.feeders.length !== 0 && (
                              <div className="ml-auto">{toggledRooms.includes(l.id) ? <AltArrowUp className="fill-ef-neutral-700" /> : <AltArrowDown className="fill-ef-neutral-700" />}</div>
                            )}
                          </div>
                        </td>
                        {tableRows
                          .slice(0, filterDate === "month" ? 31 : 24)
                          .map((p, i) => getTableCell(l.feeders.length !== 0, l.period[i]?.amount, i, l.feeders[0]?.period[i], true, { name: l.feeders[0]?.name, id: l.feeders[0]?.id }))}
                        <td>{l.total_amount}</td>
                      </tr>
                      {toggledRooms.includes(l.id) &&
                        l.feeders.map((f) => (
                          <tr key={f.id} className="bg-ef-neutral-50">
                            <td>
                              <div className="flex flex-row w-full items-center gap-4pxr">
                                <Forward className="stroke-ef-neutral-900" />
                                <span className="max-w-[116px] overflow-hidden text-ellipsis whitespace-nowrap">{f.name}</span>
                              </div>
                            </td>
                            {tableRows
                              .slice(0, filterDate === "month" ? 31 : 24)
                              .map((o, i) => getTableCell(l.feeders.length !== 0, f.period[i]?.amount, i, f.period[i], false, { name: f.name, id: f.id }))}
                            <td>{f.total_amount}</td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </main>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedLog;
